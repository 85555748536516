import classNames from 'classnames';
import { Button } from './Button';
import { PickerContext } from './PickerContext';

type Props = {
  children: React.ReactNode;
  value: string;
  className?: string;
};

export function SegmentedPicker({ children, value, className }: Props) {
  return (
    <PickerContext.Provider value={{ value }}>
      <nav
        className={classNames(
          'flex border border-outline-variant text-center font-normal text-copy-head',
          className
        )}
      >
        {children}
      </nav>
    </PickerContext.Provider>
  );
}

SegmentedPicker.Button = Button;
