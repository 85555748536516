import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VariantProps, cva } from 'class-variance-authority';
import classNames from 'classnames';
import { UseFormRegisterReturn } from 'react-hook-form';
import { HelperPopup } from '../../HelperPopup';

const switchStyles = cva(
  'relative inline-flex items-center shrink-0 rounded-full border-2 border-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 w-9 px-0.5 transition duration-200 ease-in-out',
  {
    variants: {
      checked: {
        true: 'bg-brand text-brand',
        false: 'bg-secondary text-secondary',
      },
    },
  }
);

type SwitchProps = {
  name?: string;
  checked: boolean; // Controls toggle state
  active?: boolean; // Controls opacity & interactivity
  label?: string;
  tooltip?: string; // Will display an icon with tooltip on hover next to the label
  help?: string; // Alternative to tooltip
  type?: 'filter' | 'field';
  className?: string;
  onChange: (checked: boolean) => void;
  register?: UseFormRegisterReturn;
} & VariantProps<typeof switchStyles> &
  Omit<React.HTMLProps<HTMLInputElement>, 'onChange'>;

export function Switch({
  className,
  label,
  name = label,
  checked,
  active = true,
  onChange,
  tooltip,
  help,
  register,
  type = 'field',
  ...props
}: SwitchProps) {
  const helpText = tooltip || help;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (active) {
      onChange(event.target.checked);
    }
  };

  // Get the name from register if it exists
  const inputName = register?.name || name;
  const inputId = register?.name || name;

  return (
    <div
      className={classNames(!active ? 'opacity-50' : '', 'flex items-center')}
    >
      <label
        htmlFor={inputId}
        className={classNames(
          'my-1 mr-3 flex items-center',
          type === 'filter' ? 'gap-2' : 'gap-3'
        )}
      >
        <div
          className={classNames(
            switchStyles({ checked }),
            className,
            active ? 'cursor-pointer' : 'cursor-not-allowed'
          )}
        >
          <input
            type='checkbox'
            className='hidden'
            name={inputName}
            id={inputId}
            checked={register ? undefined : checked && active}
            onChange={register ? register.onChange : handleChange}
            onBlur={register?.onBlur}
            ref={register ? register.ref : undefined}
            disabled={!active}
            {...props}
          />
          <span
            aria-hidden='true'
            className={classNames(
              'my-0.5 flex w-3.5 transform items-center justify-center rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out',
              checked ? 'translate-x-3.5' : 'translate-x-0'
            )}
          >
            <FontAwesomeIcon
              icon={checked ? faCheck : faXmark}
              className='my-0.5 h-2.5 w-2.5'
            />
          </span>
        </div>
        <span
          className={classNames(
            'pb-0.5',
            type === 'field' ? 'text-wrap' : 'truncate',
            !label && 'hidden'
          )}
        >
          {label}
        </span>
        {helpText && <HelperPopup>{helpText}</HelperPopup>}
      </label>
    </div>
  );
}
