import { AttributeFieldsFragment } from '~/components/sites/tabs/AttributesList';
import { FacetedFilterOption } from '~/components/ui/FacetedFilterSelect';
import { FragmentType, getFragmentData } from '~/gql';
import { IAttribute } from '~/types';
import { unique } from './array';

export function extractAttributes(
  attributes: Record<string, string> | IAttribute[] | undefined
) {
  if (attributes) {
    return Array.isArray(attributes)
      ? attributes.flatMap(({ name, value }) =>
          value.map((s) => `${name}: ${s}`)
        )
      : Object.keys(attributes).map((key) => `${key}: ${attributes[key]}`);
  }
  return [];
}

type ContainsAttributes = {
  attributes?: FragmentType<typeof AttributeFieldsFragment>[] | null;
};

export function extractAttributeFilterOptions(data: ContainsAttributes[]) {
  return (
    data?.reduce<FacetedFilterOption[]>((acc, item) => {
      const attributes = getFragmentData(
        AttributeFieldsFragment,
        item?.attributes
      );

      attributes?.forEach((attribute) => {
        if (attribute.type === 'attachment') {
          return;
        }

        const index = acc.findIndex(
          (opt: FacetedFilterOption) => opt.key === attribute.id
        );
        const value = attribute.value;

        if (index === -1) {
          const group = attribute.category;
          const label = attribute.name;
          acc.push({
            $group: group?.trimEnd(),
            key: attribute.id,
            label: label.trimStart(),
            value,
          });
        } else {
          acc[index].value = unique([...acc[index].value, ...value]);
        }
      });

      return acc;
    }, []) ?? []
  );
}
