import { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import Info from '../../icons/Info';

type Props = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
};

export const HelperPopup = ({ children, icon }: Props) => {
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [show, setShow] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 0],
        },
      },
      {
        name: 'flip',
        options: {
          padding: 0,
          fallbackPlacements: ['top', 'right'],
        },
      },
    ],
  });

  return (
    <>
      <span className='flex items-center' ref={setReferenceElement}>
        {icon ? (
          <div className='mr-2 inline-block'>
            <div
              onTouchEnd={() => setShow(true)}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              {icon}
            </div>
          </div>
        ) : (
          <Info
            width='44'
            height='44'
            className='inline-block cursor-pointer text-orange'
            onTouchEnd={() => setShow(true)}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          />
        )}
      </span>

      {show &&
        createPortal(
          <div
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex: 55 }}
            {...attributes.popper}
          >
            <div
              className='relative z-20 my-4 max-w-72 rounded-md bg-gray-900 px-4 py-2 text-xs font-medium text-white
              elevation-1 after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 
              after:border-x-transparent after:border-b-transparent after:border-t-gray-900'
            >
              {children}
            </div>
          </div>,
          document.querySelector('#tooltip')!
        )}
    </>
  );
};
