import classNames from 'classnames';
import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AppContext, useAppContext } from '~/App';
import { Image } from '~/components/ui/Image';
import { BottomSheet } from '~/components/ui/nucleus/Sheets';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useLocationHash } from '~/hooks/useLocationHash';
import {
  Group,
  SettingLink,
  SettingsSubpage,
  Tab,
  UserSettingsPopup,
} from '../ui';
import { AccountProfile } from './AccountProfile';
import { Integrations } from './Integrations';
import { Team } from './Team';
import { UpdateAccountPicForm } from './UpdateAccountPicForm';
export type PageProps = {
  page: SettingPage;
  showPage: Page;
  setShowPage: (page: Page) => void;
  setShowSettings: (show: boolean) => void;
  canManageAccount?: boolean;
};

export type Page =
  | null
  | 'organisation'
  | 'notifications'
  | 'team'
  | 'integrations'
  | 'profilePicture';

type SettingPage = {
  name: string;
  description: string;
  page: Page;
  Component: (props: PageProps) => JSX.Element;
};

type SettingLink = {
  name: string;
  description: string;
  linkTo: string;
};

type SettingGroup = {
  title: string;
  pages: (SettingPage | SettingLink)[];
};

const isLink = (page: SettingPage | SettingLink): page is SettingLink =>
  'linkTo' in page;

const settings: SettingGroup[] = [
  {
    title: 'settings.organisationAndUsersSettings',
    pages: [
      {
        name: 'Organisation Profile',
        description: `Manage your organisation details`,
        page: 'organisation',
        Component: AccountProfile,
      },
      {
        name: 'Team',
        description: '👥 Manage users and permissions',
        page: 'team',
        Component: Team,
      },
      {
        name: 'Integrations',
        description: '🔗 Connect external services',
        page: 'integrations',
        Component: Integrations,
      },
    ],
  },
  /* 
  TODO: Add these pages as the functionality is implemented
  {
    title: 'settings.preferencesAndCustomization',
    pages: [
      {
        name: 'Date & Time',
        description: 'Set time preferences',
        page: 'dateTime',
        Component: DateAndTimeForm,
      },
      {
        name: 'Notifications',
        description: 'Control alerts & messages',
        page: 'notifications',
        Component: Notifications,
      },
      {
        name: 'Features',
        description: '⚙️ Customize core platform functionalities, including Assets, Items, and Places ',
        page: 'features',
        Component: Features,
      },
    ],
  }, */
];

export const AccountSettings = () => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const { isMobile } = useBreakpoint();
  const [showSettings, setShowSettings] = useLocationHash('#org-settings-open');
  const [showPage, setShowPage] = useState<Page>(null);
  const { tenant } = useContext(AppContext);

  const appContext = useAppContext();

  // Define a proper type for the expected structure
  type AppContextWithRules = {
    rules?: Array<{ subject: string; action: string }>;
  };

  // Find the rule with subject "Account" using the defined type
  const contextWithRules = appContext as AppContextWithRules;
  const accountRule = Array.isArray(contextWithRules.rules)
    ? contextWithRules.rules.find((rule) => rule.subject === 'Account')
    : undefined;

  // Check if user has read or manage permission
  const canReadAccount =
    accountRule?.action === 'read' || accountRule?.action === 'manage';
  const canManageAccount = accountRule?.action === 'manage';

  // Initialize pageProps with the canManageAccount value
  const pageProps = {
    backTo: () => setShowPage(null),
    showPage,
    setShowPage,
    canManageAccount, // Use the actual value from context
    setShowSettings,
  };

  const getPageProps = (page: SettingPage) => ({ page, ...pageProps });

  const settingsContent = (
    <div className='flex flex-col items-center p-6 pt-0 lg:p-10 lg:pt-0'>
      <div
        className='h-24 w-24'
        onClick={() => canManageAccount && setShowPage('profilePicture')}
      >
        <Image
          objectFit='contain'
          size='fit'
          url={tenant.image}
          className={classNames('rounded-sm', {
            'cursor-pointer': canManageAccount,
          })}
          alt={tenant.name}
        />
      </div>
      <SettingsSubpage
        title='Edit Profile Picture'
        isOpen={showPage === 'profilePicture' ? true : false}
        onClose={() => setShowSettings(false)}
        backTo={() => {
          setShowPage(null);
        }}
        form={
          <UpdateAccountPicForm
            page={{
              name: 'Edit Profile Picture',
              description: '',
              page: 'profilePicture',
              Component: UpdateAccountPicForm,
            }}
            showPage={showPage}
            setShowPage={setShowPage}
            setShowSettings={setShowSettings}
            canManageAccount={canManageAccount}
          />
        }
      />
      <p className='my-2 text-lg font-semibold text-primary'>{tenant.name}</p>

      {settings.map((group) => (
        <Fragment key={group.title}>
          <Group title={t(group.title)} />
          {group.pages.map((page) =>
            isLink(page) ? (
              <SettingLink
                key={page.name}
                {...page}
                setShowSettings={setShowSettings}
              />
            ) : (
              <Page
                key={page.name}
                {...getPageProps(page)}
                setShowSettings={setShowSettings}
              />
            )
          )}
        </Fragment>
      ))}
    </div>
  );

  // If we're in a bottom sheet (mobile), return content directly
  if (isMobile) {
    return (
      <>
        <BottomSheet
          isOpen={showSettings}
          onClose={() => setShowSettings(false)}
          padding='none'
        >
          {settingsContent}
        </BottomSheet>
      </>
    );
  }

  // If we're on desktop, wrap in the popup
  return (
    <>
      <UserSettingsPopup
        isOpen={showSettings}
        onClose={() => setShowSettings(false)}
      >
        {settingsContent}
      </UserSettingsPopup>
    </>
  );
};

function Page(props: PageProps) {
  const { page, showPage, setShowPage, setShowSettings } = props;

  return (
    <>
      <Tab
        title={page.name}
        description={page.description}
        onClick={() => setShowPage(page.page)}
      />
      <SettingsSubpage
        title={page.name}
        isOpen={showPage === page.page ? true : false}
        backTo={() => {
          setShowPage(null);
        }}
        onClose={() => {
          setShowPage(null);
          setShowSettings(false);
        }}
        form={<page.Component {...props} />}
      />
    </>
  );
}
