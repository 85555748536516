import { faCircleXmark, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faListCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaXmark } from 'react-icons/fa6';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';
import * as yup from 'yup';
import { useAppContext } from '~/App';
import { Prompt } from '~/components/Prompt';
import { Input } from '~/components/form/TextField';
import { CreateJobScope, JobStatus, TaskInput } from '~/generated/graphql';
import { getFragmentData, graphql } from '~/gql';
import {
  CreateJobFormQuery,
  CreateJobInput,
  CreateJobsMutation,
} from '~/gql/graphql';
import { ContactFields } from '~/graphql/fragment/ContactFields';
import { JobTemplateFields } from '~/graphql/fragment/JobTemplateFields';
import { remove, splice, unique } from '~/helpers/array';
import { notEmpty } from '~/helpers/filter';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useActivityListContext } from '~/routes/activity';
import { useScheduleDataContext } from '~/routes/calendar._index';
import { useContacts } from '~/routes/resources/contacts';
import { Option } from '~/types';
import { SideLayout } from '../../layouts/side/SideLayout';
import { FindAddContact } from '../FindAddContact';
import { FindAddSite } from '../FindAddSite';
import { useSites } from '../FindAddSite/FindAddSite';
import { TagCombobox, TagEntityType } from '../TagCombobox';
import { useTags } from '../TagCombobox/tags';
import { ActivityListItem_Activity } from '../activity/ActivityListItem';
import { AttributeSelect } from '../form/AttributeSelect';
import { TimePicker } from '../form/TimePicker';
import { TextArea } from '../form/textarea/Basic';
import { Accordion } from '../ui/Accordion';
import { Confirm } from '../ui/Confirm';
import { HelperPopup } from '../ui/HelperPopup';
import { JobsRepeat, RepeatValue, repeatToInput } from '../ui/JobsRepeat';
import { Label } from '../ui/Label';
import { Switch } from '../ui/nucleus/Switch';
import { Kind, toAttachmentInput } from '../upload2';
import { ControlledUpload, UploadResult } from '../upload2/Upload';
import { ManageTasks, UseTemplate } from './CreateJobForm/ManageTasks';
import { CustomTaskForm } from './CustomTaskForm';
import { useJob } from './JobView';
import { SaveJobActions, SubmitOptions } from './SaveJobActions';
import { getDefaultScheduledEndTime } from './getDefaultScheduledEndTime';
import { useTemplateManager } from './hooks/useTemplateManager';

export type LocationOptions = {
  Asset: Option[];
  Contact: Option[];
  Site: Option[];
  StockTransfer: Option[];
  Elsewhere: Option[];
};

const CreateJobsMutationDocument = graphql(`
  mutation CreateJobs($input: CreateJobInput!) {
    createJobs(input: $input) {
      id
      status
      name
      createdAt
      location {
        id
      }
      scheduledStartDate
      scheduledEndDate
    }
  }
`);

const CreateJobSchema = yup.object().shape({
  name: yup.string().required('Job Name is required'),
  scopeIds: yup
    .array()
    .of(yup.string())
    .min(1, 'At least one Place is required'),
  repeat: yup.object().shape({
    isRepeat: yup.boolean(),
    numOfRepeats: yup.string().when('isRepeat', {
      is: true,
      then: yup.string().required('Number of repeats is required'),
    }),
  }),
});

/**
 * Form values that differ from the input type
 */
type DivergentFormValues = {
  guestyCleaningStatus: boolean;
  repeat: RepeatValue;
  // The upload field value held by the form differs from the input type
  attachments: UploadResult[];
};
type FormValues = Omit<CreateJobInput, keyof DivergentFormValues> &
  DivergentFormValues;

/**
 * FIXME these props are only required by popup on site view, not the create jobs form which is actually a route component (/jobs/new)
 * These could be moved out using composition to avoid a swathe of conditional logic
 * We want to display the same form in a different context
 * @deprecated use Props instead
 */
type CreateJobProps = {
  /** @deprecated see FIXME above */
  onSuccess?: (data: CreateJobsMutation) => void;
};

type Props = {
  data: CreateJobFormQuery;
  /** If provided, set initial value and lock the control to this location */
  locationId?: string;
  /** Passed through to SideLayout.Head */
  onClose: () => void;
};

const informMultipleSitesSelected = (jobCount: number) => {
  toast.info(`Jobs will be created for ${jobCount} places.`, {
    theme: 'dark',
    className: 'bg-[#0D9F8F] text-white',
    hideProgressBar: true,
    icon: <FontAwesomeIcon icon={faLightbulb} />,
  });
};

export const CreateJobForm = ({
  data,
  onClose,
  onSuccess: popupOnSuccess,
  ...props
}: Props & CreateJobProps) => {
  const { ability } = useAppContext();
  const navigate = useNavigate();
  const { t } = useTranslation('job');
  const { activities } = useActivityListContext();
  const activitySearchId = useSearchParams()[0].get('activity');
  const activitySearchIds = activitySearchId?.split(',');
  const unmaskedActivities = getFragmentData(
    ActivityListItem_Activity,
    // @ts-ignore FIXME
    activities?.map((item) => item?.node).filter((item) => !!item) ?? []
  );

  /** Are we creating a new job from within another job? */
  const createFrom = useJob({ required: false });
  const createFromActivity = unmaskedActivities
    ?.filter((activity) => activitySearchIds?.includes(activity?.id))
    .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1));
  const createFromActivityMessage = createFromActivity
    ?.map((act) => act?.comment?.body || act?.task?.notes)
    .filter(Boolean)
    .join('\n\n');

  const createFromActivityAttachments = (): UploadResult[] | null => {
    const attachments = createFromActivity
      ?.map((att) => att?.task?.attachments || att?.comment?.attachments)
      .filter(notEmpty)
      .flat()
      .map((att) => ({
        id: att.id,
        kind: att.kind as Kind,
        name: att.originalFilename,
        preview: att.thumb,
      }));

    return attachments && attachments.length > 0 ? attachments : null;
  };

  const scheduleContext = useScheduleDataContext();
  const [, createJobs] = useMutation(CreateJobsMutationDocument);
  const [sites] = useSites();
  const [allTags, reload] = useTags(TagEntityType.Job);
  const { isMobile } = useBreakpoint();

  const [navigateTo, setNavigateTo] = useState<string>();
  useEffect(() => {
    if (navigateTo) navigate(navigateTo);
  }, [navigateTo, navigate]);

  const { taskState, showTask, setShowTask, updateTask, deleteTask } =
    useEditTasks();

  const contacts = useContacts();
  const { pathname } = useLocation();
  const pageName = pathname.split('/')[1];

  // unmask fragment data
  const dataContacts = getFragmentData(ContactFields, data.contacts);
  const currentUserContact = dataContacts.find(
    (contact) => contact.isCurrentUser
  );

  const initialScopeIds = useCallback(() => {
    if (createFrom?.job?.location) {
      return [createFrom.job.location.id];
    }
    if (createFromActivity) {
      return unique(
        createFromActivity.map((act) => act.job?.site?.id || '').filter(Boolean)
      );
    }
    if (props.locationId) {
      return [props.locationId];
    }
    return [];
  }, [createFrom, createFromActivity, props.locationId]);

  const initialValues: FormValues = {
    name: '',
    scope: CreateJobScope.Site,
    scopeIds: initialScopeIds(),
    fromTemplate: '',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    scheduledStartStrict: '1',
    scheduledStartDate: '',
    scheduledStartTime: '',
    scheduledEndStrict: '0',
    scheduledEndDate: '',
    scheduledEndTime: '',
    owner: currentUserContact ? [currentUserContact.id] : null,
    assignee: null,
    customer: null,
    enforceOrder: false,
    tasks: [],
    includeAttributes: null,
    jobAttributes: null,
    tags: [],
    notes: createFromActivityMessage ?? '',
    attachments: createFromActivityAttachments() ?? [],
    guestyCleaningStatus: false,
    repeat: {
      isRepeat: false,
      interval: '1',
      numOfRepeats: '',
      frequency: 'daily',
      repeatOnWeek: [],
      repeatOnMonth: '',
      repeatOnYear: '',
    },
  };

  // Custom loading state to make form submission busy up until the redirection is completed
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const form = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(CreateJobSchema),
    resetOptions: { keepDirtyValues: true },
  });
  const {
    formState: { isDirty, errors },
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
  } = form;

  const {
    applyTemplate,
    selectedTemplate,
    isTemplateModified,
    removeTemplate,
    resetInitialValues,
  } = useTemplateManager({
    form,
    taskState,
    isEditMode: false,
  });

  // Initialize the form and template tracking just once
  useEffect(() => {
    // Set a timeout to ensure form is fully initialized
    const timer = setTimeout(() => {
      resetInitialValues();
    }, 0);

    return () => clearTimeout(timer);
    // Run once on mount
  }, [resetInitialValues]);

  // Handle activity search ID changes separately
  useEffect(() => {
    if (activitySearchId) {
      reset(initialValues);

      // After form is reset, update template manager's tracking
      const timer = setTimeout(() => {
        resetInitialValues();
      }, 0);

      return () => clearTimeout(timer);
    }
    // Only run when activitySearchId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitySearchId, resetInitialValues]);

  const timeZone = watch('timeZone');
  const scope = watch('scope');
  const scopeIds = watch('scopeIds');
  const assignee = watch('assignee');
  const scheduledStartDate = watch('scheduledStartDate');
  const scheduledStartTime = watch('scheduledStartTime');
  const scheduledEndDate = watch('scheduledEndDate');
  const scheduledEndTime = watch('scheduledEndTime');
  const isJobRepeat = watch('repeat.isRepeat');
  const locationId = scopeIds.length
    ? [scope, scopeIds[0]].join(':')
    : undefined;

  const selectedLocations =
    scopeIds.length > 1 ? `${scopeIds.length} Selected` : null;
  const location =
    scopeIds.length === 1
      ? sites.find((site) => site.id === scopeIds[0])
      : null;
  const where = selectedLocations || location?.name;
  const who =
    assignee &&
    contacts
      .filter(({ id }) => assignee.includes(id))
      .map(({ name }) => name)
      .join(', ');
  const when = scheduledStartDate || scheduledEndDate ? 'Scheduled' : null;
  const guestyEnabled = data.integrations.includes('guesty');

  const isMultipleJobCreation = !!(scopeIds.length > 1 || isJobRepeat);
  const notifyDefault = !isMultipleJobCreation;

  const whereCardRef = useRef<HTMLDivElement>(null);
  const [openPanelIdx, setOpenPanelIdx] = useState<number>(() => {
    if (createFrom?.job?.location) return 1;
    if (createFromActivityMessage || createFromActivityAttachments()) return 3;
    return 0;
  });
  const [animateFieldId, setAnimateFieldId] = useState<string | null>(null);

  const handlePanelToggle = useCallback(
    async (panelIndex: number | undefined) => {
      // Handle the case when no panels are open (-1) or undefined
      const nextPanelIndex = panelIndex === undefined ? -1 : panelIndex;

      // If we're clicking on a different panel or closing the current panel
      if (nextPanelIndex !== openPanelIdx) {
        // Validate fields based on which panel is currently open
        switch (openPanelIdx) {
          case 0: // Where panel
            await form.trigger('scopeIds');
            break;
          case 2: // When panel
            if (watch('repeat.isRepeat')) {
              await form.trigger('repeat');
            }
            break;
        }
      }
      setOpenPanelIdx(nextPanelIndex);
      // Scroll to the panel if it's being opened
      if (panelIndex !== null && panelIndex !== undefined) {
        setTimeout(() => {
          const panels = document.querySelectorAll('.greycard');
          panels[panelIndex]?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 300);
      }
    },
    [openPanelIdx, form.trigger, watch]
  );

  const handleSuccessCreation = (jobId: string, status: string) => {
    if (pageName === 'activity') {
      setNavigateTo(
        status === JobStatus.Created
          ? `../jobs/${jobId}/edit`
          : `../jobs/${jobId}`
      );
    } else {
      setNavigateTo(
        status === JobStatus.Created ? `../${jobId}/edit` : `../${jobId}`
      );
    }

    toast.success('Job successfully created');
  };

  const submit = async (status: JobStatus, options: SubmitOptions = {}) => {
    await handleSubmit(
      async (data) => {
        setIsSubmitting(true);

        // Process tasks before submission to ensure attributes are preserved
        const processedTasks = taskState[0]
          .filter((task) => !task._destroy)
          .map((task) => {
            // Create a complete copy of the task with all properties
            let processedTask = { ...task };

            // Make sure attributes is included in the submission
            if (task.attributes) {
              processedTask.attributes = [...task.attributes];
            }

            // Ensure Attribute task configuration is properly formatted
            if (task.type === 'Attribute' && task.attribute?.attributes) {
              if (!processedTask.attribute) {
                processedTask.attribute = { attributes: [] };
              }
              processedTask.attribute.attributes = task.attribute.attributes;
            }

            return processedTask;
          });

        // Use the processed tasks array in your form data
        const formData = form.getValues();
        const res = await createJobs({
          input: {
            ...data,
            attachments: (
              createFromActivityAttachments() ?? data.attachments
            ).map(toAttachmentInput),
            scheduledStartDate: data.scheduledStartDate || null,
            scheduledStartTime: data.scheduledStartTime || null,
            scheduledEndDate: data.scheduledEndDate || null,
            scheduledEndTime: data.scheduledEndTime || null,
            tasks: processedTasks,
            status,
            notify: options.notify,
            comment: options.comment,
            sendMeACopy: options.sendMeACopy === true,
            includePdf: options.includePdf === true,
            guestyCleaningStatus: data.guestyCleaningStatus ? 'clean' : null,
            repeat: repeatToInput(data.repeat),
          },
        });
        if (res.error) {
          toast.error('Error: ' + res.error.message.replace('[GraphQL] ', ''));
          setIsSubmitting(false);
          return;
        }

        if (res.data) {
          // TODO too many conditionals... tidy up
          if (createFrom?.job) {
            // if (scheduleContext) {
            //   scheduleContext.reload();
            // }
            // if (onSuccess) {
            //   onSuccess(res.data);
            // }
            toast.success('Job saved successfully');
            setNavigateTo('..');
          } else if (scheduleContext) {
            if (res.data.createJobs.length > 1) {
              toast.success(
                `${res.data.createJobs.length} jobs successfully created`
              );
              setNavigateTo('..');
            } else {
              toast.success(`Job successfully created`);
              // Navigate clicking 'Save Draft' btn to edit form
              setNavigateTo(
                status === JobStatus.Created
                  ? `../${res.data.createJobs[0].id}/edit`
                  : `../${res.data.createJobs[0].id}`
              );
            }
          }
          // else if (onSuccess) {
          //   onSuccess(res.data);

          //   if (res.data.createJobs.length > 1) {
          //     toast.success(
          //       `${res.data.createJobs.length} jobs successfully created`
          //     );
          //     setNavigateTo('..');
          //   } else {
          //     toast.success(`Job successfully created`);
          //     // Navigate clicking 'Save Draft' btn to edit form
          //     setNavigateTo(
          //       status === JobStatus.Created
          //         ? `../${res.data.createJobs[0].id}/edit`
          //         : `../${res.data.createJobs[0].id}`
          //     );
          //   }
          // }
          else if (popupOnSuccess) {
            popupOnSuccess(res.data);
          } else {
            // default success handler
            setTimeout(() => resetInitialValues(), 0);
            handleSuccessCreation(res.data.createJobs[0].id, status);
          }
        }
      },
      (errors) => {
        let errorMessage = 'Please fill out all required fields';
        toast.warning(errorMessage, {
          theme: 'colored',
        });

        // Find the first panel with an error
        let firstErrorPanel = null;
        if (errors.scopeIds) {
          firstErrorPanel = 0; // WHERE panel
        } else if (errors.repeat?.numOfRepeats) {
          firstErrorPanel = 2; // WHEN panel
        } else if (errors.name) {
          firstErrorPanel = 3; // WHAT panel
        }

        if (firstErrorPanel !== null) {
          const currentPanelHasError =
            (errors.scopeIds && openPanelIdx === 0) ||
            (errors.repeat?.numOfRepeats && openPanelIdx === 2) ||
            (errors.name && openPanelIdx === 3);

          if (currentPanelHasError) {
            if (openPanelIdx === 0 && errors.scopeIds) {
              setAnimateFieldId('scopeIds');
            } else if (openPanelIdx === 2 && errors.repeat?.numOfRepeats) {
              setAnimateFieldId('repeat.numOfRepeats');
            } else if (openPanelIdx === 3 && errors.name) {
              setAnimateFieldId('name');
            }
            // Remove animation class after it completes
            setTimeout(() => setAnimateFieldId(null), 1820); // Animation duration + small buffer
          } else if (
            !currentPanelHasError &&
            firstErrorPanel !== openPanelIdx
          ) {
            // First close any open panel
            setOpenPanelIdx(-1);

            // Then open the error panel after a small delay
            setTimeout(() => {
              setOpenPanelIdx(firstErrorPanel);

              // Scroll after panel is opened
              setTimeout(() => {
                const panels = document.querySelectorAll('.greycard');
                panels[firstErrorPanel]?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }, 100);
            }, 50);
          }
        }
      }
    )();
  };

  const [task, setTask] = useState<TaskInput | null>(null);
  const [shownTaskType, setShownTaskType] = useState<string | null>(null);

  useEffect(() => {
    setTask(taskState[0].find((task) => task.id === showTask) || null);
    setShownTaskType(
      taskState[0].find((item) => item.id === showTask)?.type || null
    );
  }, [showTask, taskState]);

  const taskSection = task ? (
    <CustomTaskForm
      locationId={locationId}
      task={task}
      onApply={updateTask}
      onClose={() => setShowTask(null)}
      onDelete={deleteTask}
      scopeIds={scopeIds}
      taskType={shownTaskType}
      updateTaskType={(id, type) => handleUpdateTaskType(id, type)}
      showTaskOptions
    />
  ) : null;

  const templates = getFragmentData(JobTemplateFields, data.jobTemplates);
  const templateOptions: Option[] = templates.map(({ name: value }) => ({
    value,
    label: value,
  }));

  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

  // Memoize callback functions with useCallback
  const handleShowTask = useCallback(
    (id: string) => {
      setShowTask(id);
    },
    [setShowTask]
  );

  const handleUpdateTaskType = useCallback(
    (taskId: string, taskType: string) => {
      const [, setTasks] = taskState;
      const tasksListCopy = taskState[0].slice();
      const taskIndex = tasksListCopy.findIndex((task) => task.id === taskId);
      if (taskIndex < 0) return;
      tasksListCopy[taskIndex].type = taskType;
      setTasks(tasksListCopy);
    },
    [taskState]
  );

  const isNewTask = useCallback(
    (taskId: string) => {
      const [tasks] = taskState;
      return !tasks.some((task) => task.id === taskId);
    },
    [taskState]
  );

  return (
    <FormProvider {...form}>
      <div className='flex h-full items-stretch justify-end overflow-y-auto'>
        <SideLayout>
          <SideLayout.Head onClose={onClose}>Create Job</SideLayout.Head>
          <SideLayout.Body className='px-4 pb-96 lg:pb-44'>
            <Prompt when={!navigateTo && isDirty} cancel='Keep Editing' />

            <Accordion
              defaultOpen={openPanelIdx}
              onToggledPanel={handlePanelToggle}
              openPanel={openPanelIdx}
            >
              {!props.locationId && (
                <Accordion.GreyCard
                  ref={whereCardRef}
                  error={!!errors.scopeIds}
                >
                  <Accordion.Button
                    summary={where}
                    help={errors.scopeIds ? 'Place is required' : 'Place'}
                    error={!!errors.scopeIds}
                  >
                    Where
                  </Accordion.Button>
                  <Accordion.Panel>
                    {(next) =>
                      scope === CreateJobScope.Site && (
                        <div
                          className={classNames({
                            'animate-shake': animateFieldId === 'scopeIds',
                          })}
                        >
                          <Controller
                            name='scopeIds'
                            control={control}
                            render={({ field, fieldState }) => (
                              <FindAddSite
                                optionsBoxHeight={
                                  isMobile
                                    ? '!max-h-[calc(100vh-305px)]'
                                    : '!max-h-[calc(100vh-430px)]'
                                }
                                label={t('form.site')}
                                error={fieldState.error?.message}
                                value={field.value}
                                onChange={(selected) => {
                                  if (selected && selected.length > 1) {
                                    informMultipleSitesSelected(
                                      selected.length
                                    );
                                  }
                                  if (
                                    selected &&
                                    selected.length &&
                                    selected[0].id
                                  ) {
                                    field.onChange(
                                      selected.map(({ id }) => id)
                                    );
                                    //next()
                                    // Force validation to run
                                    setTimeout(
                                      () => form.trigger('scopeIds'),
                                      0
                                    );
                                    return;
                                  }
                                  field.onChange([]);
                                }}
                                multiple
                              />
                            )}
                          />
                        </div>
                      )
                    }
                  </Accordion.Panel>
                </Accordion.GreyCard>
              )}
              <Accordion.GreyCard>
                <Accordion.Button summary={who} help='Unassigned'>
                  Who
                </Accordion.Button>
                <Accordion.Panel>
                  <Controller
                    name='owner'
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <FindAddContact
                        controlClassName='bg-white'
                        optionsBoxHeight={
                          isMobile
                            ? 'max-h-[calc(100vh-285px)]'
                            : 'max-h-[calc(100vh-450px)]'
                        }
                        label={t('form.owner')}
                        // contacts={contacts.filter((c) =>
                        //   c.relationship?.includes(ContactRelationship.Staff)
                        // )}
                        error={error?.message}
                        value={
                          value
                            ? contacts.filter(({ id }) => value.includes(id))
                            : []
                        }
                        onChange={(newValue) => {
                          onChange(
                            newValue && newValue.length > 0
                              ? newValue.map(({ id }) => id)
                              : []
                          );
                        }}
                        isTeamMember
                        multiple
                        isAbsolute={false}
                      />
                    )}
                  />
                  <Controller
                    name='assignee'
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <FindAddContact
                        controlClassName='bg-white'
                        optionsBoxHeight={
                          isMobile
                            ? 'max-h-[calc(100vh-285px)]'
                            : 'max-h-[calc(100vh-450px)]'
                        }
                        label={t('form.assignee')}
                        // contacts={contacts}
                        error={error?.message}
                        value={
                          value?.length
                            ? contacts.filter(({ id }) => value.includes(id))
                            : []
                        }
                        onChange={(newValue) => {
                          if (newValue && newValue.length > 0) {
                            onChange(newValue.map(({ id }) => id));
                          } else {
                            onChange(null);
                          }
                        }}
                        multiple
                        isAbsolute={false}
                      />
                    )}
                  />
                  <Controller
                    name='include'
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <FindAddContact
                        controlClassName='bg-white'
                        optionsBoxHeight={
                          isMobile
                            ? 'max-h-[calc(100vh-285px)]'
                            : 'max-h-[calc(100vh-450px)]'
                        }
                        label={t('form.include')}
                        // contacts={contacts}
                        error={error?.message}
                        value={
                          value
                            ? contacts.filter(({ id }) => value.includes(id))
                            : []
                        }
                        onChange={(newValue) => {
                          onChange(
                            newValue && newValue.length > 0
                              ? newValue.map(({ id }) => id)
                              : []
                          );
                        }}
                        multiple
                        hasSelectAll
                        isAbsolute={false}
                      />
                    )}
                  />
                </Accordion.Panel>
              </Accordion.GreyCard>
              <Accordion.GreyCard error={!!errors.repeat?.numOfRepeats}>
                <Accordion.Button
                  summary={
                    errors.repeat?.numOfRepeats
                      ? 'Number of repeats is required'
                      : when
                  }
                  help={'Unscheduled'}
                  error={!!errors.repeat?.numOfRepeats}
                >
                  When
                </Accordion.Button>
                <Accordion.Panel>
                  <Accordion.PanelSection>
                    {/* <div className='mb-2.5 hidden gap-4'>
                      <label>
                        <input
                          {...register('scheduledStartStrict')}
                          type='radio'
                          value='1'
                        />{' '}
                        Start at
                      </label>
                      <label>
                        <input
                          {...register('scheduledStartStrict')}
                          type='radio'
                          value='0'
                        />
                        Start from
                      </label>
                    </div> */}
                    <div className='items flex flex-wrap pt-2'>
                      <div className='flex max-w-[340px] flex-1 items-stretch gap-3 pr-1 text-left md:mr-8'>
                        <Controller
                          control={control}
                          name='scheduledStartDate'
                          render={({ field: { value, onChange } }) => (
                            <Input
                              label={t('job:form.scheduleStart')}
                              type='date'
                              value={value}
                              onChange={(event) => {
                                onChange(event);
                                setValue('repeat.repeatOnMonth', '');
                              }}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name='scheduledStartTime'
                          render={({ field: { value, onChange } }) => (
                            <TimePicker
                              label='Time'
                              focusValue='09:00'
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                        <button
                          className={classNames(
                            'pt-2.5',
                            !(scheduledStartDate || scheduledStartTime) &&
                              'pointer-events-none opacity-0'
                          )}
                          onClick={() => {
                            setValue('scheduledStartDate', '');
                            setValue('scheduledStartTime', '');
                          }}
                        >
                          <FontAwesomeIcon
                            className='h-5 w-5 text-brand transition-colors duration-300 hover:text-brand-darker'
                            icon={faCircleXmark}
                          />
                        </button>
                      </div>
                      {/* <div className='mb-2.5 hidden gap-4'>
                        <label>
                          <input
                            {...register('scheduledEndStrict')}
                            type='radio'
                            value='1'
                          />
                          End at
                        </label>
                        <label>
                          <input
                            {...register('scheduledEndStrict')}
                            type='radio'
                            value='0'
                          />
                          End before
                        </label>
                      </div> */}
                      <div className='flex max-w-[340px] flex-1 gap-3 pr-1 text-left'>
                        <Input
                          {...register('scheduledEndDate')}
                          label={t('job:form.scheduleEnd')}
                          type='date'
                        />
                        <Controller
                          control={control}
                          name='scheduledEndTime'
                          render={({ field: { value, onChange } }) => (
                            <TimePicker
                              label='Time'
                              focusValue={getDefaultScheduledEndTime(
                                watch('scheduledStartTime')
                              )}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                        <button
                          className={classNames(
                            'pt-2.5',
                            !(scheduledEndDate || scheduledEndTime) &&
                              'pointer-events-none opacity-0'
                          )}
                          onClick={() => {
                            setValue('scheduledEndDate', '');
                            setValue('scheduledEndTime', '');
                          }}
                        >
                          <FontAwesomeIcon
                            className='h-5 w-5 text-brand transition-colors duration-300 hover:text-brand-darker'
                            icon={faCircleXmark}
                          />
                        </button>
                      </div>
                    </div>

                    <JobsRepeat
                      label='Repeats'
                      className={classNames({
                        'animate-shake':
                          animateFieldId === 'repeat.numOfRepeats',
                      })}
                      onBlur={() => {
                        if (watch('repeat.isRepeat')) {
                          form.trigger('repeat');
                        }
                      }}
                    />
                  </Accordion.PanelSection>
                </Accordion.Panel>
              </Accordion.GreyCard>
              <Accordion.GreyCard error={!!errors.name}>
                <Accordion.Button
                  help={errors.name ? 'Job Name is required' : 'Description'}
                  summary={watch('name')}
                  error={!!errors.name}
                >
                  What
                </Accordion.Button>
                <Accordion.Panel>
                  {(selectedTemplate ||
                    (taskState[0].filter((task) => !task._destroy).length ===
                      0 &&
                      templateOptions.length > 0)) && (
                    <Accordion.PanelSection heading={t('translation:template')}>
                      {selectedTemplate ? (
                        <div className='mb-5 flex items-center justify-between rounded-md border border-outline-variant py-2 pl-2 pr-1'>
                          <div className='flex items-center gap-2.5'>
                            <div className='flex items-start gap-2.5 rounded-sm bg-[rgba(0,106,106,0.14)] p-1'>
                              <FontAwesomeIcon
                                icon={faListCheck}
                                className='flex h-5 w-5 items-center justify-center text-brand'
                              />
                            </div>
                            {selectedTemplate.name}
                          </div>
                          {/* TODO Create icon Button variant */}
                          <HelperPopup
                            icon={
                              <div
                                className='ml-1 flex cursor-pointer items-center justify-center rounded-full p-2 text-secondary hover:bg-grey-10 hover:text-brand-darker'
                                onClick={() => {
                                  selectedTemplate &&
                                  isTemplateModified(selectedTemplate)
                                    ? setShowRemoveConfirmation(true)
                                    : removeTemplate();
                                }}
                              >
                                <FaXmark className='h-4 w-4 ' />
                              </div>
                            }
                          >
                            Remove Template
                          </HelperPopup>
                        </div>
                      ) : (
                        <UseTemplate
                          jobTemplates={data.jobTemplates}
                          taskState={taskState}
                          onUseTemplateSelect={applyTemplate}
                          selectedTemplateName={selectedTemplate}
                        />
                      )}
                    </Accordion.PanelSection>
                  )}
                  <Accordion.PanelSection heading='Summary'>
                    <Input
                      {...register('name', {
                        onBlur: () => form.trigger('name'),
                      })}
                      label={t('form.name')}
                      required
                      error={errors.name?.message}
                      autoFocus={!isMobile}
                      maxLength={250}
                      className={classNames('mb-4', {
                        'animate-shake': animateFieldId === 'name',
                      })}
                    />
                    <div>
                      <Label>{t('translation:notes')}</Label>
                      <TextArea {...register('notes')} />
                    </div>

                    <div className={classNames({ 'mb-2': !guestyEnabled })}>
                      <Controller
                        name='attachments'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <ControlledUpload
                            id={`CreateJobForm`}
                            value={value ?? []}
                            onChange={onChange}
                            isPill={true}
                          />
                        )}
                      />
                    </div>

                    {guestyEnabled && (
                      <div className='mb-5 block'>
                        <label className='flex items-center gap-1.5 text-sm text-grey-50'>
                          <input
                            {...register('guestyCleaningStatus')}
                            type='checkbox'
                            className='h-4 w-4'
                          />
                          Update Guesty Cleaning Status
                        </label>
                      </div>
                    )}
                  </Accordion.PanelSection>
                  <Accordion.PanelSection>
                    <div className='pb-1 text-base font-semibold text-primary'>
                      {t('translation:location_attributes')}
                    </div>
                    <p className='pb-3 text-sm text-secondary'>
                      Information about the job location relevant to this job
                    </p>
                    <Controller
                      name='includeAttributes'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <AttributeSelect
                          entityType='Site'
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Accordion.PanelSection>

                  {ability.can('use', 'feat.job-attributes') && (
                    <Accordion.PanelSection>
                      <div className='pb-1 text-base font-semibold text-primary'>
                        Custom Fields
                      </div>
                      <p className='pb-3 text-sm text-secondary'>
                        Metrics you would like collected for this job
                      </p>
                      <Controller
                        name='jobAttributes'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <AttributeSelect
                            entityType='Job'
                            // label='Job attributes to collect'
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Accordion.PanelSection>
                  )}

                  <Accordion.PanelSection heading={t('translation:tag_plural')}>
                    <Controller
                      name='tags'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TagCombobox
                          containerStyles={
                            isMobile
                              ? '!max-h-[calc(100vh-170px)]'
                              : '!max-h-[calc(100vh-305px)]'
                          }
                          entityType={TagEntityType.Job}
                          options={allTags}
                          value={
                            value
                              ? allTags.filter((t) => value.includes(t.id))
                              : []
                          }
                          onChange={(tags) => {
                            reload();
                            onChange(tags.map(({ id }) => id));
                          }}
                          hasLabel={false}
                        />
                      )}
                    />
                  </Accordion.PanelSection>
                  <Accordion.PanelSection heading='Tasks'>
                    <div className='mb-5'>
                      <ManageTasks
                        state={taskState}
                        onShowTask={handleShowTask}
                        onTaskTypeUpdate={handleUpdateTaskType}
                        isNewTask={isNewTask}
                        label=''
                      />

                      {taskState[0].length > 0 && (
                        <Controller
                          name='enforceOrder'
                          control={control}
                          render={({ field }) => (
                            <Switch
                              label='Enforce task order'
                              tooltip='Each task will need to be completed before the next task can be started.'
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                      )}
                    </div>
                  </Accordion.PanelSection>
                </Accordion.Panel>
              </Accordion.GreyCard>
            </Accordion>

            {/* <FindAddContact
        label={t('job:form.customer')}
        type='Customer'
        // value={form.customer ? [form.customer] : []}
        value={form.customer ? [form.customer] : []}
        onChange={(value) => {
          setValue('customer', value.length ? value[0] : null);
        }}
      /> */}
            {/* GF-2761 temporarily hidden */}
            {/* <RadioGroup
        name='scope'
        title={t('job:form.scopeTitle')}
        options={Object.values(CreateJobScope).map((value) => ({
          value,
          label: t('type.' + value),
        }))}
        value={form.scope}
        onChange={(value) => {
          setValue('scopeIds', []);
          setValue('scope', value);
        }}
      /> */}

            {/* {form.scope === CreateJobScope.Asset && (
        <Select
          label={t('form.asset')}
          options={options.Asset}
          value={form.scopeIds.map((id) => id.toString())}
          onChange={(value) =>
            setValue(
              'scopeIds',
              value.map((v) => parseInt(v))
            )
          }
          multiple
        />
      )} */}
          </SideLayout.Body>
          <SideLayout.Foot
            className={classNames('rounded p-4', {
              '!fixed': isMobile,
            })}
          >
            <SaveJobActions
              assignee={watch('assignee')}
              jobName={watch('name')}
              loading={isSubmitting}
              onClick={submit}
              notifyDefault={notifyDefault}
              isMultipleJobCreation={isMultipleJobCreation}
            />
          </SideLayout.Foot>
        </SideLayout>
        {taskSection}
      </div>

      <Confirm
        show={showRemoveConfirmation}
        title={'Confirm Template Removal'}
        confirm={'Remove'}
        cancel={t('cancel')}
        onCancel={() => setShowRemoveConfirmation(false)}
        onConfirm={() => {
          removeTemplate();
          setShowRemoveConfirmation(false);
        }}
        danger={true}
      >
        <div className='text-md'>
          Removing this template will delete all the current tasks. Are you sure
          you want to proceed?
        </div>
      </Confirm>
    </FormProvider>
  );
};

export function useEditTasks() {
  const [showTask, setShowTask] = useState<string | null>(null);
  const taskState = useState<TaskInput[]>([]);

  const updateTask = useCallback(
    (data: TaskInput) => {
      const [tasks, setTasks] = taskState;
      setTasks((tasks) =>
        splice(
          tasks,
          tasks.findIndex(({ id }) => id === data.id),
          1,
          data
        )
      );

      setShowTask(null);
    },
    [taskState]
  );

  const deleteTask = useCallback(
    (id: string) => {
      const [, setTasks] = taskState;

      setTasks((tasks) => {
        const idx = tasks.findIndex((t) => t.id === id);
        return idx > -1 ? remove(tasks, idx) : tasks;
      });

      setShowTask(null);
    },
    [taskState]
  );

  return {
    taskState,
    showTask,
    setShowTask,
    updateTask,
    deleteTask,
  };
}
