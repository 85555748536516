import { Formik, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';
import { AttributeFormikField } from '~/components/AttributeForm';
import { SubmitButton } from '~/components/form/SubmitButton';
import { Attachment } from '~/generated/graphql';
import { getFragmentData, graphql } from '~/gql';
import {
  AttributeFieldsFragment,
  getEntityType,
  HasAttributes,
} from './AttributesList';

export type AttributeEntityType = 'Asset' | 'Contact' | 'Item' | 'Site';

type FieldValues = {
  attributes: { id: string; value: string }[];
};

type Props = {
  entityId: string;
  data: HasAttributes;
  search?: string;
  onSuccess: () => void;
  onFormTouched?: (isDirty: boolean) => void;
};

const UpdateAttributeValues = graphql(`
  mutation UpdateAttributeValues($input: UpdateAttributeValuesInput!) {
    updateAttributeValues(input: $input) {
      ...AttributeFields
    }
  }
`);

export const AttributeEditForm = ({
  entityId,
  data,
  search,
  onSuccess,
  onFormTouched,
}: Props) => {
  const { t } = useTranslation();
  const [, update] = useMutation(UpdateAttributeValues);

  const entityType = getEntityType(entityId);
  const attributes = getFragmentData(AttributeFieldsFragment, data.attributes);

  const attachments = attributes?.reduce(
    (prev: Record<string, Attachment[]>, curr) => {
      if (curr.attachments && curr.attachments.length) {
        return { ...prev, [curr.id]: curr.attachments };
      }
      return prev;
    },
    {}
  );

  // TODO decouple from update site
  const initialValues: FieldValues = {
    attributes:
      attributes
        ?.filter(({ id }) => !id.startsWith('integration_'))
        ?.map(({ id, value, attachments }) => ({
          id,
          value: value.length ? value[0] : '',
          attachments: attachments?.map((saved) => ({
            id: saved.id,
            kind: saved.kind,
            originalFilename: saved.originalFilename,
            preview: saved.thumb,
          })),
        })) ?? [],
  };

  async function handleSubmit(data: FieldValues) {
    const result = await update({
      input: {
        id: entityId,
        attributes: data.attributes,
      },
    });

    return onSuccess();
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ dirty }) => {
        return (
          <>
            {onFormTouched && <FormikDirtyEffect onDirty={onFormTouched} />}
            <div className='mb-2 mt-8'>
              <AttributeFormikField
                entityType={entityType}
                attachments={attachments}
                search={search}
              />
            </div>

            {dirty && (
              <div className='border-t-1 sticky bottom-0 rotate-180 bg-[#f8f9fa] p-4 shadow'>
                <div className='rotate-180'>
                  <SubmitButton>{t('saveChanges')}</SubmitButton>
                </div>
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
};

function FormikDirtyEffect(props: { onDirty: (dirty: boolean) => void }) {
  const { dirty } = useFormikContext();

  useEffect(() => {
    props.onDirty(dirty);
  }, [dirty]);

  return null;
}
