import { faPen, faTag } from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Pill } from '../ui/nucleus/Pill/Pill';
import { Tag } from './tags';
type TagsBuilderProps = {
  tags: Tag[];
  onRemove?: (id: string) => void;
  onUpdate?: () => void;
  onClick?: (tag: Tag) => void;
  className?: string;
  limit?: number;
  miniTag?: boolean;
};

export function TagsBuilder({
  tags,
  onRemove,
  onUpdate,
  onClick,
  className,
  limit,
  miniTag = false,
}: TagsBuilderProps) {
  if (!tags || tags.length === 0) return <></>;

  const limitedTags = limit ? tags.slice(0, limit) : tags;

  const More = () => {
    if (limit) {
      const numOfHidden = tags.length - limit;
      const styles = classNames(
        'max-h-7 text-tertiary rounded px-1.5 py-0.5 bg-gray-100 cursor-default',
        miniTag ? 'text-xs' : 'text-sm'
      );

      if (numOfHidden > 0)
        return <div className={styles}>+ {numOfHidden} more</div>;
    }

    return <></>;
  };

  return (
    <div
      className={classNames(
        'flex flex-wrap items-center',
        miniTag ? 'gap-[2px]' : 'gap-1',
        className
      )}
    >
      {limitedTags?.map((tag, i) => (
        <TagBlock
          key={i}
          hexColor={tag.colour}
          tagName={tag.name}
          onClick={onClick && (() => onClick(tag))}
          onRemove={
            onRemove &&
            (() => {
              onRemove(tag.id);
            })
          }
          miniTag={miniTag}
        />
      ))}

      <More />

      {onUpdate && <EditTagsButton onClick={onUpdate} />}
    </div>
  );
}

export function AddTagsButton({
  onClick,
  className,
}: {
  onClick: () => void;
  className?: string;
}) {
  return (
    <Pill
      type='button'
      icon={{
        icon: faTag,
        position: 'left',
      }}
      className={classNames(
        'mx-1 my-2 flex h-8 w-fit cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-full border border-outline-variant bg-white pl-3 pr-3.5 text-base font-normal text-secondary ease-standard hover:border-outline-default hover:text-primary active:border-outline-active active:text-primary',
        className
      )}
      onClick={onClick}
    >
      Add Tags
    </Pill>
  );
}

type Props = {
  hexColor: string;
  tagName: string;
  onRemove?: () => void;
  onClick?: () => void;
  miniTag?: boolean;
};

export function TagBlock({
  hexColor,
  tagName,
  onRemove,
  onClick,
  miniTag = false,
}: Props) {
  const fontSize = miniTag ? 'text-xs' : 'text-sm';

  return (
    <div
      className={classNames(
        'flex max-h-7 w-fit cursor-default items-center rounded px-1.5 py-0.5',
        onClick && 'cursor-pointer'
      )}
      style={{ background: `#${hexColor}1e` }}
      onClick={
        onClick &&
        ((e: any) => {
          onClick();
          e.stopPropagation();
        })
      }
    >
      <FontAwesomeIcon
        className={classNames(fontSize, miniTag ? 'mr-1' : 'mr-1.5')}
        style={{ color: `#${hexColor}` }}
        // @ts-ignore
        icon={faTag}
      />

      <span
        className={classNames(
          'truncate whitespace-nowrap text-gray-700',
          fontSize
        )}
      >
        {tagName}
      </span>

      {onRemove && (
        <FontAwesomeIcon
          className={classNames(
            'cursor-pointer text-[#81848F] hover:text-[#646872]',
            fontSize,
            miniTag ? 'ml-1.5' : 'ml-2.5'
          )}
          icon={faCircleXmark}
          onClick={(e: any) => {
            onRemove();
            e.stopPropagation();
          }}
        />
      )}
    </div>
  );
}

export function EditTagsButton({
  onClick,
  isLarge = false,
}: {
  onClick: () => void;
  isLarge?: boolean;
}) {
  return (
    <button
      className={classNames(
        'flex w-fit items-center rounded-[25px] border border-outline-variant',
        isLarge ? 'px-[21px] py-[10px]' : 'px-2'
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon
        className={classNames(!isLarge && 'p-[5px] text-sm')}
        color='#466463'
        // @ts-ignore
        icon={faPen}
      />
    </button>
  );
}
