import { createContext, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Split } from '~/components/Split';
import { Address } from '~/components/ui/Address';
import { BuildingPlaceholder } from '~/components/ui/BuildingPlaceholder';
import { EmptyListActions } from '~/components/ui/EmptyListActions';
import { Filter, filterKeyValueSeperator } from '~/components/ui/FilterGroup';
import { FilterGroupSearch } from '~/components/ui/FilterGroupSearch';
import { MatchList } from '~/components/ui/MatchList';
import { Result } from '~/components/ui/Result';
import { SitesQuery, useSiteTypesQuery } from '~/generated/graphql';
import { Status } from '~/gql/graphql';
import { extractAttributeFilterOptions } from '~/helpers/attributes';
import parseFilters from '~/helpers/parseFilters';
import { Filters } from '~/hooks/useFilter';
import { useFilterOptions } from '~/hooks/useFilterOptions';
import { useSearch } from '~/hooks/useSearch';
import { useSort } from '~/hooks/useSort';
import { SitesNav } from '~/layouts/nav/SitesNav';
import { useSites } from './resources/sites';

type ContextType = {
  view: string;
  setView: (type: string) => void;
};

const SEARCH_OPTIONS = {
  keys: ['name', 'type', 'address', 'state'],
};

const getListItemProps = (site: SitesQuery['sites'][0]) => ({
  id: site.id,
  linkTo: `/sites/${site.id}`,
  titleText: site.name,
  secondaryText: site.type,
  tertiaryText: <Address data={site.address} />,
  // hero: { number: site.activeLocations, directive },
  status: site.status,
  image: site.image,
});

const SiteListContext = createContext<ContextType>({} as ContextType);
export function useSiteListContext() {
  const context = useContext(SiteListContext);

  if (!context) {
    throw new Error('Must be used within SiteListContext.Provider');
  }

  return context;
}

const SiteFilterForm = ({
  data,
  onSubmit,
}: {
  data: SitesQuery['sites'];
  onSubmit: (values: {
    query: string;
    filters: ReturnType<typeof parseFilters>;
  }) => void;
}) => {
  const { t } = useTranslation();
  const [options] = useFilterOptions(data, [
    { name: 'status', path: 'status' },
    { name: 'type', path: 'type' },
    { name: 'state', path: 'state' },
  ]);
  const [searchValue, setSearchValue] = useState('');
  const [filtersValue, setFiltersValue] = useState(
    new URLSearchParams([['status', Status.Active]])
  );

  const attributeOptions = useMemo(
    // @ts-expect-error FIXME we need to fragment mask the attributes fields
    () => extractAttributeFilterOptions(data),
    [data]
  );

  const filters: Filter[] = [
    {
      name: 'status',
      label: t('status'),
      options: Object.values(Status).map((value) => ({
        value,
        label: t(`statuses.${value}`),
      })),
      searchable: false,
      type: 'select',
    },
    options.type.length > 1 && {
      name: 'type',
      label: t('siteType'),
      options: options.type,
      type: 'select',
    },
    options.state.length > 1 && {
      name: 'state',
      label: t('state'),
      options: options.state,
      type: 'select',
    },
    {
      name: 'attributes',
      label: t('attribute_plural'),
      options: attributeOptions,
      type: 'facetedSelect',
    },
  ];

  return (
    <FilterGroupSearch
      filters={filters}
      value={{ searchValue, filtersValue }}
      onChange={({ searchValue, filtersValue }) => {
        setSearchValue(searchValue);
        setFiltersValue(filtersValue);
        console.log('fv', filtersValue);

        onSubmit({ query: searchValue, filters: parseFilters(filtersValue) });
      }}
      placement={'portal'}
    />
  );
};

function applyFilters(data: ReturnType<typeof useSites>, filters: Filters) {
  const attributeFilters = filters['attributes']
    ? filters['attributes']?.reduce<{ id: string; value: string[] }[]>(
        (acc, filter) => {
          const [id, ...value] = filter.split(filterKeyValueSeperator);
          const index = acc.findIndex((f) => f.id === id);
          if (index === -1) {
            acc.push({ id, value: value });
          } else {
            acc[index].value.push(...value);
          }
          return acc;
        },
        []
      )
    : [];
  return data.filter((site) => {
    const attributesMatch = attributeFilters.every((filter) => {
      const attribute = site.attributes?.find(
        (attribute) => attribute.id === filter.id
      );
      return attribute
        ? filter.value.some((value) => attribute.value.includes(value))
        : false;
    });
    return (
      (!filters['status'] || filters['status'].includes(site.status)) &&
      (!filters['type'] || filters['type'].includes(site.type || '')) &&
      (!filters['state'] || filters['state'].includes(site.state || '')) &&
      attributesMatch
    );
  });
}

export default function SitesRoute() {
  const sites = useSites();
  const { t } = useTranslation();
  const [view, setView] = useState('');
  const [{ data: siteTypesData }] = useSiteTypesQuery({
    variables: { preset: true },
  });
  const siteTypes = siteTypesData?.siteTypes;
  const data = sites.filter((site) =>
    view === 'Other'
      ? !site.type || !siteTypes?.includes(site.type)
      : site.type === view
  );

  const [filters, setFilters] = useState<Filters>({});
  const { results, search } = useSearch(data, SEARCH_OPTIONS);
  const filtered = applyFilters(results || [], filters);
  const { sorted } = useSort(filtered, { default: 'name' });

  return (
    <SiteListContext.Provider value={{ view, setView }}>
      <Split
        nav={<SitesNav />}
        filters={
          <SiteFilterForm
            data={data ?? []}
            onSubmit={({ query, filters }) => {
              search(query);
              setFilters(filters);
            }}
          />
        }
        main={
          <>
            <Result count={sorted?.length} />
            {sorted && sorted.length > 0 ? (
              <MatchList
                className='!p-0'
                matchPath='/sites/:id/*'
                items={sorted?.map(getListItemProps) ?? []}
                placeholderIcon={<BuildingPlaceholder />}
              />
            ) : sites?.length ? (
              <div className='max-w-full flex-1 overflow-x-hidden px-7 py-4'>
                There are no {t('sites')} matching these filters...
              </div>
            ) : (
              <EmptyListActions listType='Site' />
            )}
          </>
        }
      />
    </SiteListContext.Provider>
  );
}
