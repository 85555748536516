import { SVGProps } from 'react'
const SvgSquareMono = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox='0 0 411 410'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M142.614 164.441L142.821 256.898L0 211.335L142.614 164.441Z'
      fill='#ffffffaa'
    />
    <path
      d='M157.482 0L249.934 126.392L398.616 77.5051L307.089 204.512L250.497 282.994L158.972 410L158.407 253.396L158.055 156.598L157.482 0Z'
      fill='white'
    />
    <path
      d='M302.655 54.6766C304.84 61.3196 305.009 68.4596 303.144 75.1943C301.279 81.928 297.463 87.9536 292.176 92.5085C286.892 97.0636 280.374 99.9437 273.45 100.784C266.526 101.625 259.504 100.389 253.277 97.2322C247.048 94.0748 241.889 89.1393 238.454 83.0486C235.021 76.9579 233.465 69.9864 233.983 63.0157C234.5 56.0442 237.069 49.3862 241.364 43.884C245.66 38.3811 251.489 34.2819 258.114 32.1025C262.513 30.6562 267.156 30.0921 271.775 30.4434C276.393 30.7949 280.901 32.0551 285.036 34.1508C289.173 36.2475 292.857 39.1393 295.88 42.6609C298.904 46.1827 301.205 50.2655 302.655 54.6766Z'
      fill='white'
    />
    <path
      d='M267.83 283.033L322.498 207.816L410.779 329.905L267.83 283.033Z'
      fill='#ffffffaa'
    />
  </svg>
)
export default SvgSquareMono
