import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { IconButton } from '~/components/ui/nucleus/Button/IconButton';

type Props = {
  onClick: () => void;
  iconButton?: boolean;
};

export const SettingsLink = ({ onClick, iconButton = false }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {iconButton ? (
        <IconButton
          //@ts-ignore
          icon={faCog}
          size='sm'
          onClick={onClick}
          ariaLabel={t('setting_plural')}
        />
      ) : (
        <div
          onClick={onClick}
          className='flex flex-row items-center justify-start gap-2 py-4 font-medium text-secondary hover:text-brand lg:text-base lg:font-normal'
        >
          <FontAwesomeIcon
            //@ts-ignore
            icon={faCog}
            className='mr-3 h-6 w-6'
          />
          {t('setting_plural')}
        </div>
      )}
    </>
  );
};
