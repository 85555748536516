import {
  faArrowRightFromBracket,
  faCog,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { useAppContext } from '~/App';
import { handleLogout } from '~/components/auth/LogoutLink';
import { Avatar } from '~/components/ui/Avatar';
import { DefaultAvatar } from '~/components/ui/DefaultAvatar';
import { useLocationHash } from '~/hooks/useLocationHash';

export function UserMenu() {
  const { t } = useTranslation();
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [2, 4] } }],
  });
  const [, setShowSettings] = useLocationHash('#user-settings-open');

  const { user } = useAppContext();

  return (
    <div className='relative inline-block pt-1.5 text-left'>
      <Menu>
        {({ open }) => (
          <>
            <MenuButton className='h-10 w-10' ref={setReferenceElement}>
              {user?.image ? (
                <Avatar
                  className='border-outline-bright'
                  image={user?.image}
                  size='full'
                />
              ) : (
                <DefaultAvatar
                  size='h-10 w-10'
                  bgColor='bg-surface-container-high'
                />
              )}
            </MenuButton>

            <Transition
              as={Fragment}
              show={open}
              enter='transition ease-out duration-100'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <MenuItems
                ref={setPopperElement}
                style={{ ...styles.popper, zIndex: 1000 }}
                {...attributes.popper}
                className='mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
              >
                <div className='py-1'>
                  <div className='flex flex-row gap-2 py-3 pl-4 pr-6'>
                    {user?.image ? (
                      <Avatar
                        className='border-outline-bright'
                        image={user?.image}
                        size='large'
                      />
                    ) : (
                      <DefaultAvatar
                        size='h-10 w-10'
                        bgColor='bg-surface-container-high'
                      />
                    )}
                    <div className='flex flex-col text-sm'>
                      <span className='font-medium text-primary'>
                        {user?.name}
                      </span>
                      <span className='text-tertiary'>{user?.email}</span>
                    </div>
                  </div>
                  <MenuItem
                    as='div'
                    className={({ active }) =>
                      `block cursor-default select-none rounded-sm border-t border-outline-bright px-4 py-2 ${
                        active ? 'bg-brand text-white' : 'text-primary'
                      }`
                    }
                    onClick={() => setShowSettings(true)}
                    value={null}
                  >
                    <div className='flex flex-1 items-center gap-2'>
                      <div className='h-7 w-7 p-1'>
                        <FontAwesomeIcon
                          //@ts-ignore
                          icon={faCog}
                          className='h-5 w-5'
                        />
                      </div>
                      <span className='block truncate font-normal'>
                        {t('settings.userSettings')}
                      </span>
                    </div>
                  </MenuItem>

                  <MenuItem
                    as='div'
                    className={({ active }) =>
                      `flex cursor-default select-none rounded-sm border-t border-outline-bright px-4 py-2 ${
                        active ? 'bg-brand text-white' : 'text-primary'
                      }`
                    }
                    onClick={handleLogout}
                  >
                    <div className='flex flex-1 items-center gap-2'>
                      <div className='h-7 w-7 p-1'>
                        <FontAwesomeIcon
                          //@ts-ignore
                          icon={faArrowRightFromBracket}
                          className=' h-5 w-5 pl-0.5'
                        />
                      </div>
                      <span className='block truncate font-normal'>
                        {t('signOut')}
                      </span>
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}
