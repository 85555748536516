import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import {
  SelectorIcon as ChevronUpDownIcon,
  PlusIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SvgPlaceholderImage from '~/PlaceholderImage';
import { unique } from '~/helpers/array';
import { by, notUndefined } from '~/helpers/filter';
import { styles } from '../FindAddContact/ContactSelect';
import { SearchInput } from '../FindAddContact/SearchInput';
import { BuildingPlaceholder } from '../ui/BuildingPlaceholder';
import {
  FacetedFilterOption,
  FacetedFilterSelect,
} from '../ui/FacetedFilterSelect';
import { Site } from './FindAddSite';

export type SiteSelectProps = {
  label?: string;
  error?: string;
  sites: Site[];
  value: Site[] | string[];
  onChange: (newValue: Site[]) => void;
  onCreate?: (query: string) => void;
  multiple?: boolean;
  required?: boolean;
  optionsBoxHeight?: string;
};

const isString = (value: unknown): value is string => typeof value === 'string';

const Image = ({ url }: { url?: string | null }) => (
  <div className='flex h-6 w-6 flex-shrink-0 items-center justify-center overflow-hidden rounded-[3px]'>
    {url ? (
      <img className='h-full w-full object-cover' alt='' src={url} />
    ) : (
      <span className='bg-white'>
        <SvgPlaceholderImage className='h-[22px] w-[22px] fill-current text-grey-30' />
      </span>
    )}
  </div>
);

const DISPLAY_LIMIT = 6;

export function SiteSelect({
  label,
  error,
  sites,
  value,
  onChange,
  onCreate,
  multiple = false,
  required = false,
  ...props
}: SiteSelectProps) {
  const { t } = useTranslation();
  const listboxOptionsRef = useRef<HTMLElement>(null);
  const confirmBtnRef = useRef<HTMLButtonElement>(null);
  const [query, setQuery] = useState('');
  const searched = query === '' ? sites : sites.filter(by(query, ['name']));

  const [filterValues, setFilterValues] = useState<
    { key: string; value: string[] }[]
  >([]);

  const filtered = searched.filter(({ attributes: siteAttributes }) =>
    filterValues.every(({ key, value }) =>
      value.some((v) =>
        siteAttributes?.find(({ name }) => name === key)?.value.includes(v)
      )
    )
  );

  const selected = useMemo(() => {
    return value
      .map((val) => {
        return isString(val) ? sites.find((site) => site.id === val) : val;
      })
      .filter(notUndefined);
  }, [value, sites]);

  const [isMultipleMode, setIsMultipleMode] = useState(
    () => selected.length > 0
  );

  // Update isMultipleMode when selection changes
  useMemo(() => {
    if (selected.length > 0 && !isMultipleMode) {
      setIsMultipleMode(true);
    } else if (selected.length === 0 && isMultipleMode) {
      setIsMultipleMode(false);
    }
  }, [selected.length, isMultipleMode]);

  const isItemSelected = useCallback(
    (site: Site) => {
      return selected.some((s) => s.id === site.id);
    },
    [selected]
  );

  const allFilteredSelected = filtered.every((site) =>
    selected.map(({ id }) => id).includes(site.id)
  );

  const handleItemClick = (site: Site, fromCheckbox: boolean) => {
    if (fromCheckbox || isMultipleMode) {
      // Always use multiple mode behavior when there are selections
      const isSelected = selected.some((s) => s.id === site.id);
      const newSelection = isSelected
        ? selected.filter((s) => s.id !== site.id)
        : [...selected, site];
      onChange(newSelection);
    } else {
      // Only use single mode behavior when there are no selections
      onChange([site]);
      handleClose();
    }
  };

  const attributeFilters = useMemo(
    () => getAttributeFilterOptions(sites),
    [sites]
  );

  const handleClose = () => {
    const escEvent = new KeyboardEvent('keydown', {
      key: 'Escape',
      code: 'Escape',
      keyCode: 27,
      which: 27,
      bubbles: true,
      cancelable: true,
    });
    listboxOptionsRef.current?.dispatchEvent(escEvent);
  };

  const handleChange = (newValue: Site | Site[], fromCheckbox = false) => {
    if (fromCheckbox && !isMultipleMode) {
      setIsMultipleMode(true);
    }

    if (Array.isArray(newValue)) {
      if (newValue.every((site) => site !== null)) {
        // When applying select all, don't exit multiple mode
        onChange(newValue);
      }
    } else if (newValue !== null) {
      if (isMultipleMode) {
        const isSelected = selected.some((site) => site.id === newValue.id);
        onChange(
          isSelected
            ? selected.filter((site) => site.id !== newValue.id)
            : [...selected, newValue]
        );
      } else {
        // In single mode, replace selection and close
        onChange([newValue]);
        handleClose();
      }
    }
  };

  return (
    <Field>
      {label && (
        <Label className={styles.label}>
          {label}
          {required && <span className='pl-0.5 text-brand'>*</span>}
        </Label>
      )}
      <Listbox
        value={selected}
        onChange={(newValue) => {
          // Prevent Listbox from handling changes directly
          // We'll handle all changes through handleItemClick
        }}
        multiple={isMultipleMode}
      >
        <ListboxButton
          className={classNames(styles.control, {
            'mb-5': label && !error,
            'py-1': selected.length,
            'py-3': !selected.length,
            'border-red-500': Boolean(error),
          })}
        >
          <div className='flex items-center gap-3'>
            <div className='flex flex-1 flex-wrap gap-2 overflow-x-clip text-left'>
              {selected.slice(0, DISPLAY_LIMIT).map((site) => (
                <div
                  key={site.id}
                  className={classNames(styles.selectedOption, 'rounded-md')}
                >
                  {site.image ? (
                    <Image url={site.image} />
                  ) : (
                    <BuildingPlaceholder className='!w-6 rounded-[3px] px-0 text-base' />
                  )}
                  <span className='truncate'>{site.name}</span>
                  <button
                    className='relative h-3.5 w-3.5 flex-shrink-0 rounded-full bg-[#808490] transition-colors hover:bg-[#6D6E7A]'
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChange(selected.filter(({ id }) => id !== site.id));
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      className='absolute left-0 top-0 h-2.5 w-2.5 p-0.5 text-white'
                      // size='xs'
                    />
                  </button>
                </div>
              ))}
              {selected.length > DISPLAY_LIMIT && (
                <div
                  className={classNames(
                    styles.selectedOption,
                    'rounded-md bg-grey-5'
                  )}
                >
                  <span className='truncate text-grey-50'>
                    +{selected.length - DISPLAY_LIMIT} more
                  </span>
                </div>
              )}
            </div>
            <div>
              <ChevronUpDownIcon
                className='h-5 w-5 flex-shrink-0 text-gray-400'
                aria-hidden='true'
              />
            </div>
          </div>
        </ListboxButton>
        <ListboxOptions
          ref={listboxOptionsRef}
          anchor='bottom'
          transition
          className={classNames(
            props.optionsBoxHeight,
            styles.options,
            multiple && 'pb-0',
            'flex w-[var(--button-width)] flex-col shadow-lg'
          )}
        >
          <div className='sticky -top-1 z-50 -mt-1 bg-white p-1 pb-0'>
            <SearchInput
              className='rounded-full border-none bg-grey-3'
              inputProps={{ className: 'bg-grey-3' }}
              placeholder={t('site_plural')}
              setQuery={setQuery}
              query={query}
            />
            <FacetedFilterSelect
              label='Filter by Attribute'
              options={attributeFilters}
              value={filterValues}
              onChange={setFilterValues}
            />
          </div>
          <div className='overflow-y-auto'>
            {onCreate && (
              <ListboxOption
                key='new'
                value={null}
                className={styles.option}
                onClick={() => onCreate(query)}
              >
                {({ focus }) => (
                  <div
                    className={`flex items-center gap-2 truncate whitespace-nowrap px-4 py-3 ${
                      focus ? 'font-medium' : 'font-normal'
                    }`}
                  >
                    <div className=''>
                      <PlusIcon
                        className={`h-5 w-5 ${
                          focus ? 'text-white' : 'text-brand'
                        }`}
                        aria-hidden='true'
                      />
                    </div>
                    <div className='text-base'>{t('createSite')}</div>
                  </div>
                )}
              </ListboxOption>
            )}
            {filtered.length > 0 && (
              <ListboxOption key='all' value={null} className={styles.option}>
                {({ focus }) => (
                  <div className='flex items-center'>
                    <div
                      className='flex items-center justify-center pl-4'
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isMultipleMode) {
                          setIsMultipleMode(true);
                        }
                        allFilteredSelected
                          ? handleChange(
                              selected.filter(
                                ({ id: selectedId }) =>
                                  !filtered
                                    .map(({ id: filteredId }) => filteredId)
                                    .includes(selectedId)
                              )
                            )
                          : handleChange(unique([...selected, ...filtered]));
                      }}
                    >
                      <div className='relative'>
                        <input
                          className={classNames(
                            'z-30 h-4 w-4 cursor-pointer accent-brand',
                            {
                              invisible: allFilteredSelected,
                            }
                          )}
                          type='checkbox'
                          checked={false}
                          onChange={() => {}} // React requires onChange with checked prop
                        />
                        {allFilteredSelected && (
                          <div className='absolute inset-0 flex h-4 w-4 cursor-pointer items-center justify-center rounded border border-brand bg-brand text-white'>
                            <span className='mb-0.5 text-lg font-medium leading-none'>
                              -
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={classNames(
                        'flex flex-1 items-center justify-between truncate px-2 py-2',
                        {
                          'bg-[#00707014]':
                            allFilteredSelected && isMultipleMode,
                        }
                      )}
                    >
                      <div className='flex items-center gap-2 truncate whitespace-nowrap pl-[1px]'>
                        <span className='truncate text-base'>
                          {t('selectAll')}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </ListboxOption>
            )}
            {filtered.map((site) => (
              <ListboxOption
                key={site.id}
                value={site}
                className={styles.option}
              >
                {({ focus }) => (
                  <div className='flex items-center'>
                    <div
                      className='flex items-center justify-center pl-4'
                      onClick={(e) => {
                        e.stopPropagation();
                        handleItemClick(site, true);
                      }}
                    >
                      <input
                        className={classNames(
                          'z-30 h-4 w-4 cursor-pointer accent-brand',
                          {
                            'outline outline-[1.3px] outline-white':
                              focus && isItemSelected(site),
                          }
                        )}
                        type='checkbox'
                        checked={isItemSelected(site)}
                        onChange={() => {}} // React requires onChange with checked prop
                      />
                    </div>
                    <div
                      className={classNames(
                        'flex flex-1 items-center justify-between truncate px-2 py-2',
                        {
                          'bg-[#00707014]':
                            isItemSelected(site) && isMultipleMode,
                        }
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleItemClick(site, false);
                      }}
                    >
                      <div className='flex items-center gap-2 truncate whitespace-nowrap pl-[1px]'>
                        {site.image ? (
                          <Image url={site.image} />
                        ) : (
                          <BuildingPlaceholder
                            className={classNames(
                              '!h-6 !w-6 rounded-[3px] px-0 text-base',
                              focus && 'bg-shade-20'
                            )}
                          />
                        )}
                        <span className='truncate text-base'>{site.name}</span>
                      </div>
                    </div>
                  </div>
                )}
              </ListboxOption>
            ))}
          </div>
          {isMultipleMode && (
            <div className='sticky bottom-0 z-10 flex items-center justify-between border-t bg-white p-2 text-xs'>
              <button
                className='flex-1 p-2 text-left uppercase'
                onClick={() => {
                  setIsMultipleMode(false);
                  handleClose();
                }}
              >
                {t('cancel')}
              </button>
              <button
                ref={confirmBtnRef}
                className='flex-1 p-2 text-right uppercase'
                onClick={handleClose}
              >
                {t('ok')}
              </button>
            </div>
          )}
        </ListboxOptions>
      </Listbox>
      {error && <p className='ErrorMessage'>{error}</p>}
    </Field>
  );
}

export function getAttributeFilterOptions(sites: Site[]) {
  const attributeFilters: FacetedFilterOption[] = [];
  sites.forEach(({ attributes }) => {
    attributes?.forEach(({ category, name, value, type }) => {
      if (type === 'attachment') {
        return;
      }
      if (name && value.length > 0) {
        let filterIndex = attributeFilters.findIndex(({ key }) => key === name);
        if (filterIndex === -1) {
          filterIndex = attributeFilters.length;
          const group = category;
          const label = name;
          attributeFilters.push({
            $group: group?.trimEnd(),
            label: label.trimStart(),
            key: name,
            value: [],
          });
        }
        attributeFilters[filterIndex].value.push(
          ...value.filter(
            (v) =>
              v && !attributeFilters[filterIndex].value.some((o) => o === v)
          )
        );
      }
    });
  });
  return attributeFilters;
}
