import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppContext } from '~/App';
import { LogoutLink } from '~/components/auth/LogoutLink';
import { useLocationHash } from '~/hooks/useLocationHash';
import { PoweredBy } from '~/layouts/nav/MoreNav';
import { SettingsLink } from '~/layouts/nav/SettingsLink';
import { SupportLink } from '~/layouts/nav/SupportLink';
import { TenantSwitcher } from '~/layouts/nav/TenantSwitcher';
import { Avatar } from './Avatar';
import { CountBadge } from './CountBadge';
import { DefaultAvatar } from './DefaultAvatar';
import { BottomSheet, SideSheet } from './nucleus/Sheets';

export type MenuItem = {
  key: string;
  label: React.ReactNode;
  to?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  badge?: number;
  isActive?: boolean;
};

type TopBarProps = {
  title: string;
  onMenuClick?: () => void;
  menuItems?: MenuItem[];
  onMenuItemClick?: (item: MenuItem) => void;
};

export const TopBar = ({
  title,
  onMenuClick,
  menuItems,
  onMenuItemClick,
}: TopBarProps) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showUserSettings, setShowUserSettings] = useLocationHash(
    '#user-settings-open'
  );
  const [showAccountSettings, setShowAccountSettings] =
    useLocationHash('#org-settings-open');
  const location = useLocation();

  const { user } = useAppContext();

  const handleMenuClick = () => {
    if (onMenuClick) {
      requestAnimationFrame(() => {
        onMenuClick();
      });
    } else if (menuItems) {
      setIsMenuOpen(true);
    }
  };

  const handleUserSettingsClick = () => {
    // This is already showing settings through useLocationHash
    setShowUserSettings(true);
  };

  const handleAccountSettingsClick = () => {
    // This is already showing settings through useLocationHash
    setShowAccountSettings(true);
  };

  const renderMenuItem = (item: MenuItem) => {
    const isActive =
      item.isActive || (item.to && location.pathname.startsWith(item.to));

    const content = (
      <div className='flex w-full items-center justify-between px-6 py-4'>
        <div className='flex items-center'>
          <span className={isActive ? 'underline' : ''}>{item.label}</span>
          {item.badge !== undefined && item.badge > 0 && (
            <CountBadge className='ml-2' count={item.badge} />
          )}
        </div>
        {item.icon && <span className='ml-2'>{item.icon}</span>}
      </div>
    );

    const handleClick = () => {
      requestAnimationFrame(() => {
        if (item.onClick) item.onClick();
        if (onMenuItemClick) onMenuItemClick(item);
        setIsMenuOpen(false);
      });
    };

    if (item.to) {
      return (
        <Link
          key={item.key}
          to={item.to}
          onClick={handleClick}
          className='block whitespace-nowrap border-b border-white border-opacity-15 text-lg text-white underline-offset-4'
        >
          {content}
        </Link>
      );
    }

    return (
      <button
        key={item.key}
        onClick={handleClick}
        className='block w-full whitespace-nowrap border-b border-white border-opacity-15 text-left text-lg text-white underline-offset-4'
      >
        {content}
      </button>
    );
  };

  return (
    <>
      <div className='relative my-auto grid h-16 grid-cols-[45px_minmax(0,_1fr)_45px] overflow-x-auto bg-primary-container py-2 pl-2 pr-3'>
        {onMenuClick || menuItems ? (
          <button
            className='flex items-center justify-center p-2 text-white hover:opacity-80 focus:opacity-80'
            onClick={handleMenuClick}
          >
            <FontAwesomeIcon icon={faBars} className='h-6 w-6' />
          </button>
        ) : (
          <div /> // Spacer for grid alignment
        )}
        <div className='flex items-center justify-center px-2'>
          <h1 className='max-w-full truncate text-center text-xl font-medium text-white'>
            {title}
          </h1>
        </div>
        <div className='flex items-center justify-center'>
          <button
            onClick={() => setIsProfileOpen(true)}
            className='flex h-10 w-10 items-center justify-center'
          >
            {user?.image ? (
              <Avatar image={user?.image} size='xl' />
            ) : (
              <DefaultAvatar size='h-10 w-10' />
            )}
          </button>
        </div>
      </div>

      {/* Side menu */}
      {menuItems && (
        <SideSheet
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          subnav={true}
        >
          <div className='flex flex-col divide-y'>
            {menuItems.map(renderMenuItem)}
          </div>
        </SideSheet>
      )}

      {/* User profile bottom sheet */}
      <BottomSheet
        isOpen={isProfileOpen}
        onClose={() => {
          setIsProfileOpen(false);
        }}
        title='Accounts'
        stacked={showUserSettings || showAccountSettings}
        hasDivider
      >
        <ul className='w-full pb-4'>
          <li className='border-b border-outline-bright px-4 pb-5'>
            <h2 className='mb-2.5 mt-4 font-medium leading-5 text-secondary'>
              Personal
            </h2>
            <div className='flex items-center justify-between gap-4 pl-0.5'>
              <div className='flex items-center gap-4 pl-0.5'>
                {user?.image ? (
                  <Avatar image={user?.image} size='xl' />
                ) : (
                  <DefaultAvatar
                    size='h-14 w-14'
                    bgColor='bg-surface-container-high'
                  />
                )}
                <div className='flex flex-col'>
                  <span className='text-lg font-medium'>{user?.name}</span>
                  <span className='text-sm text-secondary'>{user?.email}</span>
                </div>
              </div>

              <SettingsLink onClick={handleUserSettingsClick} iconButton />
            </div>
          </li>
          <li className='border-b border-outline-bright px-4 pb-5'>
            <h2 className='mb-2.5 mt-4 font-medium leading-5 text-secondary'>
              Organisation
            </h2>
            <div className='flex items-center justify-between gap-3'>
              <TenantSwitcher />
              <SettingsLink onClick={handleAccountSettingsClick} iconButton />
            </div>
          </li>
          <li className='cursor-pointer px-4 pt-4 text-lg text-secondary'>
            <SupportLink />
          </li>
          <li className='cursor-pointer px-6 text-lg text-secondary'>
            <LogoutLink />
          </li>
        </ul>
        <PoweredBy />
      </BottomSheet>
    </>
  );
};
