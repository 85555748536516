import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '~/types';
import { Combolist } from '../Combolist';
import { PopoverStateChangedEffect } from '../PopoverStateChangedEffect';
import { Pill } from './nucleus/Pill';

type Props = {
  label: string;
  options: Option[];
  multiple?: boolean;
  value: string[];
  onChange: (newValue: string[]) => void;
  searchable?: boolean;
  showLabel?: 'always' | 'auto';
};

export function FilterSelect({
  label,
  options,
  multiple = true,
  value,
  onChange,
  searchable,
  showLabel = 'auto',
}: Props) {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<string[]>(value);
  const [cancelling, setCancelling] = useState(false);

  // ! Combolist does not yet support a custom identifier so map options to identifiable items
  const items = options.map((opt) => ({
    $group: opt.$group,
    id: opt.value,
    name: opt.label,
  }));

  // HACK: when used in SiteSelect, this code causes the open filter to reset its state whenever
  // a site is hovered over. Core re-rendering issue should be fixed and this effect reinstated.

  // useEffect(() => {
  //   // When value changes from outside, update selected so combolist selections stay in sync
  //   setSelectedItems(value);
  // }, [value]);

  const truncatedLabel = (label: string) => {
    if (showLabel === 'auto' && label.length > 20) {
      return label.slice(0, 17) + '...';
    }
    return label;
  };

  const handleClose = () => {
    if (cancelling) {
      setCancelling(false);
      setSelectedItems(value);
      return;
    }
    onChange(selectedItems);
  };

  return (
    <Popover>
      {({ open, close: closePopover }) => {
        const getLabel = () => {
          // If the Popover is open, use the selectedItems for the label.
          // Otherwise, use the values for the label.
          const data = open ? selectedItems : value;
          return truncatedLabel(
            data.length === 0
              ? label
              : data.length === 1
              ? (showLabel === 'always' ? label + ' • ' : '') +
                (options.find((option) => option.value === data[0])?.label ||
                  data[0])
              : `${label} • ${
                  data.length >= items.length ? 'All' : data.length
                }`
          );
        };

        return (
          <>
            <PopoverButton
              className='mx-1 my-1 rounded-full'
              onClick={(event) => {
                if (options.length === 1) {
                  event.preventDefault();
                  onChange(
                    value.includes(options[0].value) ? [] : [options[0].value]
                  );
                }
              }}
            >
              <Pill
                type='filter'
                hasParent
                active={(open ? selectedItems : value).length > 0}
              >
                {getLabel()}
                {(open ? selectedItems : value).length > 0 && (
                  <span
                    className='max-h-full pt-0.5'
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange([]);
                      setCancelling(true);
                      closePopover();
                      // HACK: related to above note
                      setSelectedItems([]);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </span>
                )}
              </Pill>
            </PopoverButton>
            <PopoverPanel
              anchor='bottom start'
              className='prevent-drag-scroll border-gray-10 absolute z-[100] w-72 rounded-sm border bg-white shadow-md'
              style={{ marginTop: '6px' }}
            >
              {({ close }) => (
                <>
                  <Combolist
                    items={items}
                    searchKeys={['name']}
                    placeholder={label.toLowerCase()}
                    multiple={multiple}
                    value={items.filter((item) =>
                      selectedItems.includes(item.id)
                    )}
                    onChange={(selected) => {
                      setSelectedItems(selected.map(({ id }) => id));
                      onChange(selected.map(({ id }) => id));
                      // If single select close the popover after selection
                      !multiple && close();
                    }}
                    renderInner={(item) => item.name}
                    searchable={searchable}
                    selectAll={multiple}
                  />
                  {multiple && (
                    <div className='flex border-t p-1.5 text-xs'>
                      <PopoverButton
                        className='flex-grow p-2 text-left uppercase'
                        onClick={() => setCancelling(true)}
                      >
                        {t('cancel')}
                      </PopoverButton>
                      <PopoverButton className='flex-grow p-2 text-right uppercase'>
                        {t('ok')}
                      </PopoverButton>
                    </div>
                  )}
                </>
              )}
            </PopoverPanel>

            <PopoverStateChangedEffect open={open} onClose={handleClose} />
          </>
        );
      }}
    </Popover>
  );
}
