import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';
import * as yup from 'yup';
import { FindAddContact } from '~/components/FindAddContact';
import { useSites } from '~/components/FindAddSite/FindAddSite';
import { Prompt } from '~/components/Prompt';
import { TagCombobox, TagEntityType } from '~/components/TagCombobox';
import { useTags } from '~/components/TagCombobox/tags';
import { AttributeSelect } from '~/components/form/AttributeSelect';
import { Input } from '~/components/form/TextField';
import { TimePicker } from '~/components/form/TimePicker';
import { TextArea } from '~/components/form/textarea/Basic';
import { SiteView } from '~/components/site/SiteView';
import { HelperPopup } from '~/components/ui/HelperPopup';
import { Label } from '~/components/ui/Label';
import { PopupDialog } from '~/components/ui/PopupDialog';
import { ReadValue } from '~/components/ui/ReadValue';
import { Switch } from '~/components/ui/nucleus/Switch';
import {
  UploadResult,
  toAttachmentInput,
  toFormValue,
} from '~/components/upload2';
import { ControlledUpload } from '~/components/upload2/Upload';
import { getFragmentData, graphql } from '~/gql';
import {
  EditJobFormQuery,
  JobQuery,
  JobStatus,
  TaskInput,
} from '~/gql/graphql';
import { JobTemplateFields } from '~/graphql/fragment/JobTemplateFields';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useContacts } from '~/routes/resources/contacts';
import { Option } from '~/types';
import { taskToInput } from '.';
import { Accordion } from '../../ui/Accordion';
import { ManageTasks, UseTemplate } from '../CreateJobForm/ManageTasks';
import { JobMenu } from '../JobView/JobMenu';
import { WhereBubble } from '../JobView/JobReadView/Bubbles';

import { faListCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useAppContext } from '~/App';
import { Confirm } from '~/components/ui/Confirm';
import { CustomTaskForm } from '../CustomTaskForm';
import { SaveJobActions, SubmitOptions } from '../SaveJobActions';
import { formatLocalDate } from '../formatDate';
import { getDefaultScheduledEndTime } from '../getDefaultScheduledEndTime';
import { useTaskEditing } from '../hooks/useTaskEditing';
import { useTemplateManager } from '../hooks/useTemplateManager';

type Props = {
  job: NonNullable<JobQuery['job']>;
  form: EditJobFormQuery;
  onSuccess: (status: JobStatus) => void;
};

type FormValues = {
  test: string[];
  name: string;
  customer: string;
  scheduledStartStrict: string;
  scheduledStartDate: string;
  scheduledStartTime: string;
  scheduledEndStrict: string;
  scheduledEndDate: string;
  scheduledEndTime: string;
  owner: string[] | null;
  assignee: string[] | null;
  include: string[] | null;
  includeAttributes: string[] | null;
  jobAttributes: string[] | null;
  tags?: string[];
  notes: string;
  attachments: UploadResult[];
  enforceOrder: boolean;
  guestyCleaningStatus: boolean;
};

const UpdateJobDocument = graphql(`
  mutation UpdateJob($input: UpdateJobInput!) {
    updateJob(input: $input) {
      id
      status
      name
      notes
      attachments {
        ...AttachmentFields
      }
      scheduleStart
      scheduleEnd
      assignee {
        id
        name
      }
      customer {
        id
        name
      }
      enforceOrder
      updatedAt
      tasks {
        ...TaskData
      }
    }
  }
`);

const EditJobSchema = yup.object().shape({
  name: yup.string().required('Job Name is required'),
  repeat: yup.object().shape({
    isRepeat: yup.boolean(),
    numOfRepeats: yup.string().when('isRepeat', {
      is: true,
      then: yup.string().required('Number of repeats is required'),
    }),
  }),
  assignee: yup
    .array()
    .nullable()
    .test({
      name: 'required-if-not-created',
      message:
        'An assignee is required to save changes. Please assign someone before proceeding',
      test: function (value) {
        const jobStatus = this.options.context?.jobStatus;

        if (jobStatus === JobStatus.Created) {
          return true;
        }

        return !!value && value.length > 0;
      },
    }),
});

export function EditJobForm({ job, form, onSuccess }: Props) {
  const { t } = useTranslation(['translation', 'job']);
  const {
    tasks,
    setTasks,
    editTask,
    setEditTask,
    task,
    shownTaskType,
    updateTask,
    deleteTask: handleDeleteTask,
    updateTaskType,
  } = useTaskEditing(job?.tasks?.map((task) => taskToInput(task)) ?? []);
  const [, updateJob] = useMutation(UpdateJobDocument);
  const [sites] = useSites();
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const [allTags] = useTags(TagEntityType.Job);
  const outletContext = useOutletContext<{ scrollToJob?: () => void }>();
  const { ability } = useAppContext();

  const contacts = useContacts();

  // unmask fragment data
  // const jobTemplates = getFragmentData(JobTemplateFields, form.jobTemplates);

  const defaultValues = {
    location: job.location
      ? `${job.location.__typename}:${job.location.id}`
      : '',
    includeAttributes: job?.attributes?.map((a) => a.id) ?? null,
    jobAttributes: job?.jobAttributeIds ?? null,
    tags: job.jobTags?.map((t) => t.id) ?? [],
    notes: job.notes ?? '',
    attachments: job.attachments ? job.attachments.map(toFormValue) : [],
    owner: job.owners ? job.owners.map((o) => o.id) : null,
    assignee: job.assignees ? job.assignees.map((a) => a.id) : null,
    include: job.include ? job.include.map((i) => i.id) : null,
    scheduledStartStrict: job.scheduledStartStrict === false ? '0' : '1',
    scheduledStartDate: job.scheduledStartDate ?? '',
    scheduledStartTime: job.scheduledStartTime ?? '',
    scheduledEndStrict: job.scheduledEndStrict === false ? '0' : '1',
    scheduledEndDate: job.scheduledEndDate ?? '',
    scheduledEndTime: job.scheduledEndTime ?? '',
    enforceOrder: job.enforceOrder,
    guestyCleaningStatus: job.guestyCleaningStatus === 'clean',
    name: job.name,
  };

  const {
    formState: { isDirty, errors, isSubmitting },
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(EditJobSchema),
    context: { jobStatus: job.status },
    resetOptions: { keepDirtyValues: true },
  });

  const [animateFieldId, setAnimateFieldId] = useState<string | null>(null);
  const isComplete = job.status === JobStatus.Complete;

  const taskState: [
    TaskInput[],
    React.Dispatch<React.SetStateAction<TaskInput[]>>
  ] = [tasks, setTasks];

  const {
    selectedTemplate,
    showRemoveConfirmation,
    setShowRemoveConfirmation,
    isTemplateModified,
    applyTemplate,
    removeTemplate,
    getActiveTasksCount,
    resetInitialValues,
  } = useTemplateManager({
    form: { watch, setValue, trigger, getValues },
    taskState: taskState,
    isEditMode: true,
  });

  const [originalTaskTypes, setOriginalTaskTypes] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    const taskTypeMap: Record<string, string> = {};
    job?.tasks?.forEach((t) => {
      taskTypeMap[t.id] = t.type;
    });
    setOriginalTaskTypes(taskTypeMap);
  }, [job?.tasks]);

  useEffect(() => {
    if (job?.id) {
      resetInitialValues();
    }
  }, [job?.id]);

  const onSubmit =
    ({
      status,
      options = {},
    }: {
      status: JobStatus;
      options?: SubmitOptions;
    }) =>
    async (data: FormValues) => {
      const {
        name,
        customer,
        notes,
        owner,
        assignee,
        include,
        includeAttributes,
        enforceOrder,
        scheduledStartStrict,
        scheduledEndStrict,
        tags,
      } = data;

      const res = await updateJob({
        input: {
          id: job.id,
          name: name,
          customer: customer,
          scheduledStartStrict,
          scheduledStartDate: data.scheduledStartDate || null,
          scheduledStartTime: data.scheduledStartTime || null,
          scheduledEndStrict,
          scheduledEndDate: data.scheduledEndDate || null,
          scheduledEndTime: data.scheduledEndTime || null,
          owner,
          assignee,
          include,
          includeAttributes: data.includeAttributes,
          jobAttributes: data.jobAttributes,
          tags,
          notes,
          attachments: data.attachments.map(toAttachmentInput),
          enforceOrder: enforceOrder,
          tasks: tasks.map((task) => ({
            ...task,
            // Ensure attributes are explicitly included for each task
            attributes: task.attributes || [],
            // For Attribute tasks, ensure their special attributes field is preserved
            attribute:
              task.type === 'Attribute'
                ? {
                    attributes: task.attribute?.attributes || [],
                  }
                : undefined,
          })),
          status,
          notify: options.notify,
          comment: options.comment,
          sendMeACopy: options.sendMeACopy === true,
          includePdf: options.includePdf === true,
          guestyCleaningStatus: data.guestyCleaningStatus ? 'clean' : null,
        },
      });

      setTimeout(() => outletContext?.scrollToJob?.(), 0);

      if (res.error) {
        console.error(res.error.message);
        return;
      }

      if (!res.error) {
        // Wait a tick for the form to update
        setTimeout(() => resetInitialValues(), 0);
        onSuccess(status);
      }

      // TODO below could be moved up into onSuccess callback if it makes more sense??
      if (status !== JobStatus.Created) {
        navigate(routerLocation.pathname.slice(0, -4));
      } else {
        // ! this requires more than a form reset, e.g. tasks will need to retrieve their DB id
        reset(data);
        toast.success(`${t('statuses.Created')} saved`);
      }
    };

  const submit = useCallback(
    async (status: JobStatus, options = {}) => {
      // First validate the form
      const isFormValid = await trigger();

      // Check for validation errors
      if (Object.keys(errors).length > 0) {
        console.log('error', errors);
        toast.warning('Please fill out all required fields', {
          theme: 'colored',
        });

        // Find first panel with error
        let firstErrorPanel = null;
        if (errors.assignee) {
          firstErrorPanel = 1;
        } else if (errors.name) {
          firstErrorPanel = 3;
        }

        if (firstErrorPanel !== null) {
          const currentPanelHasError =
            (errors.assignee && openPanelIdx === 1) ||
            (errors.name && openPanelIdx === 3);

          if (currentPanelHasError) {
            if (openPanelIdx === 1 && errors.assignee) {
              setAnimateFieldId('assignee');
            } else if (openPanelIdx === 3 && errors.name) {
              setAnimateFieldId('name');
            }
            setTimeout(() => setAnimateFieldId(null), 1820);
          } else {
            setOpenPanelIdx(-1);

            setTimeout(() => {
              setOpenPanelIdx(firstErrorPanel);

              setTimeout(() => {
                const panels = document.querySelectorAll('.greycard');
                panels[firstErrorPanel]?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }, 100);
            }, 50);
          }

          return; // Stop if validation fails
        }
      }

      // If validation passes, submit the form
      handleSubmit(onSubmit({ status, options }))();
    },
    [form, job.id, taskState, onSuccess, status, errors, handleSubmit, trigger]
  );

  const hasTasks = useMemo(
    () => getActiveTasksCount() > 0,
    [getActiveTasksCount]
  );
  const guestyEnabled = form.integrations.includes('guesty');

  const location =
    job.location && job.location.__typename === 'Site'
      ? sites.find((site) => site.id === job.location?.id)
      : null;
  const where = location?.name;
  const assignee = watch('assignee');
  //const who = job.assignees?.map(({ id, name }) => <p key={id}>{name}</p>);
  const who =
    assignee &&
    contacts
      .filter(({ id }) => assignee.includes(id))
      .map(({ name }) => name)
      .join(', ');
  const startTime = watch('scheduledStartTime');
  const startDate = watch('scheduledStartDate');
  const endDate = watch('scheduledEndDate');

  const thisJob = {
    timeZone: job?.timeZone,
    scheduleStart: startDate,
    scheduleEnd: endDate,
    // completedAt?: any;
    // createdAt?: any;
    // scheduledStartTime?: startTime;
    // scheduledEndTime?: any;
  };

  const when =
    startDate && endDate
      ? `${formatLocalDate(thisJob, 'scheduledStart')} to ${formatLocalDate(
          thisJob,
          'scheduledEnd'
        )}`
      : startDate
      ? formatLocalDate(thisJob, 'scheduledStart')
      : endDate
      ? formatLocalDate(thisJob, 'scheduledEnd')
      : null;

  const [openPanelIdx, setOpenPanelIdx] = useState<number>(() => {
    return 0;
  });

  console.log(ability);

  const handlePanelToggle = useCallback(
    async (panelIndex: number | undefined) => {
      // Handle the case when no panels are open (-1) or undefined
      const nextPanelIndex = panelIndex === undefined ? -1 : panelIndex;

      // If we're clicking on a different panel or closing the current panel
      if (nextPanelIndex !== openPanelIdx) {
        // Validate fields based on which panel is currently open
        switch (openPanelIdx) {
          case 1: // Who panel
            await trigger('assignee');
            break;
          case 3: // What panel
            await trigger('name');
            break;
        }
      }
      setOpenPanelIdx(nextPanelIndex);
      // Scroll to the panel if it's being opened
      if (panelIndex !== null && panelIndex !== undefined) {
        setTimeout(() => {
          const panels = document.querySelectorAll('.greycard');
          panels[panelIndex]?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 300);
      }
    },
    [openPanelIdx, trigger, watch]
  );

  const templates = getFragmentData(JobTemplateFields, form.jobTemplates);
  const templateOptions: Option[] = templates.map(({ name: value }) => ({
    value,
    label: value,
  }));

  const [showSite, setShowSite] = useState(false);

  const shouldShowTemplateSection = () => {
    // Already using a template
    if (selectedTemplate) return true;

    // No tasks and templates are available
    if (getActiveTasksCount() === 0 && templateOptions.length > 0) return true;

    return false;
  };

  // Helper function to check if a task is newly created in this session
  const isNewTask = (taskId: string) => {
    const originalTaskIds = job?.tasks?.map((t) => t.id) || [];
    return !originalTaskIds.includes(taskId);
  };

  // Determine task type editability
  function isTaskEditable(task: TaskInput) {
    // New tasks can always have their type edited
    if (isNewTask(task.id)) {
      return true;
    }
    // Current Standard tasks can always have their type changed
    else if (task.type === 'Standard') {
      return true;
    }
    // Tasks that started as Standard can have their type changed
    else if (originalTaskTypes[task.id] === 'Standard') {
      return true;
    }
    // Existing non-Standard tasks cannot have their type changed
    else {
      return false;
    }
  }

  // Modify the taskSection to determine editability based on whether the task is new
  const taskSection = task ? (
    <CustomTaskForm
      locationId={
        job.location
          ? `${job.location.__typename}:${job.location.id}`
          : undefined
      }
      task={task}
      onApply={updateTask}
      onClose={() => setEditTask(null)}
      onDelete={handleDeleteTask}
      scopeIds={job.location ? [job.location.id] : []}
      taskType={shownTaskType}
      updateTaskType={(id, type) => updateTaskType(id, type)}
      showTaskOptions
      canEditType={isTaskEditable(task)}
    />
  ) : null;

  return (
    <div className='flex h-full items-stretch justify-end'>
      <Prompt when={!isSubmitting && isDirty} cancel='Keep Editing' />
      <SideLayout>
        <SideLayout.Head
          onClose={() => {
            navigate(
              job.status === JobStatus.Created
                ? '..'
                : routerLocation.pathname.slice(0, -4)
            );
          }}
          rightSlot={
            [JobStatus.Created, JobStatus.Declined].includes(job.status) && (
              <JobMenu job={job} editing />
            )
          }
        >
          Edit {job.name}
        </SideLayout.Head>
        <SideLayout.Body className='px-4 pb-20 lg:pb-0'>
          <Accordion
            defaultOpen={openPanelIdx}
            onToggledPanel={handlePanelToggle}
            openPanel={openPanelIdx}
          >
            <Accordion.GreyCard>
              <Accordion.Button summary={where} help='Place'>
                Where
              </Accordion.Button>
              <Accordion.Panel>
                {job.location && (
                  <div className='mb-2'>
                    <WhereBubble
                      job={job}
                      onPlaceNameClick={() => setShowSite(true)}
                    />
                  </div>
                )}
              </Accordion.Panel>
            </Accordion.GreyCard>
            <Accordion.GreyCard>
              <Accordion.Button summary={who} help='Unassigned'>
                Who
              </Accordion.Button>
              <Accordion.Panel>
                <Controller
                  name='owner'
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FindAddContact
                      controlClassName='bg-white'
                      optionsBoxHeight={
                        isMobile
                          ? 'max-h-[calc(100vh-305px)]'
                          : 'max-h-[calc(100vh-480px)]'
                      }
                      label={t('job:form.owner')}
                      error={error?.message}
                      value={
                        value
                          ? contacts.filter(({ id }) => value.includes(id))
                          : []
                      }
                      onChange={(newValue) => {
                        onChange(
                          newValue && newValue.length > 0
                            ? newValue.map(({ id }) => id)
                            : []
                        );
                      }}
                      isTeamMember
                      multiple
                      isAbsolute={false}
                    />
                  )}
                />
                {isComplete ? (
                  <ReadValue label='Assign to'>
                    {job.assignees?.map(({ id, name }) => (
                      <p key={id}>{name}</p>
                    ))}
                  </ReadValue>
                ) : (
                  <div
                    className={classNames({
                      'animate-shake': animateFieldId === 'assignee',
                    })}
                  >
                    <Controller
                      name='assignee'
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <FindAddContact
                          controlClassName='bg-white'
                          optionsBoxHeight={
                            isMobile
                              ? 'max-h-[calc(100vh-305px)]'
                              : 'max-h-[calc(100vh-480px)]'
                          }
                          label={t('job:form.assignee')}
                          contacts={contacts ?? []}
                          error={error?.message}
                          value={
                            value?.length
                              ? contacts.filter(({ id }) => value.includes(id))
                              : []
                          }
                          onChange={(newValue) => {
                            if (newValue && newValue.length > 0) {
                              onChange(newValue.map(({ id }) => id));
                            } else {
                              onChange([]);
                            }
                            trigger('assignee');
                          }}
                          multiple
                          isAbsolute={false}
                        />
                      )}
                    />
                  </div>
                )}
                <Controller
                  name='include'
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FindAddContact
                      controlClassName='bg-white'
                      optionsBoxHeight={
                        isMobile
                          ? 'max-h-[calc(100vh-305px)]'
                          : 'max-h-[calc(100vh-480px)]'
                      }
                      label={t('job:form.include')}
                      contacts={contacts ?? []}
                      error={error?.message}
                      value={
                        contacts && value
                          ? contacts.filter(({ id }) => value.includes(id))
                          : []
                      }
                      onChange={(newValue) => {
                        onChange(
                          newValue && newValue.length > 0
                            ? newValue.map(({ id }) => id)
                            : []
                        );
                      }}
                      multiple
                      hasSelectAll
                      isAbsolute={false}
                    />
                  )}
                />
              </Accordion.Panel>
            </Accordion.GreyCard>
            <Accordion.GreyCard>
              <Accordion.Button help='Unscheduled' summary={when}>
                When
              </Accordion.Button>
              <Accordion.Panel>
                <Accordion.PanelSection>
                  {isComplete ? (
                    <ReadValue label='Schedule for'>
                      {formatLocalDate(job, 'scheduledStart')}
                    </ReadValue>
                  ) : (
                    <>
                      {/*   <div className='mb-2.5 hidden gap-4'>
                        <label>
                          <input
                            {...register('scheduledStartStrict')}
                            type='radio'
                            value='1'
                          />{' '}
                          Start at
                        </label>
                        <label>
                          <input
                            {...register('scheduledStartStrict')}
                            type='radio'
                            value='0'
                          />{' '}
                          Start from
                        </label>
                      </div> */}
                      <div className='items flex flex-wrap pt-2'>
                        <div className='flex max-w-[340px] flex-1 items-stretch gap-3 pr-1 text-left md:mr-8'>
                          <Input
                            {...register('scheduledStartDate')}
                            label={t('job:form.scheduleStart')}
                            type='date'
                          />
                          <Controller
                            control={control}
                            name='scheduledStartTime'
                            render={({ field: { value, onChange } }) => (
                              <TimePicker
                                label='Time'
                                focusValue='09:00'
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                          <button
                            className={classNames(
                              'pt-2.5',
                              !(
                                watch('scheduledStartDate') ||
                                watch('scheduledStartTime')
                              ) && 'pointer-events-none opacity-0'
                            )}
                            onClick={() => {
                              setValue('scheduledStartDate', '');
                              setValue('scheduledStartTime', '');
                            }}
                          >
                            <FontAwesomeIcon
                              className='h-5 w-5 text-brand transition-colors duration-300 hover:text-brand-darker'
                              icon={faCircleXmark}
                            />
                          </button>
                        </div>
                        {/*     <div className='mb-2.5 hidden gap-4'>
                        <label>
                          <input
                            {...register('scheduledEndStrict')}
                            type='radio'
                            value='1'
                          />{' '}
                          End at
                        </label>
                        <label>
                          <input
                            {...register('scheduledEndStrict')}
                            type='radio'
                            value='0'
                          />{' '}
                          End before
                        </label>
                      </div> */}
                        <div className='flex max-w-[340px] flex-1 gap-3 pr-1 text-left'>
                          <Input
                            {...register('scheduledEndDate')}
                            label={t('job:form.scheduleEnd')}
                            type='date'
                          />
                          <Controller
                            control={control}
                            name='scheduledEndTime'
                            render={({ field: { value, onChange } }) => (
                              <TimePicker
                                label='Time'
                                focusValue={getDefaultScheduledEndTime(
                                  watch('scheduledStartTime')
                                )}
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                          <button
                            className={classNames(
                              'pt-2.5',
                              !(
                                watch('scheduledEndDate') ||
                                watch('scheduledEndTime')
                              ) && 'pointer-events-none opacity-0'
                            )}
                            onClick={() => {
                              setValue('scheduledEndDate', '');
                              setValue('scheduledEndTime', '');
                            }}
                          >
                            <FontAwesomeIcon
                              className='h-5 w-5 text-brand transition-colors duration-300 hover:text-brand-darker'
                              icon={faCircleXmark}
                            />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </Accordion.PanelSection>
              </Accordion.Panel>
            </Accordion.GreyCard>
            <Accordion.GreyCard>
              <Accordion.Button help='Description' summary={watch('name')}>
                What
              </Accordion.Button>
              <Accordion.Panel>
                {shouldShowTemplateSection() && (
                  <Accordion.PanelSection heading={t('template')}>
                    {selectedTemplate ? (
                      <div className='mb-5 flex items-center justify-between rounded-md border border-outline-variant py-2 pl-2 pr-1'>
                        <div className='flex items-center gap-2.5'>
                          <div className='flex items-start gap-2.5 rounded-sm bg-[rgba(0,106,106,0.14)] p-1'>
                            <FontAwesomeIcon
                              //@ts-ignore
                              icon={faListCheck}
                              className='flex h-5 w-5 items-center justify-center text-brand'
                            />
                          </div>
                          {selectedTemplate.name}
                        </div>
                        <HelperPopup
                          icon={
                            <div
                              className='ml-1 flex cursor-pointer items-center justify-center rounded-full p-2 text-secondary hover:bg-grey-10 hover:text-brand-darker'
                              onClick={() => {
                                if (!selectedTemplate) return;

                                if (isTemplateModified(selectedTemplate)) {
                                  setShowRemoveConfirmation(true);
                                } else {
                                  removeTemplate();
                                }
                              }}
                            >
                              <FontAwesomeIcon
                                //@ts-ignore
                                icon={faXmark}
                                className='h-4 w-4 '
                              />
                            </div>
                          }
                        >
                          Remove Template
                        </HelperPopup>
                      </div>
                    ) : (
                      <UseTemplate
                        jobTemplates={form.jobTemplates}
                        taskState={taskState}
                        onUseTemplateSelect={applyTemplate}
                        selectedTemplateName={selectedTemplate}
                      />
                    )}
                  </Accordion.PanelSection>
                )}
                <Accordion.PanelSection heading='Summary'>
                  <Input
                    label={t('job:form.name')}
                    className={classNames('mb-4', {
                      'animate-shake': animateFieldId === 'name',
                    })}
                    defaultValue={job.name}
                    {...register('name')}
                    error={errors.name?.message}
                    maxLength={250}
                    required
                    autoFocus={!isMobile}
                  />
                  <div>
                    <Label>{t('notes')}</Label>
                    <TextArea {...register('notes')} className='mb-0' />
                  </div>
                  <div className={classNames({ 'mb-2': !guestyEnabled })}>
                    <Controller
                      name='attachments'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <ControlledUpload
                          id={`EditJobForm:${job.id}`}
                          value={value ?? []}
                          onChange={onChange}
                          isPill={true}
                          multiple
                        />
                      )}
                    />
                  </div>

                  {guestyEnabled && (
                    <label className='mb-5'>
                      <input
                        {...register('guestyCleaningStatus')}
                        type='checkbox'
                      />{' '}
                      Update Guesty cleaning status
                    </label>
                  )}
                </Accordion.PanelSection>
                <Accordion.PanelSection>
                  <div className='pb-1 text-base font-semibold text-primary'>
                    {t('location_attributes')}
                  </div>
                  <p className='pb-3 text-sm text-secondary'>
                    Information about the job location relevant to this job
                  </p>
                  <div className='-mt-1'>
                    <Controller
                      name='includeAttributes'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <AttributeSelect
                          entityType='Site'
                          value={value}
                          onChange={(selected) => {
                            onChange(selected);
                          }}
                        />
                      )}
                    />
                  </div>
                </Accordion.PanelSection>

                {ability.can('use', 'feat.job-attributes') && (
                  <Accordion.PanelSection>
                    <div className='pb-1 text-base font-semibold text-primary'>
                      Custom Fields
                    </div>
                    <p className='pb-3 text-sm text-secondary'>
                      Metrics you would like collected for this job
                    </p>
                    <div className='-mt-1'>
                      <Controller
                        className='-mt-1'
                        name='jobAttributes'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <AttributeSelect
                            entityType='Job'
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                  </Accordion.PanelSection>
                )}
                <Accordion.PanelSection heading={t('tag_plural')}>
                  <Controller
                    name='tags'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TagCombobox
                        containerStyles={
                          isMobile
                            ? '!max-h-[calc(100vh-190px)]'
                            : '!max-h-[calc(100vh-410px)]'
                        }
                        entityType={TagEntityType.Job}
                        options={allTags}
                        value={
                          value
                            ? allTags.filter((t) => value.includes(t.id))
                            : []
                        }
                        onChange={(tags) => {
                          onChange(tags.map(({ id }) => id));
                        }}
                        hasLabel={false}
                      />
                    )}
                  />
                </Accordion.PanelSection>
                <Accordion.PanelSection heading='Tasks'>
                  <div>
                    <ManageTasks
                      state={[tasks, setTasks]}
                      onDeleteTask={handleDeleteTask}
                      onShowTask={setEditTask}
                      onTaskTypeUpdate={updateTaskType}
                      isEditMode={true}
                      isNewTask={isNewTask}
                      isTaskEditable={isTaskEditable}
                    />
                    {hasTasks && (
                      <>
                        <Controller
                          name='enforceOrder'
                          control={control}
                          render={({ field }) => (
                            <Switch
                              label='Enforce task order'
                              tooltip='Each task will need to be completed before the next task can be started.'
                              checked={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          )}
                        />
                      </>
                    )}
                  </div>
                </Accordion.PanelSection>
              </Accordion.Panel>
            </Accordion.GreyCard>
          </Accordion>
          <br />
          <br />
        </SideLayout.Body>
        <SideLayout.Foot
          className={classNames('z-10 rounded bg-white p-4', {
            '!fixed bottom-0 left-0 right-0': isMobile,
          })}
        >
          {/*         <SaveJobActions
            assignee={watch('assignee')}
            jobName={watch('name')}
            status={job.status}
            loading={isSubmitting}
            notifyDefault={false}
            onClick=
          /> */}{' '}
          <SaveJobActions
            assignee={watch('assignee')}
            jobName={watch('name')}
            status={job.status}
            loading={isSubmitting}
            onClick={submit}
            notifyDefault={false}
          />
        </SideLayout.Foot>
      </SideLayout>
      {taskSection}
      <PopupDialog isOpen={showSite} onClose={() => setShowSite(false)}>
        <PopupDialog.Title>{location?.name}</PopupDialog.Title>
        {job.location && (
          <SiteView
            isPopup={true}
            siteId={job.location.id}
            onClose={() => setShowSite(false)}
          />
        )}
      </PopupDialog>
      {showRemoveConfirmation && (
        <Confirm
          show={showRemoveConfirmation}
          title={'Confirm Template Removal'}
          confirm={'Remove'}
          cancel={t('cancel')}
          onCancel={() => setShowRemoveConfirmation(false)}
          onConfirm={() => {
            removeTemplate();
            setShowRemoveConfirmation(false);
          }}
          danger={true}
        >
          <div className='text-md'>
            Removing this template will delete all the current tasks. Are you
            sure you want to proceed?
          </div>
        </Confirm>
      )}
    </div>
  );
}
