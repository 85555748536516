import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { IconButton } from '~/components/ui/nucleus/Button';
export type BottomSheetProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  /**
   * Use tailwind classes
   * @example 'p-4'
   */
  padding?: string;
  /**
   * When true, this sheet is stacked behind a new one,
   * so it should shrink to 90% width and shift upward by 6px.
   */
  stacked?: boolean;
  hasDivider?: boolean;
};

export const BottomSheet = ({
  children,
  isOpen,
  onClose,
  title,
  padding = 'p-0',
  stacked = false,
  hasDivider = false,
}: BottomSheetProps) => {
  const containerVariants = {
    hidden: {
      y: '100%',
      opacity: 0,
      width: '100%',
    },
    visible: {
      y: stacked ? -10 : 0,
      width: stacked ? '90%' : '100%',
      marginInline: stacked ? 'auto' : '0',
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: [0.4, 0, 0.2, 1],
        width: {
          delay: stacked ? 0.2 : 0,
          duration: 0.2,
          ease: [0.4, 0, 0.2, 1],
        },
      },
    },
    exit: {
      y: '100%',
      opacity: 0,
      transition: { duration: 0.3, ease: [0.4, 0, 0.2, 1] },
    },
  };

  const backdropVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.35, ease: [0.4, 0, 0.2, 1] },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.3, ease: [0.4, 0, 0.2, 1] },
    },
  };

  const dragThreshold = 100; // pixels downward required to close the sheet

  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={onClose}
          as='div'
          className='fixed inset-0 z-[100]'
        >
          {/* Backdrop */}
          <motion.div
            className='fixed inset-0 bg-slate-800 bg-opacity-60'
            variants={backdropVariants}
            initial='hidden'
            animate='visible'
            exit='exit'
            onClick={onClose}
          />

          {/* Bottom Sheet Container */}
          <div className='fixed inset-x-0 bottom-0 top-6'>
            <motion.div
              className={
                'flex h-full flex-col overflow-auto rounded-t-2xl bg-surface-container-bright shadow-lg'
              }
              style={{ zIndex: stacked ? 1200 : 1100 }}
              variants={containerVariants}
              initial='hidden'
              animate='visible'
              exit='exit'
              drag='y'
              dragConstraints={{ top: 0, bottom: 0 }}
              dragElastic={0.2}
              onDragEnd={(event, info) => {
                if (info.offset.y > dragThreshold) {
                  onClose();
                }
              }}
            >
              {title ? (
                <div
                  className={classNames(
                    hasDivider && 'border-b',
                    'grid h-[70px] grid-cols-[45px_minmax(0,_1fr)_45px] items-center justify-between gap-2 px-3'
                  )}
                >
                  <div></div>
                  <div className='flex w-full items-center justify-center'>
                    <span className='max-w-full truncate text-xl font-medium'>
                      {title}
                    </span>
                  </div>
                  <IconButton
                    type='close'
                    onClick={onClose}
                    ariaLabel='Close popup'
                  />
                </div>
              ) : (
                <div
                  className={classNames(
                    hasDivider && 'border-b',
                    'flex h-[70px] items-center justify-end px-3'
                  )}
                >
                  <IconButton
                    className='ml-auto'
                    type='close'
                    onClick={onClose}
                    ariaLabel='Close popup'
                  />
                </div>
              )}

              <div
                className={classNames(
                  'flex flex-1 flex-col overflow-auto',
                  padding
                )}
              >
                {children}
              </div>
            </motion.div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
