import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import React, { createContext, useContext } from 'react';
import { IconButton } from '~/components/ui/nucleus/Button/IconButton';

type SubpageProps = {
  title: string;
  isOpen: boolean;
  backTo: () => void;
  children?: React.ReactNode;
  form?: React.ReactNode;
  direction?: 'forward' | 'backward';
  onClose: () => void;
};

type Context = {
  backTo: () => void;
};

const SettingsSubpageContext = createContext({} as Context);

export const useSettingsContext = () => useContext(SettingsSubpageContext);

const variants = {
  forward: {
    initial: { x: '100%', opacity: 0 },
    animate: { x: '0%', opacity: 1 },
    exit: { x: '10%', opacity: 0 },
  },
  backward: {
    initial: { x: '-100%', opacity: 0 },
    animate: { x: '0%', opacity: 1 },
    exit: { x: '-10%', opacity: 0 },
  },
};

export const SettingsSubpage = ({
  title,
  isOpen,
  backTo,
  children,
  form,
  onClose,
  direction = 'forward',
}: SubpageProps) => {
  const animation = variants[direction];

  return (
    <SettingsSubpageContext.Provider value={{ backTo }}>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={animation.initial}
            animate={animation.animate}
            exit={animation.exit}
            transition={{
              duration: 0.3,
              ease: [0.4, 0, 0.2, 1],
              opacity: { duration: 0.3 },
            }}
            className='fixed inset-0 z-50 overflow-y-auto rounded-2xl bg-surface-container-low p-6 lg:absolute lg:p-10'
          >
            <div className='flex h-full flex-col'>
              <div className='flex items-center gap-4 pb-10'>
                <IconButton
                  //@ts-ignore
                  icon={faArrowLeft}
                  onClick={backTo}
                  ariaLabel='Back'
                />
                <h1 className='text-lg font-medium'>{title}</h1>{' '}
                <IconButton
                  className='absolute right-8 top-8'
                  type='close'
                  onClick={onClose}
                  ariaLabel='Close popup'
                />
              </div>
              {children ?? form}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </SettingsSubpageContext.Provider>
  );
};
