export const DefaultAvatarImage = () => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid meet'
  >
    <circle cx='20' cy='13' r='8' fill='url(#gradient1)' />
    <path
      d='M5 32C5 27.5817 8.58172 24 13 24H27C31.4183 24 35 27.5817 35 32V38H5V32Z'
      fill='url(#gradient2)'
    />
    <defs>
      <linearGradient
        id='gradient1'
        x1='11'
        y1='4'
        x2='29'
        y2='22'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0' stopColor='#005959' />
        <stop offset='1' stopColor='#fff' />
      </linearGradient>
      <linearGradient
        id='gradient2'
        x1='5'
        y1='24'
        x2='35'
        y2='38'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.3' stopColor='#005959' />
        <stop offset='0.95' stopColor='#fff' />
      </linearGradient>
    </defs>
  </svg>
);

interface DefaultAvatarProps {
  /**
   * Size classes for both height and width (must include both h-X and w-X)
   * @example 'h-8 w-8', 'h-12 w-12', 'h-16 w-16'
   */
  size?: string;
  bgColor?: string;
  className?: string;
}

export const DefaultAvatar = ({
  size = 'h-8 w-8',
  bgColor = 'bg-surface-container-low',
  className = '',
}: DefaultAvatarProps) => {
  // Calculate inner size style based on scale

  return (
    <div
      className={`flex ${size} ${className} items-center justify-center rounded-full ${bgColor}`}
    >
      <div className='h-5/6 w-5/6 overflow-hidden rounded-full'>
        <DefaultAvatarImage />
      </div>
    </div>
  );
};
