import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Portal,
  Transition,
} from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Fragment, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '~/App';
import { Image } from '~/components/ui/Image';
import { initials } from '~/helpers/initials';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useLocationHash } from '~/hooks/useLocationHash';

export function TenantSwitcher() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setShowSettings] = useLocationHash('#org-settings-open');
  const {
    tenant: { id: tid },
    user,
  } = useContext(AppContext);

  const { isMobile } = useBreakpoint();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  if (!user) {
    return null;
  }

  const currentTenant = user.tenants.find((tenant) => tenant.id === tid);

  if (!currentTenant) {
    return null;
  }

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      isMobile
        ? { name: 'flip', options: { padding: 10 } }
        : { name: 'offset', options: { offset: [0, 4] } },
    ],
  });

  // Function to handle element reference setting and pass to ref
  const setButtonRef = (element: HTMLButtonElement | null) => {
    setReferenceElement(element);
    buttonRef.current = element;
  };

  const handleChange = (value: (typeof user.tenants)[0] | null) => {
    if (value === null) {
      return;
    }
    const tenantId = value.id;
    window.axios.patch(`/sessions`, { tenantId }).then(() => {
      window.location.reload();
    });
  };

  return (
    <Listbox
      className='relative flex-1'
      value={currentTenant}
      onChange={handleChange}
    >
      <div className='relative'>
        <ListboxButton
          ref={setButtonRef}
          className='focus-visible:ring-offset-orange-300 relative flex w-full items-center gap-3 rounded-md border border-outline-bright bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm lg:justify-end lg:gap-2 lg:border-outline-variant lg:py-1.5'
        >
          <div className='h-10 w-10 lg:h-7 lg:w-7'>
            <Logo name={currentTenant.name} url={currentTenant.image} />
          </div>
          <span className='block truncate text-lg lg:text-base'>
            {currentTenant?.name ?? currentTenant?.slug}
          </span>
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
            <SelectorIcon
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </span>
        </ListboxButton>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Portal>
            <ListboxOptions
              ref={setPopperElement}
              style={{
                ...styles.popper,
                width:
                  isMobile && buttonRef.current
                    ? `${buttonRef.current.offsetWidth}px`
                    : undefined,
              }}
              {...attributes.popper}
              className='absolute z-[9001] mt-1 max-h-screen overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none lg:w-80'
            >
              <div>
                {/* <Listbox.Option
                  as='div'
                  className={({ active }) =>
                    `block cursor-default select-none px-4 py-2 ${
                      active ? 'bg-brand text-white' : 'text-primary'
                    }`
                  }
                  onClick={() => navigate('/accounts/new')}
                  value={null}
                >
                  <div className='flex flex-1 items-center gap-2'>
                    <div className='h-7 w-7 p-1'>
                      <PlusIcon />
                    </div>
                    <span className='block truncate font-normal'>
                      Create New Account
                    </span>
                  </div>
                </Listbox.Option> */}
                {user.tenants.map((tenant) => (
                  <ListboxOption
                    key={tenant.id}
                    className={({ active }) =>
                      `cursor-default select-none rounded-sm px-4 py-2 ${
                        active ? 'bg-brand text-white' : 'text-primary'
                      }`
                    }
                    value={tenant}
                  >
                    {({ active, selected }) => (
                      <div className='flex justify-between'>
                        <div className='flex flex-1 items-center gap-2 '>
                          <div className='h-7 w-7'>
                            <Logo name={tenant.name} url={tenant.image} />
                          </div>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {tenant.name ?? tenant.slug}
                          </span>
                        </div>
                        {selected ? (
                          <span
                            className={`${
                              active ? 'text-inherit' : 'text-brand'
                            } flex items-center`}
                          >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </ListboxOption>
                ))}
              </div>
              <div className='mt-1 hidden border-t border-outline-bright lg:block'>
                <ListboxOption
                  as='div'
                  className={({ active }) =>
                    `block cursor-default select-none rounded-sm px-4 pb-2 pt-3 ${
                      active ? 'bg-brand text-white' : 'text-primary'
                    }`
                  }
                  onClick={() => setShowSettings(true)}
                  value={null}
                >
                  <div className='flex flex-1 items-center gap-2 pb-1'>
                    <div className='h-7 w-7 p-1'>
                      <FontAwesomeIcon
                        //@ts-ignore
                        icon={faCog}
                        className='h-5 w-5'
                      />
                    </div>
                    <span className='block truncate font-normal'>
                      {t('settings.organisationSettings')}
                    </span>
                  </div>
                </ListboxOption>
              </div>
            </ListboxOptions>
          </Portal>
        </Transition>
      </div>
    </Listbox>
  );
}

type LogoProps = {
  name: string;
  url?: string;
};

function Logo({ name, url }: LogoProps) {
  return (
    <div
      className={
        'flex h-full w-full shrink-0 items-center justify-center overflow-hidden rounded-md bg-gray-300 text-center font-medium text-gray-500 lg:text-sm'
      }
    >
      {url ? (
        <Image borders={false} objectFit='contain' url={url} size='fit' />
      ) : (
        initials(name)
      )}
    </div>
  );
}
