import { Contact } from '~/components/job/JobView/JobReadView/Bubbles';
import { Attribute } from '~/generated/graphql';
import { IAttribute } from '~/types';
import { AttributeView } from '../AttributeView';

type Props = {
  /** Model data with attributes */
  data: { attributes?: IAttribute[] } | { attributes?: Attribute[] | null };
  contacts?: Contact[];
};

/**
 * The read view for model attributes
 */
export function Attributes({ data, contacts }: Props) {
  // Clean out junk, but leave the main filtering to AttributeView
  const attributes = data.attributes?.filter((attribute) => !!attribute.name);

  if (!(attributes && attributes.length)) {
    return null;
  }

  return <AttributeView attributes={attributes} contacts={contacts} />;
}
