import qs from 'qs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';
import { useStockOnHand } from '~/api/stock-on-hand';
import { AuthGate } from '~/components/AuthGate';
import { ListDetailView } from '~/components/ListDetailView';
import {
  ManageStockActions,
  ManageStockFAB,
} from '~/components/ManageStockActions';
import { FilterForm, FilterProps } from '~/components/filter/FilterForm';
import { SelectField } from '~/components/form/downshift/SelectField';
import { StockManipulationComponent } from '~/components/smc/StockManipulationComponent';
import { TransferStock } from '~/components/stock/TransferStock';
import { StockOnHandTable } from '~/components/stock/stock-on-hand/StockOnHandTable';
import { Avatar } from '~/components/ui/Avatar';
import { Result } from '~/components/ui/Result';
import { VirtualList } from '~/components/ui/VirtualList';
import { View, ViewSwitcher } from '~/components/view-switcher/ViewSwitcher';
import { withModal } from '~/components/withModal';
import {
  ItemType,
  LocationType,
  Status,
  useLocationsQuery,
} from '~/generated/graphql';
import { getFragmentData } from '~/gql';
import { useLocalStorage } from '~/hooks';
import { useEnumOptions } from '~/hooks/useEnumOptions';
import { useSearch } from '~/hooks/useSearch';
import { StockNav } from '~/layouts/nav/StockNav';
import { StockOnHand as Stock } from '~/types';
import { ItemData_ItemFragment, ItemsQueryDocument } from './items';

enum SortBy {
  ITEM = 'item.name:asc',
  SKU = 'item.sku:asc',
  LOCATION = 'location.name:asc',
}

const SMCModal = withModal(StockManipulationComponent);
const TransferStockModal = withModal(TransferStock);

const SEARCH_OPTIONS = {
  keys: [
    'locationType',
    'location',
    'itemType',
    'sku',
    'skuCode',
    'skuName',
    'locationName',
    'spaceName',
  ],
};

export const StockOnHand = () => {
  const localStorage = useLocalStorage();
  const { t } = useTranslation();
  const [view, setView] = useState<View>('CARD');
  const navigate = useNavigate();
  const [query, setQuery] = useState<string>(() => {
    const sortBy = localStorage.get('stock-on-hand.sort') ?? SortBy.ITEM;
    if (Object.values(SortBy).includes(sortBy as any)) {
      return qs.stringify({ sortBy });
    }
    return '';
  });
  const { data, mutate: revalidate } = useStockOnHand(query);
  const { search, results } = useSearch(data, SEARCH_OPTIONS);

  const stock = results ?? [];

  const handleFiltered: FilterProps['onFiltered'] = (obj) => {
    setQuery(qs.stringify({ ...obj, sortBy: qs.parse(query)?.sortBy }));
  };

  const smcHandleSuccess = () => {
    navigate(-1);
    revalidate();
  };

  const smcHandleCancel = () => {
    navigate('/stock-on-hand');
  };

  useEffect(() => {
    const navigateTo = localStorage.get('nav.items') ?? '/stock-on-hand';
    if (navigateTo !== '/stock-on-hand') {
      navigate(navigateTo);
    }
  }, []);

  return (
    <AuthGate action='manage' subject='feat.items'>
      <ListDetailView
        nav={<StockNav />}
        actions={<ManageStockActions />}
        filters={
          <>
            <StockOnHandFilters onFiltered={handleFiltered} onSearch={search} />
            <div className='mt-3 flex items-center bg-white pr-4'>
              <ViewSwitcher selected={view} onSelect={setView} />
              <div className='label ml-4 mr-auto'>
                <span className='text-copy-body'>{t('sortBy')}</span>{' '}
                <select
                  className='bg-white'
                  value={(qs.parse(query)?.sortBy as SortBy) ?? SortBy.ITEM}
                  onChange={(event) => {
                    const value = event.target.value;
                    const obj = qs.parse(query);
                    obj.sortBy = value;
                    setQuery(qs.stringify(obj));
                    localStorage.set('stock-on-hand.sort', value);
                  }}
                >
                  <option value={SortBy.ITEM}>Item Name</option>
                  <option value={SortBy.SKU}>Item SKU</option>
                  <option value={SortBy.LOCATION}>Location</option>
                </select>
              </div>
              <Result count={stock?.length} />
            </div>
          </>
        }
        main={
          view === 'CARD' ? (
            <VirtualList
              data={stock}
              getListItemProps={getListItemProps}
              placeholderIcon={(item) => (
                <Avatar
                  name={item.skuName}
                  className='rounded'
                  size='full'
                  square
                />
              )}
            />
          ) : (
            <StockOnHandTable data={stock} />
          )
        }
      >
        <Route path='*' element={<ManageStockFAB />} />
        <Route
          path='stock-adjustment'
          element={
            <SMCModal
              mode='adjustment'
              onSuccess={smcHandleSuccess}
              onCancel={smcHandleCancel}
            />
          }
        />
        <Route
          path='stocktake'
          element={
            <SMCModal
              mode='stocktake'
              onSuccess={smcHandleSuccess}
              onCancel={smcHandleCancel}
            />
          }
        />
        <Route
          path='transfer-stock'
          element={<TransferStockModal returnTo='/stock-on-hand' />}
        />
      </ListDetailView>
    </AuthGate>
  );
};

const getListItemProps = (stock: Stock, scrollTo?: number | string | null) => ({
  id: stock.id,
  linkTo: `/stock-on-hand`,
  image: stock.image,
  titleText: stock.skuName,
  secondaryText: `${stock.locationName} - ${stock.spaceName}`,
  heroElement: (
    <div className='flex flex-col'>
      <p className='text-right text-sm leading-tight'>
        <span className='text-medium text-[17px] leading-tight'>
          {stock.soh}
        </span>
        {/* {stock.target ? ' / ' + stock : ''} */}
      </p>
      <p className='text-xs leading-5 text-grey-40'>On hand</p>
    </div>
  ),
  active: Boolean(scrollTo && '' + scrollTo === stock.id),
});

function useLocationOptions() {
  const [result] = useLocationsQuery({
    variables: { type: LocationType.Site },
  });
  const { data } = result;
  return data?.locations
    ? data.locations.map(({ id, name }) => ({ value: `s:${id}`, label: name }))
    : [];
}

function useSkuOptions() {
  const [result] = useQuery({
    query: ItemsQueryDocument,
    variables: { status: Status.Active, inConfig: true },
  });
  const { data } = result;
  return data?.items
    ? data.items.flatMap((item) => {
        const { skus } = getFragmentData(ItemData_ItemFragment, item);

        return skus.map(({ id, name }) => ({ value: id, label: name }));
      })
    : [];
}

const StockOnHandFilters = ({ onFiltered, onSearch }: FilterProps) => {
  const { t } = useTranslation();
  const itemTypes = useEnumOptions(ItemType, 'itemType');
  const locationOptions = useLocationOptions();
  const skuOptions = useSkuOptions();

  return (
    <FilterForm
      cols='5'
      initialValues={{
        locationType: [],
        location: [],
        space: [],
        itemType: [],
        skuId: [],
      }}
      onFiltered={onFiltered}
      onSearch={onSearch}
    >
      <SelectField
        floating
        name='location'
        label='Location'
        placeholder={t('location')}
        options={locationOptions}
        multiple
      />
      <SpacesSelect />
      <SelectField
        floating
        name='itemType'
        label={t('itemTypeLabel')}
        placeholder={t('itemTypeLabel')}
        options={itemTypes}
        multiple
      />
      <SelectField
        floating
        name='skuId'
        label={t('sku')}
        placeholder={t('sku')}
        options={skuOptions}
        multiple
      />
    </FilterForm>
  );
};

function SpacesSelect() {
  const { t } = useTranslation();

  const [result] = useQuery<{ spaceNames: string[] }>({
    query: '{ spaceNames }',
  });
  const { data } = result;

  const spaceOptions =
    data?.spaceNames.map((value) => ({ value, label: value })) ?? [];

  return (
    <SelectField
      floating
      name='space'
      label={t('space')}
      placeholder={t('space')}
      options={spaceOptions}
      multiple
    />
  );
}
