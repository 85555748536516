import { Dialog, DialogTitle } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { IconButton } from '~/components/ui/nucleus/Button';

interface UserSettingsPopupProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
}

export function UserSettingsPopup({
  children,
  isOpen,
  onClose,
  title,
}: UserSettingsPopupProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog
          static
          open={isOpen}
          onClose={onClose}
          className='fixed inset-0 z-[70] flex items-center justify-center overflow-hidden'
        >
          <motion.button
            className='fixed inset-0 cursor-default bg-slate-800 bg-opacity-60'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            onClick={onClose}
            aria-label='Close dialog'
          />

          {/* Container prevents scrollbar during animation */}
          <motion.div
            className='relative z-10 mx-4 flex max-h-[90vh] w-full max-w-[800px] flex-col overflow-hidden rounded-2xl bg-surface-container-low shadow-xl lg:h-[800px]'
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.25, ease: [0.4, 0, 0.2, 1] }}
          >
            <div className='flex items-center justify-between px-6 pb-0 lg:px-8 lg:pt-8'>
              <div></div>
              {title && (
                <DialogTitle className='text-lg font-medium'>
                  {title}
                </DialogTitle>
              )}
              <IconButton
                type='close'
                onClick={onClose}
                ariaLabel='Close popup'
              />
            </div>
            <div className='overflow-y-auto'>{children}</div>
          </motion.div>
        </Dialog>
      )}
    </AnimatePresence>
  );
}
