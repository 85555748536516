import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VariantProps, cva } from 'class-variance-authority';
import classNames from 'classnames';

// Classes Removed from previous styling: 'min-w-[6rem] max-w-[16rem] overflow-x-hidden'

export const pillStyles = cva(
  'flex cursor-pointer items-center h-8 justify-center gap-2 rounded-full px-4 text-base whitespace-nowrap w-fit transition-all ease-standard',
  {
    variants: {
      type: {
        // Common button styles
        button: 'border hover:text-primary active:text-primary text-secondary',
        // Common filter styles - mobile comes first, desktop styles with lg: prefix
        filter:
          'bg-white text-white lg:text-secondary lg:border lg:hover:text-primary',
      },
      active: {
        true: 'font-medium',
        false: 'font-normal',
      },
      hasParent: {
        true: '',
        false: 'mx-1 my-2',
      },
    },
    defaultVariants: {
      type: 'button',
      active: false,
      hasParent: false,
    },
    compoundVariants: [
      // Only use compound variants for specific combinations
      // Button active state
      {
        type: 'button',
        active: true,
        className: 'bg-grey-5 border-outline-active hover:border-grey-30',
      },
      // Button inactive state
      {
        type: 'button',
        active: false,
        className:
          'border-outline-variant hover:border-outline-default active:border-outline-active bg-white',
      },
      // Filter active state
      {
        type: 'filter',
        active: true,
        className:
          'bg-opacity-35 lg:bg-grey-5 lg:border-outline-active lg:active:border-outline-bright lg:hover:border-grey-30',
      },
      // Filter inactive state
      {
        type: 'filter',
        active: false,
        className:
          'bg-opacity-15 lg:border-outline-variant lg:active:border-outline-bright lg:hover:border-grey-40',
      },
    ],
  }
);

type PillProps<T extends React.ElementType> = {
  as?: T;
  icon?: {
    position?: 'left' | 'right';
    icon: IconDefinition;
    className?: string;
  };
  type?: 'button' | 'filter';
} & VariantProps<typeof pillStyles>;

export function Pill<T extends React.ElementType = 'div'>({
  as,
  className,
  active = false,
  hasParent = false,
  icon,
  type = 'button',
  ...props
}: PillProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof PillProps<T>>) {
  const Component = as ?? 'div';

  return (
    <Component
      className={classNames(pillStyles({ active, hasParent, type }), className)}
      data-filter={type === 'filter' ? 'true' : 'false'}
      {...props}
    >
      {icon && icon.position === 'left' && (
        // @ts-ignore
        <FontAwesomeIcon
          // @ts-ignore
          icon={icon.icon}
          className={classNames('h-3.5 w-3.5', icon.className)}
        />
      )}
      {props.children}
      {icon && icon.position === 'right' && (
        <FontAwesomeIcon
          // @ts-ignore
          icon={icon.icon}
          className={classNames('h-3.5 w-3.5', icon.className)}
        />
      )}
    </Component>
  );
}
