import { Thumb } from '../upload/Thumb';
import { UploadResult } from './Upload';

type Props = {
  uploads: UploadResult[];
  onRemove: (id: string) => void;
};

/**
 * Display uploads for the upload form field
 */
export function UploadPreviews({ uploads, onRemove }: Props) {
  return (
    // The margin, padding and gap here allow a little extra room for the "X" button
    <div className='mb-4 mt-6 grid w-full grid-cols-2 gap-4 xl:grid-cols-4'>
      {uploads.map(({ id, kind, preview, name }) => (
        <Thumb
          key={id}
          kind={kind}
          url={preview}
          name={name}
          onRemove={() => {
            onRemove(id);
          }}
        />
      ))}
    </div>
  );
}
