import { TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { VariantList } from '~/components/item/VariantList';
import { AttributesList } from '~/components/sites/tabs/AttributesList';
import { Gallery } from '~/components/ui/Gallery';
import { Loading } from '~/components/ui/Loading';
import { Menu } from '~/components/ui/Menu';
import { ReadValue } from '~/components/ui/ReadValue';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { TabHeader } from '~/components/ui/TabHeader';
import { getFragmentData } from '~/gql';
import { ItemsQuery } from '~/gql/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { Item, ItemData_ItemFragment } from '.';

type ChildProps = {
  item: Item;
  reload: () => void;
};

function ItemMenu() {
  const { t } = useTranslation();

  return (
    <>
      <Menu>
        <Menu.Item as={Link} to='edit'>
          {t('editItem')}
        </Menu.Item>
      </Menu>
    </>
  );
}

const ReadView = (props: ChildProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const item = getFragmentData(ItemData_ItemFragment, props.item);

  const [showInactive, setShowInactive] = useState<boolean>(false);
  const inactiveCount = useMemo(() => {
    return item.skus.reduce((count, sku) => {
      return count + (sku.status === 'Inactive' ? 1 : 0);
    }, 0);
  }, [item.skus]);

  const skus = showInactive
    ? item.skus
    : item.skus.filter((v) => v.status === 'Active');

  return (
    <SideLayout>
      <SideLayout.Head
        rightSlot={<ItemMenu />}
        onClose={() => navigate('/items')}
      >
        {item.name}
      </SideLayout.Head>
      <SideLayout.Body>
        <div className='mb-5'>
          <StatusBadge value={item.status} rounded />
        </div>

        <ReadValue label='itemTypeLabel' value={t('itemType.' + item.type)} />
        <ReadValue label='description' value={item.description} />

        {!item.hasVariants && (
          <ReadValue label='skuCode' value={item.skuPrefix} />
        )}

        <Gallery images={item.image} />

        <TabGroup className='mb-4 w-auto rounded-t-2xl border border-grey-20'>
          <TabList className='bg-blue-900/20 flex overflow-hidden rounded-t-2xl'>
            <TabHeader title='Attributes' />
            {item.hasVariants && <TabHeader title={t('Variants')} />}
          </TabList>
          <TabPanels className=''>
            <TabPanel className='relative flex min-h-[20rem] flex-col bg-grey-2'>
              <AttributesList
                attributes={item.attributes}
                onUpdate={() => props.reload()}
                entityId={`Item:${item.id}`}
                entityTypeName={t('Item')}
              />
            </TabPanel>
            <TabPanel>
              {item.hasVariants && (
                <>
                  <div className='flex items-center'>
                    <h3 className='my-4 font-semibold'></h3>
                    {inactiveCount > 0 && (
                      <label className='ml-auto block text-sm'>
                        <input
                          className='mr-1'
                          type='checkbox'
                          checked={showInactive}
                          onChange={(event) =>
                            setShowInactive(event.target.checked)
                          }
                        />
                        Show {inactiveCount} Inactive
                      </label>
                    )}
                  </div>
                  <VariantList skus={skus} />
                </>
              )}
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </SideLayout.Body>
    </SideLayout>
  );
};

type Props = {
  data?: ItemsQuery;
  reload: () => void;
};

export const ItemView = ({ data, reload }: Props) => {
  const { id } = useParams();
  const item = useMemo(
    () => data?.items.find((i) => i.id.toString() === id),
    [data, id]
  );

  if (!item) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route path='*' element={<ReadView item={item} reload={reload} />} />
    </Routes>
  );
};
