import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { Can, useAbilityContext } from '~/components/Can';
import { NavLink } from '~/components/NavLink';
import { Navbar } from '~/components/nav/Navbar';
import { PropFitLogoFull } from '~/components/ui/PropFitLogoFull';
import { BottomSheet } from '~/components/ui/nucleus/Sheets';
import { useLocalStorage } from '~/hooks';
import { NavLogo } from './NavLogo';
import ContactsIcon from './icons/Contacts';
import SpacesIcon from './icons/Spaces';

type MobileMenuState = {
  in: boolean;
  style: CSSProperties;
};

type MobileMenuProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const MobileMenu = ({ isOpen, onClose }: MobileMenuProps) => {
  const { t } = useTranslation();

  return (
    <BottomSheet title={t('more')} isOpen={isOpen} onClose={onClose}>
      <nav className='flex w-full flex-1 flex-col items-center justify-between'>
        <ul className='w-full divide-y divide-outline-bright pt-4'>
          <Can do='manage' on='feat.templates'>
            <li className='cursor-pointer px-6 text-lg text-copy-head'>
              <NavLink
                to='/job-templates'
                onClick={onClose}
                className='flex flex-row items-center justify-start py-4 font-medium text-copy-alt hover:text-brand lg:py-0 lg:text-base lg:font-normal'
              >
                <span>
                  <FontAwesomeIcon
                    className='-ml-0.5 mr-3.5 block h-6 w-8 flex-1 fill-current'
                    icon={faListCheck}
                  />
                </span>
                {t('jobTemplate_plural')}
              </NavLink>
            </li>
          </Can>
          <li className='cursor-pointer px-6 text-lg text-copy-head'>
            <NavLink
              to='/contacts'
              onClick={onClose}
              className='flex flex-row items-center justify-start py-4 font-medium text-copy-alt hover:text-brand lg:py-0 lg:text-base lg:font-normal'
            >
              <span>
                <ContactsIcon className='-ml-0.5 mr-3.5 block h-8 w-8 flex-1 fill-current' />
              </span>
              {t('contact_plural')}
            </NavLink>
          </li>
          <li className='hidden cursor-pointer px-6 text-lg text-copy-head'>
            <NavLink
              to='/spaces'
              onClick={onClose}
              className='flex flex-row items-center justify-start py-4 font-medium text-copy-alt hover:text-brand lg:py-0 lg:text-base lg:font-normal'
            >
              <span>
                <SpacesIcon className='-ml-0.5 mr-3.5 block h-8 w-8 flex-1 fill-current' />
              </span>
              {t('space_plural')}
            </NavLink>
          </li>
        </ul>
        <PoweredBy />
      </nav>
    </BottomSheet>
  );
};

export const MoreNav = () => {
  const { t } = useTranslation();
  const localStorage = useLocalStorage();

  const location = useLocation();
  const matchJobTemplates = useMatch('/job-templates/*');
  const matchAutomations = useMatch('/automations/*');
  const matchContacts = useMatch('/contacts/*');

  const pages = [
    {
      title: 'contact_plural',
      url: '/contacts',
    },
  ];

  const ability = useAbilityContext();

  if (ability.can('manage', 'feat.templates')) {
    pages.splice(0, 0, {
      title: 'jobTemplate_plural',
      url: '/job-templates',
    });
  }

  if (ability.can('manage', 'feat.automation')) {
    pages.splice(1, 0, {
      title: 'automation_plural',
      url: '/automations',
    });
  }

  if (ability.can('manage', 'feat.items')) {
    pages.push({
      title: 'space_plural',
      url: '/spaces',
    });
  }

  function remember(event: React.MouseEvent<HTMLAnchorElement>) {
    const { pathname } = event.currentTarget;
    if (pathname) {
      localStorage.set('nav.more', pathname);
    }
  }

  return (
    <>
      <div className='row flex w-full items-center justify-between py-3 pr-4 lg:hidden'>
        <NavLogo />
        {pages.map(({ title, url }) => {
          return url === location.pathname ? (
            <h1 key={url} className='text-xl font-semibold text-white'>
              {t(title)}
            </h1>
          ) : (
            ''
          );
        })}
        <div className='w-10 text-right'>&nbsp;</div>
      </div>
      <div className='hidden lg:flex'>
        <Navbar>
          {pages.map(({ title, url }) => (
            <Navbar.Item key={title} as={NavLink} to={url} onClick={remember}>
              {t(title)}
            </Navbar.Item>
          ))}
          <Can do='manage' on='feat.templates'>
            {matchJobTemplates && (
              <div className='ml-auto flex items-center'>
                <Link className='add-button' to='new'>
                  + {t('jobTemplate')}
                </Link>
              </div>
            )}
          </Can>
          <Can do='manage' on='feat.automation'>
            {matchAutomations && (
              <div className='ml-auto flex items-center'>
                <Link className='add-button' to='new'>
                  + {t('automation')}
                </Link>
              </div>
            )}
          </Can>
          {matchContacts && (
            <div className='ml-auto flex items-center'>
              <Link className='add-button' to='new'>
                + {t('contact')}
              </Link>
            </div>
          )}
        </Navbar>
      </div>
    </>
  );
};

export const PoweredBy = () => {
  return (
    <div className='mx-auto mb-5 mt-auto flex items-center gap-2'>
      <span className='text-secondary'>Powered by </span>
      <PropFitLogoFull fill='#288383 ' />
    </div>
  );
};
