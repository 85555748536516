import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AppContext } from '~/App';
import { CurrentAvatar } from '~/components/ui/Avatar';
import { BottomSheet } from '~/components/ui/nucleus/Sheets';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useLocationHash } from '~/hooks/useLocationHash';
import { NotificationsForm } from '../account/NotificationsForm';
import {
  Group,
  SettingLink,
  SettingLinkType,
  SettingsSubpage,
  Tab,
  UserSettingsPopup,
} from '../ui';
import { PersonalInfoForm } from './PersonalInfoForm';
import { UpdateEmailForm } from './UpdateEmailForm';
import { UpdatePasswordForm } from './UpdatePasswordForm';
import { UpdateProfilePicForm } from './UpdateProfilePicForm';

export type PageProps = {
  page: SettingPage;
  showPage: Page;
  setShowPage: (page: Page) => void;
  setShowSettings: (show: boolean) => void;
};

export type Page =
  | null
  | 'profilePicture'
  | 'personal'
  | 'email'
  | 'password'
  | 'notifications';

type SettingPage = {
  name: string;
  description: string;
  page: Page;
  Component: (props: PageProps) => JSX.Element;
};

type SettingGroup = {
  title: string;
  pages: (SettingPage | SettingLinkType)[];
};

const isLink = (page: SettingPage | SettingLinkType): page is SettingLinkType =>
  'linkTo' in page;

const settings: SettingGroup[] = [
  {
    title: 'settings.aboutYou',
    pages: [
      {
        name: 'Personal Information',
        description: 'Name & Phone Number',
        page: 'personal',
        Component: PersonalInfoForm,
      },
      {
        name: 'Email',
        description: '📧 Update your registered email address',
        page: 'email',
        Component: UpdateEmailForm,
      },
      {
        name: 'Security',
        description: '🔒 Update your Password',
        page: 'password',
        Component: UpdatePasswordForm,
      },
      {
        name: 'Notifications',
        description: 'Change your notification settings',
        page: 'notifications',
        Component: NotificationsForm,
      },
    ],
  },
];

export const UserSettings = () => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const { isMobile } = useBreakpoint();
  const [showSettings, setShowSettings] = useLocationHash(
    '#user-settings-open'
  );
  const [showPage, setShowPage] = useState<Page>(
    hash.endsWith(':email')
      ? 'email'
      : hash.endsWith(':notifications')
      ? 'notifications'
      : null
  );
  const { user: currentUser } = useContext(AppContext);

  const pageProps = {
    backTo: () => setShowPage(null),
    showPage,
    setShowPage,
    setShowSettings,
  };

  const getPageProps = (page: SettingPage) => ({ page, ...pageProps });

  const settingsContent = (
    <div className='flex flex-col items-center p-6 pt-0 lg:p-10 lg:pt-0'>
      <div className='h-24 w-24' onClick={() => setShowPage('profilePicture')}>
        <CurrentAvatar className='cursor-pointer' size='full' />
      </div>
      <SettingsSubpage
        onClose={() => setShowSettings(false)}
        title='Edit Profile Picture'
        isOpen={showPage === 'profilePicture' ? true : false}
        backTo={() => {
          setShowPage(null);
        }}
        form={<UpdateProfilePicForm {...pageProps} />}
      />
      <p className='pt-4 text-greyhound'>{currentUser?.name}</p>

      {settings.map((group) => (
        <Fragment key={group.title}>
          <Group title={t(group.title)} />
          {group.pages.map((page) =>
            isLink(page) ? (
              <SettingLink key={page.name} {...page} />
            ) : (
              <Page key={page.name} {...getPageProps(page)} />
            )
          )}
        </Fragment>
      ))}
    </div>
  );

  // If we're in a bottom sheet (mobile), return content directly
  if (isMobile) {
    return (
      <>
        <BottomSheet
          isOpen={showSettings}
          onClose={() => setShowSettings(false)}
          padding='none'
        >
          {settingsContent}
        </BottomSheet>
      </>
    );
  }

  // If we're on desktop, wrap in the popup
  return (
    <>
      <UserSettingsPopup
        isOpen={showSettings}
        onClose={() => setShowSettings(false)}
      >
        {settingsContent}
      </UserSettingsPopup>
    </>
  );
};

function Page(props: PageProps) {
  const { page, showPage, setShowPage, setShowSettings } = props;
  return (
    <>
      <Tab
        title={page.name}
        description={page.description}
        onClick={() => setShowPage(page.page)}
      />
      <SettingsSubpage
        title={page.name}
        isOpen={showPage === page.page ? true : false}
        backTo={() => {
          setShowPage(null);
        }}
        onClose={() => {
          setShowPage(null);
          setShowSettings(false);
        }}
        form={<page.Component {...props} />}
      />
    </>
  );
}
