import { TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useQuery } from 'urql';
import { Address, AddressLink } from '~/components/ui/Address';
import { Attributes } from '~/components/ui/Attributes';
import { Gallery } from '~/components/ui/Gallery';
import { Loading } from '~/components/ui/Loading';
import { ReadValue } from '~/components/ui/ReadValue';
import { graphql } from '~/gql';
import { parseJson } from '~/helpers';
import { useAuth } from '~/hooks';
import { DetailView } from '~/layouts/detail/DetailView';
import { KebabNav } from '~/layouts/nav/KebabNav';
import { AttributesList } from '../sites/tabs/AttributesList';
import { PopupDialog } from '../ui/PopupDialog';
import { StatusBadge } from '../ui/StatusBadge';
import { TabHeader } from '../ui/TabHeader';

export const ContactQuery = graphql(`
  query Contact($id: ID!) {
    contact(id: $id) {
      id
      status
      type
      name
      organisation {
        id
        name
      }
      relationship
      phone
      email
      streetAddress
      postalAddress
      notes
      attributes {
        ...AttributeFields
      }
      image
      images
      isCurrentUser
    }
  }
`);

const View = () => {
  const { t } = useTranslation();
  const { contactId } = useParams();
  invariant(contactId, 'organisationId is required');
  const [result, reexecuteQuery] = useQuery({
    query: ContactQuery,
    variables: { id: contactId },
  });
  const reload = () => reexecuteQuery({ requestPolicy: 'network-only' });
  const contact = result.data?.contact;
  const { is } = useAuth();
  const { pathname } = useLocation();
  const isPopup = pathname.includes('jobs/');
  const editResource = `${pathname}/edit`;

  if (!is('operator')) {
    return null;
  }

  const pages = [
    {
      title: 'editContact',
      url: editResource,
    },
  ];

  if (!contact) {
    return <Loading />;
  }

  const streetAddress = parseJson(contact.streetAddress);
  const postalAddress = parseJson(contact.postalAddress);

  return (
    <DetailView
      rightSlot={!isPopup && <KebabNav pages={pages} />}
      title={contact.name}
      returnTo='..'
    >
      <StatusBadge value={contact.status} rounded />
      <ReadValue label='typeLabel' value={contact.type} />
      <ReadValue label='organisation' value={contact.organisation?.name} />
      <ReadValue label='relationship'>
        {contact.relationship
          ?.map((r) => t(`contactRelationships.${r}`))
          .join('; ')}
      </ReadValue>
      <ReadValue label='phoneNumber' value={contact.phone} />
      <ReadValue label='emailAddress' value={contact.email} />
      <ReadValue label='streetAddress'>
        {streetAddress && <AddressLink data={streetAddress} />}
      </ReadValue>
      <ReadValue label='postalAddress'>
        {postalAddress && <Address data={postalAddress} longFormat />}
      </ReadValue>
      {/* @ts-expect-error FIXME */}
      <Attributes data={contact} />
      <ReadValue label='notes' value={contact.notes} />
      <Gallery images={contact.image} />

      <TabGroup className='mb-4 w-auto rounded-t-2xl border border-grey-20'>
        <TabList className='bg-blue-900/20 flex overflow-hidden rounded-t-2xl'>
          <TabHeader title='Attributes' />
        </TabList>
        <TabPanels className=''>
          <TabPanel className='relative flex min-h-[20rem] flex-col bg-grey-2'>
            <AttributesList
              attributes={contact.attributes}
              onUpdate={() => reload()}
              entityId={`Contact:${contact.id}`}
              entityTypeName={t('Contact')}
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </DetailView>
  );
};

export const ContactView = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isPopup = pathname.includes('jobs/');

  const removeContactSegment = () => {
    const newUrl = pathname.replace(/\/contacts\/\d+$/, '');
    navigate(newUrl);
  };

  return (
    <>
      {isPopup ? (
        <PopupDialog isOpen={true} onClose={removeContactSegment}>
          <View />
        </PopupDialog>
      ) : (
        <View />
      )}
    </>
  );
};
