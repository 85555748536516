import { Link } from 'react-router-dom';
import { SquareMono } from '~/brand/generated/propfit.app';

export function NavLogo() {
  return (
    <Link to='/' className='ml-4 mr-2 flex items-center pb-0 lg:hidden'>
      <SquareMono className='h-9 w-9' />
    </Link>
  );
}
