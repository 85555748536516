import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { useQuery } from 'urql';
import { AuthGate } from '~/components/AuthGate';
import { CreateButton } from '~/components/CreateButton';
import { FloatingActionButton } from '~/components/FloatingActionButton';
import { ListDetailView } from '~/components/ListDetailView';
import { FilterForm, FilterProps } from '~/components/filter/FilterForm';
import { StatusSelectField } from '~/components/form/StatusSelectField';
import { SelectField } from '~/components/form/downshift/SelectField';
import { ItemForm } from '~/components/item/ItemForm';
import { MatchList } from '~/components/ui/MatchList';
import { withModal } from '~/components/withModal';
import { getFragmentData, graphql } from '~/gql';
import { ItemsQuery, ItemType, Status } from '~/gql/graphql';
import { useEnumOptions } from '~/hooks/useEnumOptions';
import { useSearchFilter } from '~/hooks/useSearch';
import { useSort } from '~/hooks/useSort';
import { StockNav } from '~/layouts/nav/StockNav';
import { ItemView } from './[id]';

export const ItemsQueryDocument = graphql(`
  query Items($status: [Status!], $inConfig: Boolean) {
    items(status: $status, inConfig: $inConfig) {
      id
      ...itemData
    }
  }
`);

export const ItemData_ItemFragment = graphql(`
  fragment itemData on Item {
    id
    status
    name
    type
    description
    skuPrefix
    images
    image
    activeSkus
    hasVariants
    attributes {
      ...AttributeFields
    }
    skus {
      id
      status
      _name
      name
      code
      images
      image
      variant {
        name
        value
      }
    }
    variantTypes {
      name
      values
    }
  }
`);

export type Item = ItemsQuery['items'][0];

const ItemFormModal = withModal(ItemForm);
const ItemViewModal = withModal(ItemView);

const ItemFilters = (props: FilterProps) => {
  const { t } = useTranslation();
  const itemTypeOptions = useEnumOptions(ItemType, 'itemType');

  return (
    <FilterForm
      cols='4'
      initialValues={{ status: ['Active'], type: [] }}
      {...props}
      filterOnLoad
      includeKeyword
    >
      <StatusSelectField />
      <SelectField
        floating
        name='type'
        label={t('itemTypeLabel')}
        placeholder={t('itemTypeLabel')}
        options={itemTypeOptions}
        multiple
      />
    </FilterForm>
  );
};

const SEARCH_OPTIONS = {
  keys: ['name', 'skus.code', 'skus.name', 'status', 'type'],
};

export const Items = () => {
  const [{ data }, reexecuteQuery] = useQuery({
    query: ItemsQueryDocument,
    variables: { status: [Status.Active, Status.Inactive] },
  });
  const reload = () => reexecuteQuery({ requestPolicy: 'network-only' });
  const { results, search } = useSearchFilter(data?.items, SEARCH_OPTIONS);
  const { t } = useTranslation();
  // const { filtered, filter } = useFilter2<Item>(results);
  const { sorted } = useSort(results, { default: 'name' });

  const getListItemProps = (data: Item) => {
    const item = getFragmentData(ItemData_ItemFragment, data);

    return {
      id: item.id,
      linkTo: `/items/${item.id}`,
      image: item.image
        ? item.image
        : item.skus.find((sku) => sku.image)?.image,
      titleText: item.name,
      secondaryText: t('itemType.' + item.type),
      tertiaryText: variantsListString(item),
      // hero: {
      //   number: item.activeSkus,
      //   directive: t('variant', { count: item.activeSkus }),
      // },
      status: item.status,
    };
  };

  return (
    <AuthGate action='manage' subject='feat.items'>
      <ListDetailView
        nav={<StockNav />}
        actions={<CreateButton to='new'>{t('item')}</CreateButton>}
        filters={<ItemFilters onFiltered={search} />}
        main={
          <MatchList
            items={sorted ? sorted.map(getListItemProps) : []}
            matchPath='/items/:id/*'
            className='pb-24 lg:pb-4'
          />
        }
      >
        <Route path='*' element={<FloatingActionButton />} />
        <Route
          path='/new/*'
          element={<ItemFormModal action={() => {}} title={t('newItem')} />}
        />
        <Route
          path='/:id/edit'
          element={<ItemFormModal action={() => {}} title={t('editItem')} />}
        />
        <Route
          path='/:id/*'
          element={<ItemViewModal data={data} reload={reload} />}
        />
      </ListDetailView>
    </AuthGate>
  );
};

function variantsListString(item: Item) {
  const { skus } = getFragmentData(ItemData_ItemFragment, item);

  return skus.length === 1
    ? skus[0].code
    : skus
        .reduce(
          (arr: string[], sku) =>
            sku.status === 'Active' ? arr.concat(sku._name ?? '') : arr,
          []
        )
        .join(', ');
}
