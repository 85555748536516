import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { useStockMovements } from '~/api/stock-movements';
import { AuthGate } from '~/components/AuthGate';
import { Split } from '~/components/Split';
import { StockMovementTable } from '~/components/stock/stock-movements/StockMovementTable';
import { FilterGroupSearch } from '~/components/ui/FilterGroupSearch';
import { Result } from '~/components/ui/Result';
import { VirtualList } from '~/components/ui/VirtualList';
import { View, ViewSwitcher } from '~/components/view-switcher/ViewSwitcher';
import {
  LocationType,
  useLocationsQuery,
  useStockMovementFiltersQuery,
} from '~/generated/graphql';
import { formatDate } from '~/helpers/formatDate';
import { useSearch } from '~/hooks/useSearch';
import { StockNav } from '~/layouts/nav/StockNav';
import { StockMovement } from '~/types';

const SEARCH_OPTIONS = {
  keys: [
    'locationType',
    'location',
    'type',
    'sku',
    'enteredBy',
    'locationName',
    'skuName',
    'skuCode',
    'spaceName',
  ],
};

export const StockMovements = () => {
  const { t } = useTranslation();
  const [view, setView] = useState<View>('CARD');
  const [query, setQuery] = useState(new URLSearchParams());
  const { data, mutate: revalidate } = useStockMovements(query.toString());
  const {
    results,
    query: searchValue,
    search,
  } = useSearch(data, SEARCH_OPTIONS);
  const match = useMatch('/stock-movements/:id/*');

  const movements = results ?? [];

  const listProps = {
    data: movements,
    getListItemProps,
    scrollTo: match?.params.id ? parseInt(match.params.id) : undefined,
  };

  return (
    <AuthGate action='manage' subject='feat.items'>
      <Split
        nav={<StockNav />}
        filters={
          <div className='bg-white lg:px-4'>
            <StockMovementFilters
              searchValue={searchValue}
              filtersValue={query}
              onFiltered={setQuery}
              onSearch={search}
            />
            <div className='flex items-center justify-between px-4'>
              <ViewSwitcher selected={view} onSelect={setView} />
              <Result count={movements.length} className='mt-3' />
              <div className='whitespace-nowrap text-right'>
                <a
                  className='text-brand hover:underline'
                  href={`${
                    process.env.NODE_ENV === 'development'
                      ? 'http://localhost:4000'
                      : ''
                  }/api/export-movements?${query}`}
                >
                  {t('export')}
                </a>
              </div>
            </div>
          </div>
        }
        main={
          view === 'CARD' ? (
            <div className='flex-1'>
              <VirtualList {...listProps} />
            </div>
          ) : (
            <StockMovementTable data={movements ?? []} />
          )
        }
        context={{ movements }}
        virtual
      />
    </AuthGate>
  );
};

function useLocationOptions() {
  const [result] = useLocationsQuery({
    variables: { type: LocationType.Site },
    requestPolicy: 'cache-and-network',
  });
  const { data } = result;
  return data?.locations
    ? data.locations.map(({ id, name }) => ({ value: `s:${id}`, label: name }))
    : [];
}

const StockMovementFilters = (props: {
  searchValue: string;
  filtersValue: URLSearchParams;
  onSearch: (value: string) => void;
  onFiltered: (value: URLSearchParams) => void;
}) => {
  const { t } = useTranslation();

  const locationOptions = useLocationOptions();
  const [result] = useStockMovementFiltersQuery();
  const { data } = result;

  const movementTypeOptions = useMemo(
    () =>
      data?.movementTypes?.map((value) => ({
        value,
        label: value,
      })),
    [data]
  );

  const spaces = useMemo(
    () => data?.spaceNames.map((value) => ({ value, label: value })),
    [data]
  );

  const skus = useMemo(
    () =>
      data?.items.flatMap(({ skus }) =>
        skus.map(({ id, name }) => ({ value: id, label: name }))
      ),
    [data]
  );

  const userOptions = data?.users?.filter(({ value }) => Boolean(value));

  return (
    <FilterGroupSearch
      filters={[
        {
          type: 'select',
          name: 'location',
          label: t('location'),
          options: locationOptions,
          multiple: false,
        },
        {
          type: 'select',
          name: 'space',
          label: t('space'),
          options: spaces ?? [],
        },
        {
          type: 'select',
          name: 'sku',
          label: t('sku'),
          options: skus ?? [],
        },
        {
          type: 'select',
          name: 'type',
          label: t('movementType'),
          options: movementTypeOptions ?? [],
        },
        {
          type: 'select',
          name: 'enteredBy',
          label: t('enteredBy'),
          options: userOptions ?? [],
        },
        {
          type: 'date',
          name: 'occurred', // Will expand to `occurredAfter` and `occurredBefore`
          label: t('occurredBetween'),
        },
      ]}
      value={{
        searchValue: props.searchValue,
        filtersValue: props.filtersValue,
      }}
      onChange={({ searchValue, filtersValue }) => {
        props.onSearch(searchValue);
        props.onFiltered(filtersValue);
      }}
    />
  );
};

const getListItemProps = (
  movement: StockMovement,
  scrollTo?: number | string | null
) => ({
  id: movement.id,
  linkTo: `/stock-movements/${movement.id}`,
  image: movement.image,
  titleText: movement.skuName,
  secondaryText: movement.skuCode ?? undefined,
  tertiaryText: <Location movement={movement} />,
  heroElement: (
    <div className='flex items-center gap-3 text-right'>
      <div className='text-sm leading-tight xl:pr-2'>
        {movement.type}
        <br />
        {formatDate(movement.occurredAt, 'P HH:mm')}
      </div>
      {movement.quantity && (
        <div className='mb-0.5 text-xl'>
          {movement.quantity > 0 ? `+${movement.quantity}` : movement.quantity}
        </div>
      )}
    </div>
  ),
  active: Boolean(scrollTo && '' + scrollTo === movement.id.toString()),
});

const Location = ({ movement }: { movement: StockMovement }) => {
  const { t } = useTranslation();

  return (
    <>
      {movement.locationName} ({t('type.' + movement.locationType)}){' > '}
      {movement.spaceName}
    </>
  );
};
