import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Location, useLocation, useNavigate } from 'react-router-dom';
import { NavLink } from '~/components/NavLink';
import { Navbar } from '~/components/nav/Navbar';
import { MenuItem, TopBar } from '~/components/ui/TopBar';
import { useBreakpoint } from '~/hooks/useBreakpoint';

type Page = {
  /**
   * translation key for link text
   * @todo rename to "key" or "tKey"
   */
  title: string;
  url: string;
  /**
   * Translation key to use for create button or undefined if no create button
   */
  createButton?: string;
  type?: string;
};

type SubNavProps = {
  pages: Page[];
  /**
   * Index of page url matches
   */
  match?: number;
  permissionKey?: string;
  hideLogo?: boolean;
  isActive?: (key: string) => boolean;
  onItemClick?: (page: Page) => void;
};

// Mobile-specific SubNav component
const MobileSubNav = ({
  pages,
  menuItems,
  currentPageTitle,
}: {
  pages: Page[];
  menuItems: MenuItem[];
  currentPageTitle: string;
}) => {
  return <TopBar title={currentPageTitle} menuItems={menuItems} />;
};

// Desktop-specific SubNav component
const DesktopSubNav = ({
  pages,
  match,
  permissionKey,
  currentPage,
  handleItemClick,
  onItemClick,
  isPageActive,
}: {
  pages: Page[];
  match?: number;
  permissionKey?: string;
  currentPage?: Page;
  handleItemClick: (page: Page) => void;
  onItemClick?: (page: Page) => void;
  isPageActive: (page: Page) => boolean;
}) => {
  const { t } = useTranslation(['translation', 'nav']);

  return (
    <Navbar>
      {pages.map((page, key) => (
        <Navbar.Item
          as={NavLink}
          to={page.url}
          key={page.title}
          className='flex'
          permissionKey={permissionKey ? permissionKey : ''}
          onClick={onItemClick && (() => onItemClick(page))}
          activeKey={page.title}
          isActive={(_: boolean, location: Location) => {
            if (typeof isPageActive === 'function') {
              return isPageActive(page);
            }
            return location.pathname === page.url;
          }}
        >
          {t(page.title)}
        </Navbar.Item>
      ))}

      {match != null && pages[match].createButton && (
        <div className='ml-auto flex items-center'>
          <Link className='add-button' to='new'>
            + {t(pages[match].createButton!)}
          </Link>
        </div>
      )}
    </Navbar>
  );
};

// Main SubNav component that decides between mobile and desktop
export const SubNav = (props: SubNavProps) => {
  const { pages, isActive, onItemClick } = props;
  const { isMobile } = useBreakpoint();
  const { t } = useTranslation(['translation', 'nav']);

  const location = useLocation();
  const navigate = useNavigate();

  // Get the current active page
  const getCurrentPage = useCallback((): Page | undefined => {
    return pages.find((page) =>
      isActive ? isActive(page.title) : location.pathname === page.url
    );
  }, [isActive, location.pathname, pages]);

  // Handle item click with setTimeout to prevent navigation issues
  const handleItemClick = useCallback(
    (page: Page, event?: React.MouseEvent) => {
      if (event) {
        event.preventDefault();
      }

      // Use setTimeout to ensure state updates complete before navigation
      setTimeout(() => {
        if (onItemClick) {
          onItemClick(page);
        }

        // Navigate if needed and URL is different
        if (page.url && page.url !== location.pathname) {
          navigate(page.url);
        }
      }, 0);
    },
    [onItemClick, location.pathname, navigate]
  );

  // Get current page
  const currentPage = getCurrentPage();
  const currentPageTitle = currentPage ? t(currentPage.title) : '';

  // Function to determine if a page is active
  const isPageActive = (page: Page) => currentPage?.title === page.title;

  // Create menu items for mobile view
  const menuItems: MenuItem[] = pages.map((page) => ({
    key: page.title,
    label: t(page.title),
    isActive: isPageActive(page),
    onClick: () => handleItemClick(page),
  }));

  // For mobile view
  if (isMobile) {
    return (
      <MobileSubNav
        pages={pages}
        menuItems={menuItems}
        currentPageTitle={currentPageTitle}
      />
    );
  }

  // For desktop view
  return (
    <DesktopSubNav
      pages={pages}
      match={props.match}
      permissionKey={props.permissionKey}
      currentPage={currentPage}
      handleItemClick={handleItemClick}
      onItemClick={onItemClick}
      isPageActive={isPageActive}
    />
  );
};
