import { faAirbnb } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useQuery } from 'urql';
import { graphql } from '~/gql';
import SvgGuesty from '~/icons/Guesty';
import { Page } from '../account';
import { SettingsSubpage } from '../ui';

const ListIntegrationsDocument = graphql(`
  query ListIntegrations {
    integrations
  }
`);

type Props = {
  page: {
    name: string;
    description: string;
  };
  setShowSettings: (show: boolean) => void;
  setShowPage: (page: Page) => void;
};

// Define integration type
type IntegrationType = {
  id: string;
  name: string;
  description: string;
  icon: React.ReactNode;
  path: string;
};

// Integration configurations
const INTEGRATIONS: IntegrationType[] = [
  {
    id: 'airbnb',
    name: 'Airbnb',
    description:
      'Connect your host calendar to view check-in/check-out in schedule.',
    icon: <FontAwesomeIcon icon={faAirbnb} style={{ color: '#ff385c' }} />,
    path: 'airbnb',
  },
  {
    id: 'guesty',
    name: 'Guesty',
    description: 'Connect your Guesty account',
    icon: <SvgGuesty />,
    path: 'guesty',
  },
  {
    id: 'homhero',
    name: 'Homhero',
    description:
      'Connect your homhero account to sync your places and bookings.',
    icon: <img className='w-20' src='/homhero.png' alt='Homhero' />,
    path: 'homhero',
  },
];

// Active integration component
const ActiveIntegration = ({
  integration,
}: {
  integration: IntegrationType;
}) => (
  <div className='-mt-[1px] flex w-full cursor-pointer items-center justify-between rounded border border-outline-variant bg-white py-2 pl-4 pr-6'>
    <div className='mr-3 text-3xl'>{integration.icon}</div>
    <div className='mr-auto flex flex-col items-start justify-center'>
      <h3 className='self-start text-sm font-bold text-primary'>
        {integration.name}
      </h3>
      <p className='pb-0.5 text-left text-xs text-copy-muted opacity-80'>
        {integration.description}
      </p>
    </div>
    <div>Connected!</div>
  </div>
);

// Available integration component
const AvailableIntegration = ({
  integration,
  onClick,
}: {
  integration: IntegrationType;
  onClick: () => void;
}) => (
  <div
    className='-mt-[1px] flex w-full cursor-pointer items-center justify-between border border-outline-variant bg-white py-2 pl-4 pr-6'
    onClick={onClick}
  >
    <div className='mr-3 text-3xl'>{integration.icon}</div>
    <div className='mr-auto flex flex-col items-start justify-center'>
      <h3 className='self-start text-sm font-bold text-primary'>
        {integration.name}
      </h3>
      <p className='pb-0.5 text-left text-xs text-copy-muted opacity-80'>
        {integration.description}
      </p>
    </div>
    <button
      type='button'
      className='flex items-center rounded bg-brand px-4 py-2 text-white'
    >
      <div>Setup</div>
      <div>
        <FontAwesomeIcon
          className='ml-2 h-5 w-5'
          //@ts-ignore
          icon={faChevronRight}
        />
      </div>
    </button>
  </div>
);

// Integration setup components
function AirbnbSetup() {
  return (
    <div className='p-4'>
      <h2 className='mb-4 text-lg font-bold'>Connect Airbnb</h2>
      <p className='mb-4'>To connect your Airbnb account:</p>
      <ol className='mb-4 list-decimal pl-5'>
        <li className='mb-2'>Log in to your Airbnb host account</li>
        <li className='mb-2'>Go to Calendar Settings</li>
        <li className='mb-2'>Find the iCal export option</li>
        <li className='mb-2'>Copy your calendar URL</li>
        <li className='mb-2'>Paste it below</li>
      </ol>
      <div className='mt-6'>
        <input
          type='text'
          placeholder='Paste your iCal URL here'
          className='mb-4 w-full rounded border border-gray-300 p-2'
        />
        <button className='rounded bg-brand px-4 py-2 text-white'>
          Connect
        </button>
      </div>
    </div>
  );
}

function GuestySetup() {
  return (
    <div className='p-4'>
      <h2 className='mb-4 text-lg font-bold'>Guesty Setup</h2>
      <p className='mb-4'>
        PropFit can sync your Guesty listings and reservations to run
        automations.
      </p>

      <h2 className='mb-4 pt-4 text-lg font-semibold'>
        How To Connect Your Guesty Account
      </h2>

      <ol className='mb-4 list-decimal pl-5'>
        <li className='mb-2'>
          Visit PropFit at the Guesty marketplace by following the link:{' '}
          <a
            href='https://app.guesty.com/integrations/partners/propfit'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://app.guesty.com/integrations/partners/propfit
          </a>
        </li>
        <li className='mb-2'>
          Click on the <strong>Connect</strong> button and copy the integration
          token
        </li>
        <li className='mb-2'>
          Paste the token in the field below and click on the{' '}
          <strong>Submit</strong> button
        </li>
      </ol>

      <div className='mt-6'>
        <input
          type='text'
          placeholder='Paste your integration token here'
          className='mb-4 w-full rounded border border-gray-300 p-2'
        />
        <button className='rounded bg-brand px-4 py-2 text-white'>
          Submit
        </button>
      </div>
    </div>
  );
}

function HomheroSetup() {
  return (
    <div className='p-4'>
      <h2 className='mb-4 text-lg font-bold'>Connect Homhero</h2>
      <p className='mb-4'>
        Connect your Homhero account to sync your places and bookings.
      </p>
      <div className='mt-6'>
        <input
          type='text'
          placeholder='Homhero API key'
          className='mb-4 w-full rounded border border-gray-300 p-2'
        />
        <button className='rounded bg-brand px-4 py-2 text-white'>
          Connect
        </button>
      </div>
    </div>
  );
}

export const Integrations = ({ setShowPage, setShowSettings }: Props) => {
  const [result] = useQuery({
    query: ListIntegrationsDocument,
  });

  const { data, fetching, error } = result;
  const integrations = data?.integrations || [];

  // Subpage state
  const [activeSubpage, setActiveSubpage] = useState<string | null>(null);

  if (fetching) return <div>Loading integrations...</div>;
  if (error) return <div>Error loading integrations</div>;

  // Filter active and available integrations
  const activeIntegrations = INTEGRATIONS.filter((integration) =>
    integrations.includes(integration.id)
  );

  const availableIntegrations = INTEGRATIONS.filter(
    (integration) => !integrations.includes(integration.id)
  );

  // Render setup component based on subpage
  const renderSetupComponent = () => {
    switch (activeSubpage) {
      case 'airbnb':
        return <AirbnbSetup />;
      case 'guesty':
        return <GuestySetup />;
      case 'homhero':
        return <HomheroSetup />;
      default:
        return null;
    }
  };

  return (
    <div className='mx-auto w-full max-w-screen-md'>
      <h2 className='self-start pb-3 pt-6 font-bold text-primary'>Active</h2>

      {activeIntegrations.length > 0 ? (
        activeIntegrations.map((integration) => (
          <ActiveIntegration key={integration.id} integration={integration} />
        ))
      ) : (
        <div className='text-secondary'>No active integrations</div>
      )}

      <h2 className='self-start pb-3 pt-6 font-bold text-primary'>Available</h2>

      {availableIntegrations.map((integration) => (
        <AvailableIntegration
          key={integration.id}
          integration={integration}
          onClick={() => setActiveSubpage(integration.id)}
        />
      ))}

      <SettingsSubpage
        title={
          activeSubpage
            ? `Setup ${
                activeSubpage.charAt(0).toUpperCase() + activeSubpage.slice(1)
              }`
            : ''
        }
        onClose={() => {
          setShowSettings(false);
          setActiveSubpage(null);
          setShowPage(null);
        }}
        isOpen={!!activeSubpage}
        backTo={() => setActiveSubpage(null)}
        form={renderSetupComponent()}
      />
    </div>
  );
};
