import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Job } from '~/generated/graphql';
import { ListJobsQuery } from '~/gql/graphql';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { PopoverStateChangedEffect } from '../../PopoverStateChangedEffect';
import { Pill } from '../nucleus/Pill/Pill';

type Option = { key: string; name: string };

export enum SortedBy {
  TIME = 'time',
  PROPERTY = 'property',
  ASSIGNEE = 'assignee',
}

export function SortBy({
  className,
  options,
  value,
  onChange,
}: {
  className: string;
  options: Option[];
  value: SortedBy;
  onChange: (value: SortedBy) => void;
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(() => value);

  // Update internal state when prop value changes
  useEffect(() => {
    setSelected(value);
  }, [value]);
  const { isMobile } = useBreakpoint();

  return (
    <Popover className={classNames('relative', className)}>
      {({ open, close }) => {
        const getLabel = () => {
          const selectedOption = options.find((opt) => opt.key === selected);
          return `${t('sortBy')}: ${selectedOption?.name}`;
        };

        return (
          <>
            <PopoverButton
              className={classNames(
                isMobile
                  ? 'mx-1 my-1 rounded-full'
                  : 'label flex h-8 w-fit cursor-pointer items-center rounded-full bg-white bg-opacity-20 px-4 text-base lg:h-auto lg:bg-opacity-100 lg:px-0',
                className
              )}
            >
              {isMobile ? (
                <Pill
                  type='filter'
                  hasParent
                  active={true}
                  icon={{ position: 'right', icon: faChevronDown }}
                >
                  {getLabel()}
                </Pill>
              ) : (
                <>
                  <span className='mr-1 w-auto whitespace-nowrap text-white lg:mx-1 lg:text-secondary'>
                    {t('sortBy')}:
                  </span>
                  <span className='cursor-pointer font-semibold text-white lg:font-medium lg:text-primary'>
                    {options.find((opt) => opt.key === selected)?.name}
                  </span>
                  <FontAwesomeIcon
                    className='ml-1.5 h-3 w-3'
                    // @ts-ignore
                    icon={faChevronDown}
                  />
                </>
              )}
            </PopoverButton>

            <PopoverPanel
              anchor='bottom start'
              className='prevent-drag-scroll absolute z-[100] w-48 rounded border border-outline-variant bg-white shadow-lg'
              style={{ marginTop: '6px' }}
            >
              <div className='py-1'>
                {options.map(({ key, name }) => (
                  <button
                    key={key}
                    className={classNames(
                      'block w-full px-4 py-2 text-left text-secondary hover:bg-grey-5 hover:text-primary',
                      { 'bg-grey-5 text-primary': key === selected }
                    )}
                    onClick={() => {
                      onChange(key as SortedBy);
                      setSelected(key as SortedBy);
                      close();
                    }}
                  >
                    {name}
                  </button>
                ))}
              </div>
            </PopoverPanel>

            <PopoverStateChangedEffect open={open} onClose={() => {}} />
          </>
        );
      }}
    </Popover>
  );
}

export function sortJobs(sortBy: SortedBy, jobs?: ListJobsQuery['jobBoard']) {
  const jobsList = jobs && (jobs as Job[]);

  if (!jobsList) return [];

  const jobsGroupedByDate = jobsList.reduce((groups, job) => {
    const date = job.scheduledStartDate;
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(job);
    return groups;
  }, {} as Record<string, Job[]>);

  const sortedGroups = Object.entries(jobsGroupedByDate).map(
    ([date, jobsList]) => {
      let sortedJobs: Job[];

      switch (sortBy) {
        case SortedBy.TIME:
          sortedJobs = jobsList.sort((a, b) => {
            const timeA = a.scheduledStartTime ?? '';
            const timeB = b.scheduledStartTime ?? '';
            return timeA.localeCompare(timeB);
          });
          break;
        case SortedBy.PROPERTY:
          sortedJobs = jobsList.sort((a, b) => {
            const locationA = a.location?.name ?? '';
            const locationB = b.location?.name ?? '';
            return locationA.localeCompare(locationB);
          });
          break;
        case SortedBy.ASSIGNEE:
          sortedJobs = jobsList.sort((a, b) => {
            const assigneeA = a.assignees?.[0]?.name ?? '';
            const assigneeB = b.assignees?.[0]?.name ?? '';
            return assigneeA.localeCompare(assigneeB);
          });
          break;
        default:
          sortedJobs = jobsList;
      }

      return sortedJobs;
    }
  );

  return sortedGroups.flat() as ListJobsQuery['jobBoard'];
}
