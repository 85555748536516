import { useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';
import { Confirm, ConfirmMessage } from '~/components/ui/Confirm';
import { Dialog } from '~/components/ui/Dialog';
import { Menu } from '~/components/ui/Menu';
import {
  useCancelJobMutation,
  useDeleteJobMutation,
} from '~/generated/graphql'; // TODO move these into co-located definitions
import { graphql } from '~/gql';
import { JobQuery, JobStatus } from '~/gql/graphql';
import { useAuth } from '~/hooks';
import { ConfirmDateTime } from '../ConfirmDateTime';
import { CompleteJobDocument } from './CompleteJobButton';
import { getAttributeFormValues } from './JobReadView/CollectAttributes';

const {
  Created,
  Declined,
  Offered,
  Accepted,
  InProgress,
  Complete,
  Cancelled,
} = JobStatus;

type Props = {
  job: NonNullable<JobQuery['job']>;
  /**
   * If the menu is displayed in edit mode then some options don't make sense to display
   */
  editing?: boolean;
};

const RevertJobStatusMutation = graphql(`
  mutation RevertJobStatus(
    $id: ID!
    $currentStatus: JobStatus!
    $comment: String
  ) {
    revertJobStatus(id: $id, currentStatus: $currentStatus, comment: $comment) {
      id
      status
    }
  }
`);

function getPreviousStatus(status: JobStatus) {
  switch (status) {
    case Offered:
    case Accepted:
      return Created;
    case InProgress:
      return Accepted;
    case Complete:
      return InProgress;
  }
}

function getRevertMessage(t: TFunction, status: JobStatus) {
  return Cancelled === status
    ? 'You are about to revert the job status from cancelled and notify the participants'
    : `You are about to revert the job status to ${t([
        'statuses',
        getPreviousStatus(status) ?? '',
      ])} and notify the participants`;
}

export function JobMenu({ job, editing = false }: Props) {
  const { is } = useAuth();
  const cancelInputRef = useRef<HTMLTextAreaElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation('job');
  const [confirm, setConfirm] = useState('');
  const [isCompleting, setIsCompleting] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [, revertStatus] = useMutation(RevertJobStatusMutation);
  const [, complete] = useMutation(CompleteJobDocument);
  const [{ fetching: cancelFetching }, cancel] = useCancelJobMutation();
  const [, del] = useDeleteJobMutation();

  if (!is('operator')) {
    return null;
  }

  /**
   * A confirmation dialog is cancelled
   */
  const closeDialog = () => setConfirm('');

  const canEdit = job.permissions.includes('edit') && !editing;
  const canRevert = job.permissions.includes('revert');

  return (
    <div className='print:hidden'>
      <Menu>
        <Menu.Item
          onClick={() => {
            const newWindow = window.open(`/api/pdf/job/${job.id}`, '_blank');
            if (newWindow) {
              newWindow.opener = null;
            }
          }}
        >
          {t('generatePDF')}
        </Menu.Item>

        {canEdit && (
          <Menu.Item as={Link} to='edit'>
            {t('editJob')}
          </Menu.Item>
        )}
        {canRevert && (
          <Menu.Item onClick={() => setConfirm('revert')}>
            Revert Status
          </Menu.Item>
        )}
        {job.permissions.includes('cancel') && (
          <Menu.Item onClick={() => setIsCancelling(true)}>
            {t('cancelJob')}
          </Menu.Item>
        )}
        {job.permissions.includes('delete') && (
          <Menu.Item onClick={() => setIsDeleting(true)}>
            <span className='text-delete'>{t('deleteJob')}</span>
          </Menu.Item>
        )}
        {[Offered, Accepted, InProgress].includes(job.status) && (
          <Menu.Item onClick={() => setIsCompleting(true)}>
            {t('completeJob')}
          </Menu.Item>
        )}
        {!editing && (
          <Menu.Item
            onClick={() => {
              navigate('new');
            }}
          >
            Create New
          </Menu.Item>
        )}
        {/* {isMobile && (
          <Menu.Item
            onClick={() => {
              window.print();
            }}
          >
            Generate PDF
          </Menu.Item>
        )} */}
      </Menu>
      {confirm === 'revert' && (
        <ConfirmMessage
          title='Revert Status?'
          body={getRevertMessage(t, job.status)}
          cancel={'Cancel'}
          confirm='Revert Status'
          onCancel={closeDialog}
          onConfirm={async (message) => {
            await revertStatus({
              id: job.id,
              currentStatus: job.status,
              comment: message,
            });
            closeDialog();
          }}
        />
      )}
      {isCompleting && (
        <ConfirmDateTime
          open
          title='EndTime'
          onCancel={() => setIsCompleting(false)}
          onConfirm={async (date) => {
            const attributes = await getAttributeFormValues('Job', job.id);
            await complete({ id: job.id, endTime: date, attributes });
            setIsCompleting(false);
          }}
        />
      )}
      {isCancelling && (
        <Dialog
          title={t('confirm.cancelJob.title')}
          show={true}
          onClose={() => setIsCancelling(false)}
        >
          <div className='flex flex-col flex-wrap gap-3 px-5'>
            <p className='px-2 pt-3'>{t('confirm.cancelJob.body')}</p>
            <textarea
              ref={cancelInputRef}
              className='input mt-1 w-full resize-none rounded-md border border-grey-20 px-3 py-2.5 focus-visible:border-brand focus-visible:outline-none'
              placeholder='Add optional message...'
              autoFocus
            ></textarea>
            <div className='flex gap-3 self-end pb-5 pt-1'>
              <button
                className='px-4 py-2 text-sm font-medium uppercase text-brand-dark transition-all duration-500 hover:text-brand'
                onClick={() => setIsCancelling(false)}
              >
                Cancel
              </button>
              <button
                type='button'
                className='rounded-md border border-brand bg-brand px-5 py-2 text-sm font-medium uppercase text-white transition-all duration-200 hover:bg-brand-dark hover:transition-all hover:duration-500'
                onClick={async () => {
                  await cancel({
                    id: job.id,
                    comment: cancelInputRef.current?.value,
                  });
                  setIsCancelling(false);
                }}
                disabled={cancelFetching}
              >
                {t('cancelJob')}
              </button>
            </div>
          </div>
        </Dialog>
        // <Confirm
        //   title={t('confirm.cancelJob.title')}
        //   body={t('confirm.cancelJob.body')}
        //   cancel={t('confirm.cancelJob.cancel')}
        //   confirm={t('cancelJob')}
        //   onCancel={() => setIsCancelling(false)}
        //   onConfirm={async () => {
        //     await cancel({ id: job.id });
        //     setIsCancelling(false);
        //   }}
        // />
      )}
      {isDeleting && (
        <Confirm
          title={t('confirm.deleteJob.title')}
          body={t('confirm.deleteJob.body')}
          cancel={t('confirm.deleteJob.cancel')}
          confirm={t('deleteJob')}
          onCancel={() => setIsDeleting(false)}
          onConfirm={async () => {
            try {
              setIsDeleteLoading(true);
              await del({ id: job.id });
              // if (scheduleContext) {
              //   scheduleContext.reload();
              // }
            } catch (error) {
              console.error(error);
            } finally {
              navigate(-1);
            }
          }}
          isLoading={isDeleteLoading}
          danger
        />
      )}
    </div>
  );
}
