import classNames from 'classnames';
import { forwardRef, useLayoutEffect, useRef, useState } from 'react';

function calculateRows(textarea: HTMLTextAreaElement) {
  const lineHeight = parseInt(
    window.getComputedStyle(textarea).getPropertyValue('line-height')
  );
  textarea.style.height = '0';
  const height = textarea.scrollHeight;
  textarea.style.height = '';

  return Math.max(2, Math.ceil(height / lineHeight) - 1);
}

export const TextArea = forwardRef<HTMLTextAreaElement>(
  (
    { onChange, className, ...props }: React.HTMLProps<HTMLTextAreaElement>,
    ref
  ) => {
    const localRef = useRef<HTMLTextAreaElement | null>(null);
    const [rows, setRows] = useState(2);

    // The first time TextArea is rendered set rows to fit content
    useLayoutEffect(() => {
      setRows(calculateRows(localRef.current!));
    }, []);

    function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
      onChange && onChange(event);
      setRows(calculateRows(event.target));
    }

    return (
      <textarea
        ref={(e) => {
          typeof ref === 'function' && ref(e);
          localRef.current = e;
        }}
        className={classNames(
          'mb-4 mt-0.5 block w-full resize-none overflow-hidden rounded border border-grey-20 px-3 py-2.5 focus-visible:border-grey-40 focus-visible:outline-none',
          className
        )}
        rows={rows}
        onChange={handleChange}
        {...props}
      ></textarea>
    );
  }
);
