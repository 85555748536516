import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment, createContext, useContext } from 'react';

type TContext = {
  onClose?: () => void;
};

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
};

export function PopupDialogNew({ children, isOpen, onClose, title }: Props) {
  return (
    <Context.Provider value={{ onClose }}>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='fixed inset-0 z-[100] ' onClose={onClose}>
          <div className='flex h-screen text-center md:px-4 lg:items-center'>
            <TransitionChild
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-400'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div
                onClick={onClose}
                className='fixed inset-0 bg-slate-800 bg-opacity-60 lg:bg-opacity-20'
              />
            </TransitionChild>

            {/* This element is to trick the browser into centering the modal contents. */}

            <TransitionChild
              enter='ease-out duration-300 origin-bottom lg:origin-center'
              enterFrom='opacity-0 scale-y-0 lg:scale-y-100'
              enterTo='opacity-100 scale-y-100'
              leave='ease-in duration-300 origin-bottom'
              leaveFrom='opacity-100 scale-y-100'
              leaveTo='opacity-0 scale-y-0 lg:scale-y-100'
            >
              <div className='absolute bottom-0 left-0 right-0 top-0.5 my-6 mb-0 ml-auto mr-auto flex w-full flex-col overflow-hidden rounded-2xl rounded-b-none bg-white text-left lg:bottom-auto lg:top-auto lg:my-0 lg:max-h-[calc(100%-2rem)] lg:w-[800px] lg:rounded-b-2xl'>
                {title && <PopupDialogNew.Title>{title}</PopupDialogNew.Title>}
                {children}
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </Context.Provider>
  );
}

type TitleProps = {
  children: React.ReactNode;
  /** Edit button clicked */
  onEdit?: () => void;
  loading?: boolean;
};

const Context = createContext({} as TContext);

function usePopupDialogNewContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error('Must be rendered within PopupDialogContext');
  }
  return context;
}

function Title({ children, onEdit, loading = false }: TitleProps) {
  const { onClose } = usePopupDialogNewContext();

  return (
    <div className='z-10 grid w-full grid-cols-[35px_minmax(0,_1fr)_35px] items-center justify-start rounded bg-white p-4 pb-0 pl-5 pt-5 lg:pb-0 lg:pt-7'>
      <div
        className={classNames('h-10 w-10', {
          'text-grey-30': loading,
          'text-grey-50 hover:text-brand': !loading,
        })}
      >
        <button
          type='button'
          onClick={onClose}
          className={classNames('x h-full w-full', {
            'cursor-default': loading,
          })}
          disabled={loading}
        ></button>
      </div>
      <div className='mx-1 flex-1 overflow-hidden break-words pb-1.5 text-center text-xl font-medium text-copy-head'>
        {children}
      </div>
    </div>
  );
}

PopupDialogNew.Title = Title;
