import { Transition as HLTransition } from '@headlessui/react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import { useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';
import { getYear, isAfter, sub } from 'date-fns';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { EmptyListActions } from '~/components/ui/EmptyListActions';
import { Result } from '~/components/ui/Result';
import { ListJobsQuery } from '~/gql/graphql';
import { formatDate } from '~/helpers/formatDate';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import type { ViewMode } from '~/routes/jobs';
import { CalendarEvent } from './CalendarEvent';
import { JobListItem } from './JobListItem';

type Props = {
  view: 'scheduled' | 'completed';
  jobs?: ListJobsQuery['jobBoard'];
  scrollId?: string;
  hasFilters?: boolean;
  isSmall: boolean;
  selected?: string[];
  toggleSelected?: (id: string, shift: boolean, single?: boolean) => void;
};

const CURRENT_YEAR = getYear(new Date()).toString();

/**
 * Get a formatted date for grouping
 */
function getDate(
  view: ViewMode | undefined,
  record: ListJobsQuery['jobBoard'][number]
) {
  if (record.__typename === 'JobCalendarEvent') {
    const date =
      record.summary === 'Check-out' ? record.end : record.start || record.end;
    return formatDate(date, 'yyyy-MM-dd');
  }

  const date =
    view === 'completed'
      ? record.completedAt
      : record.scheduleStart || record.scheduleEnd;
  return formatDate(date, 'yyyy-MM-dd');
}

const JobListComponent: ForwardRefRenderFunction<
  { scrollToJob: () => void },
  Props
> = (
  { view, jobs, scrollId, hasFilters, isSmall, selected, toggleSelected },
  ref
) => {
  const { isMobile } = useBreakpoint();
  const [scrollToDate, setScrollToDate] = useState(new Date());
  const scrollableRef = useRef<HTMLDivElement>(null);
  // holds the index of the job that is today (or nearest to today)
  const [todayIndex, setTodayIndex] = useState(0);

  const rowVirtualizer = useVirtualizer({
    count: jobs?.length ?? 0,
    paddingStart: 36,
    getScrollElement: () => scrollableRef.current,
    estimateSize: () => 100,
    getItemKey: (i) => `${jobs?.[i].__typename}:${jobs?.[i].id}-${i}`,
  });

  const handleScrollToJob = () => {
    setTimeout(() => {
      if (jobs && scrollId) {
        const index = jobs.findIndex((job) => job.id === scrollId);
        if (index > -1) {
          rowVirtualizer.scrollToIndex(index, { align: 'start' });
        }
      }
    }, 0);
  };

  useImperativeHandle(ref, () => ({
    scrollToJob() {
      handleScrollToJob();
    },
  }));

  useEffect(() => {
    handleScrollToJob();
  }, []);

  useEffect(() => {
    if (!jobs) return;

    const yesterday = sub(scrollToDate, { days: 1 });

    // find the closest matching job
    const scrollTo = jobs.findIndex((job) => {
      const date = getDate(view, job);
      return isAfter(new Date(date), yesterday);
    });

    if (scrollTo < 0) return;

    setTodayIndex(scrollTo);
    rowVirtualizer.scrollToIndex(scrollTo, {
      align: 'start',
      // behavior: 'smooth',
    });
  }, [scrollToDate]);

  if (jobs?.length === 0) {
    if (hasFilters) {
      return (
        <div className='max-w-full flex-1 overflow-x-hidden px-7 py-4'>
          There are no jobs matching these filters...
        </div>
      );
    } else {
      return <EmptyListActions listType='Job' />;
    }
  }

  const items = rowVirtualizer.getVirtualItems();
  const start = rowVirtualizer.range?.startIndex ?? 0;
  const showTodayButton = todayIndex < start - 1 || todayIndex > start + 1;
  const isArrowUp = start > todayIndex;

  const isAllSelected = jobs?.length === selected?.length;

  return (
    <div className='relative max-w-full flex-1 overflow-x-hidden'>
      <div className='pointer-events-none absolute top-5 z-10 w-full'>
        <HLTransition
          show={showTodayButton}
          enter='transition-opacity duration-75'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-150'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <button
            className='pointer-events-auto mx-auto flex items-center gap-1 rounded-full border border-grey-20 bg-white px-3 py-1 text-sm shadow-md hover:bg-grey-5 lg:text-base'
            onClick={() => setScrollToDate(new Date())}
          >
            {isArrowUp ? (
              <ArrowUpIcon className='h-4' />
            ) : (
              <ArrowDownIcon className='h-4' />
            )}
            <span>Today</span>
          </button>
        </HLTransition>
      </div>

      <div
        ref={scrollableRef}
        className='relative h-[calc(100vh-12.38rem)] max-w-full flex-1 gap-3 overflow-x-hidden p-3 pb-[25vh] pt-2 lg:px-4 lg:pb-0 lg:pl-0'
        style={{
          overflowY: 'auto',
          contain: 'strict',
        }}
      >
        <Result
          count={jobs?.length}
          className='absolute w-full bg-white pt-2'
        />
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${items[0]?.start ?? 0}px)`,
            }}
          >
            {items.map((virtualItem) => {
              if (!jobs) return null;

              const i = virtualItem.index;
              const record = jobs[virtualItem.index];
              const { __typename } = record;
              const date = getDate(view, record);
              const prevDate = i === 0 ? undefined : getDate(view, jobs[i - 1]);
              const nextDate =
                i === 0 || i >= jobs.length - 1
                  ? undefined
                  : getDate(view, jobs[i + 1]);

              return (
                <div
                  className={classNames(
                    'flex whitespace-nowrap',
                    date !== prevDate ? 'lg:mt-[5px]' : isMobile ? '' : 'ml-24',
                    date !== nextDate &&
                      i !== 1 &&
                      date !== prevDate &&
                      'lg:min-h-[70px]',
                    isMobile ? 'flex-col' : 'items-center'
                  )}
                  key={virtualItem.key}
                  data-index={virtualItem.index}
                  ref={rowVirtualizer.measureElement}
                >
                  {date !== prevDate && <DateDisplay date={date} />}
                  {__typename === 'Job' && (
                    <JobListItem
                      small={isSmall}
                      job={record}
                      selected={isAllSelected || selected?.includes(record.id)}
                      onSelect={(_, shift, single) =>
                        toggleSelected?.(record.id, shift, single)
                      }
                    />
                  )}
                  {__typename === 'JobCalendarEvent' && !record.hidden && (
                    <CalendarEvent small={isSmall} event={record} />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const JobList = forwardRef(JobListComponent);

// prettier-ignore
const months = [null, 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

function DateDisplay({ date }: { date?: string | null }) {
  const { isMobile } = useBreakpoint();

  const parts = date?.match(/^(\d\d\d\d)-(\d\d)-(\d\d)/);
  const displayYear = parts && CURRENT_YEAR !== parts[1] && ` ${parts[1]}`;

  if (isMobile) {
    return (
      <div
        id={date ? date.substring(0, 10) : 'unscheduled'}
        className='my-1 block w-full scroll-mt-12 bg-white p-1 font-semibold sm:left-2 lg:hidden lg:p-3 lg:text-xl xl:left-6'
      >
        {date ? formatDate(date, 'eee do MMM') : 'Unscheduled'}
        {displayYear}
      </div>
    );
  }
  return (
    <div
      id={date ? date.substring(0, 10) : 'unscheduled'}
      className='left-0 mx-6 -mt-3 hidden w-12 bg-white text-center lg:left-6 lg:block'
    >
      <p className='text-xs lg:text-base lg:leading-tight'>
        {date && formatDate(date, 'eee')}
      </p>
      <p className='text-lg font-semibold leading-none text-grey-50 lg:text-2xl lg:leading-none'>
        {parts ? parts[3] : 'To'}
      </p>
      <p className='text-xs text-grey-40 lg:text-base lg:leading-tight'>
        {parts ? months[parseInt(parts[2])] : 'Plan'}
        {displayYear ? ` ${displayYear.slice(-2)}` : null}
      </p>
    </div>
  );
}
