import { useField } from 'formik';
import { Attachment } from '~/gql/graphql';
import { AttributeEntityType } from '../sites/tabs/AttributeEditForm';
import { AttributeFormData } from './AttributeFormData';

type Props = {
  entityType: AttributeEntityType;
  attachments?: Record<string, Attachment[]>;
  search?: string;
};

export function AttributeFormikField({
  entityType,
  attachments,
  search,
}: Props) {
  const [field, , helpers] =
    useField<{ id: string; value: string }[]>('attributes');

  const value = field.value.reduce((prev: Record<string, string>, curr) => {
    return { ...prev, [curr.id]: curr.value };
  }, {});

  const handleChange = (newValue: Record<string, string>) => {
    helpers.setValue(
      Object.keys(newValue).map((id) => {
        return { id, value: newValue[id] };
      })
    );
  };

  return (
    <div className='mx-4'>
      <AttributeFormData
        entityType={entityType}
        value={value}
        onChange={handleChange}
        attachments={attachments}
        searchValue={search}
      />
    </div>
  );
}
