import classNames from 'classnames';
import { Loading } from '../ui/Loading';
import { MobileAddButton } from '../ui/buttons/MobileAddButton';
import { Outlet } from './Outlet';

export type SplitProps = {
  nav: React.ReactNode;
  filters: React.ReactNode;
  main: React.ReactNode;
  height?: string;

  /** Optionally provide an outlet context */
  context?: unknown;

  /** If true the main section of the layout contains a virtualized scroll area */
  virtual?: boolean;

  /** If true the outlet will be hidden */
  outletHidden?: boolean;

  /**
   * Only used as a quick fix to double scrollbar on activity. Should NOT be used. Remove when able
   * @deprecated
   */
  noScroll?: boolean;
};

export function Split({
  nav,
  filters,
  main,
  height,
  context,
  virtual,
  outletHidden = false,
  noScroll,
}: SplitProps) {
  const fetching = false;

  return (
    <>
      <MobileAddButton />
      <div className='bg-primary-container pt-16 lg:bg-white lg:pt-0'>
        <div className='fixed top-0 z-20 w-full bg-primary-container lg:static lg:z-auto lg:w-auto lg:border-b lg:border-grey-20 lg:bg-white'>
          {nav}
        </div>
      </div>
      <div className='w-full lg:mt-0 lg:flex'>
        <div className='flex-1 lg:overflow-y-hidden'>
          {fetching ? (
            <div className='flex h-48 items-center justify-center'>
              <Loading spinner />
            </div>
          ) : (
            <>
              <div
                className={classNames(
                  'relative max-w-full overflow-x-hidden pb-[25vh] lg:pb-0',
                  height || 'lg:h-[calc(100vh-8.5rem)]',
                  virtual && 'h-[calc(100svh-69px)] overflow-y-hidden',
                  noScroll && 'overflow-y-hidden'
                )}
              >
                {filters && (
                  <div className='sticky top-0 z-10 border-b bg-primary-container shadow-sm lg:border-grey-20 lg:bg-white'>
                    {filters}
                  </div>
                )}
                <div
                  className={
                    virtual
                      ? 'flex h-[calc(100%-160px)] justify-center lg:h-[calc(100%-61px)]'
                      : 'px-3 pb-8 pt-3 lg:px-4'
                  }
                >
                  {main}
                </div>
              </div>
            </>
          )}
        </div>
        {!outletHidden && <Outlet context={context} height={height} />}
      </div>
    </>
  );
}
