import { faPenToSquare, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik, useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'urql';
import { AttributeView } from '~/components/AttributeView';
import { FilesF } from '~/components/FileLink';
import { PromptEffect } from '~/components/Prompt';
import { TagEntityType } from '~/components/TagCombobox';
import { useTags } from '~/components/TagCombobox/tags';
import { AssetForm } from '~/components/assets/AssetForm';
import { AssetView } from '~/components/assets/AssetView';
import { AttributeSelect_Query } from '~/components/form/AttributeSelect';
import { Button } from '~/components/form/SubmitButton';
import { ReadOnlyEditor } from '~/components/form/editor/ReadOnlyEditor';
import { CardSmall } from '~/components/ui/CardSmall';
import { HelperPopup } from '~/components/ui/HelperPopup';
import { PopupDialog } from '~/components/ui/PopupDialog';
import { ReadValue } from '~/components/ui/ReadValue';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { AttachmentFields } from '~/components/upload/AttachmentFields';
import { toAttachmentInput } from '~/components/upload2';
import { UploadField, UploadResult } from '~/components/upload2/Upload';
import {
  ChecklistItem,
  JobStatus,
  useCreateDeploymentMutation,
} from '~/generated/graphql';
import { getFragmentData, graphql } from '~/gql';
import { AttributeSelectDocument, JobQuery, TaskQuery } from '~/gql/graphql';
import { extractProperty } from '~/helpers/collection';
import { upperCaseFirst } from '~/helpers/string';
import { SideLayout } from '~/layouts/side/SideLayout';
import { TextAreaField } from '../../form/textarea/TextArea';
import { StartJobButton } from '../JobView';
import { MemoGallery } from '../JobView/Feed/Gallery';
import {
  CollectAttributes,
  getAttributeFormValues,
} from '../JobView/JobReadView/CollectAttributes';
import {
  ResetTaskStatusButton,
  // PauseTaskButton,
  // QuitTaskButton,
  // ResumeTaskButton,
  // StartTaskButton,
  WorkflowButtons,
} from '../WorkflowButtons';
import { ReadOnlyTransferTask } from './TransferTask/ReadOnlyTransferTask';
import { TransferTaskField } from './TransferTask/TransferTaskField';
import { UpdateTaskTags, UpdateTaskTagsField } from './UpdateTaskTags';

type Props = {
  enabled: boolean;
  job: NonNullable<JobQuery['job']>;
  task: TaskQuery['task'];
  onClose: () => void;
  onSuccess: () => void;
  template?: boolean;
};

type TaskFormData = {
  date: null;
  tags: string[];
  notes: string;
  attachments: UploadResult[];
  transfer: {
    selected: never[];
  };
};

enum ShowAssetAction {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
}
const { VIEW, EDIT, CREATE } = ShowAssetAction;
type AssetTask = TaskQuery['task']['assets'][number];
interface ShowAsset extends AssetTask {
  action: `${ShowAssetAction}`;
}

function addSpaceSeparators(assets: TaskQuery['task']['assets']) {
  return assets.reduce(
    (prev, curr) => {
      const spaceName = curr.deployment?.space.name || '';

      if (spaceName !== prev.space) {
        prev.space = spaceName;
        prev.all.push(spaceName);
      }
      prev.all.push(curr);
      return prev;
    },
    {
      space: '',
      all: [] as Array<TaskQuery['task']['assets'][number] | string>,
    }
  ).all;
}
const AddTagsToTaggable = graphql(`
  mutation AddTagsToTaggable($input: AddTagsToTaggableInput!) {
    addTagsToTaggable(input: $input) {
      __typename
      ... on Task {
        id
      }
      tags {
        nodes {
          id
        }
      }
    }
  }
`);

const RemoveTagsFromTaggable = graphql(`
  mutation RemoveTagsFromTaggable($input: RemoveTagsFromTaggableInput!) {
    removeTagsFromTaggable(input: $input) {
      __typename
      ... on Task {
        id
      }
      tags {
        nodes {
          id
        }
      }
    }
  }
`);

export const CompleteCustomTaskDocument = graphql(`
  mutation CompleteCustomTask($input: CompleteTaskInput!) {
    completeCustomTask(input: $input) {
      id
      status
      updatedAt
      # Completing the task may also complete the job so also fetch job status
      # incase it changed
      job {
        id
        status
      }
    }
  }
`);

export const Custom = (props: Props) => {
  const { job, task } = props;
  const [, completeTask] = useMutation(CompleteCustomTaskDocument);

  const savedJobTasks = localStorage.getItem(`job.tasks_${job.id}`)
    ? JSON.parse(localStorage.getItem(`job.tasks_${job.id}`) || '')
    : null;

  const savedTaskForm: {
    taskId: string;
    notes: string;
    attachments: UploadResult[];
  } | null =
    savedJobTasks && savedJobTasks[`task.form_${task.id}`]
      ? savedJobTasks[`task.form_${task.id}`]
      : null;

  const initialValues = {
    date: null,
    tags: extractProperty(task.tags?.nodes, 'id'),
    notes: savedTaskForm?.notes ?? task?.notes ?? '',
    // images: savedTaskForm?.images || ([] as string[]),
    attachments:
      savedTaskForm?.attachments ??
      task.attachments?.map((attachment) => {
        const data = getFragmentData(AttachmentFields, attachment);
        return {
          id: data.id,
          kind: data.kind as 'image' | 'file',
          name: data.originalFilename,
          preview: data.thumb,
        };
      }) ??
      [],
    transfer: {
      selected: [],
    },
  };

  const handleTaskFormSubmit = async (values: TaskFormData) => {
    // Find any checklist items that need saving
    let checklist: ChecklistItem[] = [];
    document
      .querySelectorAll<HTMLInputElement>('.js-custom-task-checklist-item')
      .forEach((el) => {
        const key = el.getAttribute('name');
        if (key) checklist.push({ key, checked: el.checked === true });
      });

    const input = {
      id: task.id,
      date: values.date,
      tagIds: values.tags,
      notes: values.notes,
      attachments: values.attachments.map(toAttachmentInput),
      checklist,
      transfer: values.transfer,
      attributes: await getAttributeFormValues('Task', task.id),
    };

    const res = await completeTask({ input });

    if (res.error) {
      toast.error(res.error.message.replace('[GraphQL] ', ''));
      return;
    }

    props.onSuccess();
  };

  // FIXME: makes little sense...
  if (job.name === 'Template' && job.jobNumber === 'J0000') {
    return <TaskView template {...props} />;
  }

  if (
    job.status !== JobStatus.InProgress ||
    task.status === JobStatus.Complete
  ) {
    return <TaskView {...props} />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleTaskFormSubmit}>
      <TaskForm {...props} />
    </Formik>
  );
};

/**
 * Read only task view
 */
const TaskView = ({ job, task, onClose, template = false }: Props) => {
  const { t } = useTranslation(['translation', 'job']);
  const description = ReadOnlyEditor({ content: task.description });
  const [result] = useQuery({
    query: AttributeSelect_Query,
    variables: { entityType: 'Site' },
  });
  const attributesList = result?.data?.attributes;

  // TODO would prefer this was passed down in props or context rather than fetched here
  const [result2] = useQuery({
    query: AttributeSelectDocument,
    variables: { entityType: 'Job' },
    requestPolicy: 'cache-first',
  });
  const allJobAttributes = result2.data?.attributes;

  const needToStartJob = job.status === JobStatus.Accepted;
  const [shakeButton, setShakeButton] = useState<string>('');

  const [, addTags] = useMutation(AddTagsToTaggable);
  const [, removeTags] = useMutation(RemoveTagsFromTaggable);
  const [tagOptions] = useTags(TagEntityType.Task);

  function shakeStartButton() {
    setShakeButton('wiggle-btn');
    setTimeout(() => {
      setShakeButton('');
    }, 1000);
  }

  const taskAssets = addSpaceSeparators(
    sortBy(task.assets, ['deployment.space.name'])
  );

  const showMandatoryTaskHelper =
    task.required &&
    task.status !== JobStatus.Complete &&
    task.status !== JobStatus.Incomplete;

  // We have to memoize images to prevent a re-render closing the lightgallery modal
  const images = useMemo(() => {
    return (
      task.attachments
        ?.map((attachment) => getFragmentData(AttachmentFields, attachment))
        .filter(({ kind }) => kind === 'image') ?? []
    );
  }, [task.attachments]);

  return (
    <SideLayout>
      <SideLayout.Head onClose={onClose}>{task.name}</SideLayout.Head>
      <SideLayout.Body onClick={() => shakeStartButton()}>
        {task.status !== JobStatus.Created && (
          <StatusBadge prefix={t('job:task')} value={task.status} />
        )}

        {showMandatoryTaskHelper && <MandatoryTaskHelper jobName={job.name} />}

        {description && (
          <>
            <div className='label mb-1.5'>{t('description')}</div>
            <div className='relative'>
              {/* Add overlay so the checkboxes are not clickable  */}
              <div className='absolute inset-0 z-20 bg-transparent'></div>
              <div className='mb-6'>{description}</div>
            </div>
          </>
        )}

        {task.status === JobStatus.Complete &&
          task.jobAttributeIds &&
          task.jobAttributeIds.length && (
            <CollectAttributes
              entityType='Task'
              entityId={task.id}
              selections={task.jobAttributeIds}
              values={task.taskAttributes}
              readMode
            />
          )}

        <UpdateTaskTags
          options={tagOptions}
          value={task.tags?.nodes}
          onChange={async (tags) => {
            const currentTags = extractProperty(task.tags?.nodes, 'id');
            const toAdd = tags.filter((id) => !currentTags.includes(id));
            const toRemove = currentTags.filter((id) => !tags.includes(id));
            if (toAdd.length) {
              await addTags({ input: { taggableId: task.id, tagIds: toAdd } });
            }
            if (toRemove.length) {
              await removeTags({
                input: { taggableId: task.id, tagIds: toRemove },
              });
            }
          }}
        />

        {task.type === 'Asset' && (
          <>
            <ReadValue label={'Selection'}>
              {task.config?.selection === 'all'
                ? 'All Matching Assets'
                : 'Specific Assets'}
            </ReadValue>
            <ReadValue
              label={t('space', { count: task.config?.spaces?.length })}
            >
              {task.config?.spaces?.join(', ')}
            </ReadValue>
            <ReadValue label={t('assetType')}>
              {task.config?.assetTypes?.join(', ')}
            </ReadValue>
            <ReadValue label='Allow assignee to'>
              {task.config?.allow?.map(upperCaseFirst).join(', ')}
            </ReadValue>
            <hr />
          </>
        )}

        {task.type === 'Stocktake' ? (
          <>
            {task.stocktake?.include && (
              <ReadValue label='Include' className='whitespace-pre-line'>
                {task.stocktake.include
                  .map((type) => t(`itemType.${type}_plural`))
                  .join(', ')}
              </ReadValue>
            )}
            {task.stocktake?.itemStatus && (
              <ReadValue label='Item Status' className='whitespace-pre-line'>
                {task.stocktake.itemStatus.join(', ')}
              </ReadValue>
            )}
            {task.stocktake?.spaces && (
              <ReadValue
                label={t('space_plural')}
                className='whitespace-pre-line'
              >
                {task.stocktake.spaces.join(', ')}
              </ReadValue>
            )}
          </>
        ) : null}

        {task.attributes && task.attributes.length > 0 && (
          <ReadValue label='Attributes to Display'>
            {task.attributes.map((attr, idx) => (
              <p key={idx}>
                {(attr.category ? `${attr.category} \\ ` : '') + attr.name}
              </p>
            ))}
          </ReadValue>
        )}

        {task.status !== JobStatus.Complete &&
          task.jobAttributeIds &&
          task.jobAttributeIds.length > 0 && (
            <>
              <div className='label text-medium mb-1 pt-1 text-grey-40'>
                Custom Fields
              </div>
              {task.jobAttributeIds.map((attribute) => {
                const found = allJobAttributes?.find(
                  ({ id }) => id === attribute
                );
                if (!found) {
                  return null;
                }
                return (
                  <p className='indent-5 text-sm' key={found.id}>
                    {(found.category ? `${found.category} \\ ` : '') +
                      found.name}
                  </p>
                );
              })}
              <br />
            </>
          )}

        {template ? (
          <ReadValue label='Attributes to Audit'>
            {attributesList && attributesList.length
              ? attributesList
                  .filter((attr) =>
                    // @ts-ignore TOFIX
                    task.attribute?.attributes.includes(attr.id)
                  )
                  .map((attr, idx) => (
                    <p key={idx}>
                      {(attr.category ? `${attr.category} \\ ` : '') +
                        attr.name}
                    </p>
                  ))
              : null}
          </ReadValue>
        ) : (
          task.attributes &&
          task.attributes.length > 0 && (
            <AttributeView attributes={task.attributes} />
          )
        )}

        {taskAssets.map((asset) => {
          const isDateSeparator = typeof asset === 'string';

          return isDateSeparator ? (
            <div className='my-4 text-[13px] font-bold'>{asset}</div>
          ) : (
            <CardSmall
              key={asset.id}
              linkTo=''
              titleText={asset.name}
              secondaryText={asset.deployment?.space.name}
              showImage
              image={asset.deployment?.image}
            />
          );
        })}
        <ReadValue label={t('notes')} className='whitespace-pre-line'>
          {task.notes}
        </ReadValue>

        <FilesF attachments={task.attachments ?? []} />
        <MemoGallery attachments={images} />

        {task.type === 'Transfer Stock' && job.location && task.transfer && (
          <ReadOnlyTransferTask task={task} />
          // <div className='-m-4'>
          //   <Stock location={job.location.__typename + ':' + job.location.id} />
          // </div>
        )}
      </SideLayout.Body>
      {!template && (
        <SideLayout.Foot className='p-4'>
          {needToStartJob && (
            <div className={shakeButton}>
              <StartJobButton id={job.id} />
            </div>
          )}
          <ResetTaskStatusButton id={task.id} jobStatus={job.status} />
        </SideLayout.Foot>
      )}
    </SideLayout>
  );
};
/**
 * This is the task form for DOING the task (perform task form)
 * This IS NOT the form for creating/editing the task
 */
const TaskForm = ({ enabled, job, task, onClose }: Props) => {
  const [showAsset, setShowAsset] = useState<ShowAsset | null>(null);
  const [showCreateNewAsset, setShowCreateNewAsset] = useState<boolean>(false);
  const { values } = useFormikContext<TaskFormData>();
  const { t } = useTranslation(['translation', 'job', 'asset']);
  const [uploading, setUploading] = useState(false);
  const isEnabled =
    enabled && [JobStatus.Created, JobStatus.InProgress].includes(task.status);

  const description = ReadOnlyEditor({ content: task.description });
  const [, create] = useCreateDeploymentMutation();

  /**
   * This is to remember the user's inputs without completing the job
   */
  const { notes, attachments } = values;
  const taskFormValuesToSave = {
    taskId: task.id,
    notes,
    attachments,
  };
  if (notes || attachments.length > 0) {
    const savedJobTasks = localStorage.getItem(`job.tasks_${job.id}`)
      ? JSON.parse(localStorage.getItem(`job.tasks_${job.id}`) || '')
      : null;

    // TODO re-enable this feature after working out how it's going to work properly with attachments
    // debounce(() => {
    //   localStorage.setItem(
    //     `job.tasks_${job.id}`,
    //     JSON.stringify({
    //       ...savedJobTasks,
    //       [`task.form_${task.id}`]: taskFormValuesToSave,
    //     })
    //   );
    // }, 2000)();
  }

  const taskAssets = addSpaceSeparators(
    sortBy(task.assets, ['deployment.space.name'])
  );
  const [tagOptions] = useTags(TagEntityType.Task);

  const closeAssetPopup = () => {
    setShowAsset(null);
    setShowCreateNewAsset(false);
  };

  const assetPopupTitle = () => {
    if (showAsset?.action === EDIT) {
      return t('asset:editAsset');
    }
    if (showAsset?.action === VIEW) {
      return showAsset?.name;
    }
    return t('translation:createAsset');
  };

  const required = task.required;
  const showMandatoryTaskHelper =
    required &&
    task.status !== JobStatus.Complete &&
    task.status !== JobStatus.Incomplete;

  return (
    <>
      <PopupDialog
        isOpen={!!showAsset?.action || showCreateNewAsset}
        onClose={() => closeAssetPopup()}
      >
        <PopupDialog.Title>{assetPopupTitle()}</PopupDialog.Title>
        {showAsset?.id && showAsset.action === VIEW && (
          <AssetView isPopup={true} id={showAsset.id} />
        )}
        {showAsset?.id && showAsset.action === EDIT && (
          <AssetForm
            isPopup={true}
            id={showAsset.id}
            onSuccess={() => closeAssetPopup()}
          />
        )}
        {showCreateNewAsset && !showAsset && (
          <AssetForm
            isPopup={true}
            onSuccess={async (assetId) => {
              if (!assetId) return;

              await create({
                input: {
                  assetId,
                  locationId: job.location?.id,
                  spaceName: '',
                  startDate: new Date(),
                },
              });
              closeAssetPopup();
            }}
          />
        )}
      </PopupDialog>
      <Form className='z-30 h-full w-full lg:w-auto'>
        <PromptEffect cancel='Stay with task' />
        <SideLayout>
          <SideLayout.Head onClose={onClose}>{task.name}</SideLayout.Head>
          <SideLayout.Body className='p-0'>
            {task.status !== JobStatus.Created && (
              <StatusBadge prefix={t('job:task')} value={task.status} />
            )}

            <div className='px-4'>
              {showMandatoryTaskHelper && (
                <MandatoryTaskHelper jobName={job.name} />
              )}

              {description && (
                <>
                  <div className='label mb-1.5'>{t('description')}</div>
                  <div className='mb-6'>{description}</div>
                </>
              )}
            </div>

            {task.attributes && task.attributes.length > 0 && (
              <AttributeView attributes={task.attributes} />
            )}

            {task.jobAttributeIds && task.jobAttributeIds.length > 0 && (
              <div className='px-4'>
                <CollectAttributes
                  entityType='Task'
                  entityId={task.id}
                  selections={task.jobAttributeIds}
                  values={task.taskAttributes}
                  readMode={task.status !== JobStatus.Created}
                />
              </div>
            )}

            {taskAssets.map((asset) => {
              const isDateSeparator = typeof asset === 'string';

              return isDateSeparator ? (
                <div className='mx-3 my-4 text-[13px] font-bold'>{asset}</div>
              ) : (
                <div
                  key={asset.id}
                  className='flex cursor-pointer items-center'
                  onClick={() =>
                    setShowAsset({
                      ...asset,
                      action: VIEW,
                    })
                  }
                >
                  <CardSmall
                    linkTo=''
                    titleText={asset.name}
                    secondaryText={asset.type}
                    showImage
                    image={asset.deployment?.image || asset.image}
                  />
                  {task?.config?.allow.includes(EDIT) && (
                    <div
                      className='mb-2 mr-4 flex h-[35px] w-[50px] items-center justify-center rounded-2xl border text-neutral-400 shadow-sm transition-colors hover:text-brand hover:shadow'
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowAsset({
                          ...asset,
                          action: EDIT,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </div>
                  )}
                </div>
              );
            })}

            {task.type === 'Asset' && task?.config?.allow.includes(CREATE) && (
              <button
                type='button'
                onClick={() => setShowCreateNewAsset(!showCreateNewAsset)}
                className='mx-3 mb-5 mt-1 flex items-center gap-2 rounded-3xl border px-5 py-2 text-sm shadow-sm hover:text-brand hover:shadow'
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>Create Asset</span>
              </button>
            )}

            {isEnabled && (
              <>
                <div className='flex flex-col gap-[15px] px-4'>
                  <div>
                    <UpdateTaskTagsField options={tagOptions} />
                  </div>

                  <TextAreaField
                    name='notes'
                    label={t('notes')}
                    placeholder='Add Task Notes...'
                  />
                </div>

                {task.type === 'Standard' && (
                  <div className='text-center'>
                    <UploadField
                      id={task.id}
                      name='attachments'
                      attachments_v2={task.attachments ?? []}
                      onProgress={() => setUploading(true)}
                      onComplete={() => setUploading(false)}
                      multiple
                    />
                  </div>
                )}
                {task.type === 'Transfer Stock' && (
                  <TransferTaskField job={job} task={task} />
                )}
              </>
            )}
          </SideLayout.Body>
          <SideLayout.Foot className='p-4'>
            <WorkflowButtons
              id={task.id}
              jobStatus={job.status}
              taskStatus={task.status}
            >
              <CompleteTaskButton disabled={uploading} />
            </WorkflowButtons>
          </SideLayout.Foot>
        </SideLayout>
      </Form>
    </>
  );
};

export function MandatoryTaskHelper({ jobName }: { jobName: string }) {
  return (
    <div className='mb-3 flex items-center'>
      <HelperPopup
        icon={
          <div className='flex h-[18px] w-[18px] items-center justify-center rounded-full bg-orange'>
            <span className='text-[9px] font-bold text-white'>M</span>
          </div>
        }
      >
        You will need to complete or defer this task in order to complete{' '}
        {jobName}
      </HelperPopup>
      <span>Mandatory Task</span>
    </div>
  );
}

function CompleteTaskButton({ disabled }: { disabled?: boolean }) {
  const { t } = useTranslation('job');
  const { submitForm, setFieldValue } = useFormikContext();

  const handleClick = useCallback(() => {
    setFieldValue('date', new Date());
    submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button type='button' disabled={disabled} onClick={handleClick}>
      {t('completeTask')}
    </Button>
  );
}
