import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';
import { graphql } from '~/gql';
import { ConfirmButton } from '../ConfirmDateTime';
import { getAttributeFormValues } from './JobReadView/CollectAttributes';

type Props = {
  id: string;
  enabled: boolean;
};

export const CompleteJobDocument = graphql(`
  mutation CompleteJob(
    $id: ID!
    $endTime: DateTime!
    $attributes: [AttributeInputV2!]!
  ) {
    completeJob(id: $id, endTime: $endTime, attributes: $attributes) {
      id
      status
    }
  }
`);

export function CompleteJobButton({ id, enabled }: Props) {
  const { t } = useTranslation('job');
  const [, complete] = useMutation(CompleteJobDocument);

  return (
    <ConfirmButton
      title='EndTime'
      onConfirm={async (date) => {
        const attributes = await getAttributeFormValues('Job', id);
        await complete({ id, endTime: date, attributes });
        /**
         *  Remove saved job tasks form
         */
        localStorage.removeItem(`job.tasks_${id}`);
      }}
      disabled={!enabled}
    >
      {t('job:completeJob')}
    </ConfirmButton>
  );
}
