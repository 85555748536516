import { faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useAppContext } from '~/App';
import { Input } from '~/components/form/Input';
import { Image } from '~/components/ui/Image';
import { UploadResult } from '~/components/upload2';
import { ControlledUpload } from '~/components/upload2/Upload';
import { useUpdateAccountProfileMutation } from '~/generated/graphql';
import { PageProps } from '../account';

type FormData = {
  name: string;
  logo: string | null;
  industry: string;
  logoUpload?: UploadResult[];
};

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Required'),
  logo: Yup.string().nullable(),
  industry: Yup.string().nullable(),
});

type AccountProfileProps = PageProps & {
  canManageAccount?: boolean;
};

export const AccountProfile = ({
  setShowPage,
  canManageAccount = false,
}: AccountProfileProps) => {
  const { tenant, revalidate } = useAppContext();
  const { t } = useTranslation();
  const [, save] = useUpdateAccountProfileMutation();

  // Convert tenant logo/image to UploadResult format if available
  const initialLogoUpload: UploadResult[] =
    tenant.logo && tenant.image
      ? [
          {
            id: tenant.logo,
            preview: tenant.image,
            name: tenant.name || 'Organization logo',
            kind: 'image',
          },
        ]
      : [];

  const {
    control,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
    register,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      name: tenant.name ?? '',
      logo: tenant.logo,
      industry: tenant.industry ?? '',
      logoUpload: initialLogoUpload,
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const submit = async (values: FormData) => {
    // Extract logo ID from the upload results and remove logoUpload field
    const finalValues = {
      name: values.name,
      industry: values.industry,
      logo:
        values.logoUpload && values.logoUpload.length > 0
          ? values.logoUpload[0].id
          : null,
    };

    const result = await save({ input: finalValues });
    if (result.error?.message) {
      toast.error(result.error.message.replace('[GraphQL] ', ''));
      return;
    }
    if (result.data?.updateAccountProfile) {
      toast.success('Account profile updated successfully');
      setShowPage(null);
      revalidate();
    }
  };

  const industryOptions = ['Accommodation Provider', 'Logistics', 'Events'];

  return (
    <div className='flex w-full grow'>
      <form
        onSubmit={handleSubmit(submit)}
        className='mx-auto flex flex-1 grow flex-col justify-between'
      >
        <div>
          {!canManageAccount && (
            <div className='mt-2 flex items-center gap-2 rounded-md bg-red-100 p-4 text-sm text-red-700'>
              <FontAwesomeIcon icon={faXmarkCircle} />
              <p>
                {t(
                  'You do not have permission to edit this profile. Contact your administrator if you think this is an error.'
                )}
              </p>
            </div>
          )}
          <div className='my-7'>
            <p>Organisation Logo</p>

            {canManageAccount ? (
              <div className='mb-4'>
                <Controller
                  control={control}
                  name='logoUpload'
                  render={({ field }) => (
                    <ControlledUpload
                      id='organization-logo-upload'
                      value={field.value || []}
                      onChange={(newUploads: UploadResult[]) => {
                        // Always use only the most recent upload (replacing any previous ones)
                        field.onChange(
                          newUploads.length > 0
                            ? [newUploads[newUploads.length - 1]]
                            : []
                        );
                        if (newUploads.length > 0) {
                          setValue(
                            'logo',
                            newUploads[newUploads.length - 1].id
                          );
                        } else {
                          setValue('logo', null);
                        }
                      }}
                    />
                  )}
                />
              </div>
            ) : (
              <div className='mt-3 h-24 w-24 bg-surface-container-low'>
                <Image
                  objectFit='contain'
                  size='fit'
                  url={tenant.image}
                  className='rounded-sm'
                  alt={tenant.name}
                />
              </div>
            )}
          </div>
          <Input
            {...register('name')}
            label={t('organisationName')}
            type='text'
            error={errors.name?.message}
            disabled={!canManageAccount}
            className={classNames({
              'opacity-70': !canManageAccount,
            })}
          />
          <div className='mb-5 flex w-full flex-col'>
            <label htmlFor='industry'>{t('selectYourIndustry')}</label>
            <select
              {...register('industry')}
              id='industry'
              className={classNames(
                'mt-2 rounded border border-gray88 bg-white px-3 py-3',
                {
                  'opacity-70': !canManageAccount,
                }
              )}
              disabled={!canManageAccount}
            >
              <option value='' hidden>
                {t('selectYourIndustry')}
              </option>
              {industryOptions.map((value) => (
                <option
                  value={value}
                  key={value}
                  className='p-2 font-light text-copy-head'
                >
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>

        {canManageAccount && (
          <button
            type='submit'
            className='w-full rounded-lg bg-brand p-3 font-semibold uppercase text-white transition-all duration-300 hover:bg-slate-dark disabled:opacity-50'
            disabled={isSubmitting || !canManageAccount}
          >
            {isSubmitting ? 'Saving...' : t('submit')}
          </button>
        )}
      </form>
    </div>
  );
};
