import { faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserSettingsPopup } from '~/components/users/settings/ui/UserSettingsPopup';

type EmptyListActionsProps = {
  listType: 'Site' | 'Job' | 'Contact' | 'Item' | 'Asset' | 'Template';
  hasWizard?: boolean;
};

export function EmptyListActions({
  listType,
  hasWizard,
}: EmptyListActionsProps) {
  const [openWizard, setOpenWizard] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div className='flex flex-grow flex-col items-center justify-center text-center'>
      <div className='mb-4 max-w-[25rem] px-4 pt-12 text-xl'>
        You don't have any {t(listType.toLocaleLowerCase())}s here. Would you
        like to create a new {t(listType.toLocaleLowerCase())}?
      </div>
      <Link
        className='mt-4 flex rounded-3xl bg-grey-70 px-4 py-2 font-medium text-white transition-all hover:bg-grey-90'
        to='new'
      >
        + {t(listType.toLocaleLowerCase())}
      </Link>
      {hasWizard && (
        <>
          <div className='max-w-[400px] pt-12'>Want a bit more help?</div>
          <div
            onClick={() => setOpenWizard(true)}
            className='mt-4 flex cursor-pointer items-center justify-center gap-2 rounded-3xl border border-blue-90 px-4 py-2 font-medium text-blue-90 transition-all hover:border-grey-90 hover:text-grey-90'
          >
            <FontAwesomeIcon
              icon={faMagicWandSparkles}
              className='text-yellow'
            />
            {listType} creation wizard
          </div>
          <UserSettingsPopup
            isOpen={openWizard}
            onClose={() => setOpenWizard(false)}
          >
            Popup content - help steps - go here
          </UserSettingsPopup>
        </>
      )}
    </div>
  );
}
