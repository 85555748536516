import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigation } from 'react-router-dom';
import { array, number, object, string } from 'yup';
import { FindAddContact } from '~/components/FindAddContact';
import { FindAddSite, Site } from '~/components/FindAddSite';
import { useSites } from '~/components/FindAddSite/FindAddSite';
import { getAttributeFilterOptions } from '~/components/FindAddSite/SiteSelect';
import { Prompt } from '~/components/Prompt';
import { SelectCombolist } from '~/components/SelectCombolist';
import { ControlledStatusToggle } from '~/components/form/StatusToggle';
import { Button } from '~/components/form/SubmitButton';
import { Input } from '~/components/form/TextField';
import { TimePicker } from '~/components/form/TimePicker';
import { RadioGroup } from '~/components/form/checkradio';
import { FacetedFilterSelect } from '~/components/ui/FacetedFilterSelect';
import { getFragmentData } from '~/gql';
import {
  AutomationFormQuery,
  AutomationWhereType,
  Status,
} from '~/gql/graphql';
import { ContactFields } from '~/graphql/fragment/ContactFields';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { SideLayout } from '~/layouts/side/SideLayout';
import { Accordion } from '../../ui/Accordion';

export type FormValues = {
  status: Status;
  name: string;
  on: string; // "check-in" | "check-out"
  whereType: AutomationWhereType;
  matchAttribute: { key: string; value: string[] }[]; // whereType === 'match'
  sites: Site[]; // whereType === 'select'
  jobName: string;
  template: string;
  owner: string[] | null;
  assignee: string[] | null;
  day: number;
  time: string | null;
  runOnExistingBookings: string;
};

type Props = {
  data: AutomationFormQuery;
  onSubmit: (data: FormValues) => void;
};

const schema = object({
  name: string().required('Required'),
  on: string().oneOf(['check-in', 'check-out']).required('Required'),
  sites: array().required('Required'),
  jobName: string().required('Required'),
  template: string().required('Required'),
  owner: array().of(string()).nullable(),
  assignee: array().of(string()).nullable(),
  day: number().required('Required'),
  time: string().nullable(),
});

const dayOptions = [...Array(29)].map((_, i) => {
  const value = (i - 14).toString();
  return {
    value,
    label:
      value === '0'
        ? 'Same day as'
        : i - 14 < 0
        ? `${((i - 14) * -1).toString()} days before`
        : `${value} days after`,
  };
});

export function AutomationForm({ data, onSubmit }: Props) {
  const navigation = useNavigation();
  const { automation } = data;
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const {
    control,
    formState: { errors, isDirty, isSubmitting, dirtyFields: _ },
    handleSubmit,
    register,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      status: automation?.status ?? Status.Active,
      name: automation?.name ?? '',
      on: automation?.on ?? 'check-out',
      whereType: automation?.whereType ?? AutomationWhereType.All,
      matchAttribute: automation?.matchAttribute
        ? automation.matchAttribute.map(({ __typename, ...a }) => a)
        : [],
      sites: automation?.sites ?? [],
      jobName: automation?.jobName ?? '',
      template: automation?.template.id ?? '',
      owner: automation?.owners
        ? automation.owners.map((owner) => owner.id)
        : null,
      assignee: automation?.assignees
        ? automation.assignees.map((assignee) => assignee.id)
        : null,
      day: automation?.day ?? 0,
      time: automation?.time ?? null,
      runOnExistingBookings: automation ? '' : 'true',
    },
    resolver: yupResolver(schema),
  });

  const contacts = getFragmentData(ContactFields, data.contacts);

  // Only required for attribute filters (whereType = 'match') - Site select performs its own query
  const [sites] = useSites({ requestPolicy: 'cache-first' });
  const attributeFilters = useMemo(
    () => getAttributeFilterOptions(sites),
    [sites]
  );

  const [accordionOpenOnError, setAccordionOpenOnError] = useState<
    number | null
  >(null);

  const whereType = watch('whereType');

  return (
    <SideLayout as='form' onSubmit={handleSubmit(onSubmit)}>
      <Prompt when={isDirty && !isSubmitting} cancel='Stay with Form' />
      <SideLayout.Head leftSlot={<Link to='..' className='x' />}>
        {t(['automations', automation ? 'edit' : 'new'].join('.'))}
      </SideLayout.Head>
      <SideLayout.Body>
        <Controller
          name='status'
          control={control}
          render={({ field }) => (
            <ControlledStatusToggle
              checked={field.value === 'Active'}
              onChange={(event) =>
                field.onChange(event.target.checked ? 'Active' : 'Inactive')
              }
            />
          )}
        />

        <Input
          {...register('name')}
          label={t('automation_name')}
          error={errors.name?.message}
          required
        />

        <Accordion
          defaultOpen={accordionOpenOnError !== null ? accordionOpenOnError : 0}
        >
          <Accordion.Card>
            <Accordion.Button summary={''} help=''>
              Where
            </Accordion.Button>
            <Accordion.Panel>
              <Controller
                name='whereType'
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    title=''
                    options={[
                      { value: AutomationWhereType.All, label: 'All Places' },
                      {
                        value: AutomationWhereType.Match,
                        label: 'Matching Places',
                      },
                      {
                        value: AutomationWhereType.Select,
                        label: 'Selected Places',
                      },
                    ]}
                  />
                )}
              />

              {whereType === AutomationWhereType.Match && (
                <Controller
                  name='matchAttribute'
                  control={control}
                  render={({ field }) => (
                    <FacetedFilterSelect
                      label='Filter by Attribute'
                      options={attributeFilters}
                      value={field.value || []}
                      onChange={field.onChange}
                      error={errors.matchAttribute?.message}
                      required
                    />
                  )}
                />
              )}

              {whereType === AutomationWhereType.Select && (
                <Controller
                  name='sites'
                  control={control}
                  render={({ field, fieldState }) => (
                    <FindAddSite
                      optionsBoxHeight={
                        isMobile
                          ? 'max-h-[calc(100vh-185px)]'
                          : 'max-h-[calc(100vh-320px)]'
                      }
                      label={t('site', { count: field.value?.length ?? 0 })}
                      error={fieldState.error?.message}
                      value={field.value}
                      onChange={field.onChange}
                      multiple
                    />
                  )}
                />
              )}
            </Accordion.Panel>
          </Accordion.Card>
          <Accordion.Card>
            <Accordion.Button summary={''} help=''>
              Who
            </Accordion.Button>
            <Accordion.Panel>
              <Controller
                name='owner'
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FindAddContact
                    optionsBoxHeight={
                      isMobile
                        ? 'max-h-[calc(100vh-285px)]'
                        : 'max-h-[calc(100vh-450px)]'
                    }
                    label='Job Owner(s)'
                    error={error?.message}
                    value={
                      value && value.length
                        ? contacts.filter(({ id }) => value.includes(id))
                        : []
                    }
                    onChange={(selected) =>
                      onChange(
                        selected.length ? selected.map(({ id }) => id) : null
                      )
                    }
                    isTeamMember
                    multiple
                    isAbsolute={false}
                  />
                )}
              />

              <Controller
                name='assignee'
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FindAddContact
                    optionsBoxHeight={
                      isMobile
                        ? 'max-h-[calc(100vh-285px)]'
                        : 'max-h-[calc(100vh-450px)]'
                    }
                    label='Assignee(s)'
                    error={error?.message}
                    value={
                      value
                        ? contacts.filter(({ id }) => value.includes(id))
                        : []
                    }
                    onChange={(selected) =>
                      onChange(
                        selected.length ? selected.map(({ id }) => id) : null
                      )
                    }
                    multiple
                    isAbsolute={false}
                  />
                )}
              />
            </Accordion.Panel>
          </Accordion.Card>
          <Accordion.Card>
            <Accordion.Button summary={''} help=''>
              When
            </Accordion.Button>
            <Accordion.Panel>
              <div className='grid grid-cols-2 gap-4'>
                <Controller
                  name='day'
                  control={control}
                  render={({ field }) => (
                    <SelectCombolist
                      className='mb-5'
                      label=''
                      searchable={false}
                      options={dayOptions}
                      optionsBoxHeight={
                        isMobile
                          ? 'max-h-[calc(100vh-160px)]'
                          : 'max-h-[calc(100vh-280px)]'
                      }
                      value={String(field.value)}
                      onChange={(selected) =>
                        field.onChange(parseInt(selected))
                      }
                      required
                    />
                  )}
                />

                <Controller
                  name='on'
                  control={control}
                  render={({ field }) => (
                    <SelectCombolist
                      className='mb-5'
                      label=''
                      searchable={false}
                      options={['check-out', 'check-in'].map((value) => ({
                        label: t(`automations.events.${value}`),
                        value,
                      }))}
                      optionsBoxHeight={
                        isMobile
                          ? 'max-h-[calc(100vh-160px)]'
                          : 'max-h-[calc(100vh-280px)]'
                      }
                      value={field.value}
                      onChange={(selected) => field.onChange(selected)}
                      required
                    />
                  )}
                />
              </div>
              <Controller
                control={control}
                name='time'
                render={({ field: { value, onChange } }) => (
                  <TimePicker
                    label='Scheduled Start Time'
                    value={value ?? ''}
                    onChange={(val) => onChange(val || null)}
                    error={errors.time?.message}
                  />
                )}
              />
            </Accordion.Panel>
          </Accordion.Card>
          <Accordion.Card>
            <Accordion.Button summary={''} help=''>
              What
            </Accordion.Button>
            <Accordion.Panel>
              <Input
                {...register('jobName')}
                label='Job Name'
                error={errors.jobName?.message}
                required
              />

              <Controller
                name='template'
                control={control}
                render={({ field }) => (
                  <SelectCombolist
                    label='Template'
                    options={data.jobTemplates}
                    optionsBoxHeight={
                      isMobile
                        ? 'max-h-[calc(100vh-255px)]'
                        : 'max-h-[calc(100vh-380px)]'
                    }
                    error={errors.template?.message}
                    value={field.value}
                    onChange={(selected) => field.onChange(selected)}
                    required
                  />
                )}
              />
            </Accordion.Panel>
          </Accordion.Card>
        </Accordion>

        <div className='px-3 pb-8 pt-3'>
          <label className='mb-3 block text-sm font-medium text-gray-700'>
            <input
              type='checkbox'
              {...register('runOnExistingBookings')}
              value='true'
              className='mr-2'
            />
            Run on existing bookings
          </label>

          {automation && (
            <p className='text-sm text-grey-50'>
              New jobs will only be created where there isn't an existing job
              created by this automation. Existing jobs will not be changed and
              will need to first be cancelled if you want them replaced.
            </p>
          )}
        </div>
      </SideLayout.Body>
      <SideLayout.Foot className='p-4'>
        <Button loading={navigation.state === 'submitting'}>
          {t('submit')}
        </Button>
      </SideLayout.Foot>
    </SideLayout>
  );
}
