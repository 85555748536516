import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  redirect,
  useLoaderData,
  useNavigate,
  useParams,
  useSubmit,
} from 'react-router-dom';
import invariant from 'tiny-invariant';
import { client } from '~/client';
import { SiteForm } from '~/components/sites/SiteForm';
import { Loading } from '~/components/ui/Loading';
import {
  UpdateSiteDocument,
  UpdateSiteMutation,
  UpdateSiteMutationVariables,
} from '~/generated/graphql';
import { useSiteQuery } from './sites.$siteId';
import { loader } from './sites.new';

export { loader };

export async function action({ params, request }: ActionFunctionArgs) {
  invariant(params.siteId, 'Site ID is required');
  const body = await request.formData();
  const newSite = Object.fromEntries(body);
  const input = JSON.parse(newSite.data as string);

  const res = await client
    .mutation<UpdateSiteMutation, UpdateSiteMutationVariables>(
      UpdateSiteDocument,
      { id: params.siteId, input }
    )
    .toPromise();

  const id = res.data?.updateSite?.id;
  if (id) {
    return redirect(`${window.location.pathname.slice(0, -4)}`);
  }
}

export function EditSite() {
  const data = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const submit = useSubmit();

  const { siteId } = useParams();
  invariant(siteId);
  const [result] = useSiteQuery(siteId);

  const site = result.data?.site;

  if (!site) {
    return <Loading />;
  }

  return (
    <SiteForm
      title={t('editSite')}
      site={site}
      homheroAreas={data.homheroAreas}
      homheroEnabled={data.integrations.includes('homhero')}
      onClose={() => navigate(`${window.location.pathname.slice(0, -4)}`)}
      onSubmit={(input) =>
        submit({ data: JSON.stringify(input) }, { method: 'post' })
      }
    />
  );
}
