import { useField } from 'formik';
import { Switch, SwitchProps } from './Switch';

type SwitchFieldProps = Omit<SwitchProps, 'checked' | 'onChange'>;

export const SwitchField = (props: SwitchFieldProps) => {
  const [field, , helpers] = useField<boolean>(props.name);
  const handleChange: SwitchProps['onChange'] = (checked) => {
    helpers.setValue(checked);
  };

  return (
    <Switch
      {...props}
      type='field'
      checked={field.value}
      onChange={handleChange}
    />
  );
};
