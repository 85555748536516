import {
  Outlet,
  useNavigate,
  useOutlet,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { OperationContext, useQuery } from 'urql';
import { SiteView } from '~/components/site/SiteView';
import { ErrorMessage } from '~/components/ui/Error';
import { Loading } from '~/components/ui/Loading';
import { useStockOnHandQuery } from '~/generated/graphql';
import { graphql } from '~/gql';
import { SiteQuery } from '~/gql/graphql';
import { useTitle } from '~/hooks/index';
import { useSiteListContext } from './sites';

const Site_Query = graphql(`
  query Site($id: ID!) {
    integrations
    site(id: $id) {
      id
      status
      name
      type
      address
      image
      notes
      licensor {
        id
        name
      }
      defaultSource {
        id
        type
        name
      }
      attributes {
        ...AttributeFields
      }
      integrationAttributes {
        name
        value
      }
      images
      airbnbCalendar {
        id
        provider
        url
        syncedAt
      }
      airbnbDefaultCheckIn
      airbnbDefaultCheckOut
      homhero {
        id
        name
      }
    }
  }
`);

type ContextType = {
  site: SiteQuery;
  onSuccess: () => void;
  reloadStock: (opts?: Partial<OperationContext> | undefined) => void;
};

export function useSite() {
  return useOutletContext<ContextType>();
}

export function useSiteQuery(id: string) {
  return useQuery({
    // TODO change to cache-first and invalidate correctly where required
    requestPolicy: 'cache-and-network',
    query: Site_Query,
    variables: { id },
    pause: !id,
  });
}

export default function SiteRoute() {
  const { view, setView } = useSiteListContext();
  const outlet = useOutlet();
  const { siteId } = useParams();

  const [, reloadStock] = useStockOnHandQuery({
    requestPolicy: 'cache-and-network',
    variables: { location: `Site:${siteId}` },
  });

  const [{ fetching, data }] = useSiteQuery(siteId!);
  const navigate = useNavigate();

  useTitle(data?.site ? [data.site.name].join(' ') : undefined);

  const site = data?.site;

  // TODO FIXME
  // useEffect(() => {
  //   if (site && view !== site.type) {
  //     setView(
  //       PRESET_SITE_TYPES.findIndex((t) => t === site.type) > -1
  //         ? site.type ?? ''
  //         : 'Other'
  //     );
  //   }
  // }, [site, view, setView]);

  if (fetching) return <Loading />;
  if (!site) return <ErrorMessage message='Site not found' />;

  const handleClose = () => {
    navigate('..');
  };

  return (
    <div className='relative flex h-full'>
      <SiteView siteId={site.id} onClose={handleClose} />
      {outlet && (
        <div className='absolute z-20 h-full w-full xl:relative xl:w-[414px]'>
          <Outlet context={{ site, job: false, reloadStock }} />
        </div>
      )}
    </div>
  );
}
