import localforage from 'localforage';
import { useQuery } from 'urql';
import { AttributeSelect_Query } from '~/components/form/AttributeSelect';
import { AttributeInputV2 } from '~/gql/graphql';
import { AttributeAuditForm } from '../../tasks/AttributeAuditTask/AttributeAuditForm';

type CollectType = 'Job' | 'Task';

type Props = {
  /**
   * The entity to collect attributes for
   *
   * Note that in the attribute config itself the entity type is always Job (Job attributes can be saved for both jobs and tasks)
   * This makes the name here `entityType` confusing perhaps a name like "targetType" or "collectType" would be better
   * e.g. `collect: { type: 'Job' | 'Task'; id: string; }`
   */
  entityType: CollectType;
  entityId: string;
  selections: string[];
  values?:
    | {
        id: string;
        value?: string | null;
      }[]
    | null;
  readMode: boolean;
};

function getStorageKey(type: CollectType, id: string | number) {
  return `${type}:${id}:Form`;
}

/** Attribute values to save with the job */
export async function getAttributeFormValues(
  type: CollectType,
  id: string | number
): Promise<AttributeInputV2[]> {
  const key = getStorageKey(type, id);
  const values = await localforage.getItem<Record<string, string>>(key);

  return values
    ? Object.entries(values).map(([id, value]) => ({ id, value }))
    : [];
}

export function CollectAttributes({
  entityType,
  entityId,
  selections,
  values,
  readMode,
}: Props) {
  const [result] = useQuery({
    query: AttributeSelect_Query,
    variables: { entityType: 'Job' },
  });

  const { data } = result;

  const defaultValues = selections.reduce<Record<string, string>>(
    (prev, current) => {
      return {
        ...prev,
        [current]: values?.find(({ id }) => id === current)?.value ?? '',
      };
    },
    {}
  );

  return (
    <>
      <AttributeAuditForm
        allAttributes={data?.attributes ?? []}
        contacts={[]}
        attachments={{}}
        defaultValues={defaultValues}
        storageKey={getStorageKey(entityType, entityId)}
        onChange={() => {}}
        readMode={readMode}
      />
    </>
  );
}
