import { sortBy } from 'lodash';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AssetSelect, useAssets } from '~/components/AssetSelect';
import { DeploymentFieldsFragment } from '~/components/AssetSelect/AssetSelect';
import { AssetTypeSelect } from '~/components/AssetTypeSelect';
import { useSites } from '~/components/FindAddSite/FindAddSite';
import { SiteSelect } from '~/components/FindAddSite/SiteSelect';
import { Label } from '~/components/ui/Label';
import { Switch } from '~/components/ui/nucleus/Switch';
import { getFragmentData } from '~/gql';
import { ListAssetsQuery } from '~/gql/graphql';
import { unique } from '~/helpers/array';
import { notUndefined } from '~/helpers/filter';
import {
  AssetTaskSelection,
  Control,
  Register,
  SetValue,
  SpacesSelect,
  Watch,
} from '../CustomTaskForm';

type Asset = ListAssetsQuery['assets'][number];

type Props = {
  control: Control;
  locationId?: string;
  watch: Watch;
  register: Register;
  setValue: SetValue;
  scopeIds?: string[];
  isJobTemplate?: boolean;
};

function getDeploymentFragmentData(deployment: Asset['deployment']) {
  return getFragmentData(DeploymentFieldsFragment, deployment);
}

function hasLocation(locationId: string | undefined) {
  return (asset: Asset) => {
    const deployment = getDeploymentFragmentData(asset.deployment);
    return (
      deployment &&
      `${deployment.location.__typename}:${deployment.location.id}` ===
        locationId
    );
  };
}

export function AssetTaskFields({
  control,
  locationId,
  watch,
  register,
  setValue,
  scopeIds,
  isJobTemplate = false,
}: Props) {
  const { t } = useTranslation();
  const multipleLocations = scopeIds && scopeIds.length > 1;

  const [assets] = useAssets();
  const [sites] = useSites();

  const selection = watch('asset.selection');
  const spaces = watch('asset.spaces');
  const assetLocationId = watch('asset.locationId');
  const assetTypes = watch('asset.assetTypes');

  const specificAssetsSelected = selection === AssetTaskSelection.SPECIFIC;

  const selectedLocationAssetTypes = unique(
    assets
      .filter((asset) => {
        const deployment = getDeploymentFragmentData(asset.deployment);
        if (assetLocationId && specificAssetsSelected) {
          return deployment?.location.id === assetLocationId.split(':')[1];
        }
        if (scopeIds?.length) {
          return scopeIds?.includes(deployment?.location.id ?? '');
        }
        return asset;
      })
      .map((item) => item.type)
  );

  const filterBySpacesTypes = (assets: Asset[]) => {
    return assets.filter((asset) => {
      const deployment = getDeploymentFragmentData(asset.deployment);
      return (
        (!spaces.length || spaces.includes(deployment?.space.id ?? '')) &&
        (!assetTypes.length || assetTypes.includes(asset.type))
      );
    });
  };

  useEffect(() => {
    if (multipleLocations) {
      setValue('asset.selection', 'all');
    }
  }, [multipleLocations]);

  return (
    <>
      <div className='mb-5 flex flex-wrap items-center gap-5 gap-y-2'>
        <label className='flex items-center gap-2'>
          <input
            className='h-5 w-5 accent-brand'
            {...register('asset.selection')}
            type='radio'
            value={AssetTaskSelection.SPECIFIC}
            disabled={multipleLocations || isJobTemplate}
          />{' '}
          Specific Assets
        </label>
        <label className='flex items-center gap-2'>
          <input
            className='h-5 w-5 accent-brand'
            {...register('asset.selection')}
            type='radio'
            value={AssetTaskSelection.ALL}
            disabled={multipleLocations || isJobTemplate}
          />{' '}
          All Matching Assets
        </label>
        <span className='basis-full text-sm text-tertiary'>
          The specific assets option is only available in single property jobs.
        </span>
      </div>

      {specificAssetsSelected && (
        <div className='mb-5'>
          <Label>{t('site')}</Label>
          <Controller
            name='asset.locationId'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SiteSelect
                sites={sites}
                value={value ? [value.split(':')[1]] : []}
                onChange={(selected) => {
                  return onChange(
                    selected.length ? `Site:${selected[0].id}` : ''
                  );
                }}
              />
            )}
          />
        </div>
      )}

      <Controller
        name='asset.spaces'
        control={control}
        render={({ field: { value, onChange } }) => (
          <SpacesSelect
            locationId={locationId}
            // options={spaceOptions.map((space) => ({
            //   label: space,
            //   value: space,
            // }))}
            value={value ?? []}
            onChange={(newValue) => onChange(newValue)}
            identifiedByName
            multiple
          />
        )}
      />

      <Controller
        name='asset.assetTypes'
        control={control}
        render={({ field: { value, onChange } }) => (
          <AssetTypeSelect
            value={value ?? []}
            onChange={onChange}
            options={selectedLocationAssetTypes.filter(Boolean).map((name) => ({
              value: name,
              label: name,
            }))}
          />
        )}
      />

      {specificAssetsSelected && (
        <Controller
          name='asset.assets'
          control={control}
          render={({ field: { value, onChange } }) => (
            <AssetSelect
              assets={sortBy(
                filterBySpacesTypes(
                  assets.filter(hasLocation(assetLocationId || locationId))
                ),
                ['deployment.space.name', 'name', 'type']
              )}
              value={value
                .map((id) => assets.find((asset) => asset.id === id))
                .filter(notUndefined)}
              onChange={(selected) => {
                onChange(selected.map((asset) => asset.id));
              }}
              multiple
            />
          )}
        />
      )}

      <div className='my-5 flex flex-col gap-2'>
        <Label as='p'>Allow assignee to</Label>
        <Switch
          label='Edit assets'
          checked={watch('asset.allow')?.includes('edit')}
          onChange={(checked) => {
            const currentValues = watch('asset.allow') || [];
            if (checked && !currentValues.includes('edit')) {
              setValue('asset.allow', [...currentValues, 'edit']);
            } else if (!checked && currentValues.includes('edit')) {
              setValue(
                'asset.allow',
                currentValues.filter((v) => v !== 'edit')
              );
            }
          }}
        />
        <Switch
          label='Create new assets'
          checked={watch('asset.allow')?.includes('create')}
          onChange={(checked) => {
            const currentValues = watch('asset.allow') || [];
            if (checked && !currentValues.includes('create')) {
              setValue('asset.allow', [...currentValues, 'create']);
            } else if (!checked && currentValues.includes('create')) {
              setValue(
                'asset.allow',
                currentValues.filter((v) => v !== 'create')
              );
            }
          }}
        />
      </div>
    </>
  );
}
