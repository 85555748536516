import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

export const handleLogout = (event: MouseEvent) => {
  event.preventDefault();

  axios.delete('sessions').then(() => {
    window.location.assign('/login');
  });
};

export const LogoutLink = () => {
  const { t } = useTranslation();

  return (
    <a
      href='/logout'
      onClick={handleLogout}
      className='flex flex-row items-center justify-start gap-2 py-4 text-secondary hover:text-brand lg:gap-0 lg:py-0 lg:text-base'
    >
      <span className='flex items-center justify-center text-secondary hover:text-primary'>
        <FontAwesomeIcon
          //@ts-ignore
          icon={faArrowRightFromBracket}
          className='mr-3 h-6 w-6'
        />
      </span>
      {t('signOut')}
    </a>
  );
};
