import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, {
  Children,
  cloneElement,
  createContext,
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useBreakpoint } from '~/hooks/useBreakpoint';

type Props = {
  children: false | React.ReactElement | (false | React.ReactElement)[];
  /** Index of panel to start open. Default: 0 */
  defaultOpen?: number;
  /** Array of indices of panels that are open */
  openPanel?: number | number[];
  onToggledPanel?: (idx?: number) => void;
};

type Ref = HTMLDivElement;

type Context = {
  openPanels: number[];
  toggle: (index: number) => void;
};

type TCardContext = {
  index: number;
  open: boolean;
  toggle: () => void;
};

const AccordionContext = createContext<Context>({} as Context);
const CardContext = createContext<TCardContext>({} as TCardContext);

AccordionContext.displayName = 'AccordionContext';
CardContext.displayName = 'CardContext';

const useAccordionContext = () => useContext(AccordionContext);
const useCardContext = () => useContext(CardContext);

// Memoize the AccordionRoot component
const AccordionRoot = memo(function AccordionRoot({
  children,
  defaultOpen = 0,
  openPanel,
  onToggledPanel,
}: Props) {
  // Use a single source of truth for open panels
  const [openPanels, setOpenPanels] = useState<number[]>([]);

  // Initialize open panels from props or default
  useEffect(() => {
    if (openPanel !== undefined) {
      if (Array.isArray(openPanel)) {
        setOpenPanels(openPanel);
      } else {
        setOpenPanels(openPanel >= 0 ? [openPanel] : []);
      }
    } else {
      // Default to opening the first panel if none specified
      setOpenPanels(defaultOpen >= 0 ? [defaultOpen] : []);
    }
  }, [defaultOpen, openPanel]);

  // Ensure first panel is open by default if nothing provided and no openPanel prop
  useEffect(() => {
    if (openPanel === undefined && openPanels.length === 0) {
      let index = -1;
      Children.forEach(children, (child, i) => {
        if (index < 0 && child) {
          index = i;
          return;
        }
      });
      if (index >= 0) {
        setOpenPanels([index]);
      }
    }
  }, [children, openPanel, openPanels.length]);

  // Memoized toggle function to prevent recreating on each render
  const toggle = useCallback(
    (index: number) => {
      // Call the parent's toggle handler
      if (onToggledPanel) {
        onToggledPanel(index);
      }

      // Update local state
      setOpenPanels((prev) => {
        const isPanelOpen = prev.includes(index);
        if (isPanelOpen) {
          return prev.filter((idx) => idx !== index);
        } else {
          return [...prev, index].sort();
        }
      });
    },
    [onToggledPanel]
  );

  // Memoize context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      openPanels,
      toggle,
    }),
    [openPanels, toggle]
  );

  // Memoize children to prevent unnecessary re-renders
  const memoizedChildren = useMemo(() => {
    return Children.map(children, (child, index) =>
      child ? cloneElement(child, { index }) : null
    );
  }, [children]);

  return (
    <AccordionContext.Provider value={contextValue}>
      {memoizedChildren}
    </AccordionContext.Provider>
  );
});

// Memoize the Card component
const Card = memo(
  forwardRef<Ref, Props>(
    ({ children, index }: Props & { index?: number }, ref) => {
      const { openPanels, toggle } = useAccordionContext();

      // Memoize card context value
      const cardContextValue = useMemo(
        () => ({
          index: index ?? -1,
          open: openPanels.includes(index ?? -1),
          toggle: () => {
            if (index !== undefined) {
              toggle(index);
            }
          },
        }),
        [index, openPanels, toggle]
      );

      return (
        <CardContext.Provider value={cardContextValue}>
          <div
            ref={ref}
            className='border-1 shadow-[2px_2px_3px_0px_rgba(111, 80, 80, 0.1)] mb-5 rounded-[10px] border border-grey-20'
          >
            {children}
          </div>
        </CardContext.Provider>
      );
    }
  )
);

// Memoize the GreyCard component
const GreyCard = memo(function GreyCard({
  children,
  index,
  className,
  error,
}: Props & {
  index?: number;
  className?: string;
  error?: boolean;
}) {
  const { openPanels, toggle } = useAccordionContext();
  const { isMobile } = useBreakpoint();

  // Memoize card context value
  const cardContextValue = useMemo(
    () => ({
      index: index ?? -1,
      open: openPanels.includes(index ?? -1),
      toggle: () => {
        if (index !== undefined) {
          toggle(index);
        }
      },
    }),
    [index, openPanels, toggle]
  );

  const cardClasses = useMemo(() => {
    return classNames(
      'greycard border-1 rounded-[10px] border bg-[#f1f4f3] shadow-[2px_2px_3px_0px_rgba(0,0,0,0.1)]',
      isMobile ? 'mb-[13px]' : 'mb-5',
      openPanels.includes(index ?? -1) && 'pb-2',
      error && !openPanels.includes(index ?? -1)
        ? 'border-red-500'
        : 'border-outline-variant',
      className
    );
  }, [className, error, isMobile, index, openPanels]);

  return (
    <CardContext.Provider value={cardContextValue}>
      <div className={cardClasses}>{children}</div>
    </CardContext.Provider>
  );
});

// Memoize the GreyButton component
const GreyButton = memo(function GreyButton({
  children,
  help,
  summary,
}: {
  children: React.ReactNode;
  help?: string;
  summary?: React.ReactNode;
}) {
  const { open, toggle } = useCardContext();

  const buttonClasses = useMemo(() => {
    return classNames(
      'flex w-full items-center justify-between gap-4 rounded-[10px] text-left font-medium text-grey-90',
      open ? 'bg-[#f1f4f3]' : 'bg-white'
    );
  }, [open]);

  return (
    <motion.button
      type='button'
      className={buttonClasses}
      onClick={() => toggle()}
    >
      {children}
      {!open &&
        (summary ? (
          <span className='truncate font-normal'>{summary}</span>
        ) : (
          <span className='truncate font-normal text-grey-40'>{help}</span>
        ))}
    </motion.button>
  );
});

// Memoize the Button component
const Button = memo(function Button({
  children,
  help,
  summary,
  error,
}: {
  children: React.ReactNode;
  help?: string;
  summary?: React.ReactNode;
  error?: boolean;
}) {
  const { open, toggle } = useCardContext();

  const buttonClasses = useMemo(() => {
    return classNames(
      'flex w-full justify-between gap-4 px-3 py-5 text-left font-semibold text-primary md:px-4',
      open && 'pb-0'
    );
  }, [open]);

  const childClasses = useMemo(() => {
    return open ? 'mb-4 text-lg' : 'text-md';
  }, [open]);

  return (
    <motion.button
      type='button'
      className={buttonClasses}
      onClick={() => toggle()}
    >
      <div className={childClasses}>{children} </div>
      {!open &&
        (summary ? (
          <span
            className={classNames(
              'truncate ',
              error ? 'font-light text-red-500' : 'font-normal text-primary'
            )}
          >
            {summary}
          </span>
        ) : (
          <span
            className={classNames(
              'truncate font-light',
              error ? 'text-red-500' : 'text-tertiary'
            )}
          >
            {help}
          </span>
        ))}
    </motion.button>
  );
});

// Memoize the Panel component
const Panel = memo(function Panel({
  children,
  scroll,
  className,
}: {
  children: React.ReactNode | ((next: () => void) => React.ReactNode);
  /** If true sets a max height of the open panel and scrolls if necessary, otherwise should fit content */
  scroll?: boolean;
  className?: string;
}) {
  const { index, open, toggle } = useCardContext();
  const accordionContext = useAccordionContext();

  const next = useCallback(() => {
    if (index !== undefined && index >= 0) {
      accordionContext.toggle(index + 1);
    }
  }, [accordionContext, index]);

  const panelClasses = useMemo(() => {
    return classNames(className, 'px-3 md:px-4', {
      'max-h-64 overflow-y-auto': scroll,
    });
  }, [className, scroll]);

  const variants = useMemo(
    () => ({
      open: { opacity: 1, height: 'auto' },
      collapsed: { opacity: 0, height: 0 },
    }),
    []
  );

  return (
    <AnimatePresence initial={false}>
      {open && (
        <motion.section
          key='content'
          initial='collapsed'
          animate='open'
          exit='collapsed'
          variants={variants}
          transition={{ duration: 0.25, ease: [0.04, 0.62, 0.23, 0.98] }}
          className={panelClasses}
        >
          {typeof children === 'function' ? children(next) : children}
          <div className='flex justify-end'>{/* skip/next buttons */}</div>
        </motion.section>
      )}
    </AnimatePresence>
  );
});

// Memoize the PanelSection component
const PanelSection = memo(function PanelSection({
  children,
  className,
  heading,
}: {
  children: React.ReactNode;
  className?: string;
  heading?: string;
}) {
  const sectionClasses = useMemo(() => {
    return classNames(
      'mx-0 mb-5 rounded-[5px] border border-outline-variant bg-white px-3 pb-3 pt-4 md:px-4',
      className,
      {
        'flex flex-col': heading,
      }
    );
  }, [className, heading]);

  return (
    <section className={sectionClasses}>
      {heading && (
        <label className='pb-3 text-base font-semibold text-primary'>
          {heading}
        </label>
      )}
      {children}
    </section>
  );
});

export const Accordion = Object.assign(AccordionRoot, {
  GreyCard,
  GreyButton,
  Card,
  Button,
  Panel,
  PanelSection,
});
