import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { useBreakpoint } from '~/hooks/useBreakpoint';

type Props = {
  /**
   * The first option in the array will be the primary option.
   *
   * The rest will be the alternative options, rendered bottom to top.
   */
  options: Array<{
    /** Also used as element key, therefore must be unique. */
    label: string;
    onClick: () => void;
    disabled?: boolean;
  }>;
};

export function MultiActionButton({ options }: Props) {
  const styledOptions = options.map((option) => ({
    ...option,
    label: option.label.toLocaleUpperCase(),
  }));

  const primaryOption = styledOptions[0];
  const alternativeOptions = styledOptions.slice(1);

  const [optionsCollapsed, setOptionsCollapsed] = useState(false);
  const collapseOptions = () => setOptionsCollapsed(true);
  const expandOptions = () => setOptionsCollapsed(false);

  const { isMobile } = useBreakpoint();

  return (
    <Popover className={classNames(isMobile && 'flex flex-1', 'relative')}>
      {({ close }) => (
        <>
          <div
            className={classNames(
              !isMobile && 'flex-1',
              'relative z-10 mb-0 flex w-full font-semibold text-white md:mb-4'
            )}
          >
            <button
              type='button'
              className={classNames(
                'flex-grow rounded-lg bg-brand py-4 pl-1 shadow-[0px_4px_5px_rgba(0,0,0,0.16)] transition-colors hover:bg-[#007F7B] sm:pl-12',
                {
                  'rounded-l-lg rounded-r-none border-r border-white border-opacity-20':
                    alternativeOptions.length > 0,
                  'opacity-50': primaryOption.disabled,
                }
              )}
              onClick={primaryOption.onClick}
              disabled={primaryOption.disabled}
            >
              {primaryOption.label}
            </button>
            {alternativeOptions.length > 0 && (
              <PopoverButton
                className={classNames(
                  'w-10 rounded-r-lg bg-brand shadow-[0px_4px_5px_rgba(0,0,0,0.16)] transition-colors focus-within:outline-none hover:bg-[#007F7B] md:w-12'
                )}
              >
                <FontAwesomeIcon
                  className={classNames(
                    'transition-transform duration-300 ui-open:rotate-180'
                  )}
                  icon={faChevronUp}
                />
              </PopoverButton>
            )}
          </div>
          <Transition as={Fragment}>
            <PopoverPanel
              className={classNames(
                'text-white',
                isMobile && 'absolute left-0 right-0'
              )}
            >
              {alternativeOptions.map((option, index) => (
                <Transition.Child
                  as={Fragment}
                  enter='transition ease-in-out duration-300'
                  beforeEnter={collapseOptions}
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='transition ease-in-out duration-300'
                  beforeLeave={expandOptions}
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                  key={option.label}
                >
                  <button
                    type='button'
                    className={classNames(
                      'absolute top-0 w-full rounded-lg bg-brand py-4 font-semibold hover:bg-[#007F7B]',
                      {
                        'opacity-50': option.disabled,
                      }
                    )}
                    disabled={option.disabled}
                    onClick={() => {
                      close();
                      option.onClick();
                    }}
                    style={{
                      transform: optionsCollapsed
                        ? `translateY(-${4 * (index + 1)}rem)`
                        : 'translateY(0)',
                    }}
                  >
                    {option.label}
                  </button>
                </Transition.Child>
              ))}
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
