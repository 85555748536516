import { graphql } from '~/gql';

export const JobTemplateFields = graphql(`
  fragment JobTemplateFields on JobTemplate {
    id
    isStarter
    status
    name
    tags {
      id
      entityType
      category
      name
      description
      colour
    }
    notes
    includeAttributes
    jobAttributeIds
    attachments {
      id
      kind
      ext
      thumb
      url
      originalFilename
    }
    guestyCleaningStatus
    tasks {
      id
      type
      name
      description
      attributes {
        id
        type
        category
        name
        value
      }
      jobAttributeIds
      attribute {
        id
        attributes
      }
      purchase {
        id
        spaces
      }
      stocktake {
        ...StocktakeTaskFields
      }
      config {
        selection
        spaces
        assetTypes
        allow
      }
      assets {
        id
        name
        type
      }
    }
  }
`);
