import { ResultOf } from '@graphql-typed-document-node/core';
import classNames from 'classnames';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { nanoid } from 'nanoid';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from 'urql';
import * as Yup from 'yup';
import { PromptEffect } from '~/components/Prompt';
import { StatusToggle } from '~/components/form/StatusToggle';
import { SubmitButton } from '~/components/form/SubmitButton';
import { TextField } from '~/components/form/TextField';
import { SelectField } from '~/components/form/downshift/SelectField';
import { Card } from '~/components/ui/Card';
import { Image } from '~/components/ui/Image';
import { Loading } from '~/components/ui/Loading';
import { UploadField } from '~/components/upload/Upload';
import { useAddItemMutation, useUpdateItemMutation } from '~/generated/graphql';
import { getFragmentData } from '~/gql';
import { ItemType, Status } from '~/gql/graphql';
import { useEnumOptions } from '~/hooks/useEnumOptions';
import { useSort } from '~/hooks/useSort';
import { SideLayout } from '~/layouts/side/SideLayout';
import { ItemData_ItemFragment, ItemsQueryDocument } from '~/routes/items';
import { VariantAttributesField } from './VariantAttributesField';
import { VariantsField } from './VariantsField';

enum Step {
  MAIN = 'MAIN',
  VARIANT_BUILDER = 'VARIANT_BUILDER',
  ADD_VARIANT = 'ADD_VARIANT',
  EDIT_VARIANT = 'EDIT_VARIANT',
}

type FormValues = {
  id?: string;
  status: string;
  name: string;
  type: string;
  description: string;
  code: string;
  images: string[];
  hasVariants: boolean;
  /** holds temporary values entered on variant builder before expanding into variants */
  variantTypes: {
    name: string;
    values: string[];
  }[];
  variants: {
    key: string;
    status: Status;
    name: string;
    code: string;
    attributes: {
      name: string;
      value: string[];
    }[];
    images: string[];
  }[];
  expanded?: Record<string, string>[];
};

type Props = {
  id?: string;
  action: any;
  title: string;
};

export const ItemForm = ({ action, title }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [step, setStep] = useState<Step>(Step.MAIN);
  const [edit, setEdit] = useState<string>('');

  const [{ data, fetching }] = useQuery({
    query: ItemsQueryDocument,
    requestPolicy: 'cache-first',
  });
  const [, addItem] = useAddItemMutation();
  const [, updateItem] = useUpdateItemMutation();

  const item = useMemo(
    () =>
      getFragmentData(
        ItemData_ItemFragment,
        data?.items.find((it) => it.id === id)
      ),
    [data?.items, id]
  );

  if (fetching) {
    return <Loading />;
  }

  const initialValues: FormValues = item
    ? {
        id: item.id,
        status: item.status,
        name: item.name,
        type: item.type,
        description: item.description ?? '',
        code: item.skuPrefix ?? '',
        images: item.images ?? [],
        hasVariants: item.hasVariants,
        variantTypes: [],
        variants: item.skus.map((sku) => ({
          key: nanoid(),
          id: sku.id,
          status: sku.status,
          name: sku._name ?? '',
          code: sku.code ?? '',
          attributes:
            sku.variant.map((v) => ({ name: v.name, value: [v.value] })) ?? [],
          images: sku.images,
        })),
      }
    : {
        status: 'Active',
        name: '',
        type: '',
        description: '',
        code: '',
        images: [],
        hasVariants: false,
        variantTypes: [],
        variants: [],
      };

  const handleSubmit = async (
    values: typeof initialValues,
    { resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    const { name, code, description, hasVariants, images } = values;
    const variants = values.variants.map(({ key, ...variant }) => {
      variant.attributes = variant.attributes.map(
        // @ts-ignore key is missing from type
        ({ key, ...attribute }) => attribute
      );
      return variant;
    });

    const result = item
      ? await updateItem({
          id: item.id,
          input: {
            status: values.status as Status,
            name,
            type: values.type as ItemType,
            code,
            description,
            hasVariants,
            variants,
            images,
          },
        })
      : await addItem({
          input: {
            status: values.status as Status,
            name,
            type: values.type as ItemType,
            code,
            description,
            hasVariants,
            variants,
            images,
          },
        });

    if (result?.error) {
      toast.error(result.error.graphQLErrors.join(', '));
      return;
    }

    resetForm();
    navigate('/items/');
  };

  const stepper = {
    items: data?.items ?? [],
    variantKey: edit,
    setEdit,
    setStep,
    back: () => setStep(Step.MAIN),
  };

  const Component = (() => {
    switch (step) {
      case Step.VARIANT_BUILDER:
        return VariantBuilder;
      case Step.ADD_VARIANT:
        return AddVariantForm;
      case Step.EDIT_VARIANT:
        return EditVariantForm;
      case Step.MAIN:
      default:
        return Main;
    }
  })();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={buildSchema(
        id,
        data?.items.filter((item) => item.id !== id) ?? []
      )}
    >
      {/* @ts-expect-error FIXME */}
      <Component {...stepper} />
    </Formik>
  );
};

function buildSchema(
  itemId: string | undefined,
  itemsData: ResultOf<typeof ItemsQueryDocument>['items']
) {
  const items = getFragmentData(ItemData_ItemFragment, itemsData);
  const skus = items.map((item) => item.skuPrefix).filter((code) => code);

  return Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .test(
        'unique-name',
        'Must be unique',
        (value) =>
          !items.find(({ id, name }) => name === value && id !== itemId)
      ),
    type: Yup.string().required('Required'),
    description: Yup.string(),
    code: Yup.string().test(
      'unique-code',
      'SKU code already in use',
      (value) => !(value && skus.includes(value))
    ),
    hasVariants: Yup.bool().required(),
    variants: Yup.array().of(
      Yup.object().shape({ name: Yup.string(), values: Yup.array() })
    ),
  });
}

type StepProps = {
  setEdit: (key: string) => void;
  setStep: (step: Step) => void;
};

const Main = ({ setEdit, setStep }: StepProps) => {
  const { values } = useFormikContext<FormValues>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const typeOptions = useEnumOptions(ItemType, 'itemType');
  const isNew = !values.id;

  // const addVariantsButton = (
  //   <button
  //     className='button-text'
  //     onClick={() => setStep(Step.VARIANT_BUILDER)}
  //   >
  //     {t('addVariant_plural')}
  //   </button>
  // );

  // const addVariantButton = (
  //   <button
  //     className='button-text mt-5'
  //     onClick={() => {
  //       setStep(Step.ADD_VARIANT);
  //     }}
  //   >
  //     {t('addVariant')}
  //   </button>
  // );

  const handleClose = isNew
    ? () => navigate('/items')
    : () => navigate('/items/' + values.id);

  return (
    <>
      <PromptEffect />
      <SideLayout>
        <SideLayout.Head onClose={handleClose}>
          {isNew ? t('newItem') : t('editItem')}
        </SideLayout.Head>
        <SideLayout.Body>
          <StatusToggle />
          <TextField name='name' label={t('name')} required />
          <SelectField
            name='type'
            label={t('itemTypeLabel')}
            options={typeOptions}
            required
          />
          <TextField name='description' label={t('description')} />

          {/* Item level SKU is not editable if variants exist */}
          {!values.hasVariants && (
            <TextField name='code' label={t('skuCode')} />
          )}

          <UploadField name='images' />
          {/* 
          {values.hasVariants ? (
            <>
              <p className='mb-5 font-semibold'>Variants</p>
              <div className='text-center'>
                <Variants
                  edit={(key) => {
                    setEdit(key);
                    setStep(Step.EDIT_VARIANT);
                  }}
                />
                {addVariantButton}
              </div>
            </>
          ) : !values.id ? (
            <div className='pt-5 text-center'>{addVariantsButton}</div>
          ) : (
            <div className='text-center'>{addVariantButton}</div>
          )} */}

          {values.hasVariants && (
            <>
              <p className='mb-5 font-semibold'>Variants</p>
              <div className='text-center'>
                <Variants
                  edit={(key) => {
                    setEdit(key);
                    setStep(Step.EDIT_VARIANT);
                  }}
                />
              </div>
            </>
          )}
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <SubmitButton />
        </SideLayout.Foot>
      </SideLayout>
    </>
  );
};

const VariantBuilder = ({ setStep }: StepProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const expandVariants = useCallback(
    (variantTypes: FormValues['variantTypes']) => {
      /** Variant Types */
      const vt = variantTypes.filter(({ name }) => name !== '');
      return vt.reduce((prev: Record<string, string>[], { name, values }) => {
        if (!prev.length) return values.map((v) => ({ [name]: v }));

        const next: typeof prev = [];
        prev.forEach((i) => {
          values.forEach((j) => {
            next.push({ ...i, [name]: j });
          });
        });
        return next;
      }, []);
    },
    []
  );

  const buildVariant = useCallback(
    (skuPrefix: string) => (v: Record<string, string>) => ({
      key: nanoid(),
      status: Status.Active,
      name: Object.values(v).join(' / '),
      code: '',
      attributes: Object.keys(v).map((name) => ({
        name,
        value: v[name],
      })),
      images: [],
    }),
    []
  );

  const done = () => {
    setFieldValue('hasVariants', true);
    setFieldValue('code', ''); // unset the item sku code aka sku prefix
    setFieldValue(
      'variants',
      expandVariants(values.variantTypes).map(buildVariant(values.code))
    );
    setStep(Step.MAIN);
  };

  return (
    <SideLayout>
      <SideLayout.Head onClose={() => setStep(Step.MAIN)}>
        {t('addVariant_plural')}
      </SideLayout.Head>
      <SideLayout.Body>
        <VariantsField />
      </SideLayout.Body>
      <SideLayout.Foot className='p-4'>
        <button
          className='w-full rounded-lg bg-brand p-3 font-semibold capitalize text-white disabled:opacity-50'
          type='button'
          onClick={done}
        >
          {t('submit')}
        </button>
      </SideLayout.Foot>
    </SideLayout>
  );
};

function variantFormSchema(
  items: ResultOf<typeof ItemData_ItemFragment>[],
  variants: FormValues['variants'],
  variant?: { name: string; code: string }
) {
  // Variant names must be unique within the same item
  const names = variants.map((variant) => variant.name);

  // Variant SKU codes must be unique across all items
  const codes = [
    ...items.flatMap((item) => [
      item.skuPrefix,
      ...item.skus.map((variant) => variant.code),
    ]),
    ...variants.map((variant) => variant.code),
  ].filter((code) => !!code);

  return Yup.object().shape({
    status: Yup.string().oneOf(['Active', 'Inactive']).required(),
    name: Yup.string()
      .required('Required')
      .test(
        'unique-name',
        'Must be unique',
        (value) => value === variant?.name || !names.includes(value!)
      ),
    code: Yup.string().test(
      'unique-code',
      'Must be unique',
      (value) => value === variant?.code || !codes.includes(value)
    ),
    // attributes: Yup.array()
    images: Yup.array().of(Yup.string()),
  });
}

type AddVariantProps = {
  items: ResultOf<typeof ItemData_ItemFragment>[];
  back: () => void;
};

const AddVariantForm = ({ items, back }: AddVariantProps) => {
  const {
    values: { hasVariants, variants },
    setFieldValue,
  } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const handleSubmit = (values: FormValues['variants'][0]) => {
    const variant = {
      ...values,
      attributes: values.attributes.filter(({ name }) => !!name),
    };

    let prevVariants = variants;

    if (!hasVariants) {
      prevVariants[0].name = 'Original';
      setFieldValue('hasVariants', true);
    }

    setFieldValue('variants', [...prevVariants, variant]);
    back();
  };

  return (
    <Formik
      initialValues={{
        key: nanoid(),
        status: Status.Active,
        name: '',
        code: '',
        attributes: [],
        images: [],
      }}
      validationSchema={variantFormSchema(items, variants)}
      onSubmit={handleSubmit}
    >
      <SideLayout>
        <SideLayout.Head iconClass='chevron-left' onClose={back}>
          {t('newVariant')}
        </SideLayout.Head>
        <SideLayout.Body>
          <StatusToggle />
          <TextField name='name' label={t('variantName')} autoFocus />
          <TextField name='code' label={t('skuCode')} />
          <VariantAttributesField />
          <UploadField name='images' />
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <SubmitButton />
        </SideLayout.Foot>
      </SideLayout>
    </Formik>
  );
};

type EditVariantProps = {
  items: ResultOf<typeof ItemData_ItemFragment>[];
  variantKey: string;
  back: () => void;
};

const EditVariantForm = ({ items, variantKey, back }: EditVariantProps) => {
  const {
    values: { variants },
    setFieldValue,
  } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const idx = variants.findIndex(({ key }) => key === variantKey);
  const variant = variants[idx];

  if (!variant) {
    throw new Error('Missing variant');
  }

  const initialValues = {
    status: variant.status,
    name: variant.name,
    code: variant.code,
    attributes: variant.attributes,
    images: variant.images,
  };

  const handleSubmit = (values: typeof initialValues) => {
    const variant = {
      ...values,
      attributes: values.attributes.filter(({ name }) => !!name),
    };
    const newVal = [...variants];
    newVal[idx] = {
      ...newVal[idx],
      ...variant,
    };
    setFieldValue('variants', newVal);
    back();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={variantFormSchema(items, variants, variant)}
      onSubmit={handleSubmit}
    >
      <SideLayout>
        <SideLayout.Head iconClass='chevron-left' onClose={back}>
          {t('editVariant')}
        </SideLayout.Head>
        <SideLayout.Body>
          <StatusToggle />
          <TextField name='name' label={t('variantName')} autoFocus />
          <TextField name='code' label={t('skuCode')} />
          <VariantAttributesField />
          <UploadField name='images' />
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <SubmitButton />
        </SideLayout.Foot>
      </SideLayout>
    </Formik>
  );
};

const Variants = ({ edit }: { edit: (key: string) => void }) => {
  const { values } = useFormikContext<FormValues>();
  const variants = values.variants;
  const { sorted } = useSort(variants, { default: 'name' });

  return (
    <>
      {sorted?.map((variant) => {
        const image = variant.images.length ? variant.images[0] : null;
        return (
          <Card key={variant.key} shadow>
            <div
              className='flex cursor-pointer p-4'
              onClick={() => edit(variant.key)}
            >
              <div className='mr-3'>
                <Image url={image} />
              </div>
              <div className='flex-1 leading-tight'>
                <div className='text-sm font-semibold'>{variant.name}</div>
                <div className='text-xs'>{variant.code}</div>
                <div className='text-xs text-gray-600'>
                  {variant.attributes
                    .map(({ name, value }) => `${name}: ${value}`)
                    .join(', ')}
                </div>
              </div>
              <div className='mt-auto'>
                <div
                  className={classNames(
                    'badge',
                    variant.status === 'Inactive'
                      ? 'badge-inactive'
                      : 'badge-active'
                  )}
                >
                  {variant.status}
                </div>
              </div>
            </div>
          </Card>
        );
      })}
    </>
  );
};
