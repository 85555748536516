import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@headlessui/react';
import { cva } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { PropfitLogoFull } from '../../logos/PropfitLogoFull';

export type SidesheetProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  subnav?: boolean;
};

const sideSheetStyles = cva('w-screen max-w-md overflow-auto shadow-lg', {
  variants: {
    subnav: {
      true: 'bg-primary-container text-white',
      false: 'bg-white',
    },
  },
  defaultVariants: {
    subnav: false,
  },
});

export const SideSheet = ({
  children,
  isOpen,
  onClose,
  title,
  subnav = false,
}: SidesheetProps) => {
  const containerVariants = {
    hidden: {
      x: '-100%',
      opacity: 0,
      scaleX: 0.95,
    },
    visible: {
      x: 0,
      opacity: 1,
      scaleX: 1,
      transition: {
        x: { duration: 0.35, ease: [0.4, 0, 0.2, 1] },
        opacity: { duration: 0.35, ease: [0.4, 0, 0.2, 1] },
        scaleX: { duration: 0.3, ease: [0.4, 0, 0.2, 1] },
      },
    },
    exit: {
      x: '-100%',
      opacity: 0,
      scaleX: 0.95,
      transition: {
        x: { duration: 0.3, ease: [0.4, 0, 0.2, 1] },
        opacity: { duration: 0.3, ease: [0.4, 0, 0.2, 1] },
        scaleX: { duration: 0.3, ease: [0.4, 0, 0.2, 1] },
      },
    },
  };

  const backdropVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.35, ease: [0.4, 0, 0.2, 1] },
    },
    exit: { opacity: 0, transition: { duration: 0.3, ease: [0.4, 0, 0.2, 1] } },
  };

  const dragThreshold = 100; // pixels dragged to trigger close

  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={onClose}
          as='div'
          className='fixed inset-0 z-[100]'
        >
          {/* Backdrop */}
          <motion.div
            className='fixed inset-0 bg-black bg-opacity-40'
            variants={backdropVariants}
            initial='hidden'
            animate='visible'
            exit='exit'
            onClick={onClose}
          />

          {/* Sidesheet Container with a 40px gap on the right */}
          <div className='fixed inset-y-0 left-0 right-10 flex max-w-md overflow-clip rounded-br-3xl rounded-tr-3xl'>
            <motion.div
              className={sideSheetStyles({ subnav })}
              variants={containerVariants}
              initial='hidden'
              animate='visible'
              exit='exit'
              drag='x'
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={0.2}
              onDragEnd={(event, info) => {
                if (info.offset.x < -dragThreshold) {
                  onClose();
                }
              }}
            >
              <div className='flex items-center justify-between p-4 pb-7 pr-6'>
                {title ? (
                  <div className='text-xl font-medium'>{title}</div>
                ) : (
                  <PropfitLogoFull className='h-10 w-10 text-white' />
                )}
                <button type='button' onClick={onClose} className='text-white'>
                  <FontAwesomeIcon icon={faXmark} className='h-6 w-6' />
                </button>
              </div>

              {children}
            </motion.div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
