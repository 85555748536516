import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAppContext } from '~/App';
import { Full as Logo } from '~/brand/generated/propfit.app'; // Five Star
import { NavLink as BaseNavLink, NavLinkProps } from '~/components/NavLink';
import { AccountSettings } from '~/components/users/settings/account';
import { UserSettings } from '../../components/users/settings/user';
import AssetsIcon from './icons/Assets';
import ItemsIcon from './icons/Items';
import JobsIcon from './icons/Jobs';
import MoreIcon from './icons/More';
import SitesIcon from './icons/Sites';
import { MobileMenu as MoreMobileMenu } from './MoreNav';
import styles from './Nav.module.css';
import { SupportLink } from './SupportLink';
import { TenantSwitcher } from './TenantSwitcher';
import { UserMenu } from './UserMenu';

const Mobile = () => {
  const nav = useRef<HTMLElement>(null);
  const prevScrollY = useRef(0);
  const ticking = useRef(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { t } = useTranslation('nav');
  const { ability } = useAppContext();

  const handleScroll = useCallback(() => {
    if (!ticking.current) {
      if (window.scrollY > 0) {
        nav.current?.classList.toggle(
          styles.out,
          window.scrollY > prevScrollY.current
        );
        prevScrollY.current = window.scrollY;
      }

      setTimeout(() => {
        ticking.current = false;
      }, 200);

      ticking.current = true;
    }
  }, []);

  useEffect(() => {
    if (!nav) return;
    // resetting nav state
    nav.current?.classList.remove(styles.out);
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <MoreMobileMenu isOpen={showMenu} onClose={() => setShowMenu(false)} />
      <nav
        ref={nav}
        className={[
          styles.mobile,
          'flex justify-around',
          'fixed bottom-0 w-full',
          'lg:hidden',
          'pb-4 lg:pb-0',
          'z-40',
        ].join(' ')}
      >
        <MobileNavLink to='/jobs'>
          <JobsIcon className='block flex-1 stroke-current lg:hidden' />
          <span className='block flex-shrink'>{t('job')}</span>
        </MobileNavLink>
        <MobileNavLink
          to='/sites'
          matches={['/sites', '/site-config', '/site-locations']}
        >
          <SitesIcon className='block h-10  w-10 flex-1 fill-current stroke-current lg:hidden' />
          <span className='block flex-shrink'>{t('site')}</span>
        </MobileNavLink>
        <MobileNavLink
          to='/stock-on-hand'
          matches={[
            '/config',
            '/stock-movements',
            '/stock-transfers',
            '/stock-on-hand',
            '/items',
          ]}
          locked={ability.cannot('read', 'feat.items')}
        >
          <ItemsIcon className='block flex-1 stroke-current lg:hidden' />
          {t('item')}
        </MobileNavLink>
        <MobileNavLink
          to='/assets'
          matches={['/assets', '/deployments', '/asset-models']}
          className='flex flex-col items-center justify-center p-1 lg:hidden'
          locked={ability.cannot('read', 'feat.assets')}
        >
          <AssetsIcon className='block flex-1 lg:hidden' />
          {t('asset')}
        </MobileNavLink>
        <button
          className='flex flex-col items-center justify-center p-1'
          onClick={() => setShowMenu(true)}
        >
          <MoreIcon className='block flex-1 fill-current stroke-current' />
          {t('more')}
        </button>
      </nav>
    </>
  );
};

export const Desktop = () => {
  const { t } = useTranslation('nav');
  const { ability } = useAppContext();
  const { user } = useAppContext();

  return (
    <nav
      className={styles.nav + ' hidden h-[69px] items-center border-b lg:flex'}
    >
      <Link to='/' className='px-4 pl-6'>
        <Logo className='my-auto h-10' />
      </Link>
      <DesktopNavLink to='/jobs' matches={['/activity', '/jobs']}>
        {t('job')}
      </DesktopNavLink>
      <DesktopNavLink
        to='/sites'
        matches={['/sites', '/site-config', '/site-locations']}
      >
        {t('site')}
      </DesktopNavLink>
      <DesktopNavLink
        to='/stock-on-hand'
        matches={[
          '/config',
          '/stock-movements',
          '/stock-transfers',
          '/stock-on-hand',
          '/items',
        ]}
        locked={ability.cannot('read', 'feat.items')}
      >
        {t('item')}
      </DesktopNavLink>
      <DesktopNavLink
        to='/assets'
        matches={['/assets', '/asset-config', '/deployments', '/asset-models']}
        locked={ability.cannot('read', 'feat.assets')}
      >
        {t('asset')}
      </DesktopNavLink>
      <DesktopNavLink
        to={
          ability.can('manage', 'feat.templates')
            ? '/job-templates'
            : '/contacts'
        }
        matches={['/job-templates', '/automations', '/contacts', '/spaces']}
      >
        {t('more')}
      </DesktopNavLink>
      <div className='lg:mr-auto'></div>
      {/* <DesktopNavLink to='/inbox'>
        <InboxIcon className='mr-1 h-5 w-5' /> {t('inbox')} 
      </DesktopNavLink> */}
      <div className='mr-5 flex items-center gap-4'>
        <SupportLink />
        <TenantSwitcher />
        <UserMenu />
      </div>
    </nav>
  );
};

export const MainNav = () => {
  const { isAuthenticated } = useAppContext();
  const location = useLocation();

  if (!isAuthenticated) {
    return null;
  }

  if (location.pathname.endsWith('/attribute-audit')) {
    return null;
  }

  return (
    <>
      <Mobile />
      <Desktop />
      <UserSettings />
      <AccountSettings />
    </>
  );
};

const MobileNavLink = (props: NavLinkProps) => {
  return (
    <BaseNavLink
      {...props}
      className={classNames(
        'relative flex flex-col items-center justify-center p-1',
        {
          'pointer-events-none': props.locked,
        }
      )}
    />
  );
};

const DesktopNavLink = (props: NavLinkProps) => {
  return (
    <BaseNavLink
      {...props}
      className={classNames(
        'flex items-center self-stretch border-b-4 border-white px-4 pt-1 text-copy-nav transition-colors duration-300 ease-in-out hover:border-brand',
        { 'pointer-events-none': props.locked }
      )}
    />
  );
};
