import { faCalendar, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import {
  endOfWeek,
  isBefore,
  isSameDay,
  startOfToday,
  startOfWeek,
} from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopoverStateChangedEffect } from '../PopoverStateChangedEffect';
import { Pill } from './nucleus/Pill';

// Helper function to check if date range matches current week
const isCurrentWeek = (afterDate?: Date, beforeDate?: Date) => {
  if (!afterDate || !beforeDate) return false;

  const today = startOfToday();
  const weekStart = startOfWeek(today, { weekStartsOn: 1 }); // Start on Monday
  const weekEnd = endOfWeek(today, { weekStartsOn: 1 });

  return isSameDay(afterDate, weekStart) && isSameDay(beforeDate, weekEnd);
};

export type DateRange = { afterDate?: Date; beforeDate?: Date };
type Props = {
  label?: string;
  value: DateRange | null;
  onChange: (newDateRange: DateRange | null) => void;
};

export function FilterDateRange({ label = 'Date', value, onChange }: Props) {
  const [afterDate, setAfterDate] = useState<Date | undefined>(
    value?.afterDate
  );
  const [beforeDate, setBeforeDate] = useState<Date | undefined>(
    value?.beforeDate
  );

  useEffect(() => {
    // When value changes from outside, update internal state
    // so date selections stay in sync
    setAfterDate(value?.afterDate);
    setBeforeDate(value?.beforeDate);
  }, [value]);

  const [cancelling, setCancelling] = useState(false);
  const handleClose = () => {
    if (cancelling) {
      setCancelling(false);
      setAfterDate(value?.afterDate);
      setBeforeDate(value?.beforeDate);
      return;
    }

    if (beforeDate && afterDate) {
      onChange(
        isBefore(afterDate, beforeDate) ? { afterDate, beforeDate } : null
      );
      return;
    }

    if (beforeDate) {
      onChange({ beforeDate, afterDate: undefined });
      return;
    }

    if (afterDate) {
      onChange({ beforeDate: undefined, afterDate });
      return;
    }
  };
  const { t } = useTranslation();

  return (
    <>
      <Popover>
        {({ open, close: closePopover }) => {
          const getLabel = () => {
            // If the Popover is open, use the afterDate and beforeDate
            //  for the label.
            // Otherwise, use the value for the label.
            const data = open ? { afterDate, beforeDate } : value;
            let displayLabel: string;

            // Check if date range matches current week
            if (
              data?.afterDate &&
              data?.beforeDate &&
              isCurrentWeek(data.afterDate, data.beforeDate)
            ) {
              displayLabel = 'This week';
            } else if (data?.afterDate && data?.beforeDate) {
              if (data.afterDate.getTime() === data.beforeDate.getTime()) {
                displayLabel =
                  'On • ' + data.afterDate.toLocaleDateString('en-AU');
              } else {
                displayLabel =
                  data.afterDate.toLocaleDateString('en-AU') +
                  ' • ' +
                  data.beforeDate.toLocaleDateString('en-AU');
              }
            } else if (data?.afterDate && !data?.beforeDate) {
              displayLabel =
                'After • ' + data.afterDate.toLocaleDateString('en-AU');
            } else if (!data?.afterDate && data?.beforeDate) {
              displayLabel =
                'Before • ' + data.beforeDate.toLocaleDateString('en-AU');
            } else {
              displayLabel = label;
            }
            return displayLabel;
          };

          return (
            <>
              <PopoverButton className='mx-1 my-1 rounded-full'>
                <Pill
                  icon={{
                    position: 'left',
                    icon: faCalendar,
                  }}
                  type='filter'
                  hasParent
                  active={open || Boolean(afterDate) || Boolean(beforeDate)}
                >
                  {getLabel()}
                  {(open
                    ? afterDate || beforeDate
                    : value?.afterDate || value?.beforeDate) && (
                    <button
                      className='max-h-full cursor-pointer pt-0.5'
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(null);
                        closePopover();
                      }}
                    >
                      {/* @ts-ignore */}
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  )}
                </Pill>
              </PopoverButton>
              <PopoverPanel
                anchor='bottom start'
                className='prevent-drag-scroll border-gray-10 z-[100] mt-1 rounded-sm border bg-white shadow-md'
              >
                <div className='grid grid-cols-2 items-center gap-x-4 p-3'>
                  <label className='text-copy-alt'>Occurs After</label>
                  <label className='text-copy-alt'>Occurs Before</label>
                  <input
                    className='mt-0.5 block w-full appearance-none whitespace-nowrap rounded border border-grey-20 bg-white px-3 py-2.5 focus-visible:border-grey-40'
                    type='date'
                    value={afterDate?.toISOString().substring(0, 10) ?? ''}
                    max={beforeDate?.toISOString().substring(0, 10)}
                    onChange={(e) =>
                      setAfterDate(e.target.valueAsDate || undefined)
                    }
                  />
                  <input
                    className='mt-0.5 block w-full appearance-none whitespace-nowrap rounded border border-grey-20 bg-white px-3 py-2.5 focus-visible:border-grey-40'
                    type='date'
                    value={beforeDate?.toISOString().substring(0, 10) ?? ''}
                    min={afterDate?.toISOString().substring(0, 10)}
                    onChange={(e) =>
                      setBeforeDate(e.target.valueAsDate || undefined)
                    }
                  />
                </div>
                <div className='flex border-t p-1.5 text-xs'>
                  <PopoverButton
                    className='flex-grow p-2 text-left uppercase'
                    onClick={() => setCancelling(true)}
                  >
                    {t('cancel')}
                  </PopoverButton>
                  <PopoverButton className='flex-grow p-2 text-right uppercase'>
                    {t('ok')}
                  </PopoverButton>
                </div>
              </PopoverPanel>
              <PopoverStateChangedEffect open={open} onClose={handleClose} />
            </>
          );
        }}
      </Popover>
    </>
  );
}
