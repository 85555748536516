import { useCallback, useState } from 'react';
import { TaskInput } from '~/generated/graphql';
import { splice } from '~/helpers/array';

export function useTaskEditing(initialTasks: TaskInput[] = []) {
  // State for task management
  const [tasks, setTasks] = useState<TaskInput[]>(initialTasks);
  const [editTask, setEditTask] = useState<string | null>(null);
  const [task, setTask] = useState<TaskInput | null>(null);
  const [shownTaskType, setShownTaskType] = useState<string>('');

  // Handle selecting a task for editing
  const handleSelectTask = useCallback(
    (taskId: string | null) => {
      if (!taskId) {
        // Batch state updates into a single render cycle
        setTimeout(() => {
          setEditTask(null);
          setTask(null);
          setShownTaskType('');
        }, 0);
        return;
      }

      const selectedTask = tasks.find((t) => t.id === taskId);
      if (selectedTask) {
        // Batch state updates into a single render cycle
        setTimeout(() => {
          setEditTask(taskId);
          setTask(selectedTask);
          setShownTaskType(selectedTask.type);
        }, 0);
      }
    },
    [tasks]
  );

  // Handle updating a task - preserve all task properties
  const updateTask = useCallback((updatedTask: TaskInput) => {
    setTimeout(() => {
      setTasks((tasks) => {
        // Find the current task to make sure we're not losing properties
        const existingTaskIndex = tasks.findIndex(
          ({ id }) => id === updatedTask.id
        );
        const existingTask = tasks[existingTaskIndex];

        // Create a proper merged task that preserves all properties
        const mergedTask = existingTask
          ? { ...existingTask, ...updatedTask }
          : updatedTask;

        return splice(tasks, existingTaskIndex, 1, mergedTask);
      });
    }, 1);

    // Close task editor after update
    setEditTask(null);
    setTask(null);
  }, []);

  // Handle deleting a task
  const deleteTask = useCallback(
    (taskId: string) => {
      const taskToDelete = tasks.find((t) => t.id === taskId);
      if (!taskToDelete) return;

      // Use the existing pattern from the original code
      updateTask({ ...taskToDelete, _destroy: true });
    },
    [tasks, updateTask]
  );

  // Handle updating a task's type
  const updateTaskType = useCallback(
    (taskId: string, newType: string) => {
      setTasks((currentTasks) =>
        currentTasks.map((t) => (t.id === taskId ? { ...t, type: newType } : t))
      );

      // If this is the task being edited, update the shown type
      if (editTask === taskId) {
        setShownTaskType(newType);
        // Also update the task object to reflect the new type
        setTask((currentTask) =>
          currentTask ? { ...currentTask, type: newType } : null
        );
      }
    },
    [editTask]
  );

  return {
    // State
    tasks,
    setTasks,
    editTask,
    task,
    shownTaskType,

    // Callbacks
    setEditTask: handleSelectTask, // Replace the simple setter with our callback
    updateTask,
    deleteTask,
    updateTaskType,
  };
}
