import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { useAppContext } from '~/App';
import { UploadResult } from '~/components/upload2';
import { ControlledUpload } from '~/components/upload2/Upload';
import { useSetProfilePicMutation } from '~/generated/graphql';

type FormData = {
  imageUpload: UploadResult[];
};

type Props = {
  backTo: () => void;
};

export const UpdateProfilePicForm = ({ backTo }: Props) => {
  const { user: currentUser, revalidate } = useAppContext();
  invariant(currentUser);
  const [, setProfilePic] = useSetProfilePicMutation();

  // Convert user profile image to UploadResult format if available
  const initialImageUpload: UploadResult[] = currentUser.image
    ? [
        {
          id: currentUser.image,
          preview: currentUser.image,
          name: currentUser.name || 'Profile picture',
          kind: 'image',
        },
      ]
    : [];

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      imageUpload: initialImageUpload,
    },
  });

  const submit = async (values: FormData) => {
    // Extract the image ID from the upload results
    const image =
      values.imageUpload && values.imageUpload.length > 0
        ? values.imageUpload[0].id
        : null;

    if (!image) {
      toast.error('Please select an image');
      return;
    }

    const result = await setProfilePic({ image });
    if (result.error) {
      toast.error('Something went wrong. Please try again');
      return;
    }

    toast.success('Profile picture updated successfully');
    setTimeout(async () => {
      await revalidate();
      backTo();
    }, 1000);
  };

  return (
    <div className='flex w-full flex-1 flex-col'>
      <form
        onSubmit={handleSubmit(submit)}
        className='flex w-full grow flex-col'
      >
        <div className='mb-4'>
          <Controller
            control={control}
            name='imageUpload'
            render={({ field }) => (
              <ControlledUpload
                id='profile-image-upload'
                value={field.value || []}
                onChange={(newUploads: UploadResult[]) => {
                  // Always use only the most recent upload (replacing any previous ones)
                  field.onChange(
                    newUploads.length > 0
                      ? [newUploads[newUploads.length - 1]]
                      : []
                  );
                }}
              />
            )}
          />
        </div>
        <div className='mt-auto'>
          <button
            type='submit'
            className='w-full rounded-lg bg-brand p-3 font-semibold uppercase text-white transition-all duration-300 hover:bg-slate-dark disabled:opacity-50'
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};
