import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { SiteView } from '~/components/site/SiteView';
import { PopupDialog } from '~/components/ui/PopupDialog';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useContacts } from '~/routes/resources/contacts';
import { formatLocalDate } from '../../formatDate';
import { Job } from '../JobView';
import { WhatBubble, WhenBubble, WhereBubble, WhoBubble } from './Bubbles';

type Props = {
  job: Job;
};

export function JobReadView({ job }: Props) {
  const contacts = useContacts();
  const { isMobile } = useBreakpoint();
  const [showSite, setShowSite] = useState(false);

  const jobStart = formatLocalDate(job, 'scheduledStart');
  const jobEnd = formatLocalDate(job, 'scheduledEnd');
  const site = job.location?.__typename === 'Site' ? job.location : undefined;

  const Card = ({
    label,
    children,
  }: {
    label: string;
    children: ReactNode;
  }) => (
    <div
      className={classNames(
        'flex flex-col gap-[21px] rounded-[10px] border border-outline-variant bg-grey-3 shadow-md',
        isMobile ? 'px-[13px] pt-4' : 'px-[21px] pt-5'
      )}
    >
      <h6 className='text-[18px] font-medium leading-5'>{label}</h6>
      <div className={classNames(isMobile ? 'pb-4' : 'pb-5')}>{children}</div>
    </div>
  );

  return (
    <div
      className={classNames('flex flex-col', isMobile ? 'gap-[13px]' : 'gap-5')}
    >
      {job.location?.name && (
        <Card label='Where'>
          <WhereBubble job={job} onPlaceNameClick={() => setShowSite(true)} />
        </Card>
      )}

      {/* {whenSummary(job) !== '' && ( */}
      {(jobStart || jobEnd) && (
        <Card label='When'>
          <WhenBubble job={job} />
        </Card>
      )}

      {job.assignees?.length && (
        <Card label='Who'>
          <WhoBubble job={job} contacts={contacts} />
        </Card>
      )}

      <Card label='What'>
        <WhatBubble job={job} contacts={contacts} />
      </Card>

      <PopupDialog isOpen={showSite} onClose={() => setShowSite(false)}>
        <PopupDialog.Title>{site?.name}</PopupDialog.Title>
        {job.location && (
          <SiteView
            isPopup={true}
            siteId={job.location.id}
            onClose={() => setShowSite(false)}
          />
        )}
      </PopupDialog>
    </div>
  );
}
