import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'urql';
import { useAbilityContext } from '~/components/Can';
import { NavLink } from '~/components/NavLink';
import { Navbar } from '~/components/nav/Navbar';
import { CountBadge } from '~/components/ui/CountBadge';
import { MenuItem, TopBar } from '~/components/ui/TopBar';
import { graphql } from '~/gql';
import { useLocalStorage } from '~/hooks';
import { NavLogo } from './NavLogo';

type Props = {
  unreadActivity?: number;
};

const ActivitiesUnreadCount = graphql(`
  query ActivityUnreadCount {
    allActivity {
      totalUnreadCount
    }
  }
`);

function useUpdateActivityTotalUnreadCount(total: number | undefined) {
  const [result] = useQuery({
    query: ActivitiesUnreadCount,
    requestPolicy: 'cache-and-network',
    pause: total !== undefined,
  });

  if (total) return total;
  return result.data?.allActivity.totalUnreadCount ?? 0;
}

/** Convert translation key to route path */
const toRoutePath = (key: string) => {
  switch (key) {
    // case 'nav:job-templates':
    //   return '/job-templates';
    // case 'nav:job-automations':
    //   return '/automations';
    default:
      return `/jobs/${key.substring('nav:jobs.'.length)}`;
  }
};

const getNavItems = (hasScheduledAccess: boolean) => {
  const items = [
    'nav:jobs.scheduled',
    'nav:jobs.unscheduled',
    'nav:jobs.completed',
    // 'nav:job-templates',
    // 'nav:automations',
  ];
  // Hide calendar for now
  if (hasScheduledAccess) {
    items.unshift('nav:jobs.calendar');
  }
  return items;
};

const Mobile = ({ unreadActivity }: Props) => {
  const { t } = useTranslation(['translation', 'nav']);
  const ability = useAbilityContext();
  const localStorage = useLocalStorage();
  const location = useLocation();

  // Hides calendar on mobile - Reinstate when calendar updates completed
  const hasScheduledAccess = false;
  //const hasScheduledAccess = ability.can('read', 'feat.schedule_view');
  const navItems = getNavItems(hasScheduledAccess);

  function remember(event: React.MouseEvent<HTMLAnchorElement>) {
    const { pathname } = event.currentTarget;
    if (pathname) {
      localStorage.set('nav.jobs', pathname);
    }
  }

  const getCurrentPageName = () => {
    if (location.pathname.startsWith('/activity'))
      return t('nav:jobs.activity');
    if (location.pathname.startsWith('/jobs/calendar'))
      return t('nav:jobs.calendar');
    if (location.pathname.startsWith('/jobs/scheduled'))
      return t('nav:jobs.scheduled');
    if (location.pathname.startsWith('/jobs/unscheduled'))
      return t('nav:jobs.unscheduled');
    if (location.pathname.startsWith('/jobs/completed'))
      return t('nav:jobs.completed');
    if (location.pathname.startsWith('/job-templates'))
      return t('nav:job-templates');
    if (location.pathname.startsWith('/automations'))
      return t('nav:automations');
    return t('nav:jobs.all');
  };

  // Create menu items for the TopBar
  const menuItems: MenuItem[] = [
    ...(ability.can('use', 'feat.activity')
      ? [
          {
            key: 'activity',
            label: t('nav:jobs.activity'),
            to: '/activity',
            badge: unreadActivity || 0,
          },
        ]
      : []),
    ...navItems.map((key) => ({
      key,
      label: t(key),
      to: toRoutePath(key),
    })),
  ];

  return (
    <nav className='lg:hidden'>
      <TopBar
        title={getCurrentPageName()}
        menuItems={menuItems}
        onMenuItemClick={(item) => {
          if (item.to) {
            remember({ currentTarget: { pathname: item.to } } as any);
          }
        }}
      />
    </nav>
  );
};

const Desktop = ({ unreadActivity }: Props) => {
  const { t } = useTranslation(['translation', 'nav']);
  const ability = useAbilityContext();
  const localStorage = useLocalStorage();
  const hasScheduledAccess = ability.can('read', 'feat.schedule_view');
  const navItems = getNavItems(hasScheduledAccess);

  function remember(event: React.MouseEvent<HTMLAnchorElement>) {
    const { pathname } = event.currentTarget;
    if (pathname) {
      localStorage.set('nav.jobs', pathname);
    }
  }

  return (
    <div className='z-50 hidden items-center justify-between bg-white lg:flex'>
      <NavLogo />
      <Navbar className='overflow-x-hidden'>
        {ability.can('use', 'feat.activity') && (
          <Navbar.Item
            className='relative pl-5 pr-5'
            as={NavLink}
            to='/activity'
            activeKey='nav:jobs.activity'
            onClick={remember}
          >
            {t('nav:jobs.activity')}
            {unreadActivity && (
              <div className='absolute right-[22px] top-3'>
                <CountBadge
                  className='absolute left-0 top-0'
                  count={unreadActivity}
                />
              </div>
            )}
          </Navbar.Item>
        )}
        {navItems.map((key) => (
          <Navbar.Item
            key={key}
            as={NavLink}
            to={toRoutePath(key)}
            className='pr-1'
            activeKey={key}
            isActive={() => location.pathname.startsWith(toRoutePath(key))}
            onClick={remember}
          >
            {t(key)}
          </Navbar.Item>
        ))}
      </Navbar>
      <div className='flex items-center'>
        <Link className='add-button' to='new'>
          + {t('job')}
        </Link>
      </div>
    </div>
  );
};

export function JobsNav(props: Props) {
  const unreadActivity = useUpdateActivityTotalUnreadCount(
    props.unreadActivity
  );
  const location = useLocation();

  return (
    <>
      <Mobile unreadActivity={unreadActivity} />
      <Desktop unreadActivity={unreadActivity} />
    </>
  );
}
