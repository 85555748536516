import React from 'react';

interface PropfitLogoFullProps {
  className?: string;
  alt?: string;
  height?: string | number;
  width?: string | number;
}

export const PropfitLogoFull: React.FC<PropfitLogoFullProps> = ({
  className = '',
  alt = 'Propfit',
  height,
  width,
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='139'
      height='44'
      viewBox='0 0 139 44'
      className='h-12 w-auto'
    >
      <g clipPath='url(#clip0_678_16641)'>
        <path
          d='M15.4287 17.2464L15.4504 26.9429L0.5 22.1644L15.4287 17.2464Z'
          fill='currentColor'
        />
        <path
          d='M16.9851 0L26.6629 13.2557L42.2267 8.12858L17.1411 43L16.9851 0Z'
          fill='currentColor'
        />
        <path
          d='M32.1817 5.73423C32.4103 6.43093 32.4281 7.17977 32.2329 7.88607C32.0376 8.5923 31.6381 9.22425 31.0847 9.70197C30.5315 10.1797 29.8493 10.4817 29.1245 10.5699C28.3997 10.6581 27.6648 10.5284 27.0128 10.1974C26.3608 9.86623 25.8208 9.34861 25.4613 8.70983C25.1018 8.07105 24.9389 7.33989 24.9931 6.60881C25.0472 5.87765 25.3162 5.17938 25.7658 4.60232C26.2155 4.02519 26.8257 3.59527 27.5192 3.3667C27.9797 3.21502 28.4656 3.15585 28.9491 3.19271C29.4327 3.22957 29.9044 3.36172 30.3374 3.58153C30.7703 3.80142 31.1561 4.10469 31.4725 4.47405C31.789 4.8434 32.03 5.2716 32.1817 5.73423Z'
          fill='currentColor'
        />
        <path
          d='M28.5362 29.684L34.2587 21.7956L43.5 34.5998L28.5362 29.684Z'
          fill='currentColor'
        />
        <path
          d='M53.7233 27.7595V12.3595H59.7293C61.0786 12.3595 62.2299 12.5721 63.1833 12.9975C64.1366 13.4228 64.8699 14.0388 65.3833 14.8455C65.8966 15.6521 66.1533 16.6128 66.1533 17.7275C66.1533 18.8421 65.8966 19.8028 65.3833 20.6095C64.8699 21.4015 64.1366 22.0175 63.1833 22.4575C62.2299 22.8828 61.0786 23.0955 59.7293 23.0955H54.9333L55.9233 22.0615V27.7595H53.7233ZM55.9233 22.2815L54.9333 21.1815H59.6633C61.0713 21.1815 62.1346 20.8808 62.8533 20.2795C63.5866 19.6781 63.9533 18.8275 63.9533 17.7275C63.9533 16.6275 63.5866 15.7768 62.8533 15.1755C62.1346 14.5741 61.0713 14.2735 59.6633 14.2735H54.9333L55.9233 13.1735V22.2815Z'
          fill='currentColor'
        />
        <path
          d='M68.8625 27.7595V16.0995H70.8865V19.2675L70.6885 18.4755C71.0112 17.6688 71.5539 17.0528 72.3165 16.6275C73.0792 16.2021 74.0179 15.9895 75.1325 15.9895V18.0355C75.0445 18.0208 74.9565 18.0135 74.8685 18.0135C74.7952 18.0135 74.7219 18.0135 74.6485 18.0135C73.5192 18.0135 72.6245 18.3508 71.9645 19.0255C71.3045 19.7001 70.9745 20.6755 70.9745 21.9515V27.7595H68.8625Z'
          fill='currentColor'
        />
        <path
          d='M82.6222 27.8915C81.4488 27.8915 80.4075 27.6348 79.4982 27.1215C78.5888 26.6081 77.8702 25.9041 77.3422 25.0095C76.8142 24.1001 76.5502 23.0735 76.5502 21.9295C76.5502 20.7708 76.8142 19.7441 77.3422 18.8495C77.8702 17.9548 78.5888 17.2581 79.4982 16.7595C80.4075 16.2461 81.4488 15.9895 82.6222 15.9895C83.7808 15.9895 84.8148 16.2461 85.7242 16.7595C86.6482 17.2581 87.3668 17.9548 87.8802 18.8495C88.4082 19.7295 88.6722 20.7561 88.6722 21.9295C88.6722 23.0881 88.4082 24.1148 87.8802 25.0095C87.3668 25.9041 86.6482 26.6081 85.7242 27.1215C84.8148 27.6348 83.7808 27.8915 82.6222 27.8915ZM82.6222 26.0435C83.3702 26.0435 84.0375 25.8748 84.6242 25.5375C85.2255 25.2001 85.6948 24.7235 86.0322 24.1075C86.3695 23.4768 86.5382 22.7508 86.5382 21.9295C86.5382 21.0935 86.3695 20.3748 86.0322 19.7735C85.6948 19.1575 85.2255 18.6808 84.6242 18.3435C84.0375 18.0061 83.3702 17.8375 82.6222 17.8375C81.8742 17.8375 81.2068 18.0061 80.6202 18.3435C80.0335 18.6808 79.5642 19.1575 79.2122 19.7735C78.8602 20.3748 78.6842 21.0935 78.6842 21.9295C78.6842 22.7508 78.8602 23.4768 79.2122 24.1075C79.5642 24.7235 80.0335 25.2001 80.6202 25.5375C81.2068 25.8748 81.8742 26.0435 82.6222 26.0435Z'
          fill='currentColor'
        />
        <path
          d='M97.775 27.8915C96.807 27.8915 95.9197 27.6715 95.113 27.2315C94.321 26.7768 93.683 26.1095 93.199 25.2295C92.7297 24.3495 92.495 23.2495 92.495 21.9295C92.495 20.6095 92.7223 19.5095 93.177 18.6295C93.6463 17.7495 94.277 17.0895 95.069 16.6495C95.8757 16.2095 96.7777 15.9895 97.775 15.9895C98.919 15.9895 99.931 16.2388 100.811 16.7375C101.691 17.2361 102.388 17.9328 102.901 18.8275C103.414 19.7075 103.671 20.7415 103.671 21.9295C103.671 23.1175 103.414 24.1588 102.901 25.0535C102.388 25.9481 101.691 26.6448 100.811 27.1435C99.931 27.6421 98.919 27.8915 97.775 27.8915ZM91.593 32.0275V16.0995H93.617V19.2455L93.485 21.9515L93.705 24.6575V32.0275H91.593ZM97.599 26.0435C98.347 26.0435 99.0143 25.8748 99.601 25.5375C100.202 25.2001 100.672 24.7235 101.009 24.1075C101.361 23.4768 101.537 22.7508 101.537 21.9295C101.537 21.0935 101.361 20.3748 101.009 19.7735C100.672 19.1575 100.202 18.6808 99.601 18.3435C99.0143 18.0061 98.347 17.8375 97.599 17.8375C96.8657 17.8375 96.1983 18.0061 95.597 18.3435C95.0103 18.6808 94.541 19.1575 94.189 19.7735C93.8517 20.3748 93.683 21.0935 93.683 21.9295C93.683 22.7508 93.8517 23.4768 94.189 24.1075C94.541 24.7235 95.0103 25.2001 95.597 25.5375C96.1983 25.8748 96.8657 26.0435 97.599 26.0435Z'
          fill='currentColor'
        />
        <path
          d='M108.877 19.6415H116.819V21.5335H108.877V19.6415ZM109.097 27.7595H106.897V12.3595H117.765V14.2735H109.097V27.7595Z'
          fill='currentColor'
        />
        <path
          d='M120.554 27.7595V16.0995H122.666V27.7595H120.554ZM121.61 13.8555C121.199 13.8555 120.855 13.7235 120.576 13.4595C120.312 13.1955 120.18 12.8728 120.18 12.4915C120.18 12.0955 120.312 11.7655 120.576 11.5015C120.855 11.2375 121.199 11.1055 121.61 11.1055C122.021 11.1055 122.358 11.2375 122.622 11.5015C122.901 11.7508 123.04 12.0661 123.04 12.4475C123.04 12.8435 122.908 13.1808 122.644 13.4595C122.38 13.7235 122.035 13.8555 121.61 13.8555Z'
          fill='currentColor'
        />
        <path
          d='M130.702 27.8915C129.529 27.8915 128.62 27.5761 127.974 26.9455C127.329 26.3148 127.006 25.4128 127.006 24.2395V13.5475H129.118V24.1515C129.118 24.7821 129.272 25.2661 129.58 25.6035C129.903 25.9408 130.358 26.1095 130.944 26.1095C131.604 26.1095 132.154 25.9261 132.594 25.5595L133.254 27.0775C132.932 27.3561 132.543 27.5615 132.088 27.6935C131.648 27.8255 131.186 27.8915 130.702 27.8915ZM125.026 17.8375V16.0995H132.462V17.8375H125.026Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_678_16641'>
          <rect
            width='137.754'
            height='43.133'
            fill='currentColor'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
