import { yupResolver } from '@hookform/resolvers/yup';
import { Editor } from '~/components/form/editor/Editor';
// import { Editor as Lexical } from '~/components/Editor';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { EditorState, RawDraftContentState, convertToRaw } from 'draft-js';
import { useCallback, useEffect, useState } from 'react';
import {
  Control as BaseControl,
  Controller,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import * as yup from 'yup';
import { Button } from '~/components/form/SubmitButton';
import { Input } from '~/components/form/TextField';
import { Select, SelectProps } from '~/components/form/downshift/Select';
import { useConfirm } from '~/components/ui/Confirm';
import { HelperPopup } from '~/components/ui/HelperPopup';
import {
  ItemType,
  LocationType,
  SelectStockOption,
  Status,
  StockSelection,
  StockTransferRule,
  TaskInput,
  TransferTaskInput,
  useLocationsQuery,
  useStockOnHandQuery,
} from '~/generated/graphql';
import { parseJson } from '~/helpers';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { SideLayout } from '~/layouts/side/SideLayout';
import { TagEntityType } from '../TagCombobox';
import { useTags } from '../TagCombobox/tags';
import { AttributeSelect } from '../form/AttributeSelect';
import { Accordion } from '../ui/Accordion';
import { Switch } from '../ui/nucleus/Switch';
import { taskTypeOptions } from './CreateJobForm/ManageTasks';
import { StockToDispatch } from './CreateJobForm/StockToDispatch';
import { StockToReceive } from './CreateJobForm/StockToReceive';
import { DeleteTaskButton } from './DeleteTaskButton';
import { TaskIcon } from './JobView/TaskList/TaskListItem';
import { AssetTaskFields } from './TaskForm/AssetTaskFields';
import { UpdateTaskTags } from './tasks/UpdateTaskTags';

type FormValues = {
  name: string;
  description: RawDraftContentState | null | undefined;
  attributes: string[];
  jobAttributes: string[];
  tagIds: string[];
  type: string;
  required?: boolean;

  item?: {
    /** Source location ID of the form Type:id */
    locationId: string;
    supplyJob: boolean;
    selectStock: SelectStockOption;
    // AUTO
    processQueuedChanges?: boolean;
    fillTo: StockTransferRule;
    // MANUAL
    reserveStock: boolean;
    dispatch: StockSelection[];
    receive: StockSelection[];
  };

  attribute?: {
    /** List of attribute IDs to include in the task */
    attributes: string[];
  };

  purchase?: {
    spaces: string[];
  };

  stocktake?: {
    include: ItemType[];
    itemStatus: Status[];
    spaces: string[];
  };

  transfer?: TransferTaskInput;

  asset?: {
    assets: string[];
    selection: string;
    locationId: string;
    spaces: string[];
    assetTypes: string[];
    allow: string[];
  };
};

type Props = {
  locationId?: string;
  task: TaskInput;
  taskType: string | null;
  updateTaskType: (id: string, value: string) => void;
  onApply: (task: TaskInput) => void;
  onClose: () => void;
  onDelete: (id: string) => void;
  scopeIds?: string[];
  showTaskOptions?: boolean;
  isJobTemplate?: boolean;
  canEditType?: boolean;
  onAttributesChange?: (taskId: string, attributes: string[]) => void;
  onAttributeTaskAttributesChange?: (
    taskId: string,
    attributeTaskAttributes: string[]
  ) => void;
};

export enum AssetTaskSelection {
  SPECIFIC = 'specific',
  ALL = 'all',
}

export type Control = BaseControl<FormValues>;
export type Register = UseFormRegister<FormValues>;
export type Watch = UseFormWatch<FormValues>;
export type SetValue = UseFormSetValue<FormValues>;

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

function createDefaultValueRestock(): FormValues['item'] {
  return {
    /** Source location ID of the form Type:id */
    locationId: '',
    supplyJob: true,
    selectStock: SelectStockOption.MANUAL,
    // AUTO
    processQueuedChanges: false,
    fillTo: StockTransferRule.Target,
    // MANUAL
    reserveStock: true,
    dispatch: [],
    receive: [],
  };
}

function createDefaultValueStocktake(): FormValues['stocktake'] {
  return {
    include: Object.values(ItemType),
    itemStatus: [Status.Active],
    spaces: [],
  };
}

export function CustomTaskForm({
  locationId,
  task,
  taskType,
  updateTaskType,
  onApply,
  onClose,
  onDelete,
  scopeIds,
  showTaskOptions = false,
  isJobTemplate = false,
  canEditType = true,
}: Props) {
  const { t } = useTranslation(['translation', 'job']);
  const [showSelect, setShowSelect] = useState<0 | 1 | null>(null);
  const scope = [locationId];
  const multipleLocations = scopeIds && scopeIds.length > 1;

  const typeOptions = taskTypeOptions();

  const { siteId } = useParams();

  const editorState = EditorState.createEmpty();
  const defaultDescription = convertToRaw(editorState.getCurrentContent());

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const { isMobile } = useBreakpoint();

  // Initialize form with task data
  useEffect(() => {
    reset({
      name: task.name ?? '',
      description: task.description
        ? parseJson(task.description)
        : defaultDescription,
      attributes: task.attributes ?? [],
      jobAttributes: task.jobAttributes ?? [],
      type: taskType || '',
      tagIds: task.tagIds ?? [],
      asset: {
        locationId: locationId ?? '',
        assets: task.asset?.assets ?? [],
        selection: task.asset?.selection
          ? task.asset.selection
          : multipleLocations || isJobTemplate
          ? AssetTaskSelection.ALL
          : AssetTaskSelection.SPECIFIC,
        spaces: task.asset?.spaces ?? [],
        assetTypes: task.asset?.assetTypes ?? [],
        allow: task.asset?.allow ?? [],
      },
      required: task.required ?? false,
      item: (task.item as FormValues['item']) ?? createDefaultValueRestock(),
      attribute: task.attribute
        ? { attributes: task.attribute.attributes ?? [] }
        : { attributes: [] },
      purchase: task.purchase
        ? { spaces: task.purchase.spaces ?? [] }
        : { spaces: [] },
      stocktake: task.stocktake
        ? {
            include: task.stocktake.include ?? Object.values(ItemType),
            itemStatus: task.stocktake.itemStatus ?? [Status.Active],
            spaces: task.stocktake.spaces ?? [],
          }
        : createDefaultValueStocktake(),
      transfer: task.transfer
        ? {
            from: {
              id: task.transfer.from.id ?? '',
              spaces: task.transfer.from.spaces ?? [],
            },
            to: { id: locationId, spaces: task.transfer.to.spaces ?? [] },
          }
        : {
            from: { id: '', spaces: [] },
            to: { id: locationId, spaces: [] },
          },
    });
  }, [task]);

  const { confirm } = useConfirm({ when: isDirty });

  // If job location changes we need to reset the destination location in Transfer Stock tasks
  useEffect(() => {
    setValue('transfer.to.id', locationId ?? '');
    setValue('transfer.to.spaces', []);
  }, [locationId, setValue]);

  // Watch for changes to attributes and update parent component
  const attributes = watch('attributes');
  const attributeTaskAttributes = watch('attribute.attributes');

  // Simplify the apply function since attributes are handled by callbacks
  const apply = (data: FormValues) => {
    const {
      name,
      description,
      type,
      attributes,
      asset,
      item,
      stocktake,
      transfer,
      required,
    } = data;

    onApply({
      id: task.id,
      name,
      description: JSON.stringify(description),
      tagIds: data.tagIds,
      attributes: data.attributes,
      jobAttributes: data.jobAttributes,
      asset: asset
        ? {
            ...(asset.selection === AssetTaskSelection.SPECIFIC
              ? {
                  selection: asset.selection,
                  assets: asset.assets,
                  allow: asset.allow,
                }
              : {
                  selection: asset.selection,
                  assetTypes: asset.assetTypes,
                  spaces: asset.spaces,
                  allow: asset.allow,
                }),
          }
        : undefined,
      type: taskType || '',
      item,
      attribute: data.attribute, // Ensure attribute object is included for Attribute tasks
      purchase: data.purchase,
      stocktake,
      transfer,
      required,
    });
  };

  const statusOptions = [
    { value: Status.Active, label: t('active') },
    { value: Status.Inactive, label: t('inactive') },
  ];

  // const type = watch('type');
  const selectStock = watch('item.selectStock');

  function renderTaskOptions() {
    if (taskType === 'Attribute') {
      return (
        <>
          <h3 className='mb-2 font-medium text-primary'>Attributes</h3>
          <p className='-mt-0.5 mb-2 text-sm text-secondary'>
            The attributes that will be audited for this task
          </p>
          <div className='transition-all duration-300 ease-in-out'>
            <AttributeSelect
              entityType='Site'
              value={attributeTaskAttributes}
              onChange={(selected) =>
                setValue('attribute.attributes', selected)
              }
              isAttributesTask
            />
          </div>
        </>
      );
    }

    if (taskType === 'Purchase') {
      return (
        <>
          <Controller
            name='purchase.spaces'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SpacesSelect
                locationId={locationId}
                value={value ?? []}
                onChange={onChange}
                identifiedByName
                multiple
              />
            )}
          />
        </>
      );
    }
    if (taskType === 'Stocktake') {
      return (
        <div className='mb-40'>
          <div className='my-4 text-sm'>Include:</div>
          {Object.values(ItemType).map((itemType) => {
            // Need a unique input name for each checkbox
            const fieldName = `stocktake.include.${itemType}`;
            return (
              <div key={itemType} className='mb-4 flex items-center'>
                <Switch
                  name={fieldName}
                  label={t(`itemType.${itemType}`, { count: 0 })}
                  checked={watch('stocktake.include').includes(itemType)}
                  onChange={(checked: boolean) => {
                    const currentValues = watch('stocktake.include');
                    if (checked && !currentValues.includes(itemType)) {
                      setValue('stocktake.include', [
                        ...currentValues,
                        itemType,
                      ]);
                    } else if (!checked && currentValues.includes(itemType)) {
                      setValue(
                        'stocktake.include',
                        currentValues.filter((item) => item !== itemType)
                      );
                    }
                  }}
                />
              </div>
            );
          })}

          <div className='my-4 text-sm'>Item Status:</div>
          {statusOptions.map(({ value, label }) => {
            // Need a unique input name for each checkbox
            const fieldName = `stocktake.itemStatus.${value}`;
            return (
              <div key={value} className='mb-4 flex items-center'>
                <Switch
                  name={fieldName}
                  checked={watch('stocktake.itemStatus').includes(value)}
                  onChange={(checked: boolean) => {
                    const currentValues = watch('stocktake.itemStatus');
                    if (checked && !currentValues.includes(value)) {
                      setValue('stocktake.itemStatus', [
                        ...currentValues,
                        value,
                      ]);
                    } else if (!checked && currentValues.includes(value)) {
                      setValue(
                        'stocktake.itemStatus',
                        currentValues.filter((item) => item !== value)
                      );
                    }
                  }}
                />
                <div
                  className={classNames(
                    'flex w-24 items-center justify-center rounded-full py-0.5 text-center text-white',
                    label === 'Active' ? 'bg-green-600' : 'bg-red-600'
                  )}
                >
                  {label}
                </div>
              </div>
            );
          })}

          <Controller
            name='stocktake.spaces'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SpacesSelect
                locationId={locationId}
                value={value ?? []}
                onChange={onChange}
                multiple
              />
            )}
          />
        </div>
      );
    }

    // Old Move Stock/Stock Transfer version of Restock
    if (taskType === 'Restock') {
      return (
        <>
          <Controller
            name='item.locationId'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SourceLocationSelect
                value={value ? [value] : []}
                onChange={(selected) => onChange(selected[0])}
              />
            )}
          />

          <div className='mb-4 flex items-center'>
            <Switch
              {...register('item.supplyJob')}
              name='item.supplyJob'
              type='field'
              checked={watch('item.supplyJob')}
              onChange={(checked: boolean) => {
                setValue('item.supplyJob', checked);
              }}
              label='Create job for source to supply the stock'
            />
            <HelperPopup>
              If you don't create a corresponding job for the stock to be
              supplied, the stock will be recorded as removed from the source at
              the same time it is added or used at the destination.
            </HelperPopup>
          </div>
          <div className='mb-4 flex items-center'>
            <p className='text-sm'>Select stock</p>
            <label className='block whitespace-nowrap pl-4 text-sm'>
              <input
                {...register('item.selectStock')}
                className='mr-2 align-text-top'
                type='radio'
                value={SelectStockOption.AUTO}
              />{' '}
              Automatically
            </label>
            <label className='block whitespace-nowrap pl-4 text-sm'>
              <input
                {...register('item.selectStock')}
                className='mr-2 align-text-top'
                type='radio'
                value={SelectStockOption.MANUAL}
              />{' '}
              Manually
            </label>
            <HelperPopup>
              Automatic stock selection uses the configured Target or Capacity
              of each SKU within its assigned Space and the available stock at
              the selected source to determine how much of each SKU should be
              selected.
            </HelperPopup>
          </div>
          {selectStock === SelectStockOption.AUTO ? (
            <>
              {/* If scope is site
          <Select
            label='Asset(s) to restock'
            options={[]}
            value={[]}
            onChange={() => {}}
          /> */}

              {/* <div className='mb-4'>
              <label className='text-brand text-xs font-semibold'>
                <input
                  className='mr-4 align-text-top'
                  type='checkbox'
                  name='processQueuedChanges'
                  checked={values.processQueuedChanges}
                  onChange={() => {}}
                  // onChange={handleChange}
                />
                Process queued changes
              </label>
            </div> */}

              <div className='mb-4 flex'>
                <p className='text-sm'>Fill to</p>
                {Object.values(StockTransferRule).map((value) => (
                  <label key={value} className='block pl-4 text-sm'>
                    <input
                      {...register('item.fillTo')}
                      className='mr-2 align-text-top'
                      type='radio'
                      value={value}
                    />{' '}
                    {value}
                  </label>
                ))}
              </div>
            </>
          ) : (
            <div className='text-center'>
              <Button
                type='button'
                color='primary'
                onClick={() => setShowSelect(0)}
                disabled={!(locationId && watch('item.locationId'))}
              >
                Select Stock
              </Button>
              {!locationId ? (
                <p className='text-sm font-semibold text-delete'>
                  Job Location is required to select stock
                </p>
              ) : !watch('item.locationId') ? (
                <p className='text-sm font-semibold text-delete'>
                  Source Location is required to select stock
                </p>
              ) : null}
              {scope.length > 1 && (
                <p className='text-sm font-semibold text-copy-alt'>
                  Multiple job locations not yet supported.
                  <br />
                  Please select only one destination.
                </p>
              )}
            </div>
          )}
        </>
      );
    }

    if (taskType === 'Transfer Stock') {
      return (
        <>
          <Controller
            name='transfer.from.id'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SourceLocationSelect
                value={value ? [value] : []}
                onChange={(selected) => onChange(selected[0])}
              />
            )}
          />
          <Controller
            name='transfer.from.spaces'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SpacesSelect
                locationId={watch('transfer.from.id')}
                value={value ?? []}
                onChange={onChange}
              />
            )}
          />
          To:
          <Controller
            name='transfer.to.spaces'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SpacesSelect
                locationId={locationId}
                value={value ?? []}
                onChange={onChange}
                multiple
              />
            )}
          />
        </>
      );
    }

    if (taskType === 'Asset') {
      return (
        <AssetTaskFields
          control={control}
          locationId={locationId}
          watch={watch}
          register={register}
          setValue={setValue}
          scopeIds={scopeIds}
          isJobTemplate={isJobTemplate}
        />
      );
    }

    return null;
  }

  if (showSelect === 0) {
    const sourceLocationId = watch('item.locationId');
    return (
      <StockToDispatch
        location={sourceLocationId}
        initialValues={{
          reserveStock: watch('item.reserveStock') ?? '',
          dispatch: watch('item.dispatch') ?? [],
        }}
        onBack={() => setShowSelect(null)}
        onSubmit={(form) => {
          setValue('item.reserveStock', form.reserveStock);
          setValue('item.dispatch', form.dispatch);
          // if (
          //   JSON.stringify(form.dispatch) === JSON.stringify(values.dispatch)
          // ) {
          //   onChange((state) => ({ ...state, ...form }));
          // } else {
          //   // Reset receive if dispatch changed
          //   onChange((state) => ({ ...state, ...form, receive: [] }));
          // }
          setShowSelect(1);
        }}
      />
    );
  }

  if (showSelect === 1) {
    return (
      <StockToReceive
        location={scope[0]}
        dispatch={watch('item.dispatch')}
        initialValues={{ receive: watch('item.receive') ?? [] }}
        onBack={() => setShowSelect(0)}
        onSubmit={(values) => {
          setValue('item.receive', values.receive);
          setShowSelect(null);
        }}
      />
    );
  }

  const [tagOptions] = useTags(TagEntityType.Task);

  // Memoize initial state for openPanelIdx
  const [openPanelIdx, setOpenPanelIdx] = useState<number[]>(() => {
    return taskType !== 'Standard' ? [0, 1, 2] : [0, 1];
  });

  // Memoize the panel toggle handler with useCallback
  const handlePanelToggle = useCallback(
    (panelIndex: number | undefined) => {
      // If panelIndex is undefined, do nothing
      if (panelIndex === undefined) return;

      setOpenPanelIdx((prevOpenPanels) => {
        // Check if the panel is already open
        const isPanelOpen = prevOpenPanels.includes(panelIndex);

        if (isPanelOpen) {
          // If it's open, remove it from the array to close it
          return prevOpenPanels.filter((idx) => idx !== panelIndex);
        } else {
          // If it's closed, add it to the array to open it
          return [...prevOpenPanels, panelIndex].sort((a, b) => a - b);
        }
      });
    },
    [] // No dependencies needed as we're using functional state update
  );

  // Use an effect with proper dependencies to update panels when taskType changes
  useEffect(() => {
    setOpenPanelIdx((prev) => {
      // Create a new array to avoid mutation
      let updated = [...prev];

      if (taskType !== 'Standard') {
        // Add the third panel index if it's not already included
        if (!updated.includes(2)) {
          updated.push(2);
          updated.sort((a, b) => a - b);
        }
      } else {
        // Remove the third panel index if task type is Standard
        updated = updated.filter((idx) => idx !== 2);
      }

      // Only return a new array if it's different to avoid unnecessary updates
      return JSON.stringify(updated) !== JSON.stringify(prev) ? updated : prev;
    });
  }, [taskType]);

  return (
    <>
      <SideLayout
        className={classNames(
          siteId ? 'absolute inset-0' : 'absolute lg:flex-1 xl:relative'
        )}
        willOverflow={true}
      >
        <SideLayout.Head onClose={onClose}>Edit Task</SideLayout.Head>
        <SideLayout.Body className='pl-4 pr-3'>
          <Accordion
            onToggledPanel={handlePanelToggle}
            openPanel={openPanelIdx}
          >
            <Accordion.GreyCard>
              <Accordion.Button
                summary={
                  taskType === 'Attribute' ? 'Attribute Audit' : taskType || ''
                }
              >
                <div className='flex items-center gap-2'>
                  <FontAwesomeIcon
                    className='h-4 w-4 text-secondary'
                    // @ts-ignore
                    icon={faCog}
                  />
                  <span>Set Up</span>
                </div>
              </Accordion.Button>
              <Accordion.Panel className='transition-all ease-standard'>
                <Accordion.PanelSection>
                  {typeOptions.length > 1 &&
                    (!canEditType ? (
                      <div className='mb-4 flex flex-col gap-1'>
                        <span className='text-sm font-medium text-grey-50'>
                          Type
                        </span>
                        <div className='flex gap-2 rounded-md border border-outline-bright bg-surface-container-low px-3 py-3 focus-visible:outline-none'>
                          <TaskIcon taskType={taskType || ''} />{' '}
                          {taskType === 'Attribute'
                            ? 'Attribute Audit'
                            : taskType || ''}
                        </div>
                        <span className='text-sm text-secondary'>
                          Complex Tasks Type cannot be changed after Job
                          Creation
                        </span>
                      </div>
                    ) : (
                      <Select
                        floatingOptions={false}
                        label='Type'
                        options={typeOptions}
                        value={[taskType || '']}
                        onChange={(selected) =>
                          updateTaskType(task.id, selected[0])
                        }
                        hasSelectorIcon
                      />
                    ))}

                  <Controller
                    name='required'
                    control={control}
                    render={({ field }) => (
                      <Switch
                        name='required'
                        label='Mandatory'
                        help='Mandatory tasks are required to be completed prior to the job being marked as complete.'
                        checked={field.value ?? false}
                        onChange={(value: boolean) => field.onChange(value)}
                      />
                    )}
                  />
                </Accordion.PanelSection>
              </Accordion.Panel>
            </Accordion.GreyCard>
            <Accordion.GreyCard error={false}>
              <Accordion.Button summary={task.name}>What</Accordion.Button>
              <Accordion.Panel>
                <Accordion.PanelSection title='Details'>
                  <Input
                    {...register('name')}
                    label={'Name *'}
                    className={classNames('mb-6', {
                      'border-red-500 focus:border-red-500': errors.name,
                    })}
                    error={errors.name?.message}
                    autoFocus={!isMobile}
                  />
                  <div className='mb-1 ml-1 mt-[12px] text-sm font-medium text-grey-50'>
                    {t('description')}
                  </div>
                  <Controller
                    name='description'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Editor
                        content={value}
                        onUpdate={(raw) => {
                          onChange(raw);
                        }}
                        placeholder='Add Task Description...'
                      />
                    )}
                  />
                </Accordion.PanelSection>
                <Accordion.PanelSection title='Tags'>
                  <Controller
                    name='tagIds'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <div>
                        <UpdateTaskTags
                          value={tagOptions.filter((t) =>
                            value?.includes(t.id)
                          )}
                          options={tagOptions}
                          onChange={onChange}
                        />
                      </div>
                    )}
                  />
                </Accordion.PanelSection>
                <Accordion.PanelSection heading='Job Location Attributes'>
                  <p className='-mt-1 mb-2 text-sm text-secondary'>
                    Information about the job location relevant to this task
                  </p>
                  <Controller
                    name='attributes'
                    control={control}
                    render={({ field }) => (
                      <AttributeSelect entityType='Site' {...field} />
                    )}
                  />
                </Accordion.PanelSection>
              </Accordion.Panel>
            </Accordion.GreyCard>
            {taskType !== 'Standard' && (
              <Accordion.GreyCard>
                <Accordion.Button>
                  <div className='flex items-center gap-2'>
                    <TaskIcon
                      className='text-secondary'
                      taskType={taskType || ''}
                    />
                    <span>
                      {taskType === 'Attribute' ? 'Attribute Audit' : taskType}
                    </span>
                  </div>
                </Accordion.Button>
                <Accordion.Panel>
                  <Accordion.PanelSection>
                    {renderTaskOptions()}
                  </Accordion.PanelSection>
                </Accordion.Panel>
              </Accordion.GreyCard>
            )}
          </Accordion>

          <div className='mb-40 mt-20'>
            <DeleteTaskButton id={task.id} onDelete={onDelete} />
          </div>
        </SideLayout.Body>
        <SideLayout.Foot className='w-full p-4'>
          <Button onClick={handleSubmit(apply)}>{t('apply')}</Button>
        </SideLayout.Foot>
      </SideLayout>

      {/* <Confirm
        show={showUnsavedChangesDialog}
        title='Unsaved Changes'
        body='You have unsaved changes. What would you like to do?'
        cancel='Discard Changes'
        confirm='Save Changes'
        danger={true}
        onCancel={() => {
          setShowUnsavedChangesDialog(false);
          onClose();
          handleTaskNavigation();
        }}
        onConfirm={async () => {
          setShowUnsavedChangesDialog(false);
          await handleSubmit(apply)();
          handleTaskNavigation();
        }}
      /> */}
    </>
  );
}

type SpacesSelectProps = {
  locationId?: string;
  identifiedByName?: boolean;
} & Pick<SelectProps, 'value' | 'onChange' | 'multiple'>;

export function SpacesSelect({
  locationId,
  identifiedByName,
  ...props
}: SpacesSelectProps) {
  const { t } = useTranslation();

  const [result] = useStockOnHandQuery({
    requestPolicy: 'cache-and-network',
    variables: { location: locationId },
    pause: !locationId,
  });
  const { data } = result;

  const [all] = useQuery<{ spaceNames: string[] }>({
    requestPolicy: 'cache-and-network',
    query: '{ spaceNames }',
    pause: Boolean(locationId),
  });

  const spaceOptions =
    data?.stockOnHand
      .filter(({ space }, index, self) => {
        // Filter for unique spaces by name in the list of space skus
        return self.findIndex(({ space: { id } }) => id === space.id) === index;
      })
      .map(({ space: { id, name } }) => {
        return {
          value: identifiedByName ? name : id,
          label: name,
        };
      }) ??
    all.data?.spaceNames.map((value) => ({ value, label: value })) ??
    [];

  return (
    <Select
      {...props}
      label={t(props.multiple ? 'space_plural' : 'space')}
      options={spaceOptions}
    />
  );
}

function SourceLocationSelect(props: Pick<SelectProps, 'value' | 'onChange'>) {
  const [result] = useLocationsQuery({
    variables: {
      type: LocationType.Site,
    },
  });
  const { data } = result;

  return (
    <Select
      {...props}
      label='Source Location'
      options={
        data?.locations.map(({ __typename: type, id, name }) => ({
          value: `${type}:${id}`,
          label: name,
        })) ?? []
      }
    />
  );
}
