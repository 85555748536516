import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubmitFunction, useNavigate, useParams } from 'react-router-dom';
import Toggle from 'react-toggle';
import invariant from 'tiny-invariant';
import * as yup from 'yup';
import { Prompt } from '~/components/Prompt';
import { Button } from '~/components/form/SubmitButton';
import { Input } from '~/components/form/TextField';
import { TextArea } from '~/components/form/textarea/TextArea';
import {
  ManageTasks,
  taskTemplateToTaskInput,
  UseTemplate,
} from '~/components/job/CreateJobForm/ManageTasks';
import { CustomTaskForm } from '~/components/job/CustomTaskForm';
import { TaskInput } from '~/generated/graphql';
import { getFragmentData } from '~/gql';
import { JobTemplateInput, Status } from '~/gql/graphql';
import { JobTemplateFields } from '~/graphql/fragment/JobTemplateFields';
import { parseJson } from '~/helpers';
import { splice } from '~/helpers/array';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useJobTemplateListContext } from '~/routes/job-templates._index';
import { Option } from '~/types';
import { TagCombobox, TagEntityType } from '../TagCombobox';
import { useTags } from '../TagCombobox/tags';
import { AttributeSelect } from '../form/AttributeSelect';
import { toAttachmentInput, toFormValue, UploadResult } from '../upload2';
import { ControlledUpload } from '../upload2/Upload';

export type LocationOptions = {
  Asset: Option[];
  Contact: Option[];
  Site: Option[];
  StockTransfer: Option[];
  Elsewhere: Option[];
};

type FormValues = {
  status: Status;
  name: string;
  tasks: TaskInput[];
  notes: string | null;
  tags: string[] | null;
  includeAttributes: string[] | null;
  jobAttributes: string[] | null;
  attachments: UploadResult[];
  fromTemplate: string;
  guestyCleaningStatus: boolean;
};

const createValidationSchema = (
  currentName: string | undefined,
  templates: readonly { name: string }[]
) =>
  yup.object().shape({
    name: yup
      .string()
      .required('Required')
      .test('unique', 'Name must be unique', (value) => {
        return (
          currentName === value ||
          !templates.some((template) => template.name === value)
        );
      }),
  });

type Props = {
  data?: {
    integrations: string[];
  };
  clone?: boolean;
  onClose?: () => void;
  onSubmit: SubmitFunction;
};

export const JobTemplateForm = ({ data, clone, onClose, onSubmit }: Props) => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const { t } = useTranslation();
  const { templates } = useJobTemplateListContext();
  const [allTags] = useTags(TagEntityType.Job);
  const { isMobile } = useBreakpoint();
  const [editTask, setEditTask] = useState<string | null>(null);
  const [tasks, setTasks] = useState<TaskInput[]>([]);
  const [isTasksDirty, setIsTasksDirty] = useState(false);

  const template = getFragmentData(
    JobTemplateFields,
    templates?.find(({ id }) => id === templateId)
  );

  useEffect(() => {
    if (template) {
      setTasks(template.tasks.map(taskTemplateToTaskInput));
      setIsTasksDirty(false);
    }
  }, [template, setTasks]);

  useEffect(() => {
    const original = template?.tasks.map(taskTemplateToTaskInput);
    setIsTasksDirty(isEqual(tasks, original));
  }, [tasks]);

  const initialValues = {
    fromTemplate: '',
    status: template?.status ?? Status.Active,
    name: template?.name ?? '',
    tags: template?.tags?.map((tag) => tag.id) ?? [],
    notes: template?.notes ?? '',
    includeAttributes: template?.includeAttributes ?? null,
    jobAttributes: template?.jobAttributeIds ?? null,
    attachments: (template?.attachments ?? []).map(toFormValue),
    guestyCleaningStatus: template?.guestyCleaningStatus === 'clean',
  };

  const guestyEnabled = data?.integrations.includes('guesty');

  const {
    register,
    formState: { isDirty, isSubmitting: loading, errors },
    control,
    setValue,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(
      createValidationSchema(
        clone ? undefined : template?.name,
        templates ?? []
      )
    ),
  });

  const includeAttributes = watch('includeAttributes');
  const jobAttributes = watch('jobAttributes');

  const submit = async () => {
    await handleSubmit(async (values) => {
      console.log('values', values);
      onSubmit(serialize(toInput(tasks, values)), { method: 'post' });
    })();
  };

  const [task, setTask] = useState<TaskInput | null>(null);
  const [shownTaskType, setShownTaskType] = useState<string | null>(null);

  useEffect(() => {
    setTask(tasks?.find((task) => task.id === editTask) || null);
    setShownTaskType(tasks?.find((item) => item.id === editTask)?.type || null);
  }, [tasks, editTask]);

  const updateTaskType = (taskId: string, taskType: string) => {
    const tasksListCopy = tasks.slice();
    const taskIndex = tasksListCopy.findIndex((task) => task.id === taskId);
    if (taskIndex < 0) return;
    tasksListCopy[taskIndex].type = taskType;
    setTasks(tasksListCopy);
  };

  const updateTask = (data: TaskInput) => {
    if (data._destroy) {
      setTasks((tasks) =>
        splice(
          tasks,
          tasks.findIndex(({ id }) => id === data.id),
          1
        )
      );
    } else {
      setTasks((tasks) =>
        splice(
          tasks,
          tasks.findIndex(({ id }) => id === data.id),
          1,
          data
        )
      );
    }

    setEditTask(null);
  };

  const handleDeleteTask = (id: string) => {
    const task = tasks.find((t) => t.id === id);
    if (!task) return;
    updateTask({ ...task, _destroy: true });
  };

  if (task) {
    return (
      <CustomTaskForm
        task={task}
        onApply={updateTask}
        onClose={() => setEditTask(null)}
        onDelete={handleDeleteTask}
        showTaskOptions
        isJobTemplate
        taskType={shownTaskType}
        updateTaskType={(id, type) => updateTaskType(id, type)}
      />
    );
  }

  return (
    <SideLayout>
      <SideLayout.Head
        onClose={() => {
          onClose ? onClose() : navigate('..');
        }}
      >
        {template
          ? clone
            ? `Clone ${template.name}`
            : `Edit ${template.name}`
          : t('newTemplate')}
      </SideLayout.Head>
      <SideLayout.Body>
        <Prompt when={!loading && (isDirty || isTasksDirty)} />
        <Controller
          name='status'
          control={control}
          render={({ field }) => (
            <div className='mb-5 flex items-center justify-center gap-2.5'>
              <Toggle
                id='status-toggle'
                className='status-toggle'
                defaultChecked={field.value === 'Active'}
                onChange={(event) =>
                  field.onChange(event.target.checked ? 'Active' : 'Inactive')
                }
              />
              <label
                className={classNames(
                  field.value === 'Active' ? 'text-success' : ' text-red-600',
                  'pb-1 font-bold'
                )}
                htmlFor='status-toggle'
              >
                {field.value}
              </label>
            </div>
          )}
        />

        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <Input
              label={t('templateName')}
              maxLength={32}
              required
              value={field.value ?? ''}
              error={errors.name?.message}
              onChange={field.onChange}
            />
          )}
        />

        {tasks.filter((task) => !task._destroy).length === 0 &&
          templates &&
          templates.length > 0 && (
            <UseTemplate
              taskState={[tasks, setTasks]}
              jobTemplates={templates ?? []}
              onUseTemplateSelect={(template) => {
                if (template.tags) {
                  setValue(
                    'tags',
                    template.tags.map((tag) => tag.id)
                  );
                }
                if (template.notes) {
                  setValue('notes', template.notes);
                }
                if (template.includeAttributes) {
                  setValue('includeAttributes', template.includeAttributes);
                }
                if (template.jobAttributeIds) {
                  setValue('jobAttributes', template.jobAttributeIds);
                }
                if (template.attachments) {
                  setValue(
                    'attachments',
                    template.attachments.map(toFormValue)
                  );
                }
              }}
            />
          )}

        <ManageTasks
          state={[tasks, setTasks]}
          onShowTask={setEditTask}
          onTaskTypeUpdate={updateTaskType}
        />

        <AttributeSelect
          entityType='Site'
          label='Place attributes to display'
          value={includeAttributes}
          onChange={(selected) => setValue('includeAttributes', selected)}
        />

        <AttributeSelect
          entityType='Job'
          label='Custom Fields'
          value={jobAttributes}
          onChange={(selected) => setValue('jobAttributes', selected)}
        />

        <Controller
          name='tags'
          control={control}
          render={({ field: { value, onChange } }) => (
            <TagCombobox
              containerStyles={
                isMobile
                  ? '!max-h-[calc(100vh-170px)]'
                  : '!max-h-[calc(100vh-305px)]'
              }
              entityType={TagEntityType.Job}
              options={allTags}
              value={allTags.filter((t) => value?.includes(t.id))}
              onChange={(tags) => {
                onChange(tags.length ? tags.map(({ id }) => id) : null);
              }}
            />
          )}
        />

        <Controller
          name='notes'
          control={control}
          render={({ field }) => (
            <TextArea
              label='Notes'
              value={field.value ?? ''}
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          name='attachments'
          control={control}
          render={({ field: { value, onChange } }) => (
            <ControlledUpload
              id={`JobTemplateForm:${templateId}`}
              value={value ?? []}
              onChange={onChange}
            />
          )}
        />

        {guestyEnabled && (
          <label className='mb-5'>
            <input {...register('guestyCleaningStatus')} type='checkbox' />{' '}
            Update Guesty cleaning status
          </label>
        )}

        <br />
        <br />
      </SideLayout.Body>
      <SideLayout.Foot className='rounded p-4'>
        <Button title='saveTemplate' onClick={submit}>
          {loading
            ? 'Submitting...'
            : !templateId || clone
            ? t('saveTemplate')
            : t('updateTemplate')}
        </Button>
      </SideLayout.Foot>
    </SideLayout>
  );
};

export function toInput(
  tasks: TaskInput[],
  values: Partial<FormValues>
): JobTemplateInput {
  invariant(values.name, 'Name is required');
  return {
    tasks: tasks.map((task) => ({
      id: task.id,
      type: task.type,
      name: task.name,
      description: task.description,
      attributes: task.attributes, // include attributes (site attributes to display)
      jobAttributes: task.jobAttributes, // job attributes to collect
      attribute: task.attribute, // attribute task
      purchase: task.purchase,
      stocktake: task.stocktake,
      asset: task.asset,
    })),
    name: values.name,
    tags: values.tags ?? null,
    notes: values.notes ?? null,
    status: values.status ?? Status.Active,
    includeAttributes: values.includeAttributes ?? null,
    jobAttributes: values.jobAttributes ?? null,
    attachments: (values.attachments ?? []).map(toAttachmentInput),
    guestyCleaningStatus: values.guestyCleaningStatus ? 'clean' : null,
  };
}

export function serialize(input: JobTemplateInput) {
  const formData = new FormData();
  formData.append('input', JSON.stringify(input));
  return formData;
}

export function unserialize(formData: globalThis.FormData): JobTemplateInput {
  const input = parseJson(formData.get('input')?.toString() ?? '');
  if (!input) {
    throw new Error('Invalid input');
  }
  return input;
}
