import { CheckIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Outlet,
  useNavigate,
  useOutlet,
  useParams,
} from 'react-router-dom';
import { useQuery } from 'urql';
import { useTenant } from '~/App';
import { Files } from '~/components/FileLink';
import { TagsBuilder } from '~/components/TagCombobox/TagsBuilder';
import { MemoGallery } from '~/components/job/JobView/Feed/Gallery';
import { TaskIcon } from '~/components/job/JobView/TaskList/TaskListItem';
import { ReadValue } from '~/components/ui/ReadValue';
import { StatusBadge } from '~/components/ui/StatusBadge';
import { FragmentType, getFragmentData } from '~/gql';
import { AttributeSelectDocument } from '~/gql/graphql';
import { JobTemplateFields } from '~/graphql/fragment/JobTemplateFields';
import { KebabNav } from '~/layouts/nav/KebabNav';
import { SideLayout } from '~/layouts/side/SideLayout';
import { useJobTemplateListContext } from './job-templates._index';

type Props = {
  template: FragmentType<typeof JobTemplateFields>;
  onClose: () => void;
  isPopup?: boolean;
};

export default function ViewTemplateRoute() {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { templateId } = useParams();
  const { templates } = useJobTemplateListContext();

  const template = templates?.find((template) => template.id === templateId);

  const handleClose = () => {
    navigate('..');
  };

  if (!template) {
    return null;
  }

  return (
    <div className='relative flex h-full'>
      <TemplateViewMain template={template} onClose={handleClose} />
      {outlet && (
        <div className='absolute z-50 h-full w-full xl:relative'>
          <Outlet context={{ template }} />
        </div>
      )}
    </div>
  );
}

const TemplateViewMain = ({ onClose, isPopup, ...props }: Props) => {
  const template = getFragmentData(JobTemplateFields, props.template);
  const { t } = useTranslation();
  const { id } = useTenant();

  const [result] = useQuery({
    query: AttributeSelectDocument,
    variables: { entityType: 'Site' },
    requestPolicy: 'cache-first',
  });
  const attributes = result.data?.attributes;

  const [result2] = useQuery({
    query: AttributeSelectDocument,
    variables: { entityType: 'Job' },
    requestPolicy: 'cache-first',
  });
  const allJobAttributes = result2.data?.attributes;

  const pages = [
    ...(template.isStarter && id !== '31'
      ? []
      : [
          {
            title: 'editTemplate',
            url: `/job-templates/${template.id}/edit`,
          },
        ]),
    {
      title: 'cloneTemplate',
      url: `/job-templates/${template.id}/clone`,
    },
  ];

  return (
    <SideLayout isPopup={isPopup}>
      {!isPopup && (
        <div className='block'>
          <SideLayout.Head
            onClose={onClose}
            rightSlot={<KebabNav pages={pages} />}
          >
            {template.name}
          </SideLayout.Head>
        </div>
      )}
      <SideLayout.Body className='p-0'>
        <div className='px-4 text-left'>
          <StatusBadge value={template?.status} rounded />
          {template.tasks.length > 0 && (
            <div className='mb-5'>
              <div className='label text-medium mb-1 pt-1 text-grey-40'>
                Tasks
              </div>
              {template.tasks.map((task, i) => (
                <Link
                  key={task.id}
                  to={`tasks/${task.id}`}
                  className='mt-3 flex w-full items-center justify-between rounded border border-grey-10 px-3 py-2.5 text-grey-50 shadow transition-all hover:shadow-md'
                >
                  {task.name}
                  <div className='ml-auto flex h-5 items-center justify-center text-brand'>
                    <TaskIcon
                      taskType={task.type}
                      taskDescription={task.description}
                    />
                  </div>
                </Link>
              ))}
            </div>
          )}

          {template.includeAttributes &&
            template.includeAttributes.length > 0 && (
              <>
                {' '}
                <div className='label text-medium mb-1 pt-1 text-grey-40'>
                  Attributes
                </div>
                {template.includeAttributes.map((attribute) => {
                  const found = attributes?.find(({ id }) => id === attribute);
                  if (!found) {
                    return null;
                  }
                  return (
                    <p className='indent-5 text-sm' key={found.id}>
                      {(found.category ? `${found.category} \\ ` : '') +
                        found.name}
                    </p>
                  );
                })}
                <br />
              </>
            )}

          {template.jobAttributeIds && template.jobAttributeIds.length > 0 && (
            <>
              <div className='label text-medium mb-1 pt-1 text-grey-40'>
                Custom Fields
              </div>
              {template.jobAttributeIds.map((attribute) => {
                const found = allJobAttributes?.find(
                  ({ id }) => id === attribute
                );
                if (!found) {
                  return null;
                }
                return (
                  <p className='indent-5 text-sm' key={found.id}>
                    {(found.category ? `${found.category} \\ ` : '') +
                      found.name}
                  </p>
                );
              })}
              <br />
            </>
          )}

          <ReadValue label='tag_plural'>
            {template.tags && template.tags.length > 0 && (
              <p className='flex flex-[1_0_0] flex-wrap items-center justify-start gap-1.5'>
                <TagsBuilder tags={template.tags} />
              </p>
            )}
          </ReadValue>
          <ReadValue label='notes' value={template.notes} />
          {template.attachments && template.attachments.length > 0 && (
            <>
              <div className='label mb-1.5'>
                {t('attachment', { count: template.attachments.length })}
              </div>
              <Files attachments={template.attachments} />
              <MemoGallery attachments={template.attachments} />
            </>
          )}
          <ReadValue label='Guesty cleaning status'>
            {template.guestyCleaningStatus && (
              <div className='flex items-center gap-2'>
                <CheckIcon className='h-5 w-5 text-brand' />
                Update Guesty cleaning status
              </div>
            )}
          </ReadValue>
        </div>
      </SideLayout.Body>
    </SideLayout>
  );
};
