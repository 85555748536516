import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Combolist } from '../Combolist';
import { PopoverStateChangedEffect } from '../PopoverStateChangedEffect';
import { Pill } from './nucleus/Pill';

export type FacetedFilterOption = {
  $group?: string;
  label?: string;
  key: string;
  value: string[];
};

type Props = {
  label: string;
  options: FacetedFilterOption[];
  value: { key: string; value: string[] }[];
  onChange: (newValue: { key: string; value: string[] }[]) => void;
};

function truncatedLabel(label: string) {
  if (label.length > 30) {
    return label.slice(0, 27) + '...';
  }
  return label;
}

function getSubLabel(label: string, values: string[]) {
  return truncatedLabel(
    values.length > 1
      ? `${label} • ${values.length}`
      : `${label} is ${values[0]}`
  );
}

export function FacetedFilterSelect({
  label,
  options,
  value,
  onChange,
}: Props) {
  const { t } = useTranslation();
  const [selected, setSelected] =
    useState<{ key: string; value: string[] }[]>(value);

  const [cancelling, setCancelling] = useState(false);

  const handleClose = () => {
    // When the main menu is close, call onChange with the selected state
    // to 'save' pending changes in the submenu
    if (!cancelling) {
      onChange(selected);
    } else {
      setSelected(value);
      setCancelling(false);
    }
  };

  useEffect(() => {
    // When value changes from outside, update selected so combolist selections stay in sync
    setSelected(value);
  }, [value]);

  return (
    <Popover>
      {({ open }) => {
        // 1. If the Popover is open, use selected state for the labels.
        const data = (open ? selected : value)
          // 2. Map the structure holding labels into something more closely resembling the rich attributes
          .map(({ key, value }) => ({
            id: key,
            label: options.find((o) => o.key === key)?.label ?? key,
            value,
          }));

        return (
          <>
            <div className='flex w-full flex-row overflow-x-auto'>
              <PopoverButton className='my-2 rounded-full'>
                <Pill type='filter' active={data.length > 0} hasParent>
                  {truncatedLabel(label)}
                  {data.length > 0 && (
                    <span
                      className='max-h-full cursor-pointer pt-0.5'
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange([]);
                        setSelected([]);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </span>
                  )}
                </Pill>
              </PopoverButton>
              {data
                .filter((v) => v.value.length > 0)
                .map((v) => (
                  <Pill key={v.id} type='filter' active={true}>
                    {getSubLabel(v.label, v.value)}
                    <span
                      className='max-h-full cursor-pointer pt-0.5'
                      onClick={() => {
                        onChange(selected.filter((s) => s.key !== v.id));
                        setSelected(selected.filter((s) => s.key !== v.id));
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </span>
                  </Pill>
                ))}
            </div>
            <PopoverPanel
              anchor='bottom start'
              className='prevent-drag-scroll border-gray-10 absolute z-[100] w-72 rounded-sm border bg-white p-2 pb-1 shadow-md'
            >
              {/* <div className='prevent-drag-scroll border-gray-10 absolute z-50 w-72 rounded-sm border bg-white p-2 pb-1 shadow-md'> */}
              <Combolist
                items={options
                  .map((o) =>
                    o.value.map((v) => ({
                      $group: o.$group || t('ungrouped'),
                      $subgroup: o.label || o.key,
                      id: [o.key, v].join(':'),
                      name: v,
                    }))
                  )
                  .flat()
                  .sort(({ $group: groupA }) =>
                    groupA === t('ungrouped') ? -1 : 1
                  )}
                searchKeys={['$group', '$subgroup', 'name']}
                multiple
                value={selected
                  .map(({ key, value }) =>
                    value.map((v) => ({
                      $group: options.find((o) => o.key === key)?.$group,
                      $subgroup:
                        options.find((o) => o.key === key)?.label || key,
                      id: [key, v].join(':'),
                      name: v,
                    }))
                  )
                  .flat()}
                onChange={(selected) => {
                  setSelected(
                    selected.reduce((acc, { id }) => {
                      const [key, ...rest] = id.split(':');
                      const val = rest.join(':');
                      const index = acc.findIndex((v) => v.key === key);
                      if (index === -1) {
                        return [...acc, { key: key, value: [val] }];
                      } else {
                        return [
                          ...acc.slice(0, index),
                          {
                            key: key,
                            value: [...acc[index].value, val],
                          },
                          ...acc.slice(index + 1),
                        ];
                      }
                    }, [] as { key: string; value: string[] }[])
                  );
                }}
                renderInner={({ id, name }) => (
                  <div
                    className={classNames(
                      'truncate',
                      data
                        .filter(({ value }) => value.length > 0)
                        .map(({ id }) => id)
                        .includes(id) && 'font-semibold'
                    )}
                  >
                    {name}
                  </div>
                )}
              />
              <div className='flex border-t p-1.5 text-xs'>
                <PopoverButton
                  className='flex-grow p-2 text-left uppercase'
                  onClick={() => setCancelling(true)}
                >
                  {t('cancel')}
                </PopoverButton>
                <PopoverButton className='flex-grow p-2 text-right uppercase'>
                  {t('ok')}
                </PopoverButton>
              </div>
              {/* </div> */}
            </PopoverPanel>
            <PopoverStateChangedEffect open={open} onClose={handleClose} />
          </>
        );
      }}
    </Popover>
  );
}
