import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

export type SettingLinkType = {
  name: string;
  description: string;
  linkTo: string;
};

export type TabProps = {
  title: string;
  description: string;
  onClick?: any;
};

export type GroupProps = {
  title: string;
};

export function Group({ title }: GroupProps) {
  return (
    <h2 className='self-start pb-3 pt-6 font-semibold text-primary'>{title}</h2>
  );
}

export const Tab = ({ title, description, onClick }: TabProps) => (
  <div
    onClick={onClick}
    className='-mt-[1px] flex w-full cursor-pointer items-center justify-between border border-outline-variant bg-white py-2 pl-4 pr-6'
  >
    <div className='flex flex-col items-start justify-center'>
      <h3 className='self-start font-semibold text-primary'>{title}</h3>
      <p className='pb-0.5 text-left text-xs text-tertiary opacity-80'>
        {description}
      </p>
    </div>
    <div className='w-8'>
      <FontAwesomeIcon
        //@ts-ignore
        icon={faChevronRight}
        className='ml-auto w-5 text-secondary hover:text-primary'
      />
    </div>
  </div>
);

export function SettingLink({ name, description, linkTo }: SettingLinkType) {
  const navigate = useNavigate();

  return (
    <Tab
      title={name}
      description={description}
      onClick={() => navigate(linkTo)}
    />
  );
}
