import {
  faCompressAlt,
  faExpandAlt,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';
import {
  faPlaneArrival,
  faPlaneDeparture,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useVirtualizer } from '@tanstack/react-virtual';
import classNames from 'classnames';
import {
  add,
  addDays,
  areIntervalsOverlapping,
  differenceInCalendarDays,
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  parseISO,
  startOfDay,
  startOfYear,
  subDays,
} from 'date-fns';
import { enAU } from 'date-fns/locale';
import { clamp, floor, max, round } from 'lodash';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMutation } from 'urql';
import { Attribute, JobStatus } from '~/generated/graphql';
import { graphql } from '~/gql';
import { parseJson } from '~/helpers';
import { formatTime } from '~/helpers/formatDate';
import { useLocalStorage } from '~/hooks';
import useWindowDimensions from '~/hooks/useWindowDimensions';
import { ScheduleViewData, ZoomLevel } from '~/routes/calendar._index';
import { Button } from '../form/SubmitButton';
import { ShortAddress } from '../ui/Address';
import { Avatar } from '../ui/Avatar';
import { BuildingPlaceholder } from '../ui/BuildingPlaceholder';
import { Dialog } from '../ui/Dialog';
import { Image } from '../ui/Image';
import { Switch } from '../ui/nucleus/Switch';
import { CalendarEventOrganiser } from './CalendarEventOrganiser';

export type CalendarEvent = {
  y: number;
  x: number;
  event: ScheduleViewData[number]['calendar'][number];
  eventIndex: number;
  siteId: string;
};

type Props = {
  data: ScheduleViewData;
  /** Job clicked */
  onClick: (jobId: string) => void;
  // bounds: { lower: number; upper: number }
  jobDetail: boolean;
  bookingsHidden: boolean;
  /** @deprecated reload is not required as ScheduleEvent reschedules are handled in the graphcache */
  reload: () => void;
  scrollDate: Date | null;
  selectedJobId?: string;
  zoomLevel: ZoomLevel;
  navigateInDirection: 'prev' | 'next' | null;
  afterNavigate: () => void;
  children?: ReactNode;
};

const RescheduleJobDocument = graphql(`
  mutation RescheduleJob($input: RescheduleJobInput!) {
    rescheduleJob(input: $input) {
      id
      scheduleStart
      scheduleEnd
      scheduledStartDate
      scheduledEndDate
    }
  }
`);

const ZOOM_LEVEL_TO_TOP_LEVEL_EVENT_COUNT_MAX: Record<
  ZoomLevel,
  {
    withoutTimes: number;
    withTimes: number;
  }
> = {
  month: {
    withoutTimes: 2,
    withTimes: 1,
  },
  default: {
    withoutTimes: 2,
    withTimes: 2,
  },
  week: {
    withoutTimes: 4,
    withTimes: 4,
  },
} as const;

export function ScheduleView({
  data,
  onClick,
  jobDetail,
  bookingsHidden,
  reload,
  scrollDate,
  selectedJobId,
  zoomLevel,
  navigateInDirection,
  afterNavigate,
  children,
}: Props) {
  useEffect(() => {
    scrollToDate(new Date(), 1, false);
  }, []);

  const { width: windowWidth } = useWindowDimensions();

  const { t } = useTranslation(['translation', 'job']);
  const parentRef = React.useRef<HTMLDivElement>(null);

  const topLevelJobCountMax =
    ZOOM_LEVEL_TO_TOP_LEVEL_EVENT_COUNT_MAX[zoomLevel][
      jobDetail ? 'withTimes' : 'withoutTimes'
    ];

  // Size constants for placement/sizing calculations
  const headerHeight = 56;
  const sideBarWidth = 333;
  const jobPillHeight = jobDetail ? 48 : 27;
  const expandPillSize = 23;
  const zoomLevelsToOverlappingPillOffset: Record<ZoomLevel, number> = {
    month: 20,
    default: 25,
    // Use a dynamic value for week because it can be any size
    week: clamp(((windowWidth - sideBarWidth) / 7) * 0.3, 20, 40),
  } as const;
  const overlappingPillOffset = zoomLevelsToOverlappingPillOffset[zoomLevel];
  const jobPillUnderExpandPillMarginRight = 8;
  // * If you are changing the height of the booking pill you must also adjust
  // * its translation factors in the ScheduleEventPill function
  // TODO: Remove deprecated constant
  /**
   * - `20`px if bookings are visible
   * - `0`px if bookings are hidden
   * @deprecated This needs to be renamed/reworked because we are doing booking
   * hover animations with pure css now. This is currently just used to
   * indicate to the pill if it is visible or not.
   */
  const expandedBookingPillHeight = bookingsHidden ? 0 : 20;
  // * We have to use a scale factor to adjust the height of the booking pill
  // * because we are relying on scale transformations to animate it
  /**
   * @deprecated This needs to be renamed/reworked because we are doing booking
   * hover animations with pure css now. This is currently just used to
   * calculate the expected height of the booking pill for layout and background
   * stuff.
   */
  const bookingPillScaleFactor = 0.45;
  /**
   * - `9`px if bookings are visible
   * - `0`px if bookings are hidden
   * @deprecated This needs to be renamed/reworked because it is no longer used
   * to determine height of the booking pill so is misleading.
   */
  const bookingPillHeight = expandedBookingPillHeight * bookingPillScaleFactor;
  const jobPillMargin = 2;
  const minRowHeight =
    bookingPillHeight + jobPillMargin + jobPillHeight + jobPillMargin;

  /**
   * Are you changing or adding to the column widths? Make sure you double check in
   * {@link ScheduleEventPill} to make sure the pill is still being padded correctly.
   */
  const zoomLevelsToColumnWidth: Record<ZoomLevel, number> = {
    month: 59,
    default: 100,
    week: (windowWidth - sideBarWidth) / 7,
  } as const;
  const columnWidth = zoomLevelsToColumnWidth[zoomLevel];

  const now = new Date();
  const start = startOfYear(add(now, { years: -1 }));
  const end = startOfYear(add(now, { years: 2 }));
  const count = differenceInCalendarDays(end, start);
  const indexOfToday = differenceInCalendarDays(now, start);

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => columnWidth,
    paddingStart: sideBarWidth,
    overscan: 5,
  });

  const dateFromColumnIndex = (index: number) => addDays(start, index);
  useEffect(() => {
    const { startIndex: firstVisibleIndex } = visibleRange || {};

    const scrollTargetDate =
      firstVisibleIndex && dateFromColumnIndex(firstVisibleIndex);

    columnVirtualizer.setOptions({
      ...columnVirtualizer.options,
      estimateSize: () => columnWidth,
    });
    // const range = columnVirtualizer.range;
    columnVirtualizer.measure();

    scrollTargetDate && scrollToDate(scrollTargetDate, 0, false);
    // scrollToDate(scrollDate || now);
    // columnVirtualizer.scrollToIndex(
    //   range ? (range.endIndex - range.startIndex) / 2 : 0
    // );
  }, [columnWidth]);

  const [visibleRange, setVisibleRange] = useState<{
    startIndex: number;
    endIndex: number;
  } | null>(null);
  useEffect(() => {
    const getFirstVisibleIndex = () =>
      columnVirtualizer.range
        ? columnVirtualizer.range.startIndex +
          Math.floor(sideBarWidth / columnWidth) +
          1
        : 0;

    const getLastVisibleIndex = () =>
      columnVirtualizer.range ? columnVirtualizer.range.endIndex : 0;

    setVisibleRange({
      startIndex: getFirstVisibleIndex(),
      endIndex: getLastVisibleIndex(),
    });
  }, [columnVirtualizer.range]);

  const navigateToAdjacentPeriod = (direction: 'prev' | 'next') => {
    // Smoothly scroll to the adjacent period
    if (!visibleRange) return;

    switch (direction) {
      case 'prev':
        scrollToDate(
          dateFromColumnIndex(
            visibleRange.startIndex -
              (visibleRange.endIndex - visibleRange.startIndex)
          ),
          0,
          true
        );
        break;
      case 'next':
        scrollToDate(dateFromColumnIndex(visibleRange.endIndex), 0, true);
        break;
    }
  };

  useEffect(() => {
    if (navigateInDirection) {
      navigateToAdjacentPeriod(navigateInDirection);
      afterNavigate();
    }
  }, [navigateInDirection]);

  const rowVirtualizer = useVirtualizer({
    paddingEnd: headerHeight,
    count: data.length, // Each entry in the data array is a site
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => minRowHeight,
    overscan: 5,
    // measureElement: (element, virtualizer) => {
    //   return siteHeight(element.getAttribute('data-siteid') ?? '0');
    // },
  });

  const [, rescheduleJob] = useMutation(RescheduleJobDocument);

  const findEventsForDate = React.useCallback(
    (date: Date, max: number = 4) => {
      const today = startOfDay(date);

      return data.map((site) =>
        site.calendar
          .reduce((prev: (null | typeof item)[], item) => {
            const start = startOfDay(parseISO(item.start));
            const end =
              item.end && add(startOfDay(parseISO(item.end)), { days: 0 });

            if (
              end &&
              isAfter(today, start) &&
              (isEqual(today, end) || isBefore(today, end))
            ) {
              return [...prev, null];
            }

            return isEqual(start, today)
              ? item.status === 'Booking' // Bookings are always first
                ? [item]
                : [...prev, item]
              : prev;
          }, [])
          .slice(0, max)
      );
    },
    [data]
  );

  const scrollToDate = (date: Date, paddingLeft: number, smooth: boolean) => {
    const [offset] = columnVirtualizer.getOffsetForIndex(
      differenceInCalendarDays(startOfDay(subDays(date, paddingLeft)), start),
      'start'
    );
    columnVirtualizer.scrollToOffset(Math.max(0, offset - sideBarWidth), {
      behavior: smooth ? 'smooth' : 'auto',
    });
  };

  useEffect(() => {
    if (!scrollDate) return;
    scrollToDate(scrollDate, 1, true);
  }, [scrollDate]);

  const eventStatusSortOrder: Record<string, number> = {
    Booking: 1,
    Created: 2,
    Offered: 3,
    Declined: 3,
    Accepted: 4,
    Active: 5,
    InProgress: 6,
    Complete: 7,
    Cancelled: 8,
  };

  const { startIndex, endIndex } = columnVirtualizer.range ?? {
    startIndex: 0,
    endIndex: 0,
  };
  // TODO rename or use stateful visibleRange
  const roughVisibleRange = {
    start: add(start, { days: startIndex - 7 }),
    end: add(start, { days: endIndex + 10 }),
  };
  const events = data.flatMap((site, siteIndex): CalendarEvent[] => {
    return (
      site.calendar
        // 1. filter down to events that are visible on screen
        .filter((event, eventIndex) => {
          if (eventIndex > 1) {
            // return false;
          }
          // return true;
          const start = new Date(event.start);
          const end = new Date(event.end || event.start);
          return areIntervalsOverlapping({ start, end }, roughVisibleRange);
        })
        // 2. sort by duration, status, then summary
        .sort((eventA, eventB) => {
          const eventADuration =
            CalendarEventOrganiser.getCalendarEventLength(eventA);
          const eventBDuration =
            CalendarEventOrganiser.getCalendarEventLength(eventB);
          if (eventADuration !== eventBDuration) {
            return eventBDuration - eventADuration;
          } else {
            if (!(eventA.status in eventStatusSortOrder)) {
              console.warn(
                'Sorting order of status',
                eventA.status,
                'on event id',
                eventA.id,
                'is not defined'
              );
            }
            const statusSortOrderDifference =
              (eventStatusSortOrder[eventA.status] || 99) -
              (eventStatusSortOrder[eventB.status] || 99);
            if (statusSortOrderDifference !== 0) {
              return statusSortOrderDifference;
            } else {
              return eventA.summary.localeCompare(eventB.summary);
            }
          }
        })
        // 3. store the x and y position before flattening
        .map((event, eventIndex) => {
          return {
            y: siteIndex,
            x: differenceInCalendarDays(new Date(event.start), start),
            event,
            eventIndex,
            siteId: site.id,
          };
        })
    );
  });

  const [eventVpos, setEventVpos] = useState<Record<string, number>>({});
  // Store the most recent occupied cells statefully for use in calculating DnD previews
  const [recentOccupied, setRecentOccupied] = useState<
    Array<Array<Array<boolean | undefined>>>
  >([]);

  // Calculate event vposes
  useEffect(() => {
    const organiser = new CalendarEventOrganiser({ events, zoomLevel });

    setEventVpos(organiser.getEventVpos());
    setRecentOccupied(organiser.getOccupied());
  }, [columnVirtualizer.range, data]);

  const storage = useLocalStorage();

  const getLocalExpandedSiteIds = () => {
    const localString = storage.get('calendar.expandedSiteIds');
    return parseJson(localString) as string[];
  };

  const setLocalExpandedSiteIds = (ids: string[]) => {
    storage.set('calendar.expandedSiteIds', JSON.stringify(ids));
  };

  const [expandedSiteIds, setStateExpandedSiteIds] = useState<string[]>(
    getLocalExpandedSiteIds() || []
  );

  const setExpandedSiteIds = (ids: string[]) => {
    setStateExpandedSiteIds(ids);
    setLocalExpandedSiteIds(ids);
  };

  const expandSite = (siteId: string) => {
    setExpandedSiteIds(expandedSiteIds.concat(siteId));
  };

  const collapseSite = (siteId: string) => {
    setExpandedSiteIds(expandedSiteIds.filter((id) => id !== siteId));
  };

  // Clear the cached site indeces when the data changes, as indices will change
  const siteRowMap = useMemo(() => {
    return data.reduce((map: Record<string, number>, site, i) => {
      map[site.id] = i;
      return map;
    }, {});
  }, [data]);

  const getSiteIndexById = (siteId: string) => {
    return siteRowMap[siteId];
  };

  const getSiteIdByIndex = (siteIndex: number) => data[siteIndex]?.id;

  const isSiteExpanded = (siteId: string) => expandedSiteIds.includes(siteId);

  /* 
    Works similarly to the above `getNextAvailableVpos`, but works on the
    stateful `recentOccupied` instead. 
  */
  const getPreviewVpos = (
    siteIndex: number,
    startIndex: number,
    endIndex: number
  ) => {
    var candidateVpos = 0;

    const rangeOccupied = (
      siteIndex: number,
      startIndex: number,
      endIndex: number,
      vpos: number
    ) => {
      for (var i = startIndex; i <= endIndex; i++) {
        if (recentOccupied[siteIndex]?.[i]?.[vpos]) {
          return true;
        }
      }
      return false;
    };

    while (rangeOccupied(siteIndex, startIndex, endIndex, candidateVpos)) {
      candidateVpos++;
      if (candidateVpos === 1 && !isSiteExpanded(getSiteIdByIndex(siteIndex)))
        break;
    }

    return candidateVpos;
  };

  /**
   * Keys are `siteIndex`s converted to strings for easy rendering with virtualisation
   * This can still be indexed like `expandButtons[siteIndex]` due to implicit coercion
   * from number to string in keys.
   */
  const expandButtons: Record<
    string,
    {
      siteId: string;
      columnIndex: number;
      expandCount: number;
      eventId: string;
      isMultiDayEvent: boolean;
    }[]
  > = {};

  // Which site currently has a job being dragged if any
  const [draggedSiteId, setDraggedSiteId] = useState<string | null>(null);

  const siteHeight = (siteId: string) => {
    const siteEvents = events.filter(
      ({ y: siteIndex, event: { status } }) =>
        siteIndex === getSiteIndexById(siteId) && status !== 'Booking'
    );

    if (!isSiteExpanded(siteId)) {
      const multiDayJobColumns: number[] = [];

      const hasMultiDayJobExpandButton = siteEvents.some(
        ({ x: columnIndex, event }) => {
          const days = CalendarEventOrganiser.getCalendarEventLength(event);

          if (multiDayJobColumns.includes(columnIndex)) {
            return true;
          }

          if (days > 1) {
            // Add all columns for this multi-day job to the list
            multiDayJobColumns.push(
              ...[...Array(days).keys()].map((i) => i + columnIndex)
            );
          }

          return false;
        }
      );

      const height =
        minRowHeight +
        (draggedSiteId === siteId
          ? jobPillHeight + jobPillMargin
          : hasMultiDayJobExpandButton
          ? expandPillSize + jobPillMargin
          : 0);

      return height;
    } else {
      const jobCounts: number[] = [];

      siteEvents.forEach(({ x: columnIndex, event }) => {
        const days = CalendarEventOrganiser.getCalendarEventLength(event);

        for (let i = 0; i < days; i++) {
          if (jobCounts[columnIndex + i] === undefined) {
            jobCounts[columnIndex + i] = 1;
          } else {
            jobCounts[columnIndex + i]++;
          }
        }
      });
      return (
        bookingPillHeight +
        jobPillMargin +
        ((max(jobCounts) || 1) + (draggedSiteId === siteId ? 1 : 0)) *
          (jobPillHeight + jobPillMargin)
      );
    }
  };

  // Calculate the top of a site row from its index
  // by summing the heights of sites with an index lower than it.
  const siteTop = (siteId: string) => {
    var top = 0;
    for (let i = 0; i < getSiteIndexById(siteId); i++) {
      top += siteHeight(getSiteIdByIndex(i));
    }
    return top;
  };

  const lastSiteId = getSiteIdByIndex(data.length - 1);
  const totalHeight =
    headerHeight + siteTop(lastSiteId) + siteHeight(lastSiteId);

  const [hoveredJobId, setHoveredJobId] = useState<string>();

  const handleHoverJob = (jobId: string) => {
    // setHoveredJobId(jobId);
  };
  const handleUnhoverJob = () => {
    // setHoveredJobId(undefined);
  };

  const handleHoverBooking = (eventId: string) => {
    // setHoveredBookingId(eventId);
  };

  const handleUnhoverBooking = () => {
    // setHoveredBookingId(null);
  };

  /* bars-4 https://heroicons.com/  */
  const expandedViewIcon = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      className='h-6 w-6'
    >
      <path
        fillRule='evenodd'
        d='M3 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 5.25zm0 4.5A.75.75 0 013.75 9h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 9.75zm0 4.5a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zm0 4.5a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z'
        clipRule='evenodd'
      />
    </svg>
  );

  /* squares-2x2 https://heroicons.com/ */
  const condensedViewIcon = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='currentColor'
      className='h-6 w-6'
    >
      <path
        fillRule='evenodd'
        d='M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z'
        clipRule='evenodd'
      />
    </svg>
  );

  const handleEventClick = (eventStatus: string, eventId: string) => {
    if (eventStatus === 'Booking') {
      onClick(`bookings/${eventId}`);
    } else if (eventStatus === JobStatus.Created) {
      onClick(`${eventId}/edit`);
    } else {
      onClick(eventId);
    }
  };

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [rescheduleDetails, setRescheduleDetails] = useState<{
    jobStatus: JobStatus;
    jobId: string;
    jobName: string;
    assigneeName: string;
    offsetDays: number;
  } | null>(null);

  return (
    <DndProvider backend={HTML5Backend}>
      {/* Reschedule offered/accepted job dialog */}
      <RescheduleJobDialog
        isOpen={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
        onSubmit={(_, { notify, comment }) => {
          if (!rescheduleDetails) return;
          rescheduleJob({
            input: {
              id: rescheduleDetails.jobId,
              offsetDays: rescheduleDetails.offsetDays,
              comment,
              notify,
            },
          });
          setDialogIsOpen(false);
          reload();
        }}
        jobName={rescheduleDetails?.jobName || ''}
        assigneeName={rescheduleDetails?.assigneeName || ''}
        action={
          rescheduleDetails?.jobStatus === JobStatus.Offered
            ? 'offer'
            : 'confirm'
        }
      />
      <div className='relative'>
        <div
          ref={parentRef}
          style={{
            overflow: 'auto',
            width: '100%',
            height: 'calc(100vh - 5rem - 4rem - 4rem)',
            // transform: 'rotate(180deg)',
          }}
        >
          <div
            className='bg-[#FBFBFB]'
            style={{
              width: columnVirtualizer.getTotalSize(),
              height: rowVirtualizer.getTotalSize(),
              // height: totalHeight,
              position: 'relative',
            }}
          >
            {/* Draw grid lines and cell bg colours */}
            {columnVirtualizer.getVirtualItems().map((column) => {
              // const date = add(start, { days: column.index });
              // const events = findEventsForDate(date, expanded ? 4 : 2);
              return (
                <div
                  key={`grid-index-${column.key}`}
                  className={classNames(
                    column.index === indexOfToday && 'bg-[#EB00FF1A]',
                    'pointer-events-none transition-height duration-300'
                    // DEBUG visibleRange visualisation
                    // {
                    //   'bg-red-500': column.index === visibleRange?.endIndex,
                    //   'bg-blue': column.index === visibleRange?.startIndex,
                    // }
                  )}
                  style={{
                    position: 'absolute',
                    top: headerHeight,
                    left: 0,
                    height: totalHeight - headerHeight,
                    width: column.size,
                    transform: `translateX(${column.start}px)`,
                  }}
                >
                  {rowVirtualizer.getVirtualItems().map((row) => {
                    return (
                      <div
                        key={row.key}
                        className={classNames(
                          'border-b border-r border-x-[#E0E0E0] border-y-gray66'
                        )}
                        style={{
                          position: 'absolute',
                          top: row.start,
                          left: 0,
                          height: siteHeight(data[row.index].id),
                          width: column.size,
                        }}
                      >
                        {!bookingsHidden && (
                          <div
                            className={classNames(
                              column.index === indexOfToday
                                ? 'bg-[#E7D2E9]'
                                : 'bg-[#EEEEEE]'
                            )}
                            style={{
                              height: bookingPillHeight,
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}

            {/* Add drop zones */}
            {columnVirtualizer.getVirtualItems().map((column) => {
              // const date = add(start, { days: column.index });
              // const events = findEventsForDate(date, expanded ? 4 : 2);

              return (
                <div
                  key={`drop-zones-column-index-${column.key}`}
                  style={{
                    position: 'absolute',
                    top: headerHeight,
                    left: 0,
                    height: totalHeight - headerHeight,
                    width: column.size,
                    transform: `translateX(${column.start}px)`,
                  }}
                >
                  {data.map((site) => (
                    <JobDropZone
                      key={site.id}
                      siteId={site.id}
                      date={add(start, { days: column.index })}
                      previewTop={(days: number) => {
                        const top =
                          bookingPillHeight +
                          (jobPillHeight + jobPillMargin) *
                            getPreviewVpos(
                              getSiteIndexById(site.id),
                              column.index,
                              column.index + days - 1
                            ) +
                          jobPillMargin;

                        return top;
                      }}
                      onDrop={(jobId, dayOffset) => {
                        rescheduleJob({
                          input: {
                            id: jobId,
                            offsetDays: dayOffset,
                          },
                        }).then(reload);
                      }}
                      onNonDraftDrop={(
                        jobId,
                        dayOffset,
                        jobStatus,
                        jobName,
                        assigneeName
                      ) => {
                        setRescheduleDetails({
                          jobId,
                          jobStatus,
                          jobName,
                          assigneeName,
                          offsetDays: dayOffset,
                        });
                        setDialogIsOpen(true);
                      }}
                    >
                      <div
                        style={{
                          height: siteHeight(site.id),
                        }}
                      />
                    </JobDropZone>
                  ))}
                </div>
              );
            })}

            {/* Render events */}
            {rowVirtualizer.getVirtualItems().flatMap((row) => {
              const siteEvents = events.filter(
                ({ y: siteIndex }) => siteIndex === row.index
              );

              /** `encounteredTopLevelMultiDayEvent[columnIndex] === true` if we should not render any more jobs in this column */
              let encounteredTopLevelMultiDayJob: boolean[] = [];

              /** `vposesOverlappedByExpandButtons[columnIndex].has(vpos) === true` if this vpos is overlapped by an expand button */
              let vposesOverlappedByExpandButtons: Record<
                number,
                Set<number>
              > = [];

              return siteEvents.map(
                (
                  { x: columnIndex, y: siteIndex, event, eventIndex, siteId },
                  i
                ) => {
                  // Ignore bookings if they are hidden
                  if (event.status === 'Booking' && bookingsHidden) return null;

                  if (
                    event.status !== 'Booking' &&
                    encounteredTopLevelMultiDayJob[columnIndex]
                  )
                    return null;

                  const vpos =
                    event.status === 'Booking'
                      ? 0
                      : // Force ourselves to handle the case where vpos is undefined
                        (eventVpos[event.id] as number | undefined);

                  // ? vposes have not been calculated yet?
                  if (vpos === undefined) {
                    return null;
                  }

                  const thisEventStart = startOfDay(new Date(event.start));
                  const days =
                    CalendarEventOrganiser.getCalendarEventLength(event);
                  const isMultiDayEvent = days > 1;

                  const isExpanded = isSiteExpanded(siteId);

                  if (
                    eventVpos[event.id] >= topLevelJobCountMax &&
                    !isExpanded
                  ) {
                    return null; // cell is full
                  }

                  if (
                    vpos === 0 &&
                    isMultiDayEvent &&
                    !isExpanded &&
                    event.status !== 'Booking'
                  ) {
                    for (
                      let i = columnIndex;
                      i <= columnIndex + days - 1;
                      i++
                    ) {
                      encounteredTopLevelMultiDayJob[i] = true;
                    }
                  }

                  // Add expand button if there are multiple jobs in the same row/col
                  if (event.status !== 'Booking' && vpos === 0) {
                    for (
                      let i = columnIndex;
                      i <= columnIndex + days - 1;
                      i++
                    ) {
                      const expandCount =
                        events.filter(({ y, event: checkEvent }) => {
                          const checkEventStart = startOfDay(
                            new Date(checkEvent.start)
                          );
                          const checkEventEnd = startOfDay(
                            addDays(
                              new Date(checkEvent.end || checkEvent.start),
                              1
                            )
                          );
                          const targetDate = addDays(
                            thisEventStart,
                            i - columnIndex
                          );
                          return (
                            areIntervalsOverlapping(
                              { start: checkEventStart, end: checkEventEnd },
                              { start: targetDate, end: addDays(targetDate, 1) }
                            ) &&
                            y === siteIndex &&
                            checkEvent.status !== 'Booking'
                          );
                        }).length - (isMultiDayEvent ? 1 : topLevelJobCountMax);
                      if (expandCount >= 1) {
                        if (!expandButtons[siteIndex])
                          expandButtons[siteIndex] = [];

                        expandButtons[siteIndex].push({
                          siteId,
                          columnIndex: i,
                          expandCount,
                          eventId: event.id,
                          isMultiDayEvent: isMultiDayEvent,
                        });
                        // Decide which vposes are overlapped by expand buttons
                        // i.e which vposes should have a gap on the right side

                        if (!vposesOverlappedByExpandButtons[i]) {
                          vposesOverlappedByExpandButtons[i] = new Set();
                        }
                        if (isMultiDayEvent) {
                          // Multi day events should not have a gap on the right side
                          // since expand buttons appear below them.
                          // But, the row of jobs below a multi-day event does.
                          // vposesOverlappedByExpandButtons[i].add(vpos + 1);
                        } else {
                          // Single day events should have a gap on the right side
                          vposesOverlappedByExpandButtons[i].add(vpos);
                          if (!isExpanded) {
                            // When not expanded, we also need to add a gap for the
                            // vposes of the overlapping jobs at the top level
                            for (let v = 1; v <= topLevelJobCountMax; v++) {
                              vposesOverlappedByExpandButtons[i].add(v);
                            }
                          }
                        }
                      }
                    }
                  }

                  const hasExpandButtonAtEnd =
                    vposesOverlappedByExpandButtons[
                      columnIndex + days - 1
                    ]?.has(vpos);

                  const widthAdjusment =
                    // right margin for expand button
                    (hasExpandButtonAtEnd
                      ? jobPillUnderExpandPillMarginRight
                      : 0) +
                    // width adjustment for overlapping jobs
                    (isExpanded ? 0 : vpos * overlappingPillOffset);

                  const pillWidth =
                    event.status === 'Booking'
                      ? floor((days - 1) * columnWidth) - jobPillMargin
                      : days * columnWidth - jobPillMargin - widthAdjusment;

                  const previewPillWidth = days * columnWidth - jobPillMargin;

                  const eventPill = (
                    <ScheduleEventPill
                      // useless key
                      key={`${event.id}:pill`}
                      event={event}
                      height={
                        event.status === 'Booking'
                          ? expandedBookingPillHeight
                          : jobPillHeight
                      }
                      width={pillWidth}
                      zoomLevel={zoomLevel}
                      selected={event.id === selectedJobId}
                    />
                  );

                  const previewPill = (
                    <ScheduleEventPill
                      // useless key
                      key={event.id + ':preview'}
                      event={event}
                      width={previewPillWidth}
                      height={jobPillHeight}
                      zoomLevel={zoomLevel}
                    />
                  );

                  return (
                    <DraggableJobWrapper
                      // why duplicates? something seems off (i added to suppress warning)
                      key={`${event.id}:wrapper:${i}`}
                      eventDetails={{
                        id: event.id,
                        status: event.status,
                        siteId: siteId,
                        scheduledStart: new Date(event.start),
                        days: days,
                        jobName: event.summary,
                        assigneeName: event.assignee?.name || '',
                      }}
                      onDragBegin={() => {
                        setDraggedSiteId(siteId);
                      }}
                      onDragEnd={() => {
                        setDraggedSiteId(null);
                      }}
                      preview={previewPill}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          translate: `${
                            // x translation
                            columnVirtualizer.getOffsetForIndex(
                              columnIndex,
                              'start'
                            )[0] +
                            (event.status === 'Booking'
                              ? round(columnWidth * (1 / 2))
                              : 0) +
                            // Multiple job pills in top row need an offset
                            (vpos > 0 && !isExpanded
                              ? vpos * overlappingPillOffset
                              : 0)
                          }px ${
                            // y translation
                            siteTop(siteId) +
                            headerHeight +
                            (event.status === 'Booking'
                              ? 0
                              : bookingPillHeight + jobPillMargin) +
                            (isExpanded
                              ? vpos * (jobPillHeight + jobPillMargin)
                              : 0)
                          }px`,
                          // Ensures bookings are rendered above expand buttons
                          zIndex: event.status === 'Booking' ? 1 : 0,
                        }}
                        key={event.status + ':' + event.id}
                        className='hover:cursor-pointer'
                        onClick={() => handleEventClick(event.status, event.id)}
                        onMouseEnter={
                          event.status === 'Booking'
                            ? () => {
                                handleHoverBooking(event.id);
                              }
                            : () => {
                                handleHoverJob(event.id);
                              }
                        }
                        onMouseLeave={
                          event.status === 'Booking'
                            ? () => handleUnhoverBooking()
                            : () => {
                                handleUnhoverJob();
                              }
                        }
                      >
                        {eventPill}
                      </div>
                    </DraggableJobWrapper>
                  );
                }
              );
            })}

            {/* Render expand buttons */}
            {rowVirtualizer.getVirtualItems().flatMap((row) => {
              if (!Object.keys(expandButtons).includes(row.index.toString())) {
                return null;
              }

              return expandButtons[row.index.toString()].map(
                ({
                  siteId,
                  columnIndex,
                  expandCount,
                  eventId,
                  isMultiDayEvent,
                }) => {
                  const isRowExpanded = isSiteExpanded(siteId);

                  return (
                    <div
                      key={`${siteId}:${columnIndex}:expand`}
                      className={classNames(
                        'flex select-none flex-col items-center justify-end text-xs'
                        // {
                        //   'justify-start': isMultiDayEvent,
                        //   'justify-end': !isMultiDayEvent,
                        // }
                      )}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: expandPillSize + jobPillMargin * 2,
                        height: jobPillHeight,
                        transform: `translateX(${
                          columnVirtualizer.getOffsetForIndex(
                            columnIndex,
                            'start'
                          )[0] +
                          columnWidth -
                          expandPillSize -
                          jobPillMargin * 2
                          // (isMultiDayEvent && !isRowExpanded ? 3 : 2) // ! This ternary is a hack
                        }px) translateY(${
                          headerHeight +
                          siteTop(siteId) +
                          bookingPillHeight +
                          jobPillMargin +
                          (isRowExpanded
                            ? isMultiDayEvent
                              ? expandCount * (jobPillHeight + jobPillMargin)
                              : 0
                            : isMultiDayEvent
                            ? expandPillSize + jobPillMargin
                            : 0)
                        }px)`,
                        zIndex: 0,
                      }}
                      onClick={
                        isRowExpanded
                          ? () => collapseSite(siteId)
                          : () => expandSite(siteId)
                      }
                      onMouseEnter={() => handleHoverJob(eventId)}
                      onMouseLeave={() => handleUnhoverJob()}
                    >
                      <div
                        className='flex cursor-pointer select-none items-center justify-center whitespace-nowrap rounded-md border-0.5 border-[#6E7979] bg-[#EBEEEE] transition-colors duration-500 hover:bg-[#C9CBCF]'
                        style={{
                          width:
                            // isMultiDayEvent && !isRowExpanded
                            false ? '100%' : expandPillSize,
                          height:
                            // isMultiDayEvent && !isRowExpanded
                            false ? '100%' : expandPillSize,
                        }}
                      >
                        {isRowExpanded ? (
                          <FontAwesomeIcon icon={faMinus} size='xs' />
                        ) : (
                          '+' + (expandCount < 100 ? ' ' + expandCount : '')
                        )}
                      </div>
                    </div>
                  );
                }
              );
            })}

            {/* Sticky Date Headers */}
            {columnVirtualizer.getVirtualItems().map((column) => {
              // const date = add(start, { days: column.index });
              // const events = findEventsForDate(date, expanded ? 4 : 2);

              return (
                <div
                  key={`date-header-index-${column.key}`}
                  className={classNames('pointer-events-none z-40')}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: totalHeight,
                    width: column.size,
                    transform: `translateX(${column.start}px)`,
                  }}
                >
                  <Title start={start} days={column.index} />
                </div>
              );
            })}

            {/* Fixed Side/Header/Area */}
            <div
              // TODO: Consider using a fixed position for the header instead of sticky
              // and having the virtualizer begin below it. This would allow hovered bookings
              // to overlap the header.
              className='sticky left-0 z-50 min-h-full border-r bg-white hover:cursor-default'
              style={{ width: sideBarWidth }}
            >
              <h2 className='sticky top-0 z-10 flex h-14 items-center border-b border-gray66 bg-white px-8 text-lg font-medium text-black'>
                {data.length} {t('site', { count: data.length })}
              </h2>
              {data.map((site, siteIndex) => (
                <div
                  key={site.id}
                  className='group relative flex items-center border-b border-gray66 px-6 duration-300'
                  style={{
                    height: siteHeight(site.id),
                  }}
                  data-siteid={site.id}
                  data-index={siteIndex}
                  ref={rowVirtualizer.measureElement}
                >
                  <Link
                    className='flex items-center gap-3 overflow-hidden'
                    to={`sites/${site.id}`}
                  >
                    <div
                      className={
                        siteHeight(site.id) > 30 ? 'h-8 w-8' : 'h-6 w-6'
                      }
                    >
                      {site.image ? (
                        <Image url={site.image} size='fit' />
                      ) : (
                        <BuildingPlaceholder className='text-lg' />
                      )}
                    </div>
                    <div
                      className={classNames(
                        'flex flex-1 flex-col overflow-hidden'
                      )}
                    >
                      <h3
                        className={
                          'truncate py-1 text-sm font-medium leading-3 text-grey-50'
                        }
                      >
                        {site.name}
                      </h3>
                      {siteHeight(site.id) > 30 && (
                        <ShortAddress
                          data={site.address}
                          className='truncate py-0.5 text-xs font-light leading-none text-grey-50'
                        />
                      )}
                    </div>
                  </Link>
                  {
                    <div
                      className={classNames(
                        'absolute right-1 top-1 flex h-4 w-4 items-center justify-center rounded-sm opacity-0 ring-1 ring-black ring-opacity-0 transition-opacity duration-300 group-hover:opacity-100',
                        'hover:cursor-pointer hover:ring-opacity-30'
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        isSiteExpanded(site.id)
                          ? setExpandedSiteIds(
                              expandedSiteIds.filter((id) => id !== site.id)
                            )
                          : setExpandedSiteIds(expandedSiteIds.concat(site.id));
                      }}
                    >
                      <FontAwesomeIcon
                        icon={
                          isSiteExpanded(site.id) ? faCompressAlt : faExpandAlt
                        }
                        size='xs'
                        color='#80858E'
                      />
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>

          {/* Expand/Collapse View Button */}
          <div
            className='absolute bottom-8 left-0 right-0 z-50 mx-auto flex w-fit flex-row gap-2 rounded-full bg-black px-3 py-1.5 text-white transition-all hover:cursor-pointer'
            onClick={() => {
              expandedSiteIds.length > 0
                ? setExpandedSiteIds([])
                : setExpandedSiteIds(data.map(({ id: siteId }) => siteId));
            }}
          >
            {expandedSiteIds.length > 0 ? (
              <>
                {condensedViewIcon}
                <div>Collapse View</div>
              </>
            ) : (
              <>
                {expandedViewIcon}
                <div>Expand View</div>
              </>
            )}
          </div>
        </div>
        {children}
      </div>
    </DndProvider>
  );
}

export function ScheduleEventPill({
  event: {
    status,
    attributes,
    summary,
    start: startDate,
    end: endDate,
    scheduledStartTime,
    scheduledEndTime,
    assignee,
  },
  height,
  width,
  zoomLevel,
  absolutePosition = true,
  showDates = false,
  selected = false,
}: {
  event: {
    summary: string;
    status: string;
    attributes?: any[] | null;
    assignee?: {
      name: string;
      image?: string | null;
    } | null;
    scheduledStartTime?: any;
    scheduledEndTime?: any;
    start: any;
    end?: any;
  };
  height: React.CSSProperties['height'];
  width: React.CSSProperties['width'];
  zoomLevel?: ZoomLevel;
  absolutePosition?: boolean;
  showDates?: boolean;
  selected?: boolean;
}) {
  const { t } = useTranslation(['translation', 'job']);

  const formatDate = (datestr: string) => {
    return format(new Date(datestr), 'eee P', { locale: enAU });
  };

  const scheduleInfoBuilder: string[] = [];

  // Construct the schedule info string
  if (scheduledStartTime) {
    scheduleInfoBuilder.push(formatTime(scheduledStartTime));
    if (showDates) {
      scheduleInfoBuilder.push('on');
    }
  }
  if (showDates) {
    scheduleInfoBuilder.push(formatDate(startDate));
  }
  if ((showDates && endDate) || (scheduledStartTime && scheduledEndTime)) {
    scheduleInfoBuilder.push('to');
  }
  if (scheduledEndTime) {
    scheduleInfoBuilder.push(formatTime(scheduledEndTime));
    if (showDates && endDate) {
      scheduleInfoBuilder.push('on');
    }
  }
  if (showDates && endDate) {
    scheduleInfoBuilder.push(formatDate(endDate));
  }

  const scheduleInfo: ReactNode = scheduleInfoBuilder
    .filter((s) => s !== null)
    .join(' ');

  const isMultiDay =
    endDate && !isSameDay(new Date(startDate), new Date(endDate));

  return (
    <div
      className={classNames(
        // If the event has a colored attribute, use that, otherwise use the status color

        getAttributeColors(attributes) || getEventColors(status),
        'group flex flex-col items-stretch justify-between overflow-hidden text-xs leading-5',
        status === 'Booking'
          ? height === 0
            ? 'hidden'
            : '-translate-y-1.5 scale-y-45 rounded-full px-2 transition-transform duration-300 hover:-translate-y-2.5 hover:scale-y-100 hover:shadow-md'
          : zoomLevel
          ? {
              'rounded-md transition-height': true, // Any non-booking event
              'pt-0.5': zoomLevel !== 'month', // Not smallest
              'px-[3px] pb-px pt-[2px]': zoomLevel === 'month', // Smallest size
              'px-[4px]': zoomLevel === 'default', // Middle size
              'px-[5px]': zoomLevel === 'week', // Biggest size
            }
          : 'rounded-md px-2 transition-height', // Any non-booking event with no zoom level
        selected && 'ring-2 ring-brand-lighter'
      )}
      style={{
        position: absolutePosition ? 'absolute' : 'unset',
        // transitionProperty: 'height, scale, translate',
        top: 0,
        left: 0,
        height: height,
        // the length of the event in days determines its width
        width: width,
      }}
    >
      <div>
        {status === 'Booking' ? (
          <div
            className={classNames(
              'flex w-full items-center justify-between opacity-0 transition-opacity duration-300 group-hover:opacity-100'
            )}
          >
            <div className='overflow-hidden whitespace-nowrap'>
              <FontAwesomeIcon icon={faPlaneArrival} />{' '}
              {formatTime(scheduledStartTime)}
            </div>
            <p>{summary}</p>
            <p className='overflow-hidden whitespace-nowrap'>
              {formatTime(scheduledEndTime)}{' '}
              <FontAwesomeIcon icon={faPlaneDeparture} />
            </p>
          </div>
        ) : (
          <>
            <div className='flex justify-between'>
              <p
                className={classNames(
                  'truncate font-medium',
                  assignee && zoomLevel !== 'month' && 'pr-3'
                )}
              >
                {summary}
              </p>
              {assignee && (
                <div className={classNames('h-5 w-5 flex-shrink-0')}>
                  <Avatar
                    name={assignee?.name}
                    image={assignee?.image}
                    size={'full-small-text'}
                    bg={'white'}
                  />
                </div>
              )}
            </div>
            <div className='flex justify-between pt-0.5'>
              <div>{scheduleInfo}</div>
              <div
                className={classNames('mt-auto w-fit whitespace-nowrap', {
                  hidden: zoomLevel === 'month' && !isMultiDay,
                })}
              >
                {t('job:' + status)}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function Title({ start, days }: { start: Date; days: number }) {
  const date = add(start, { days });
  return (
    <div className='sticky top-0 flex h-14 flex-col items-center justify-center border-b border-gray66 bg-white'>
      <p>{format(date, 'EEEEEE dd')}</p>
      <p className='text-xs font-light text-grey-50'>{format(date, 'MMM')}</p>
    </div>
  );
}

function getAttributeColors(attributes?: Attribute[] | null) {
  const owner = attributes?.find((a) => a.name === 'Owner');
  if (owner?.value) {
    return 'bg-gray-500 text-white';
  }
}

export function getEventColors(status: string) {
  switch (status) {
    case 'Booking':
      return 'bg-[#298888] text-white';
    case 'Active':
      return 'bg-status-active border-0.5 border-status-stroke-accepted text-white';
    case 'Inactive':
    case 'Incomplete':
      return 'bg-status-inactive text-white';
    case 'Complete':
      return 'bg-status-complete border-0.5 border-status-stroke-completed text-white';
    case 'InProgress':
      return 'bg-status-inprogress border-0.5 border-status-stroke-inprogress text-white';
    case 'Accepted':
      return 'bg-status-accepted border-0.5 border-status-stroke-accepted text-white';
    case 'Cancelled':
      return 'bg-status-cancelled border-0.5 border-status-stroke-cancelled text-white';
    case 'Offered':
      return 'bg-status-offered border-0.5 border-status-stroke-offered text-white';
    default:
      return 'bg-status-created border-0.5 border-status-stroke-created text-gray-900';
  }
}

// Draggables and droppables insist on being their own components
// See this discussion: https://github.com/react-dnd/react-dnd/issues/1494#issuecomment-768965896

const DraggableJobWrapper = ({
  children,
  preview,
  eventDetails,
  onDragBegin,
  onDragEnd,
}: {
  children: ReactNode;
  preview: ReactNode;
  eventDetails: {
    id: string;
    status: string;
    siteId: string;
    scheduledStart: Date;
    days: number;
    jobName: string;
    assigneeName: string;
  };
  onDragBegin?: () => void;
  onDragEnd?: () => void;
}) => {
  const [collectedProps, drag] = useDrag({
    type: 'job',
    item: () => {
      // "When `item` is a function, it is fired at the beginning of the drag operation"
      // https://react-dnd.github.io/react-dnd/docs/api/use-drag
      onDragBegin && onDragBegin();

      return {
        eventId: eventDetails.id,
        siteId: eventDetails.siteId,
        scheduledStart: eventDetails.scheduledStart,
        days: eventDetails.days,
        jobPreview: preview,
        status: eventDetails.status,
        jobName: eventDetails.jobName,
        assigneeName: eventDetails.assigneeName,
      };
    },
    end: (_, monitor) => {
      // "For every begin call, a corresponding end call is guaranteed"
      onDragEnd && onDragEnd();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (_) => {
      // Only allow dragging of Draft, Offered and Accepted jobs
      return (
        eventDetails.status === 'Created' ||
        eventDetails.status === 'Offered' ||
        eventDetails.status === 'Accepted'
      );
    },
  });

  return (
    <div
      key={eventDetails.id}
      ref={drag}
      className={classNames('transition-opacity', {
        'opacity-50': collectedProps.isDragging,
      })}
    >
      {children}
    </div>
  );
};

const JobDropZone = ({
  children,
  siteId,
  date,
  previewTop,
  onDrop,
  onNonDraftDrop,
}: {
  children: ReactNode;
  siteId: string;
  date: Date;
  previewTop?: (days: number) => number;
  onDrop?: (jobId: string, dayOffset: number) => void;
  onNonDraftDrop?: (
    jobId: string,
    dayOffset: number,
    jobStatus: JobStatus,
    jobName: string,
    assigneeName: string
  ) => void;
}) => {
  const [{ days, isOver, canDrop, jobPreview }, drop] = useDrop({
    accept: 'job',
    drop: (item, _) => {
      const { eventId, scheduledStart, status, jobName, assigneeName } =
        item as {
          eventId: string;
          scheduledStart: Date;
          status: string;
          jobName: string;
          assigneeName: string;
        };
      const dayOffset = differenceInCalendarDays(date, scheduledStart);

      if (status === 'Created') {
        onDrop && onDrop(eventId, dayOffset);
      } else if (status === 'Offered' || status === 'Accepted') {
        const jobStatus = JobStatus[status as keyof typeof JobStatus];
        onNonDraftDrop &&
          onNonDraftDrop(eventId, dayOffset, jobStatus, jobName, assigneeName);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      days: (monitor.getItem() as { days: number } | undefined)?.days,
      jobPreview: (monitor.getItem() as { jobPreview: ReactNode } | undefined)
        ?.jobPreview,
    }),
    canDrop: (item, monitor) => {
      // HACK: is there a better way to do this without a type assertion?
      return (item as { siteId: string })?.siteId === siteId;
    },
  });

  return (
    <div className={'relative'} ref={drop}>
      {children}
      {canDrop && previewTop && days && (
        <div
          className={classNames('absolute transition-opacity', {
            'opacity-0': !isOver,
          })}
          style={{
            top: previewTop(days),
          }}
        >
          {jobPreview}
        </div>
      )}
    </div>
  );
};

const RescheduleJobDialog = ({
  isOpen,
  onClose,
  onSubmit,
  jobName,
  assigneeName,
  action,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    status: JobStatus,
    options: { notify: boolean; comment: string | undefined }
  ) => void;
  jobName: string;
  assigneeName: string;
  action: 'offer' | 'confirm';
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [notify, setNotify] = useState(true);

  const submitButtonLabel = action + ' job';

  return (
    <Dialog title='Almost there!' show={isOpen} onClose={onClose}>
      <div className='flex flex-col flex-wrap gap-3 px-5'>
        <p className='px-2 pt-3'>
          You are about to {action} the job{' '}
          <span className='font-semibold'>{jobName}</span>{' '}
          {action === 'offer' ? 'to' : 'for'}{' '}
          <span className='font-semibold'>{assigneeName}</span>
        </p>
        <Switch
          label='Notify assignees'
          checked={notify}
          onChange={setNotify}
        />
        <textarea
          ref={inputRef}
          className={classNames(
            'input mt-1 w-full resize-none rounded-md border border-grey-20 px-3 py-2.5 focus-visible:border-brand focus-visible:outline-none',
            !notify && 'hidden'
          )}
          placeholder='Add optional message...'
          autoFocus
        ></textarea>
        <div className='flex gap-3 self-end pb-5 pt-1'>
          <button
            className={classNames(
              'px-4 py-2 text-sm font-medium uppercase text-brand-dark transition-all duration-500 hover:text-brand'
            )}
            onClick={onClose}
          >
            Go back
          </button>
          <Button
            type='button'
            size='small'
            onClick={() => {
              action === 'confirm'
                ? onSubmit(JobStatus.Accepted, {
                    notify,
                    comment: inputRef.current?.value,
                  })
                : onSubmit(JobStatus.Offered, {
                    notify,
                    comment: inputRef.current?.value,
                  });
            }}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
