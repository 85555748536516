import { createContext, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { useJob } from '~/components/job/JobView';
import { AttributeTask } from '~/components/job/tasks/AttributeTask';
import { Custom } from '~/components/job/tasks/Custom';
import { MoveStock } from '~/components/job/tasks/MoveStock';
import { PurchaseTask } from '~/components/job/tasks/PurchaseTask';
import { Stocktake } from '~/components/job/tasks/Stocktake';
import { ErrorMessage } from '~/components/ui/Error';
import { JobStatus } from '~/generated/graphql';
import { graphql } from '~/gql';
import { SideLayout } from '~/layouts/side/SideLayout';

type Job = NonNullable<ReturnType<typeof useJob>>['job'];

const TaskDocument = graphql(`
  query Task($id: ID!) {
    task(id: $id) {
      id
      required
      type
      status
      name
      description
      tags {
        nodes {
          id
          entityType
          name
          category
          description
          colour
        }
      }
      attributes {
        id
        type
        category
        name
        value
        attachments {
          id
          kind
          ext
          thumb
          url
          originalFilename
        }
      }
      jobAttributeIds
      taskAttributes {
        id
        value
      }
      notes
      attachments {
        ...AttachmentFields
      }
      config {
        selection
        spaces
        assetTypes
        allow
      }
      assets {
        id
        name
        image
        type
        model {
          id
          displayName
        }
        deployment {
          id
          image
          location {
            id
            name
          }
          space {
            id
            name
          }
        }
      }
      purchase {
        id
        spaces
        movements {
          id
          qty
        }
      }
      stocktake {
        id
        include
        itemStatus
        spaces
        items {
          id
          adjustment
          newOnHand
        }
      }
      stockTransfer {
        id
        rule
        items {
          id
          spaceId
          skuId
          pick
          picked
          packed
          dispatched
          received
          putAway
        }
      }
      transfer {
        available {
          id
          qty
        }
        moved {
          id
          qty
        }
        stockOnHand {
          # ...spaceSkuData
          id
          reserved
          soh
          target
          capacity
          reorderLevel
          space {
            id
            name
            location {
              __typename
              id
              name
            }
            attributes {
              name
              value
            }
          }
          sku {
            id
            code
            name
            itemType
            image
            attributes {
              name
              value
            }
          }
        }
      }
      config {
        selection
        spaces
        assetTypes
        allow
      }
      ...AttributeTask
    }
  }
`);

type Context = {
  revalidate: () => void;
};
export const TaskViewContext = createContext({} as Context);

export const useTaskViewContext = () => useContext(TaskViewContext);

export const TaskView = () => {
  const { taskId } = useParams();
  const { job, onSuccess } = useJob() as NonNullable<ReturnType<typeof useJob>>;

  const [result, reexecuteQuery] = useQuery({
    query: TaskDocument,
    variables: { id: taskId! },
    requestPolicy: 'cache-and-network',
  });

  const task = result.data?.task;
  const revalidate = () => reexecuteQuery({ requestPolicy: 'network-only' });
  const navigate = useNavigate();
  const { hash } = useLocation();
  const messagesOpen = hash === '#messages-open' ? '#messages-open' : '';

  const canComplete = job.status === JobStatus.InProgress;

  const handleClose = () => navigate(`..${messagesOpen}`);
  const handleSuccess = () => {
    onSuccess();
    handleClose();
  };

  const handleReset = () => {
    !result.fetching && revalidate();
  };

  if (result.fetching) {
    return <Placeholder id={taskId} job={job} />;
  }

  if (!task) {
    return <ErrorMessage />;
  }

  if (task.type === 'Attribute') {
    return (
      <AttributeTask
        enabled={canComplete}
        job={job}
        task={task}
        onReset={handleReset}
        onSuccess={handleSuccess}
        onClose={handleClose}
      />
    );
  }

  if (task.purchase) {
    return (
      <PurchaseTask
        enabled={canComplete}
        job={job}
        task={task}
        onSuccess={handleSuccess}
        onClose={handleClose}
      />
    );
  }

  if (task.stocktake) {
    return (
      <Stocktake
        enabled={canComplete}
        job={job}
        task={task}
        onSuccess={handleSuccess}
        onClose={handleClose}
      />
    );
  }

  if (task.stockTransfer) {
    return (
      <MoveStock
        enabled={true}
        job={job}
        onSuccess={handleSuccess}
        onClose={handleClose}
      />
    );
  }

  return (
    <TaskViewContext.Provider value={{ revalidate }}>
      <Custom
        job={job}
        task={task}
        onClose={handleClose}
        onSuccess={handleSuccess}
        enabled={canComplete}
      />
    </TaskViewContext.Provider>
  );
};

const Placeholder = ({ id, job }: { id: string | undefined; job: Job }) => {
  const task = job.tasks.find((t) => t.id === id);
  return (
    <SideLayout>
      <SideLayout.Head onClose={() => {}}>{task?.name}</SideLayout.Head>
      <SideLayout.Body>&nbsp;</SideLayout.Body>
      <SideLayout.Foot className='p-4'>&nbsp;</SideLayout.Foot>
    </SideLayout>
  );
};
