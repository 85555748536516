import { useCallback, useEffect, useRef, useState } from 'react';
import {
  FieldValues,
  Path,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import { TaskInput } from '~/generated/graphql';
import { JobTemplateFieldsFragment } from '~/gql/graphql';

type TemplateManagerProps<T extends FieldValues = any> = {
  form: {
    watch: UseFormWatch<T>;
    setValue: UseFormSetValue<T>;
    trigger: UseFormTrigger<T>;
    getValues: UseFormGetValues<T>;
  };
  taskState: [TaskInput[], React.Dispatch<React.SetStateAction<TaskInput[]>>];
  isEditMode?: boolean;
  taskConverter?: (templateTask: any) => TaskInput;
};

export const useTemplateManager = <T extends FieldValues>({
  form,
  taskState,
  isEditMode = false,
  taskConverter,
}: TemplateManagerProps<T>) => {
  const { watch, setValue, trigger } = form;
  const [tasks, setTasks] = taskState;
  const [selectedTemplate, setSelectedTemplate] =
    useState<JobTemplateFieldsFragment | null>(null);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [templateTagIds, setTemplateTagIds] = useState<string[]>([]);
  const [templateAttributeIds, setTemplateAttributeIds] = useState<string[]>(
    []
  );
  const [templateId, setTemplateId] = useState<string | null>(null);
  const [initialName, setInitialName] = useState<string>('');
  const [initialTags, setInitialTags] = useState<string[]>([]);
  const [initialAttributes, setInitialAttributes] = useState<string[]>([]);

  // Use refs instead of state for tracking initial values
  const initialValuesRef = useRef({
    name: '',
    tags: [] as string[],
    attributes: [] as string[],
    notes: '',
  });

  const resetInitialValues = useCallback(() => {
    const watchFn = form.watch;
    const currentName = watchFn('name' as Path<T>) || '';
    const currentTags = (watchFn('tags' as Path<T>) || []) as string[];
    const currentAttributes = (watchFn('includeAttributes' as Path<T>) ||
      []) as string[];
    const currentNotes = watchFn('notes' as Path<T>) || '';

    initialValuesRef.current = {
      name: currentName,
      tags: Array.isArray(currentTags) ? [...currentTags] : [],
      attributes: Array.isArray(currentAttributes)
        ? [...currentAttributes]
        : [],
      notes: currentNotes,
    };
  }, [form]);

  // Compute active tasks count on demand
  const getActiveTasksCount = () => {
    return tasks.filter((task) => !task._destroy).length;
  };
  const hasExistingData = () => {
    const currentName = watch('name' as Path<T>);

    // Get active tasks count
    const currentTasks = getActiveTasksCount() > 0;

    return Boolean(currentTasks);
  };

  const arraysEqual = (arr1: any[] = [], arr2: any[] = []) => {
    if (arr1?.length !== arr2?.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };

  const isTemplateModified = (template: JobTemplateFieldsFragment) => {
    const currentName = watch('name' as any);
    const currentNotes = watch('notes' as any);
    const currentTags: string[] = (watch('tags' as any) || []) as string[];
    const currentAttributes: string[] = (watch('includeAttributes' as any) ||
      []) as string[];

    // Get active tasks count
    const currentTaskCount = getActiveTasksCount();

    const templateName = template.name
      .replace(/\s*template[-_\s]*\S*/gi, '')
      .trim();
    const templateNotes = template.notes || '';
    const templateTags = (template.tags?.map(({ id }) => id) || []) as string[];
    const templateAttributes = (template.includeAttributes || []) as string[];
    const templateTasks = template.tasks || [];
    const templateTaskCount = templateTasks?.length ?? 0;

    // In edit mode, we need different comparison logic
    if (isEditMode) {
      // Check if all template tags are still present
      const allTemplateTagsPresent =
        templateTags.length === 0
          ? true
          : templateTags.every(
              (tagId: string) =>
                Array.isArray(currentTags) && currentTags.includes(tagId)
            );

      // Check if all template attributes are still present
      const allTemplateAttributesPresent =
        templateAttributes.length === 0
          ? true
          : templateAttributes.every(
              (attrId: string) =>
                Array.isArray(currentAttributes) &&
                currentAttributes.includes(attrId)
            );

      // Check if tasks match the template (using count as a simple heuristic)
      const tasksModified = currentTaskCount !== templateTaskCount;

      // In edit mode, consider template modified if tags/attributes were removed or tasks modified
      const isModified =
        !allTemplateTagsPresent ||
        !allTemplateAttributesPresent ||
        tasksModified;

      return isModified;
    } else {
      // Create mode logic remains the same
      const isModified =
        currentName !== templateName ||
        currentNotes !== templateNotes ||
        !arraysEqual(currentTags, templateTags) ||
        !arraysEqual(currentAttributes, templateAttributes) ||
        currentTaskCount !== templateTaskCount;

      return isModified;
    }
  };

  const applyTemplate = async (template: JobTemplateFieldsFragment) => {
    try {
      // Make sure to call resetInitialValues before applying the template
      resetInitialValues();

      const currentName = watch('name' as any) || '';

      // Only update the name if it's empty - consistent in both create and edit modes
      if (!currentName) {
        // Clean template name
        const cleanTemplateName = template.name
          .replace(/\s*template[-_\s]*\S*/gi, '')
          .trim();

        setValue('name' as any, cleanTemplateName as any);
      }

      if (template.tags) {
        // Track the tag IDs from the template for potential removal later
        const newTemplateTagIds = template.tags.map(({ id }) => id);
        setTemplateTagIds(newTemplateTagIds);

        // Get current tags
        const currentTags = watch('tags' as any) || [];

        // Always combine existing tags with template tags (no duplicates)

        if (Array.isArray(currentTags)) {
          const combinedTags = [
            ...new Set([...currentTags, ...newTemplateTagIds]),
          ];
          setValue('tags' as any, combinedTags as any);
        } else {
          setValue('tags' as any, newTemplateTagIds as any);
        }
      }

      if (template.notes) {
        setValue('notes' as any, template.notes as any);
      }

      if (template.includeAttributes) {
        // Track the attribute IDs from the template for potential removal later
        const newTemplateAttributeIds = [...template.includeAttributes];
        setTemplateAttributeIds(newTemplateAttributeIds);

        // Get current attributes
        const currentAttributes = watch('includeAttributes' as any) || [];

        // Always combine existing attributes with template attributes (no duplicates)
        if (Array.isArray(currentAttributes)) {
          const combinedAttributes = [
            ...new Set([...currentAttributes, ...newTemplateAttributeIds]),
          ];
          setValue('includeAttributes' as any, combinedAttributes as any);
        } else {
          setValue('includeAttributes' as any, newTemplateAttributeIds as any);
        }
      }

      // Handle tasks
      const [_, setTasks] = taskState;
      if (template.tasks && template.tasks.length > 0 && taskConverter) {
        // Create tasks with unique IDs
        const newTemplateId = `template-${Date.now()}`;
        setTemplateId(newTemplateId);

        const newTasks = template.tasks.map((templateTask, index) => {
          // Use the provided taskConverter function
          const task = taskConverter(templateTask);
          return { ...task, id: `${newTemplateId}-task-${index}` };
        });

        setTasks(newTasks);
      }

      // Trigger validation after setting values
      await trigger(['name'] as any);
      setSelectedTemplate(template);
    } catch (error) {
      toast.error('Failed to apply template. Please try again.');
    }
  };

  const removeTemplate = () => {
    const currentName = watch('name' as any) || '';
    const templateName =
      selectedTemplate?.name?.replace(/\s*template[-_\s]*\S*/gi, '').trim() ||
      '';

    if (currentName === templateName) {
      setValue('name' as any, initialValuesRef.current.name as any);
    }

    // Use ref values for tags
    const currentTags = (watch('tags' as any) || []) as string[];
    if (Array.isArray(currentTags)) {
      const baseTags = [...initialValuesRef.current.tags];
      const nonTemplateTags = currentTags.filter(
        (tagId: string) => !templateTagIds.includes(tagId)
      );
      const finalTags = [...new Set([...baseTags, ...nonTemplateTags])];
      setValue('tags' as any, finalTags as any);
    }

    // Use ref values for attributes
    const currentAttributes = (watch('includeAttributes' as any) ||
      []) as string[];
    if (Array.isArray(currentAttributes)) {
      const baseAttributes = [...initialValuesRef.current.attributes];
      const nonTemplateAttributes = currentAttributes.filter(
        (attrId: string) => !templateAttributeIds.includes(attrId)
      );
      const finalAttributes = [
        ...new Set([...baseAttributes, ...nonTemplateAttributes]),
      ];
      setValue('includeAttributes' as any, finalAttributes as any);
    }

    // Only reset notes if they match the template notes
    const currentNotes = watch('notes' as any) || '';
    const templateNotes = selectedTemplate?.notes || '';

    if (currentNotes === templateNotes) {
      // If notes are still the template notes, restore to initial notes
      setValue('notes' as any, initialValuesRef.current.notes as any);
    } else {
      // If user has modified the notes, keep their changes
      // Do nothing here - keep the current notes
    }

    // Handle tasks differently based on mode
    if (isEditMode) {
      // In edit mode, we should mark tasks as _destroy: true rather than filtering them out
      const [_, setTasksFn] = taskState;
      setTasksFn((prevTasks) => {
        const updatedTasks = prevTasks.map((task) => ({
          ...task,
          _destroy: true,
        }));
        return updatedTasks;
      });
    } else {
      // In create mode, we can simply remove all tasks
      const [_, setTasksFn] = taskState;
      setTasksFn([]);
    }

    setTemplateTagIds([]); // Clear the tracked template tags
    setTemplateAttributeIds([]); // Clear the tracked template attributes
    setSelectedTemplate(null);
    setShowRemoveConfirmation(false);
    resetInitialValues();
  };

  // Add this back, using a one-time initialization
  useEffect(() => {
    // Only initialize on first mount
    resetInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    selectedTemplate,
    showRemoveConfirmation,
    setShowRemoveConfirmation,
    hasExistingData,
    isTemplateModified,
    applyTemplate,
    removeTemplate,
    getActiveTasksCount,
    resetInitialValues,
  };
};
