import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { IconButton } from '~/components/ui/nucleus/Button/IconButton';

export const handleSupportMessengerOpen = () => {
  window.location.href = 'https://support.prop.fit/hc/en-us';
};

export const SupportLink = () => {
  const { t } = useTranslation();

  return (
    <a
      href='https://support.prop.fit/hc/en-us'
      target='_blank'
      rel='noopener noreferrer'
      className='flex flex-row items-center justify-start gap-3 py-4 text-secondary hover:text-brand lg:gap-0 lg:py-0 lg:text-base'
    >
      <IconButton
        //@ts-ignore
        icon={faCircleQuestion}
        size='sm'
        className='hidden text-tertiary lg:block'
      />
      <IconButton
        //@ts-ignore
        icon={faCircleQuestion}
        size='md'
        className='text-tertiary lg:hidden'
      />
      <span className='block lg:hidden'>{t('support')}</span>
    </a>
  );
};
