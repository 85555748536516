import { faGears } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VariantProps, cva } from 'class-variance-authority';
import { useContext, useState } from 'react';
import { AppContext } from '~/App';
import { initials } from '~/helpers/initials';
import { Image } from './Image';
const avatarStyles = cva(
  'aspect-square flex shrink-0 items-center justify-center overflow-hidden text-center font-medium no-underline text-secondary',
  {
    variants: {
      size: {
        small: 'h-6 w-6 text-[11px]',
        medium: 'h-6 w-6 lg:h-8 lg:w-8',
        large: 'h-7 w-7 lg:h-9 lg:w-9',
        xl: 'h-10 w-10 lg:h-12 lg:w-12',
        full: '',
        'full-small-text': 'text-[12px]',
      },
      square: {
        // This should be changed in a further iteration to move text settings away from "square" option as that is not clear.
        true: 'bg-shade-10 text-shade-50 text-lg lg:text-xl',
        false: 'rounded-full',
      },
      bg: {
        white: 'bg-white',
        grey: 'bg-grey-20',
      },
    },
    defaultVariants: {
      size: 'medium',
      square: false,
      bg: 'grey',
    },
    // This should also be changed in a further iteration to move text settings away from "square" option as that is not clear.
    compoundVariants: [
      {
        size: ['full', 'medium', 'large'],
        square: false,
        className: 'text-xs lg:text-sm',
      },
    ],
  }
);

type Props = VariantProps<typeof avatarStyles> & {
  /**
   * A name to be turned into initials
   */
  name?: string;
  /**
   * URL to a profile picture
   */
  image?: string | null | undefined;
  automation?: boolean;
  className?: string;
};

export const CurrentAvatar = (props: Props) => {
  const { user: currentUser } = useContext(AppContext);
  return (
    <Avatar name={currentUser?.name} image={currentUser?.image} {...props} />
  );
};

/**
 * Display an image or initials representing a person
 */
export const Avatar = ({
  name,
  image,
  className,
  automation = false,
  square,
  size,
  bg,
}: Props) => {
  const avatarImage = getImage(automation, image, name);

  return (
    <abbr
      className={avatarStyles({ square, size, className, bg })}
      style={{ textDecoration: 'none' }}
      title={name ?? 'Unassigned'}
    >
      {avatarImage}
    </abbr>
  );
};

function getImage(
  automation: boolean | null,
  image: string | null | undefined,
  name: string | undefined
) {
  const [imageLoaded, setImageLoaded] = useState(true);

  if (automation) {
    return (
      <FontAwesomeIcon
        icon={faGears}
        className='h-full w-full p-1.5 text-primary'
      />
    );
  } else if (image && imageLoaded) {
    return (
      <Image
        url={image}
        size='fit'
        borders={false}
        onImageLoad={() => setImageLoaded(true)}
        onImageError={() => setImageLoaded(false)}
      />
    );
  } else if (name || (name && !imageLoaded)) {
    return initials(name).toUpperCase();
  } else return 'U';
}
