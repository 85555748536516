import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '~/components/form/SubmitButton';
import { Dialog } from '~/components/ui/Dialog';
import { Switch } from '~/components/ui/nucleus/Switch';
import { SubmitOptions } from '.';

export type ConfirmFn = (submitOptions: SubmitOptions) => void;

type Props = {
  children: React.ReactNode;
  open: boolean;
  loading: boolean;
  showNotifyOptions: boolean;
  submitButtonLabel?: string;
  onBack: () => void;
  onConfirm: ConfirmFn;
};

export function Confirmation({
  children,
  open,
  loading,
  showNotifyOptions,
  submitButtonLabel = 'Go Ahead',
  onBack,
  onConfirm,
}: Props) {
  const { t } = useTranslation();
  const [notify, setNotify] = useState<boolean>(true);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  return (
    <Dialog
      title='Bulk Change Warning!'
      show={open}
      onClose={() => {
        if (loading) return;
        onBack();
      }}
    >
      <div className='flex flex-col flex-wrap gap-3 px-5'>
        <p className='px-2 pt-3'>{children}</p>
        {showNotifyOptions && (
          <>
            <Switch
              label='Notify assignees'
              checked={notify}
              onChange={setNotify}
            />
            <textarea
              ref={inputRef}
              className={classNames(
                'input mt-1 w-full resize-none rounded-md border border-grey-20 px-3 py-2.5 focus-visible:border-brand focus-visible:outline-none',
                !notify && 'hidden'
              )}
              placeholder='Add optional message...'
              autoFocus
            ></textarea>
          </>
        )}
        <div className='flex gap-3 self-end pb-5 pt-1'>
          <button
            className={classNames(
              'px-4 py-2 text-sm font-medium uppercase text-brand-dark transition-all duration-500 hover:text-brand',
              {
                'text-grey-40 hover:text-grey-40': loading,
              }
            )}
            onClick={onBack}
            disabled={loading}
          >
            Go back
          </button>
          <Button
            type='button'
            loading={loading}
            size='small'
            onClick={() => {
              onConfirm(
                showNotifyOptions
                  ? {
                      notify: notify,
                      comment: inputRef.current?.value,
                      //   sendMeACopy: false,
                      //   includePdf: false,
                    }
                  : {
                      notify: false,
                    }
              );
            }}
          >
            {loading ? t('loading') : submitButtonLabel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
