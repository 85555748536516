import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { TopBar } from '~/components/ui/TopBar';
import { useSiteTypesQuery } from '~/generated/graphql';
import { useLocalStorage } from '~/hooks';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useSiteListContext } from '~/routes/sites';
import { SubNav } from './SubNav';

export const SitesNav = () => {
  const localStorage = useLocalStorage();
  const { t } = useTranslation();
  const { view, setView } = useSiteListContext();
  const location = useLocation();
  const { isMobile } = useBreakpoint();

  const [result] = useSiteTypesQuery({
    variables: { preset: true },
    requestPolicy: 'cache-and-network',
  });

  const { data, fetching } = result;

  // SubNav component compatible data structure
  const visibleItems = [...(data?.siteTypes ?? []), 'Other'].map((type) => ({
    type,
    title: `${type}s`,
    url: `/sites`,
  }));

  useEffect(() => {
    if (!fetching && !view && visibleItems.length > 0) {
      try {
        // Add null check before accessing localStorage
        const savedView = localStorage?.get('nav.sites');
        setView(savedView ?? visibleItems[0].type);
      } catch (error) {
        console.error('Error accessing localStorage:', error);
        // Fallback to first item if localStorage fails
        setView(visibleItems[0].type);
      }
    }
  }, [fetching, visibleItems, view, setView, localStorage]);

  // Create menu items for site types - with null checks
  const siteTypeMenuItems = visibleItems.map((item) => ({
    key: `site-type-${item.type}`,
    label: item.title,
    isActive: item.type === view,
    onClick: () => {
      setView(item.type);
      try {
        // Add null check before setting localStorage
        if (localStorage) {
          localStorage.set('nav.sites', item.type);
        }
      } catch (error) {
        console.error('Error setting localStorage:', error);
      }
    },
  }));

  // Get the current title based on the selected view
  const getTitle = () => {
    if (view) {
      return `${view}s`;
    }
    return 'Sites';
  };

  if (isMobile) {
    if (!data || data.siteTypes.length === 0) {
      return <TopBar title={getTitle()} />;
    }
    return <TopBar title={getTitle()} menuItems={siteTypeMenuItems} />;
  }

  if (!data || data.siteTypes.length === 0) {
    return null;
  }

  return (
    <div className='z-50 flex items-center justify-between'>
      <SubNav
        pages={visibleItems}
        isActive={(key) => key === view + 's'}
        onItemClick={({ type }) => {
          // @ts-expect-error Fix type definition - needs generic support for additional properties
          setView(type);
          try {
            // Add null check before setting localStorage
            if (localStorage) {
              // @ts-expect-error Fix type definition - needs generic support for additional properties
              localStorage.set('nav.sites', type);
            }
          } catch (error) {
            console.error('Error setting localStorage:', error);
          }
        }}
      />
      <div>
        <Link className='add-button' to='new'>
          + {t('site')}
        </Link>
      </div>
    </div>
  );
};
