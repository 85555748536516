import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { useAppContext } from '~/App';
import { UploadResult } from '~/components/upload2';
import { ControlledUpload } from '~/components/upload2/Upload';
import { useUpdateAccountProfileMutation } from '~/generated/graphql';
import { PageProps } from '../account';

type FormData = {
  logoUpload: UploadResult[];
};

type Props = PageProps;

export const UpdateAccountPicForm = ({ setShowPage }: Props) => {
  const { tenant, revalidate } = useAppContext();
  invariant(tenant);
  const [, updateAccountProfile] = useUpdateAccountProfileMutation();

  // Convert tenant logo/image to UploadResult format if available
  const initialLogoUpload: UploadResult[] =
    tenant.logo && tenant.image
      ? [
          {
            id: tenant.logo,
            preview: tenant.image,
            name: tenant.name || 'Organization logo',
            kind: 'image',
          },
        ]
      : [];

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      logoUpload: initialLogoUpload,
    },
  });

  const submit = async (values: FormData) => {
    // Extract the logo ID from the upload results
    const logo =
      values.logoUpload && values.logoUpload.length > 0
        ? values.logoUpload[0].id
        : null;

    const result = await updateAccountProfile({
      input: {
        logo,
        name: tenant.name || 'My Organization',
      },
    });

    if (result.error) {
      toast.error('Something went wrong. Please try again');
      return;
    }

    toast.success('Profile picture updated successfully');
    setTimeout(async () => {
      await revalidate();
      setShowPage(null);
    }, 1000);
  };

  return (
    <div className='flex w-full flex-1 flex-col'>
      <form
        onSubmit={handleSubmit(submit)}
        className='flex w-full grow flex-col'
      >
        <div className='mb-4'>
          <Controller
            control={control}
            name='logoUpload'
            render={({ field }) => (
              <ControlledUpload
                id='organization-logo-upload'
                value={field.value || []}
                onChange={(newUploads: UploadResult[]) => {
                  // Always use only the most recent upload (replacing any previous ones)
                  field.onChange(
                    newUploads.length > 0
                      ? [newUploads[newUploads.length - 1]]
                      : []
                  );
                }}
              />
            )}
          />
        </div>
        <div className='mt-auto'>
          <button
            type='submit'
            className='w-full rounded-lg bg-brand p-3 font-semibold uppercase text-white transition-all duration-300 hover:bg-slate-dark disabled:opacity-50'
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};
