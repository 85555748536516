import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { faFilter as faFilterSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { ControlledKeywordSearch } from '../ControlledKeywordSearch';
import { Filter, FilterGroup } from './FilterGroup';
import { SortedBy } from './sortBy';

type Value = {
  searchValue: string;
  filtersValue: URLSearchParams;
  placeholder?: string;
};

type Props = {
  filters: Filter[];
  value: Value;
  onChange: (value: Value) => void;
  placement?: 'portal';
  placeholder?: string;

  /**
   * Keyword search is currently broken on Activity view so have added this option to hide it
   */
  hideKeywordSearch?: boolean;

  // Add sort options
  sortOptions?: Array<{ key: SortedBy; name: string }>;
  sortBy?: SortedBy;
  onSortChange?: (value: SortedBy) => void;
};

export function FilterGroupSearch({
  filters,
  value,
  onChange,
  placement,
  hideKeywordSearch,
  sortOptions,
  sortBy,
  onSortChange,
}: Props) {
  const [showFilters, setShowFilters] = useState(false);
  const { isMobile } = useBreakpoint();

  // Material Design animation constants
  const filterAnimations = {
    initial: {
      opacity: 0,
      y: -20,
      scale: 0.95,
      transformOrigin: 'top center',
    },
    animate: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.25,
        ease: [0.4, 0.0, 0.2, 1], // Material standard easing
      },
    },
    exit: {
      opacity: 0,
      y: -10,
      scale: 0.98,
      transition: {
        duration: 0.2,
        ease: [0.4, 0.0, 1, 1], // Material acceleration easing
      },
    },
  };

  return (
    <div className='w-full max-w-full overflow-hidden bg-primary-container pb-3 lg:flex lg:w-auto lg:items-center lg:justify-start lg:gap-4 lg:bg-white lg:px-4 lg:py-[7px]'>
      {hideKeywordSearch ? (
        <div className='mx-3 flex items-center justify-end lg:mx-0 lg:w-12'>
          {isMobile && (
            <FilterToggleButton
              filtersValue={value.filtersValue}
              onToggle={() => setShowFilters(!showFilters)}
              isOpen={showFilters}
            />
          )}
        </div>
      ) : (
        <>
          <div className='mx-3 mb-1 flex gap-2 lg:mb-0'>
            <ControlledKeywordSearch
              value={value.searchValue}
              onChange={(query) => onChange({ ...value, searchValue: query })}
            />
            {isMobile && (
              <FilterToggleButton
                filtersValue={value.filtersValue}
                onToggle={() => setShowFilters(!showFilters)}
                isOpen={showFilters}
              />
            )}
          </div>
        </>
      )}

      <AnimatePresence mode='wait'>
        {(showFilters || !isMobile) && (
          <motion.div
            className='z-10 ml-1 w-full'
            initial={isMobile ? filterAnimations.initial : undefined}
            animate={isMobile ? filterAnimations.animate : undefined}
            exit={isMobile ? filterAnimations.exit : undefined}
          >
            <FilterGroup
              filters={filters}
              value={value.filtersValue}
              onChange={(filters) => {
                // Simply pass the filters up to the parent component
                onChange({ ...value, filtersValue: filters });
              }}
              placement={placement}
              sortByOptions={sortOptions}
              sortBy={sortBy}
              sortByOnChange={onSortChange}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export function FilterToggleButton({
  filtersValue,
  onToggle,
  isOpen,
}: {
  filtersValue: URLSearchParams;
  onToggle: () => void;
  isOpen: boolean;
}) {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const currentView = pathParts[2] || '';
  const isScheduledView = currentView === 'scheduled';
  const isCompletedView = currentView === 'completed';

  // Count active filters (excluding internal flags)
  let activeFilterCount = 0;
  let hasScheduledDateFilter = false;
  let hasCompletedDateFilter = false;

  for (const [key, value] of filtersValue.entries()) {
    // Skip internal flags and empty values
    if (key.startsWith('_') || !value || value === '') {
      continue;
    }

    // Check for date filters to count them as a single filter per view
    if (key === 'scheduledDateAfter' || key === 'scheduledDateBefore') {
      hasScheduledDateFilter = true;
      continue;
    }

    if (key === 'completedDateAfter' || key === 'completedDateBefore') {
      hasCompletedDateFilter = true;
      continue;
    }

    // Count all other filters normally
    activeFilterCount++;
  }

  // Add date filters to the count, but only for the current view
  if (hasScheduledDateFilter && isScheduledView) activeFilterCount++;
  if (hasCompletedDateFilter && isCompletedView) activeFilterCount++;

  const hasActiveFilters = activeFilterCount > 0;

  return (
    <div
      className='relative flex h-[42px] w-[42px] cursor-pointer items-center justify-center rounded-full bg-white bg-opacity-20'
      onClick={onToggle}
    >
      <FontAwesomeIcon
        // @ts-ignore
        icon={hasActiveFilters ? faFilterSolid : faFilter}
        className='h-5 w-5 text-white'
      />

      {(isOpen || hasActiveFilters) && (
        <div className='absolute bottom-1.5 right-1.5 flex h-[17px] w-[17px] items-center justify-center rounded-full border border-primary-container border-opacity-80 bg-white text-primary-container'>
          {isOpen ? (
            <span className='text-xs font-bold'>-</span>
          ) : (
            <span className='text-xs'>{activeFilterCount}</span>
          )}
        </div>
      )}
    </div>
  );
}
