import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from 'urql';
import { MultiActionButton } from '~/components/form/MultiActionButton';
import { Button } from '~/components/form/SubmitButton';
import { Input } from '~/components/form/TextField';
import { TimePicker } from '~/components/form/TimePicker';
import { Switch } from '~/components/ui/nucleus/Switch';
import { graphql } from '~/gql';
import { JobStatus } from '~/gql/graphql';
import { SideLayout } from '~/layouts/side/SideLayout';
import { ConfirmFn, Confirmation } from '../SaveJobActions';

type Props = {
  selected: string[];
  onCancel: () => void;
  onSuccess: () => void;
};

type Value = {
  checked: boolean;
  value: string;
};

type FormValues = {
  scheduledStartDate: Value;
  scheduledStartTime: Value;
  scheduledEndDate: Value;
  scheduledEndTime: Value;
};

const RescheduleJobs_Mutation = graphql(`
  mutation RescheduleJobs($input: RescheduleJobsInput!) {
    rescheduleJobs(input: $input) {
      id
      status
      scheduleStart
      scheduleEnd
      scheduledStartStrict
      scheduledStartDate
      scheduledStartTime
      scheduledEndStrict
      scheduledEndDate
      scheduledEndTime
      completedAt
      permissions
    }
  }
`);

export function BulkReschedule({ selected, onCancel, onSuccess }: Props) {
  const { t } = useTranslation();
  const [result, rescheduleJobs] = useMutation(RescheduleJobs_Mutation);
  const {
    formState: { isDirty, errors, isSubmitting },
    control,
    register,
    handleSubmit,
    watch,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      scheduledStartDate: {
        checked: false,
        value: '',
      },
      scheduledStartTime: {
        checked: false,
        value: '',
      },
      scheduledEndDate: {
        checked: false,
        value: '',
      },
      scheduledEndTime: {
        checked: false,
        value: '',
      },
    },
  });

  // Confirmation dialog state
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [action, setAction] = useState<'offer' | 'confirm' | null>(null);

  useEffect(() => {
    if (result.error) {
      toast.error(result.error.message.replace('[GraphQL] ', ''));
      setIsOpen(false);
      return;
    }
    if (result.data) onSuccess();
  }, [result, onSuccess]);

  const confirm = (a: typeof action) => {
    setAction(a);
    setIsOpen(true);
  };

  const startDateChecked = watch('scheduledStartDate.checked');
  const startTimeChecked = watch('scheduledStartTime.checked');
  const endDateChecked = watch('scheduledEndDate.checked');
  const endTimeChecked = watch('scheduledEndTime.checked');

  const values = watch();

  const loading = result.fetching;
  const hasSelected = Object.values(values).some(
    (val) => val.checked && val.value
  );

  const submit: ConfirmFn = ({ notify, comment }) => {
    // TODO should utilise `handleSubmit`

    rescheduleJobs({
      input: {
        jobIds: selected,
        startDate: values.scheduledStartDate.checked
          ? values.scheduledStartDate.value || null
          : undefined,
        startTime: values.scheduledStartTime.checked
          ? values.scheduledStartTime.value || null
          : undefined,
        endDate: values.scheduledEndDate.checked
          ? values.scheduledEndDate.value || null
          : undefined,
        endTime: values.scheduledEndTime.checked
          ? values.scheduledEndTime.value || null
          : undefined,
        status:
          action === 'offer'
            ? JobStatus.Offered
            : action === 'confirm'
            ? JobStatus.Accepted
            : undefined,
        notify: notify,
        comment: comment,
      },
    });
  };

  return (
    <>
      <Confirmation
        open={isOpen}
        loading={loading}
        showNotifyOptions={action !== null}
        onBack={() => setIsOpen(false)}
        onConfirm={submit}
      >
        You are about to {action ?? 'save'}{' '}
        <span className='font-semibold'>{selected.length}</span> jobs.{' '}
      </Confirmation>
      <SideLayout>
        <SideLayout.Head iconClass='chevron-left' onClose={onCancel}>
          Bulk Change
        </SideLayout.Head>
        <SideLayout.Body>
          <div className='flex w-full flex-col items-stretch gap-2 text-left'>
            <div className='flex flex-col'>
              <Controller
                control={control}
                name='scheduledStartDate.checked'
                render={({ field: { value, onChange } }) => (
                  <Switch
                    label='Change Scheduled Start Date'
                    checked={value}
                    onChange={(checked) => onChange(checked)}
                  />
                )}
              />
              <div
                className={classNames('w-1/2', {
                  'opacity-60': !startDateChecked,
                })}
              >
                <Input
                  {...register('scheduledStartDate.value')}
                  label=''
                  type='date'
                  disabled={!startDateChecked}
                />
              </div>
            </div>
            <div className='flex flex-col'>
              <Controller
                control={control}
                name='scheduledStartTime.checked'
                render={({ field: { value, onChange } }) => (
                  <Switch
                    label='Change Scheduled Start Time'
                    checked={value}
                    onChange={(checked) => onChange(checked)}
                  />
                )}
              />
              <Controller
                control={control}
                name='scheduledStartTime.value'
                render={({ field: { value, onChange } }) => (
                  <div
                    className={classNames('flex items-center gap-3', {
                      'opacity-80': !startTimeChecked,
                    })}
                  >
                    <TimePicker
                      label=''
                      focusValue='09:00'
                      value={value}
                      onChange={onChange}
                      disabled={!startTimeChecked}
                    />
                    <button
                      className='mb-3 text-grey-30'
                      onClick={() => {
                        setValue('scheduledStartTime.value', '');
                      }}
                      disabled={!startTimeChecked}
                    >
                      <FontAwesomeIcon className='h-5 w-5' icon={faTrashAlt} />
                    </button>
                  </div>
                )}
              />
            </div>

            <div className='flex flex-col'>
              <Controller
                control={control}
                name='scheduledEndDate.checked'
                render={({ field: { value, onChange } }) => (
                  <Switch
                    label='Change Scheduled End Date'
                    checked={value}
                    onChange={(checked) => onChange(checked)}
                  />
                )}
              />
              <div
                className={classNames('w-1/2', {
                  'opacity-60': !endDateChecked,
                })}
              >
                <Input
                  {...register('scheduledEndDate.value')}
                  label=''
                  type='date'
                  disabled={!endDateChecked}
                />
              </div>
            </div>
            <div className='flex flex-col'>
              <Controller
                control={control}
                name='scheduledEndTime.checked'
                render={({ field: { value, onChange } }) => (
                  <Switch
                    label='Change Scheduled End Time'
                    checked={value}
                    onChange={(checked) => onChange(checked)}
                  />
                )}
              />
              <Controller
                control={control}
                name='scheduledEndTime.value'
                render={({ field: { value, onChange } }) => (
                  <div
                    className={classNames('flex items-center gap-3', {
                      'opacity-80': !endTimeChecked,
                    })}
                  >
                    <TimePicker
                      label=''
                      focusValue='09:00'
                      value={value}
                      onChange={onChange}
                      disabled={!endTimeChecked}
                    />
                    <button
                      className='mb-3 text-grey-30'
                      onClick={() => {
                        setValue('scheduledEndTime.value', '');
                      }}
                      disabled={!endTimeChecked}
                    >
                      <FontAwesomeIcon className='h-5 w-5' icon={faTrashAlt} />
                    </button>
                  </div>
                )}
              />
            </div>
          </div>
        </SideLayout.Body>
        <SideLayout.Foot className='p-4'>
          <MultiActionButton
            options={[
              {
                label: 'Offer Jobs',
                onClick: () => confirm('offer'),
                disabled: !hasSelected,
              },
              {
                label: 'Confirm Jobs',
                onClick: () => confirm('confirm'),
                disabled: !hasSelected,
              },
            ]}
          />
          <Button
            type='button'
            intent='secondary'
            onClick={() => confirm(null)}
            disabled={!hasSelected}
          >
            Save
          </Button>
        </SideLayout.Foot>
      </SideLayout>
    </>
  );
}
